import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import pluralize from 'pluralize'

import { useState } from 'react'
import { ApiCallContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ApiCall/context/ApiCallContext'
import { Chip, ContentGroup, InfoRow, TextInput } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowAssignUser } from '../../../InfoRowAssignUser'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useIsPermitted } from 'utils/hooks'

export const ApiCallStepDetails: React.FC = () => {
  const { isPermitted } = useIsPermitted()
  const {
    dataSubjectVariablesCount,
    error,
    formDataCount,
    identitiesCount,
    isCallbackErrored,
    isWebHookErrored,
    outcomeVariablesCount,
    submitNotes,
    timelineIssues,
    timelineIssuesCount,
    webhook,
    webhookContext,
  } = useContext(ApiCallContext)

  const { workflowExecutionId, stepId, stepDetails, workflowStep } = useContext(
    RightInvocationWorkflowStepDetailViewContext,
  )
  const isNotComplete = workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS
  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)

  const [notes, setNotes] = useState(webhookContext?.notes)
  const [isEditing, setIsEditing] = useState(false)
  const [previousValue, setPreviousValue] = useState(notes)
  const [isLoading, setIsLoading] = useState(false)
  const errorDetails = (
    <Box>
      {isWebHookErrored ? (
        <Box display="flex" flexDirection="column">
          <Typography color="error.main" variant="label">
            Error with webhook
          </Typography>
          <Typography sx={{ mt: 0.25 }}>
            {error?.webhookDetails?.statusCode
              ? `Status code: ${error?.webhookDetails?.statusCode}`
              : `${webhook?.name || 'Webhook'} is not responding.`}
          </Typography>
        </Box>
      ) : null}

      {isCallbackErrored ? (
        <>
          <Box display="flex" flexDirection="column">
            <Typography color="error.main" variant="label">
              Can't connect to a callback.
            </Typography>
            <Typography sx={{ mt: 0.25 }}>
              {error?.webhookDetails?.statusCode
                ? `Status code: ${error?.webhookDetails?.statusCode}`
                : 'Callback is not responding.'}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  )

  const successDetails = (
    <Box display="flex" flexDirection="column" gap={1}>
      {!!dataSubjectVariablesCount && (
        <Box>
          <Typography>
            {`${dataSubjectVariablesCount} Data Subject ${pluralize(
              'Variable',
              dataSubjectVariablesCount || 0,
            )} received`}
          </Typography>
        </Box>
      )}

      {!!outcomeVariablesCount && (
        <>
          <Box>
            <Typography>
              {`${outcomeVariablesCount} Outcome ${pluralize('Variable', outcomeVariablesCount || 0)} received`}
            </Typography>
          </Box>
        </>
      )}

      {!!identitiesCount && (
        <>
          <Box>
            <Typography>
              {`${identitiesCount} Identity ${pluralize('Space', identitiesCount || 0)} received`}
            </Typography>
          </Box>
        </>
      )}

      {!!!dataSubjectVariablesCount && !identitiesCount && (
        <>
          <Box>
            <Typography>No Data Subject Variables or Identifiers received.</Typography>
          </Box>
        </>
      )}

      {!!formDataCount && (
        <Box>
          <Typography>{`${formDataCount} Form ${pluralize('Field', formDataCount || 0)} received`}</Typography>
        </Box>
      )}
    </Box>
  )

  const issuesDetails = (
    <Box display="flex" flexDirection="column" gap={1}>
      {!!timelineIssuesCount && (
        <Box>
          <Typography variant="label" color="chileanFire.main">
            {`${timelineIssuesCount} ${pluralize('Issue', timelineIssuesCount || 0)}`}:
          </Typography>
        </Box>
      )}
      {!!timelineIssues.invalidDataSubjectVariables && (
        <Box>
          <Typography>
            {`${timelineIssues.invalidDataSubjectVariables} Invalid Data Subject ${pluralize(
              'Value',
              timelineIssues.invalidDataSubjectVariables || 0,
            )}`}
          </Typography>
        </Box>
      )}

      {!!timelineIssues.unknownDataSubjectVariables && (
        <Box>
          <Typography>{`${timelineIssues.unknownDataSubjectVariables} Unknown Data Subject ${pluralize(
            'Variable',
            timelineIssues.unknownDataSubjectVariables || 0,
          )}`}</Typography>
        </Box>
      )}

      {!!timelineIssues.unknownIdentities && (
        <Box>
          <Typography>
            {`${timelineIssues.unknownIdentities} Unknown Identity ${pluralize(
              'Space',
              timelineIssues.unknownIdentities || 0,
            )}`}
          </Typography>
        </Box>
      )}

      {!!timelineIssues.invalidFormData && (
        <Box>
          <Typography>
            {`${timelineIssues.invalidFormData} Invalid Form ${pluralize(
              'Field',
              timelineIssues.invalidFormData || 0,
            )}`}
          </Typography>
        </Box>
      )}

      {!!timelineIssues.unknownFormData && (
        <Box>
          <Typography>
            {`${timelineIssues.unknownFormData} Unknown Form ${pluralize(
              'Field',
              timelineIssues.unknownFormData || 0,
            )}`}
          </Typography>
        </Box>
      )}

      {!!timelineIssues.invalidOutcomeVariables && (
        <Box>
          <Typography>
            {`${timelineIssues.invalidOutcomeVariables} Invalid Outcome ${pluralize(
              'Variable',
              timelineIssues.invalidOutcomeVariables || 0,
            )}`}
          </Typography>
        </Box>
      )}

      {!!timelineIssues.unknownOutcomeVariables && (
        <Box>
          <Typography>
            {`${timelineIssues.unknownOutcomeVariables} Unknown Outcome ${pluralize(
              'Variable',
              timelineIssues.unknownOutcomeVariables || 0,
            )}`}
          </Typography>
        </Box>
      )}
    </Box>
  )

  const details = error ? errorDetails : timelineIssuesCount ? issuesDetails : successDetails

  const handleOnSubmitCallback = () => {
    setIsLoading(false)
  }
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ContentGroup variant="inner-page-header" titleVariant="h3" title="Details" />
      <InfoRow title="Status Details">{details || <EmptyValueRenderer />}</InfoRow>
      <InfoRow title="Webhook">
        {webhook && (webhook.name || webhookContext?.webhook?.timeout) ? (
          `${webhook.name ? `${webhook.name}` : ''}${
            webhookContext?.webhook?.timeout ? `, Timeout: ${webhookContext?.webhook?.timeout} minutes` : ''
          }`
        ) : (
          <EmptyValueRenderer />
        )}
        {webhook?.testMode ? <Chip label="Test Mode" size="small" sx={{ ml: 2 }}></Chip> : null}
      </InfoRow>

      <InfoRow
        title="Notes"
        isEditable
        isEditing={isEditing}
        isLoading={isLoading}
        onEditChange={() => {
          setIsEditing(true)
          setPreviousValue(notes)
        }}
        onAcceptChange={() => {
          setIsEditing(false)
          setIsLoading(true)
          submitNotes(notes || '', handleOnSubmitCallback)
        }}
        onCancelChange={() => {
          setIsEditing(false)
          setNotes(previousValue)
        }}
        isEmpty={!notes}
      >
        {!isEditing ? (
          <Box display="flex" justifyContent="space-between" width="100%">
            {notes || <EmptyValueRenderer />}
          </Box>
        ) : (
          <Box display="flex" width="100%" flexDirection="column">
            <TextInput
              sx={{
                '& .MuiFormHelperText-root': {
                  color: theme => `${theme.palette.chileanFire.main} !important`,
                },
              }}
              autoFocus
              placeholder="Add Notes"
              multiline
              fullWidth
              value={notes}
              onChange={e => setNotes(e.currentTarget.value)}
              variant="ghost"
            />
          </Box>
        )}
      </InfoRow>

      {stepDetails.assignee ? (
        <InfoRowAssignUser
          infoRowTitle="Assignee"
          assigneeInfo={stepDetails?.assigneeInfo || null}
          assignee={stepDetails.assignee}
          canEdit={canReassign && isNotComplete}
          isComplete={isNotComplete}
          workflowExecutionId={workflowExecutionId}
          workflowStepId={stepId}
        />
      ) : null}
    </Box>
  )
}
