import React from 'react'
import Box from '@mui/material/Box'
import {
  Card,
  CircularProgress,
  CircularProgressSize,
  Icon,
  ListItem,
  Status,
  StatusState,
  IconMap,
  StatusVariant,
  Chip,
  AvatarStack,
  Avatar,
  AvatarSize,
} from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/styles'

const STATUS_ARRAY = [
  { label: '4 connections', icon: 'OConnectionTrue' },
  { label: 'Consent' },
  { label: 'DSR' },
  { label: 'Preferences' },
  { label: 'Data Discovery' },
  { label: 'Tag Orchestration' },
  { label: 'Identity Verification' },
]

const StyledCircularProgress = styled(CircularProgress)(() => ({
  backgroundColor: '#FEEBD9',
  padding: '4px',
  borderRadius: '4px',
  color: '#F97700',
}))

const AvatarCardContent = () => {
  return (
    <>
      <ListItem divider endIcon={<Icon name="OArrowCRight" />} title="5 connections" />
      <Box display="flex" flexDirection="column">
        {STATUS_ARRAY.map(status => (
          <Status
            label={status.label}
            status={StatusState.success}
            variant={StatusVariant.ghost}
            icon={(status.icon as keyof typeof IconMap) || 'FCheckRound'}
          />
        ))}
      </Box>
    </>
  )
}

const MediaCardContent = () => {
  return (
    <>
      <Box>
        <Typography variant="smallLabel" mb="4px" display="block" color={theme => theme.palette.Text.Secondary}>
          Status
        </Typography>
        <StyledCircularProgress
          variant="determinate"
          value={75}
          label="70% (26/32)"
          size={CircularProgressSize.large}
        />
      </Box>
      <Box>
        <Typography variant="smallLabel" mb="4px" display="block" color={theme => theme.palette.Text.Secondary}>
          Template
        </Typography>
        <Chip label="General DPIA 2023" sx={{ marginTop: '4px' }} size="small" />
      </Box>
      <Box>
        <Typography variant="smallLabel" mb="4px" display="block" color={theme => theme.palette.Text.Secondary}>
          Assignees
        </Typography>
        <AvatarStack>
          {Array.from({ length: 6 }, (v, i) => i).map(num => (
            <Avatar size={AvatarSize.medium} src={`https://i.pravatar.cc/150?img=${num + 1}`} />
          ))}
        </AvatarStack>
      </Box>
    </>
  )
}

const LeftContent = () => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Status label="Database" status={StatusState.draft} variant={StatusVariant.ghost} icon="Database" />
      <Box display="flex" alignItems="center" gap="4px">
        <Avatar src="https://cdn.uat.ketchjs.com/locker/v1/assets/logos/apple.png" isLogo />
        <Typography variant="smallLabel">Apple</Typography>
      </Box>
      <Chip label="XS Chip" size="small" />
      <Chip label="XS Chip" size="small" />
    </Box>
  )
}

const RightContent = () => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <Typography variant="smallBody" color={theme => theme.palette.Text.Secondary}>
        Data Owner
      </Typography>
      <Typography variant="smallBody">Max Anderson</Typography>
    </Box>
  )
}

const FullwidthCardContent = () => {
  return (
    <Box display="flex" gap="16px">
      {Array.from({ length: 4 }, (v, i) => (
        <Card key={i} cardVariant="small" cardTitle={`#${i + 1}`} subTitle="Item desc" />
      ))}
    </Box>
  )
}

const FullWidthSimpleCardContent = () => {
  return (
    <Box display="flex" gap="16px">
      {Array.from({ length: 4 }, (v, i) => (
        <Card key={i} cardVariant="small" cardTitle={`#${i + 1}`} subTitle="Item desc" />
      ))}
    </Box>
  )
}

export const CardExamples: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={2} sx={{ backgroundColor: 'lightgray' }}>
      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Snapshot
        </Typography>
        <Box display="flex" gap="16px">
          {Array.from(Array(4)).map((v, i) => (
            <Card cardTitle={`Snapshot #${i + 1}`} subTitle="Subtitle" key={i} />
          ))}
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Card is numerical
        </Typography>
        <Box display="flex" gap="16px">
          {Array.from(Array(4)).map((v, i) => (
            <Card cardTitle={`${(i + 1) * 10}`} subTitle="Subtitle" key={i} isNumerical />
          ))}
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Operator
        </Typography>
        <Box display="flex" gap="16px">
          {Array.from(Array(2)).map((v, i) => (
            <Card
              cardTitle={`Operator #${i + 1}`}
              key={i}
              cardVariant="operator"
              subTitle="Enabled on all properties (5)."
              subTitleWhenToggleOff="Off"
              subTitleLink="Customize"
              maxCardWidth="300px"
            />
          ))}
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Primary Action
        </Typography>
        <Box display="flex" gap="16px">
          {Array.from(Array(2)).map((v, i) => (
            <Card cardTitle={`Action #${i + 1}`} key={i} cardVariant="fixedWidth" isClickable />
          ))}
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Secondary Action
        </Typography>
        <Box display="flex" gap="16px">
          <Card
            isClickable
            cardTitle="Scanning..."
            secondaryIcon={<CircularProgress size={CircularProgressSize.large} />}
            primaryIcon="OArrowCRight"
            textColor="#2A49E0"
            backgroundColor="rgba(87, 116, 255, 0.3)"
            onClick={() => alert('Secondary Action')}
          />
          <Card
            isClickable
            isNumerical
            cardTitle="14"
            subTitle="Needs Review"
            secondaryIcon="OImportant"
            primaryIcon="OArrowCRight"
            textColor="#F97700"
            backgroundColor="#FEEBD9"
            onClick={() => alert('Secondary Action')}
          />
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Avatar Card
        </Typography>
        <Box display="flex" gap="16px">
          <Card
            cardVariant="avatar"
            cardTitle="All caught up"
            avatarComponent={
              <Avatar
                src="https://cdn.uat.ketchjs.com/locker/v1/assets/logos/aarki.svg"
                variant="rounded"
                borderColor="darkGrey"
                isLogo
                size={AvatarSize.xlarge}
              />
            }
            moreMenuItems={[{ label: 'Option 1' }, { label: 'Option 2' }]}
            footerChips={[{ label: 'PII' }, { label: 'Sensitive' }]}
            cardContent={<AvatarCardContent />}
          />
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Media Card
        </Typography>
        <Box display="flex" gap="16px">
          <Card
            cardVariant="media"
            cardTitle="Super long title wrapping demo, yeah it can be very long"
            moreMenuItems={[{ label: 'Option 1' }, { label: 'Option 2' }]}
            footerText="Last Updated Jul 21 2022, 12:34 PM"
            cardContent={<MediaCardContent />}
          />
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Fullwidth Card
        </Typography>
        <Box display="flex" gap="16px">
          <Card
            cardVariant="fullwidth"
            title="All caught up"
            subTitle="No new trackers discovered."
            moreMenuItems={[{ label: 'Option 1' }, { label: 'Option 2' }]}
            fullWidthHeaderLeftContent={<LeftContent />}
            fullWidthHeaderRightContent={<RightContent />}
            cardContent={<FullwidthCardContent />}
          />
        </Box>
      </Box>

      <Box width="100%" padding="16px">
        <Typography variant="h3" marginBottom={2}>
          Fullwidth Card Simple
        </Typography>
        <Box display="flex" gap="16px">
          <Card
            cardVariant="fullwidth"
            title="All caught up"
            subTitle="No new trackers discovered."
            moreMenuItems={[{ label: 'Option 1' }, { label: 'Option 2' }]}
            moreMenuIcon="OArrowCRight"
            cardContent={<FullWidthSimpleCardContent />}
          />
        </Box>
      </Box>
    </Box>
  )
}
