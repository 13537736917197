import React from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Box, Chip } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { Link } from 'components/ui-kit/link/Link'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as WidgetIcon } from 'assets/icons/unutilizedData.svg'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { getRiskScoreDetails } from 'pages/insights/utils'
import { assets } from 'pages/insights/dataRisks/__mocks__/unutilizedDataAssets'
import { getMockAccessIndex } from 'pages/insights/dataRisks/__mocks__/utils'
// Mock API for Pagination replace with actual API when available
import { usePermitsStatsPerIdentitySpace } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpace'

const PREFIX = 'AccessAndUsageRisksUnutilizedData'

const classes = {
  accessAndUsageRisksUnutilizedDataContainer: `${PREFIX}-accessAndUsageRisksUnutilizedDataContainer`,
  rowClassName: `${PREFIX}-rowClassName`,
  assetIcon: `${PREFIX}-assetIcon`,
  tableHoverState: `${PREFIX}-tableHoverState`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.accessAndUsageRisksUnutilizedDataContainer}`]: {
    flex: '0 1 460px',
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    padding: spacing(3),
  },

  [`& .${classes.rowClassName}`]: {
    minHeight: 52,
  },

  [`& .${classes.assetIcon}`]: {
    height: 18,
    width: 18,
    marginRight: spacing(1.5),
  },

  [`& .${classes.tableHoverState}`]: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

//TODO: Real Data and Pagination
export const AccessAndUsageRisksUnutilizedData: React.FC = () => {
  const navigate = useNavigate()

  const { data, isLoading, pagination } = usePermitsStatsPerIdentitySpace({
    params: {
      endDate: '2022-07-31',
      startDate: '2022-07-24',
    },
  }) // Replace hook appropriate api call when available

  const rows = assets.sort((a, b) => (a.dataRiskScore < b.dataRiskScore ? 1 : -1))
  return (
    <StyledBox className={classes.accessAndUsageRisksUnutilizedDataContainer}>
      <Link variant="buttonWrap" to={RoutesManager.insights.dataRisks.unutilizedData.root.getURL()}>
        <Box display="flex" alignItems="center" gap={1.5} mb={3} className={classes.tableHoverState}>
          <WidgetIcon width={48} height={48} />
          <Box display="flex" flexDirection="column" flex={1}>
            <Text size={20} weight={700} mb={1.25}>
              Unutilized Data
            </Text>
            <Text size={14} color="darkDuskFaded">
              Data assets that contain personal or sensitive data which are not actively used.
            </Text>
          </Box>
        </Box>
      </Link>
      <TablePaginated
        variant="page"
        isSmallHeaderText
        rowClassName={classes.rowClassName}
        pagination={pagination}
        pending={isLoading}
        items={data}
        onRowClick={(_, index) => {
          const asset = rows?.[getMockAccessIndex(index, pagination)]
          const targetPath = RoutesManager.insights.dataRisks.unutilizedData.view.root.getURL({ code: asset.id })
          navigate(targetPath)
        }}
        cellSettings={{
          name: {
            label: 'Name',
            width: 296,
            cellRenderer: (_, index) => {
              const displayName = rows?.[getMockAccessIndex(index, pagination)]?.name
              const canonicalResourceType = rows?.[getMockAccessIndex(index, pagination)]?.canonicalResourceType
              return (
                <Box display="flex" alignItems="center">
                  <AssetTypeIcon assetType={canonicalResourceType || 0} className={classes.assetIcon} />
                  <Text size={14} lineHeight={2.5}>
                    {displayName}
                  </Text>
                </Box>
              )
            },
          },
          riskScore: {
            width: 110,
            label: 'Risk Score',
            cellRenderer: (_, index) => {
              const dataRiskScore = rows?.[getMockAccessIndex(index, pagination)]?.dataRiskScore
              const riskScoreDetails = getRiskScoreDetails(dataRiskScore)
              return (
                <Chip
                  label={`${dataRiskScore} ${riskScoreDetails.category}`}
                  sx={{
                    color: riskScoreDetails.textColor,
                    backgroundColor: riskScoreDetails.bgColor,
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                  size="small"
                />
              )
            },
          },
        }}
      />
    </StyledBox>
  )
}
