import { Box, styled, Tooltip, Typography } from '@mui/material'
import { Drawer } from 'components/ui-kit/drawer/Drawer'
import pluralize from 'pluralize'
import React, { useMemo, useState } from 'react'
import { useTagsList } from '../context/TagsContext'
import { BulkUpdateTagPurposesModal } from './BulkUpdateTagPurposesModal'
import { BulkUpdateTagStatusModal } from './BulkUpdateTagStatusModal'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Button, Chip, DataGrid, Icon, TableCell, TextInput, theme } from '@ketch-com/deck'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { TagPurposesMultiSelectChip } from './TagPurposesMultiSelectChip'
import { TagStatusUpdateCell } from './TagStatusUpdateCell'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { SourceType } from '../utils/enums'
import debounce from 'lodash/debounce'

export type Props = {
  open: boolean
  onClose: () => void
}

const BulkActionsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  height: '40px',
  minHeight: '40px',
  background: theme.palette.Black.o8,
  borderRadius: '11px',
  marginTop: '8px',
})

const BulkActionsLeftBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const NewTagsDrawer: React.FC<Props> = ({ open, onClose }) => {
  const {
    newTags,
    newWebTagsPagination: pagination,
    newTagsLogoUrls: logoUrls,
    bulkUpdateTagIds,
    setBulkUpdateTagIds,
    tagsSummaryData,
    isTagsWriter,
    isNewWebTagsListLoading: isLoading,
    newTagsQuery,
    setNewTagsQuery,
  } = useTagsList()
  const [showBulkUpdateTagPurposeModal, setShowBulkUpdateTagPurposeModal] = useState(false)
  const [showBulkUpdateTagStatusModal, setShowBulkUpdateTagStatusModal] = useState(false)
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState(newTagsQuery)

  const debouncedSetNewTagsQuery = useMemo(
    () =>
      debounce((query: string) => {
        setNewTagsQuery(query)
      }, 250),
    [setNewTagsQuery],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value
    setSearchQuery(query)
    debouncedSetNewTagsQuery(query)
  }

  const showBulkActions = selectedTagIds.length > 0

  const columns = useMemo(() => {
    const isIssues = newTags.some(item => !!item.hasIssues)

    return [
      ...(isIssues
        ? [
            {
              field: 'issues',
              headerName: 'Issues',
              width: 35,
              renderCell: (params: GridRenderCellParams) => (
                <TableCell>
                  <IssuesCellRenderer hasIssues={params.row.hasIssues} entityType={ApplicationEntity.WEBTAG} />
                </TableCell>
              ),
              sortable: false,
              renderHeader: () => null,
            },
          ]
        : []),
      {
        field: 'nameAndCode',
        headerName: 'Tag',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell justifyContent="space-between" width="100%">
            <NameAndCodeCellRenderer name={params.row.name} code={params.row.code} />
            {params.row.paused && (
              <Tooltip title={`This tag is paused in ${params.row?.appName}`} placement="bottom-start">
                <span>
                  <Chip label="Paused" size="small" />
                </span>
              </Tooltip>
            )}
          </TableCell>
        ),
        sortable: false,
        width: 280,
      },
      {
        field: 'source',
        headerName: 'Source Connection',
        renderCell: (params: GridRenderCellParams) => {
          // Find the app (system) logo URL for this tag
          const isPropertyTag = params.row.sourceType === SourceType.Property
          const logoUrl = logoUrls[params.row.appCode]

          const title = isPropertyTag ? params.row?.propertyName : params.row?.appInstanceName
          const description = isPropertyTag ? 'Property' : params.row?.appName

          return (
            <TableCell>
              {title || description ? (
                <Box display="flex" flexDirection="column">
                  <Typography>{title}</Typography>
                  <Box display="flex" alignItems="center" gap="4px">
                    {logoUrl && <img src={logoUrl} alt={`${params.row.appCode} logo`} width={20} height={20} />}
                    <Typography variant="footnote" color={theme.palette.Text.Primary}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <EmptyValueRenderer />
              )}
            </TableCell>
          )
        },
        sortable: false,
        width: 250,
      },
      {
        field: 'purposeDependencies',
        headerName: 'Purposes',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <TagPurposesMultiSelectChip
              tag={params.row}
              purposes={params.row.purposes}
              advancedConsentModeEnabled={params.row.advancedConsentModeEnabled}
            />
          </TableCell>
        ),
        sortable: false,
        width: 220,
      },
      {
        field: 'status',
        headerName: 'Orchestration Status',
        renderCell: (params: GridRenderCellParams) => {
          // Status change for this tag is disabled if:
          // - User does not have the tags writer permission
          // - There are no purposes mapped or Built-in consent checks is enabled
          const disabled =
            !isTagsWriter ||
            !params.row?.purposes ||
            !params.row?.purposes.length ||
            params.row.advancedConsentModeEnabled
          return <TagStatusUpdateCell tag={params.row} disabled={disabled} />
        },
        sortable: false,
        width: 200,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Update',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params.row?.metadata?.updatedAt} />
          </TableCell>
        ),
        sortable: false,
        width: 200,
      },
    ]
  }, [newTags, logoUrls, isTagsWriter])

  // Pagination
  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )
  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )
  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const handleBulkUpdatePurposeClick = () => {
    // Set the tags IDs which the bulk update purpose modal will use
    setBulkUpdateTagIds(selectedTagIds)
    setShowBulkUpdateTagPurposeModal(true)
  }

  const handleBulkChangeStatusClick = () => {
    // Set the tags IDs which the bulk update status modal will use
    setBulkUpdateTagIds(selectedTagIds)
    setShowBulkUpdateTagStatusModal(true)
  }

  const handleClearClick = () => setSelectedTagIds([])

  const handleClearSearch = () => {
    setSearchQuery('')
    debouncedSetNewTagsQuery('')
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          height: '90%',
          maxWidth: '1300px',
          margin: 'auto', // Centered
          padding: '32px',
          borderRadius: '37px 37px 0px 0px',
          overflowY: 'scroll',
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="16px">
        <Box>
          <Typography variant="h2">New Tags Found</Typography>
          <Typography component="p" variant="label" mt="16px">
            {tagsSummaryData?.newTags} new {pluralize('tag', tagsSummaryData?.newTags)} found.
          </Typography>
          <Typography component="p" variant="body">
            Please review the list below, map purposes and enable consent.
          </Typography>
        </Box>

        <Box>
          <TextInput
            placeholder="Search"
            startIcon={<Icon name="OMag" />}
            size="small"
            inputWidth="250px"
            fullWidth={false}
            onChange={handleSearchChange}
            value={searchQuery}
            endIcon={searchQuery && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={handleClearSearch} />}
          />
        </Box>

        {/* Bulk actions */}
        {showBulkActions && (
          <BulkActionsBox>
            <BulkActionsLeftBox>
              <Typography variant="label">
                {selectedTagIds.length} {pluralize('tag', selectedTagIds.length)} selected:
              </Typography>
              <Button
                variant="link"
                color="secondary"
                startIcon={<Icon name={'OScales'} />}
                onClick={handleBulkUpdatePurposeClick}
              >
                <Typography variant="label">Update Purposes</Typography>
              </Button>
              <Button
                variant="link"
                color="secondary"
                startIcon={<Icon name={'ORefresh'} />}
                onClick={handleBulkChangeStatusClick}
              >
                <Typography variant="label">Change Status</Typography>
              </Button>
            </BulkActionsLeftBox>
            <Button variant="link" color="secondary" onClick={handleClearClick}>
              <Typography variant="label">Clear</Typography>
            </Button>
          </BulkActionsBox>
        )}

        {/* Tags list */}
        <DataGrid
          rows={newTags || []}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnResize
          disableColumnReorder
          disableBorder
          disableAggregation
          disableRowSelectionOnClick={!isTagsWriter}
          hideFooterRowCount
          getRowId={row => row.ID}
          loading={isLoading || pagination.isPending}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          rowSelectionModel={selectedTagIds}
          onRowSelectionModelChange={(selectedIds, _) => setSelectedTagIds(selectedIds as string[])}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults || rowsPerPage * totalPages}
          checkboxSelection={isTagsWriter}
          hideFooterPagination={totalPages < 2}
          hideFooter={totalPages < 2}
          noRowsOverlayProps={{ buttonProps: { onClick: handleClearSearch } }}
          autoPageSize
          sx={{
            border: `1px solid ${theme.palette.Common.Divider}`,
            borderRadius: '11px',

            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
            // The following disables the background for selected items and fixes borders
            '.Mui-selected': {
              backgroundImage: 'none !important',
            },
            '.MuiDataGrid-row:not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.Common.Divider}`,
              borderRadius: '0px !important',
            },
            '.MuiDataGrid-row': {
              borderTopLeftRadius: '0px !important',
              borderTopRightRadius: '0px !important',
            },
            '.MuiDataGrid-row::before': {
              height: '0px !important',
            },
          }}
        />
      </Box>

      {/* Tag status bulk update modal */}
      {showBulkUpdateTagPurposeModal && (
        <BulkUpdateTagPurposesModal
          tagIds={bulkUpdateTagIds}
          webTags={newTags}
          onClose={() => setShowBulkUpdateTagPurposeModal(false)}
        />
      )}

      {/* Tag purposes bulk update modal */}
      {showBulkUpdateTagStatusModal && (
        <BulkUpdateTagStatusModal
          tagIds={bulkUpdateTagIds}
          webTags={newTags}
          onClose={() => setShowBulkUpdateTagStatusModal(false)}
        />
      )}
    </Drawer>
  )
}
