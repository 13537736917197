import React from 'react'
import { Box, Grid } from '@mui/material'

import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { HooksFormSection } from './components'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { getDoesAssetSupportHooks } from './utils'
import { ManageInferredLabelsInput, ManageLabelsInput } from 'pages/assetManager/components'
import { MaybeNull } from 'interfaces/common'
import { PERSONAL_DATA_CATEGORY_OPTIONS } from 'pages/assetManager/constants'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useEditAssetFormUtils } from './hooks'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ASSET_TYPE_ENUM } from 'interfaces/assets'
import { EditAutoDiscoveryFormSection, EditPurposesFormSection } from 'pages/assetManager/databases/upsert/components'

type Props = {
  assetSummary?: MaybeNull<AssetSummaryDTO>
}

export const EditAssetForm: React.FC<Props> = ({ assetSummary }) => {
  const { isPermitted } = useIsPermitted()
  const doesAssetSupportHooks = getDoesAssetSupportHooks(assetSummary) && isPermitted(PERMISSIONS.HOOK_ASSIGN)
  const { isUsersLoading, users, onDragEnd, hooks } = useEditAssetFormUtils({ assetSummary })
  const shouldShowEditPurposesFormSection =
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE
  const shouldShowAutoDiscoveryFormSection =
    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ===
    ASSET_TYPE_ENUM.CANONICAL_RESOURCE_TYPE_DATABASE

  if (isUsersLoading)
    return (
      <Box my={4}>
        <Spinner />
      </Box>
    )

  return (
    <>
      <FormSection title="Basic Details">
        <FormRow>
          <Grid container>
            <Grid item xs={6}>
              <FormInput
                required
                disabled
                fullWidth
                name="name"
                label="Name"
                placeholder="e.g. Overarching policy applicable to all customer data."
              />
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <FormTextArea
                fullWidth
                name="description"
                label="Description"
                placeholder="Describe the asset contents..."
              />
            </Grid>
          </Grid>
        </FormRow>

        <FormRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormDropdown
                label="Data Sensitivity"
                required
                fullWidth
                name="dataCategory"
                placeholder="Select Data Type"
                items={PERSONAL_DATA_CATEGORY_OPTIONS}
              />
            </Grid>

            <Grid item xs={3}>
              <FormDropdown
                label="Data Owner"
                required
                fullWidth
                name="dataOwner"
                placeholder="Select Data Owner"
                items={users.map(u => ({
                  id: u.ID,
                  name: u?.firstName && u?.lastName ? `${u.firstName} ${u.lastName}` : u.email,
                }))}
              />
            </Grid>
          </Grid>
        </FormRow>
      </FormSection>

      {shouldShowAutoDiscoveryFormSection ? <EditAutoDiscoveryFormSection assetSummary={assetSummary} /> : null}

      <FormSection title="Assign Classifications" subTitle="Select classifications from the list.">
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <ManageLabelsInput assetCode={assetSummary?.asset?.resource?.id || ''} />
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={9}>
            <ManageInferredLabelsInput assetCode={assetSummary?.asset?.resource?.id || ''} />
          </Grid>
        </Grid>
      </FormSection>

      {doesAssetSupportHooks ? (
        <HooksFormSection assetSummary={assetSummary} onDragEnd={onDragEnd} hooks={hooks} />
      ) : null}

      {shouldShowEditPurposesFormSection ? <EditPurposesFormSection /> : null}
    </>
  )
}
