import React, { useContext, useState } from 'react'
import { Typography, Box, Link } from '@mui/material'
import {
  DataGrid,
  TableCell,
  ActionSheetItem,
  DropListButton,
  ListItemText,
  Chip,
  IconDictionary,
  Icon,
  DateRangePicker,
  Spinner,
} from '@ketch-com/deck'
import { TrackerDiagnosticsContext, TrackerDetailsContext } from 'pages/policyCenter/trackers/context'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { PageDetail, DomainProvenanceProvenance } from '@ketch-com/atlas/dist/apiTypes/foundOn'
import pluralize from 'pluralize'
import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro'
import { FoundOnModal } from 'pages/policyCenter/trackers/details/components/diagnostics/components/FoundOnModal'

export const FoundOn: React.FC = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const { properties } = useContext(TrackerDetailsContext)
  const {
    trackerFoundOnPagesCount,
    trackerFoundOnPages,
    hasNextTrackerFoundOnPages,
    isFetchingTrackerFoundOnPages,
    isFetchingNextTrackerFoundOnPages,
    fetchNextTrackerFoundOnPages,
    foundOnProperty,
    setFoundOnProperty,
    isLoadingTrackerFoundOnPages,
    foundOnDynamicDateRange,
    setFoundOnDynamicDateRange,
    foundOnStaticDateRange,
    foundOnModalData,
    setFoundOnModalData,
    isLoadingTrackerFoundOnDates,
  } = useContext(TrackerDiagnosticsContext)

  const options = properties.map(property => ({
    label: property.name,
    value: property.code,
    platform: property.platform,
  }))
  const rows = trackerFoundOnPages?.map(page => page) || []
  const cols = [
    {
      width: 400,
      field: 'url',
      headerName: 'URL',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Link href={params?.row?.url} rel="noopener noreferrer" target="_blank" color="darkDusk.main">
              {params?.row?.url}
            </Link>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 200,
      field: 'domain',
      headerName: 'Domain',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        switch (params?.row?.domainProvenance?.length) {
          case 0:
            return (
              <TableCell>
                <Typography variant="body">None</Typography>
              </TableCell>
            )
          case 1:
            return (
              <TableCell>
                <Typography variant="body">{params?.row?.domainProvenance?.[0]?.domain}</Typography>
              </TableCell>
            )

          default:
            return (
              <TableCell>
                <Chip
                  size="small"
                  clickable
                  label={params?.row?.domainProvenance?.length}
                  onClick={() => {
                    setFoundOnModalData(params?.row)
                  }}
                />
              </TableCell>
            )
        }
      },
      sortable: false,
    },
    {
      width: 200,
      field: 'provenance',
      headerName: 'Provenance',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        if (!params?.row?.domainProvenance?.length)
          return (
            <TableCell>
              <Typography variant="body">None</Typography>
            </TableCell>
          )

        let isFirstParty = false
        let isThirdParty = false

        for (const dp of params?.row?.domainProvenance) {
          if (!isFirstParty && dp.provenance === DomainProvenanceProvenance.first_party) isFirstParty = true
          if (!isThirdParty && dp.provenance === DomainProvenanceProvenance.third_party) isThirdParty = true
          if (isFirstParty && isThirdParty) break
        }

        const isMultiple = isFirstParty && isThirdParty

        if (!isMultiple && isFirstParty)
          return (
            <TableCell>
              <Chip size="small" label="1st Party" />
            </TableCell>
          )
        if (!isMultiple && isThirdParty)
          return (
            <TableCell>
              <Chip size="small" label="3rd Party" />
            </TableCell>
          )
        return (
          <TableCell>
            <Chip
              size="small"
              clickable
              label="Multiple"
              onClick={() => {
                setFoundOnModalData(params?.row)
              }}
            />
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={0.5} flexDirection="column">
        <Typography variant="h4">Found On</Typography>
        <Typography variant="body" color="darkDuskFaded.main">
          Information about where the tracker was found and where it came from on websites over time.
        </Typography>
      </Box>

      {isLoadingTrackerFoundOnDates ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={300}>
          <Spinner size="32px" thickness={3} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap={2} alignItems="center">
            <DropListButton
              value={foundOnProperty}
              width="200px"
              placeholder="All Properties"
              disabled={false}
              size="small"
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              getOptionLabel={option => option.label}
              renderOption={(props, option, { selected }) => {
                return (
                  <ActionSheetItem key={option.value} selected={selected} {...props}>
                    <ListItemText selected={selected}>{option.label}</ListItemText>
                  </ActionSheetItem>
                )
              }}
              options={options}
              onChange={(event, newValue) => {
                setFoundOnProperty(newValue)
              }}
            />
            <DateRangePicker
              label=""
              value={foundOnDynamicDateRange}
              disableFuture
              minDate={foundOnStaticDateRange?.[0] ? foundOnStaticDateRange?.[0] : undefined}
              maxDate={foundOnStaticDateRange?.[1] ? foundOnStaticDateRange?.[1] : undefined}
              onChange={(newValue: DateRange<Dayjs>) => {
                if (newValue) {
                  if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
                    setFoundOnDynamicDateRange(newValue)
                    setIsCalendarOpen(false)
                  }
                }
              }}
              onOpen={() => {
                setIsCalendarOpen(true)
              }}
              onClose={() => {
                setIsCalendarOpen(false)
              }}
              open={isCalendarOpen}
              slots={{
                actionBar: () => null,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    endAdornment: <Icon name={IconDictionary.OCalendar} width={25} height={25} />,
                  },
                },
              }}
              disabled={!foundOnDynamicDateRange?.[0] && !foundOnDynamicDateRange?.[1]}
            />
            <Typography variant="label">
              This tracker was found on {trackerFoundOnPagesCount?.numPages || 0}{' '}
              {pluralize('page', trackerFoundOnPagesCount?.numPages)}.
            </Typography>
          </Box>
          <Box width="100%" height={300}>
            <DataGrid
              getRowHeight={() => 'auto'}
              getRowId={(row: PageDetail) => row?.url}
              sx={{
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
              }}
              rows={rows}
              columns={cols}
              hideFooter={!isFetchingNextTrackerFoundOnPages}
              hideFooterPagination={!isFetchingNextTrackerFoundOnPages}
              hideFooterRowCount
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              disableChildrenSorting
              disableRowSelectionOnClick
              disableRowHoverStates
              loading={isLoadingTrackerFoundOnPages}
              columnMenuProps={
                {
                  slots: {
                    columnMenuFilterItem: null,
                    columnMenuAggregationItem: null,
                    columnMenuGroupingItem: null,
                  },
                } as any
              }
              onRowsScrollEnd={() => {
                if (hasNextTrackerFoundOnPages && !isFetchingTrackerFoundOnPages && !isFetchingNextTrackerFoundOnPages)
                  fetchNextTrackerFoundOnPages()
              }}
              paginationLoading={isFetchingNextTrackerFoundOnPages}
              isPaginationLoading={isFetchingNextTrackerFoundOnPages}
              noRowsOverlayProps={{
                title: 'No data for the selected filter criteria',
                subTitle:
                  "Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again.",
                iconName: IconDictionary.OFilter,
                buttonTitle: '',
              }}
            />
          </Box>
        </Box>
      )}

      {foundOnModalData ? <FoundOnModal /> : null}
    </Box>
  )
}
