import { ClassificationRefreshSchedule } from '@ketch-com/windlass/dist/appliance/databases'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface UpdateClassificationScheduleParams {
  connectionId: string
  assetId: string
  formData: ClassificationRefreshSchedule
}

export const updateClassificationSchedule = ({
  connectionId,
  assetId,
  formData,
}: UpdateClassificationScheduleParams) => {
  return API.put<UpdateClassificationScheduleParams>(
    formatRequestString({
      entityUrl: `/api/asset-manager/connections/${connectionId}/databases/${assetId}/classification-schedule`,
    }),
    formData,
  )
}
