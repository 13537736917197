import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { ApprovedTrackerPropertiesContext, TrackerDetailsContext } from 'pages/policyCenter/trackers/context'
import { ApprovedTrackerProperties } from 'pages/policyCenter/trackers/components'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const PropertiesVisibility: React.FC = () => {
  const { trackerDetails } = useContext(TrackerDetailsContext)
  const { trackerKeys, trackerProperties } = useContext(ApprovedTrackerPropertiesContext)

  return (
    <Box>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: trackerDetails?.key },
          { title: 'Properties & Visibility' },
        ]}
      />
      <Box
        pb={3}
        mb={2}
        sx={{
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Typography variant="h3">Properties & Visibility</Typography>
        <Typography variant="body" color="darkDuskFaded.main">
          Configure disclosures and see when this tracker was last seen.
        </Typography>
      </Box>
      <ApprovedTrackerProperties trackerKeys={trackerKeys} properties={trackerProperties} />
    </Box>
  )
}
