import { PutAssetsV2RequestBodyDTO, PutAssetsV2ResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

interface UpdateInstalledDataSystemAssetsParams {
  installedDataSystemId: string
  body: PutAssetsV2RequestBodyDTO
}

export const updateInstalledDataSystemAssets = ({
  installedDataSystemId,
  body,
}: UpdateInstalledDataSystemAssetsParams) => {
  return API.put<PutAssetsV2ResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${installedDataSystemId}/assets`,
    }),
    body,
  )
}
