import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { EmptyState, ContentGroup, TooltipButton, DataGrid, TableCell } from '@ketch-com/deck'
import { Pagination } from 'api/common/paginatedQuery'
import { ControllerDTO } from 'interfaces/controllers/controller'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { ControllersListDropdown } from 'pages/policyCenter/controllers/list/ControllersListDropdown'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isFetching: boolean
  isLoading: boolean
  controllers: ControllerDTO[]
  pagination: Pagination
}

export const ControllersList: React.FC<Props> = ({ isFetching, isLoading, controllers, pagination }) => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const isEntitledToCreateController = isEntitled(ENTITLEMENTS.QTY_CONTROLLER)

  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateController = isPermitted([PERMISSIONS.CONTROLLER_WRITE])

  const canCreateController = isEntitledToCreateController && isPermittedToCreateController

  const handleUpsertControllerClick = (code?: string) => {
    navigate(RoutesManager.policyCenter.controllers.upsert.root.getURL({ code: code || NEW_ITEM_CODE }))
  }

  const breadcrumbs = [
    { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
    { title: 'Controllers', link: RoutesManager.policyCenter.controllers.root.getURL() },
  ]

  const rows = controllers.map(controller => ({
    ID: controller.ID,
    name: controller.name,
    code: controller.code,
    updatedAt: controller?.metadata?.updatedAt,
  }))

  type RowType = (typeof rows)[0]

  const gridColumns = [
    {
      field: 'Name',
      headerName: 'Name',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell columnGap={1.5}>
            <NameAndCodeCellRenderer code={row.code} name={row.name} />
          </TableCell>
        )
      },
      sortable: false,
      flex: 1,
    },
    {
      field: 'Created',
      headerName: 'Created',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return <FormattedDateRenderer date={row?.updatedAt} />
      },
      width: 225,
      sortable: false,
    },
    {
      field: 'Dropdown',
      headerName: '',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return <ControllersListDropdown controller={row} />
      },
      width: 60,
      sortable: false,
    },
  ]

  return (
    <Box width="100%" maxWidth="1280px">
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ContentGroup
        title="Controllers"
        titleVariant="h2"
        variant="top-header"
        isDivider={true}
        actionRightBlockComponent={
          isPermittedToCreateController ? (
            <TooltipButton
              onClick={() => handleUpsertControllerClick()}
              title={
                !isEntitledToCreateController
                  ? 'No controller entitlements remaining, please delete one or contact your admin for more'
                  : undefined
              }
              disabled={!isEntitledToCreateController}
            >
              Create Controller
            </TooltipButton>
          ) : null
        }
      />

      <Box sx={{ marginTop: '22px', backgroundColor: 'white.main', borderRadius: '11px', padding: '14px' }}>
        {!isFetching && !controllers.length ? (
          <EmptyState
            iconName="OShield"
            title="There are no controllers for this organization"
            subTitle={canCreateController ? 'Create your first one to populate the table.' : undefined}
            primaryButtonTitle={canCreateController ? 'Create Controller' : undefined}
            primaryButtonProps={{
              hidden: !canCreateController,
              onClick: () => handleUpsertControllerClick(),
            }}
          />
        ) : (
          <DataGrid
            rowCount={controllers.length}
            rows={rows}
            columns={gridColumns}
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableChildrenSorting
            disableColumnResize
            hideFooter
            disableRowSelectionOnClick
            getRowId={({ ID }) => ID}
            sx={{ padding: 0 }}
          />
        )}
      </Box>
    </Box>
  )
}
