import React from 'react'
import Box from '@mui/material/Box'
import { Button, Icon } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'

export const ButtonExamples: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Button variant="iconCustomRounded" color="tertiary">
          <Icon name="OBin" />
        </Button>

        <Typography variant="label">
          variant = "iconCustomRounded" <br /> color = "tertiary"
        </Typography>
      </Box>
    </Box>
  )
}
