import { createUseMutation, CustomMutationConfig } from 'api/common/mutation'
import { updateClassificationSchedule } from '../fetchers/updateClassificationSchedule'

const useCustomMutation = createUseMutation({
  mutationFn: updateClassificationSchedule,
})

type Config = CustomMutationConfig<typeof useCustomMutation>

export const useUpdateClassificationSchedule = (config?: Config) => {
  return useCustomMutation(config)
}
