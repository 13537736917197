import React, { useContext } from 'react'
import { Formik } from 'formik'

import { EditWorkflowContext } from './contexts/EditWorkflowContext'
import { EditWorkflowFormContainer } from './components/EditWorkflowFormContainer'
import { EditWorkflowContainerContextProvider } from './components/EditWorkflowFormContainer/context/EditWorkflowContainerContext'

export const EditWorkflow: React.FC = () => {
  const editWorkflowContext = useContext(EditWorkflowContext)

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={editWorkflowContext.initialValues}
      onSubmit={editWorkflowContext.submitWithWorkflowConfigChecks}
      validationSchema={editWorkflowContext.validationSchema}
    >
      {() => {
        return (
          <EditWorkflowContainerContextProvider>
            <EditWorkflowFormContainer />
          </EditWorkflowContainerContextProvider>
        )
      }}
    </Formik>
  )
}
