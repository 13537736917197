import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { Box, Typography } from '@mui/material'
import { Connections } from '../components/Connections/Connections'
import { DataSystemWidgets } from './components/Widgets/DataSystemWidgets'
import { Avatar, AvatarSize, Chip, Spinner, theme, ViewLayout } from '@ketch-com/deck'
import { showToast } from 'components/modals/AlertComponent'
import { Logger } from 'utils/Logger'
import { AppActions } from 'pages/dataSystems/DataSystem/components/AppActions'
import { Overview } from 'pages/dataSystems/DataSystem/components/Overview'
import { Details } from 'pages/dataSystems/DataSystem/components/Details'
import { useDataSystemsContext } from './context/DataSystemContext'
import { RiskManagement } from './components/RiskManagementTab'
import { DataSystemAssetsTab } from './assetsTab/DataSystemAssetsTab'
import { useAssetsTabFilterContext } from './assetsTab/context/AssetsTabFilterContext'
import { DataSystemsDataCategoriesModal } from './assetsTab/components/DataSystemsDataCategoriesModal'
import { Capabilities } from 'interfaces/dataSystems/Capabilities/Capabilities'
import { Capabilites } from 'pages/dataSystems/components/Capabilities'
import { TabDefinition } from 'pages/dataSystems/DataSystem/types'
import RightsOrchestrationTab from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'

export const DataSystem = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const { search } = location

  /*
   * Awkward way of figuring current tab
   * cleaner way is to use router for tab navigation. Treat each tab item as a href
   * delete wayward code to establish `const newTabs`, useEffect etc.
   * All you need is DOM to have `onClick = () => navigate(..)`
   */
  const p = useParams()
  const baseSystemUrl = RoutesManager.systems.id.root.getURL(p)

  const {
    appDescriptor,
    isLoading,
    isError,
    dataClassificationsRolloverProps,
    isEntitledToDemo,
    showAssetsTab,
    showRightsOrchestrationTab,
    isEntitledToDataMap,
  } = useDataSystemsContext()

  const [currentTab, setCurrentTab] = useState<number>()
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const { assetsDataSystems } = useAssetsTabFilterContext()

  const assetsCount = useMemo(() => {
    if (assetsDataSystems.dataSystems?.[0]) {
      return assetsDataSystems.dataSystems[0].totalAssetCount
    }
  }, [assetsDataSystems.dataSystems])

  const newTabs = useMemo(() => {
    // Let it load before determining the tabs to show
    if (!appDescriptor) {
      return []
    }

    const overviewTab: TabDefinition = {
      composition: {
        label: 'Overview',
        link: {
          pathname: relativePath(RoutesManager.systems.id.overview.root.pattern),
          search,
        },
      },
    }

    const assetsTab: TabDefinition = {
      composition: {
        label: (
          <Typography variant="label">
            Assets{' '}
            <Typography
              variant="label"
              color={currentTab === 2 ? theme.palette.Text.Primary : theme.palette.Text.Disabled}
            >
              {assetsCount ?? 0}
            </Typography>
          </Typography>
        ),
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.assets.root.pattern)}`,
          search,
        },
      },
      isShow: () => showAssetsTab,
    }

    const integrationDetails: TabDefinition = {
      composition: {
        label: 'Integration Details',
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.details.root.pattern)}`,
          search,
        },
      },
    }

    const riskManagementTab = {
      composition: {
        label: 'Risk Management',
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.riskManagement.root.pattern)}`,
          search,
        },
      },
      isShow: () => isEntitledToDemo && isEntitledToDataMap,
    }

    const rightsOrchestrationTab: TabDefinition = {
      composition: {
        label: 'Rights Orchestration',
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.rightsOrchestration.root.pattern)}`,
          search,
        },
      },
      isShow: () =>
        Boolean(appDescriptor.dataSystem?.supportedCapabilities?.includes(Capabilities.INTROSPECTION_APP_CAPABILITY)) &&
        showRightsOrchestrationTab,
    }

    if (!appDescriptor.dataSystem?.appCode) {
      if (isEntitledToDemo && isEntitledToDataMap) {
        return [overviewTab.composition, riskManagementTab.composition]
      }
      return [overviewTab.composition]
    }
    const connectionsTab: TabDefinition = {
      composition: {
        label: (
          <Typography variant="label">
            Connections{' '}
            <Typography variant="label" color={currentTab === 1 ? 'darkDusk.main' : 'fadedDarkGrey.main'}>
              {appDescriptor.connections?.length ?? 0}
            </Typography>
          </Typography>
        ),
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.connections.root.pattern)}`,
          search,
        },
      },
    }

    return [overviewTab, connectionsTab, rightsOrchestrationTab, assetsTab, integrationDetails, riskManagementTab]
      .filter(tab => {
        if (!tab.isShow) return true
        else return tab.isShow()
      })
      .map(i => i.composition)
  }, [
    appDescriptor,
    search,
    currentTab,
    assetsCount,
    showAssetsTab,
    isEntitledToDemo,
    isEntitledToDataMap,
    showRightsOrchestrationTab,
  ])

  useEffect(() => {
    if (newTabs.length === 0) {
      return
    }
    let currentIndex
    if (currentTab === undefined) {
      const currentRoute = relativePath(location.pathname, `${baseSystemUrl}/`)
      currentIndex = newTabs.findIndex(i => i.link.pathname === currentRoute)
      currentIndex = currentIndex === -1 ? 0 : currentIndex
      setCurrentTab(currentIndex)
    } else {
      currentIndex = currentTab
    }
    navigate(newTabs[currentIndex].link)
  }, [baseSystemUrl, currentTab, location.pathname, navigate, newTabs])

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  } else if (isError || !appDescriptor) {
    Logger.error('Data Systems: Error fetching Installed Data System')
    return null
  } else if (!appDescriptor?.dataSystem) {
    Logger.error('Installed Data System missing Data System')
    showToast({ content: 'Internal Server Error. Please contact support', type: 'error' })
    return null
  }

  const hasCredentialsIssue = appDescriptor.connections?.some(connection => {
    return connection.hasCredentialsIssue === true
  })

  return (
    <>
      <ViewLayout
        contentGroupProps={{
          title: appDescriptor.dataSystem?.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.systems.systems.root.getURL()),
          },
          imageBlockComponent: (
            <Avatar
              isLogo
              src={appDescriptor.dataSystem?.logoUrl}
              alt={appDescriptor.dataSystem?.logoUrl}
              variant="rounded"
              size={AvatarSize['2xlarge']}
              backgroundColor={theme.palette.tertiary.main}
            />
          ),
          infoBlockComponent: (
            <Box display="flex" alignItems="center" gap={1} flexWrap={'wrap'}>
              <Chip
                label={appDescriptor?.type}
                size="small"
                sx={{
                  textTransform: 'capitalize',
                }}
              />
              <Capabilites
                isShortVersion
                hasNoConnections={!appDescriptor.connections?.length}
                hasCredentialsIssue={hasCredentialsIssue}
                supportedCapabilites={appDescriptor.dataSystem?.supportedCapabilities!}
              />
            </Box>
          ),
          actionRightBlockComponent: <AppActions appDescriptor={appDescriptor} />,
        }}
        tabsComponentProps={{ value: currentTab, onChange: handleChange, tabItems: newTabs }}
        widgetContentComponent={<DataSystemWidgets />}
      >
        <Routes location={location}>
          <Route
            path={RoutesManager.systems.id.overview.root.pattern}
            element={<Overview appDescriptor={appDescriptor} />}
          />
          <Route
            path={RoutesManager.systems.id.connections.root.pattern}
            element={<Connections appDescriptor={appDescriptor} backgroundColor={theme.palette.superLightGrey.main} />}
          />
          <Route path={RoutesManager.systems.id.assets.root.pattern} element={<DataSystemAssetsTab />} />
          <Route
            path={RoutesManager.systems.id.rightsOrchestration.root.pattern}
            element={<RightsOrchestrationTab />}
          />
          <Route path={RoutesManager.systems.id.riskManagement.root.pattern} element={<RiskManagement />} />
          <Route
            path={RoutesManager.systems.id.details.root.pattern}
            element={<Details appDescriptor={appDescriptor} />}
          />
        </Routes>
        <DataSystemsDataCategoriesModal open={!!dataClassificationsRolloverProps} />
      </ViewLayout>
    </>
  )
}
