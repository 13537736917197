import React from 'react'
import { Status, StatusVariant, StatusState, IconMap, IconDictionary } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

interface RightsQueueStatusStylesResultType {
  icon?: keyof typeof IconMap
  spinner?: boolean
  label: string
  variant: StatusVariant
  status: StatusState
}

const getRightsQueueStatusStyles = (state: StatusState): RightsQueueStatusStylesResultType => {
  // Define styles based on status
  // For simplicity, using the same icon and variant for all
  return {
    icon: IconDictionary.FClock,
    label: `Status: ${state}`,
    variant: StatusVariant.filled,
    status: state,
  }
}

export const StatusExample: React.FC = () => {
  return (
    <>
      <Typography variant="h3" mb={2}>
        DECK Status component
      </Typography>

      <Box display="flex" gap={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          {Object.values(StatusState).map(state => {
            const { status } = getRightsQueueStatusStyles(state)
            return <Status label={status} variant={StatusVariant.outlined} status={status} />
          })}
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          {Object.values(StatusState).map(state => {
            const { icon, label, status, variant, spinner } = getRightsQueueStatusStyles(state)
            return (
              <Box>
                <Status
                  key={state}
                  label={label}
                  isLoading={spinner}
                  status={status}
                  icon={icon}
                  secondaryLabel={'Secondary label'}
                  variant={variant}
                />
              </Box>
            )
          })}
        </Box>

        <Box display="flex" gap={2} flexWrap="wrap" flexDirection="column">
          {Object.values(StatusState).map(state => {
            const { icon, label, status, spinner } = getRightsQueueStatusStyles(state)
            return (
              <Box>
                <Status
                  key={state}
                  label={label}
                  isLoading={spinner}
                  status={status}
                  icon={icon}
                  variant={StatusVariant.filled}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  )
}
