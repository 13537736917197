import { useContext, useState } from 'react'
import { InternalSystemDsrContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/context'
import { Box, Typography } from '@mui/material'
import { ExecutionResultForDBDTO, ExecutionResultAssetDTO } from '@ketch-com/figurehead'
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  theme,
  IconDictionary,
  Icon,
  Chip,
  DataGrid,
  TableCell,
  Status,
  StatusVariant,
  StatusState,
  StatusProps,
} from '@ketch-com/deck'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { MaybeNull } from 'interfaces/common'
import { InternalSystemDsrExecutionInfoModal } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/components'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

type Props = {
  dsrExecution: ExecutionResultForDBDTO
}

export const InternalSystemDsrExecution: React.FC<Props> = ({ dsrExecution }) => {
  const [currentExecutionResult, setCurrentExecutionResult] = useState<MaybeNull<ExecutionResultAssetDTO>>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const { stepDetails, retrySql, isRetryingSql, isResolving, workflowStep, rightInvocation } =
    useContext(InternalSystemDsrContext)

  const isAllInProgress = dsrExecution?.dbResults?.every(dbResult => dbResult.status === 6)
  const isAllCompleted = dsrExecution?.dbResults?.every(dbResult => dbResult.status === 1)
  const isAllErrored = dsrExecution?.dbResults?.every(dbResult => dbResult.status === 2)
  const isSomeInProgress = !isAllInProgress && dsrExecution?.dbResults?.some(dbResult => dbResult.status === 6)
  const isSomeCompleted = !isAllCompleted && dsrExecution?.dbResults?.some(dbResult => dbResult.status === 1)
  const isSomeErrored = !isAllErrored && dsrExecution?.dbResults?.some(dbResult => dbResult.status === 2)
  const isSomeRolledBack = dsrExecution?.dbResults?.some(dbResult => dbResult.status === 5)
  let mixedLabel = null

  let dbStatusProps: StatusProps = {
    label: 'Unknown',
    variant: StatusVariant.ghost,
    icon: IconDictionary.FPointer,
    status: StatusState.draft,
  }

  if (isSomeInProgress || isSomeCompleted || isSomeErrored) {
    if (!isSomeRolledBack && !isAllCompleted && !isAllErrored && !isAllInProgress)
      mixedLabel = (
        <Typography variant="label" color="darkDuskFaded.main">
          Mixed
        </Typography>
      )

    dbStatusProps = {
      label: '',
      icon: 'Dot',
      variant: StatusVariant.ghost,
      status: isSomeInProgress ? StatusState.inProgress : StatusState.error,
      isMixedDot: true,
    }
  }

  if (isSomeErrored && isSomeRolledBack)
    dbStatusProps = {
      label: 'Error, Rolled Back',
      icon: IconDictionary.FImportant,
      variant: StatusVariant.ghost,
      status: StatusState.error,
    }

  if (isAllCompleted)
    dbStatusProps = {
      label: 'Completed',
      icon: IconDictionary.FCheckRound,
      variant: StatusVariant.ghost,
      status: StatusState.success,
    }

  if (isAllErrored)
    dbStatusProps = {
      label: 'Error',
      icon: IconDictionary.FImportant,
      variant: StatusVariant.ghost,
      status: StatusState.error,
    }

  if (isAllInProgress)
    dbStatusProps = {
      label: 'In Progress',
      icon: IconDictionary.FPointer,
      variant: StatusVariant.ghost,
      status: StatusState.inProgress,
    }

  const retryForQueries: ExecutionResultAssetDTO[] =
    dsrExecution?.dbResults?.filter(execution => execution?.retryForQuery) || []

  const DsrRetry =
    (rightInvocation?.rightType === 'delete' || rightInvocation?.rightCode === 'test') &&
    workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS &&
    (retryForQueries?.length || dsrExecution?.retryForDb) ? (
      <Button
        disabled={isRetryingSql || isResolving}
        onClick={e => {
          e.stopPropagation()
          const enactmentIDs =
            retryForQueries?.map(query => query?.enactmentID || '')?.filter(enactmentID => enactmentID) || []
          retrySql({
            params: {
              workflowExecutionId: stepDetails?.workflowExecutionID,
              stepId: stepDetails?.stepID,
              payload: {
                internalSystemDSR: {
                  enactmentIDs,
                  databaseName: dsrExecution?.dbName,
                  connectionCode: dsrExecution?.connectionCode,
                  shouldRetryWholeConnection: dsrExecution?.retryForDb,
                },
              },
            },
          })
        }}
        color="secondary"
      >
        <Typography variant="label">Retry {retryForQueries?.length ? retryForQueries?.length : ''}</Typography>
      </Button>
    ) : null

  return (
    <>
      <Accordion
        key={dsrExecution?.dbName}
        color={theme.palette.Black.o4}
        expanded={isExpanded}
        sx={{ border: `1px solid ${theme.palette.Black.o16}` }}
      >
        <AccordionSummary
          icon={IconDictionary.OArrowCRight}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" gap={1}>
              <Box display="flex" alignItems="center" gap={0.5}>
                <Icon name={IconDictionary.Database} width={16} height={16} />
                <img
                  src={getAssetCloudImgUrl(dsrExecution?.provider || '')}
                  alt={dsrExecution?.provider}
                  width={16}
                  height={16}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="h4">{dsrExecution?.dbName}</Typography>
                <Chip
                  label={
                    <Typography variant="smallLabel" color="darkDuskFaded.main">
                      {dsrExecution?.connectionCode || ''}
                    </Typography>
                  }
                  size="small"
                  icon={<Icon name={IconDictionary.OConnectionTrue} iconColor={theme.palette.Text.Secondary} />}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" alignItems="center">
                <Status {...dbStatusProps} />
                {mixedLabel}
              </Box>

              {DsrRetry}
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <DataGrid
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 53}
            getRowId={row => row?.enactmentID}
            sx={{
              backgroundColor: 'unset',
              '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.superLightGrey.main,
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: theme.palette.superLightGrey.main,
              },
              '& .MuiDataGrid-cell': {
                backgroundColor: theme.palette.superLightGrey.main,
              },
              '& .MuiDataGrid-cell:hover': {
                backgroundColor: theme.palette.superLightGrey.main,
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                backgroundColor: theme.palette.superLightGrey.main,
              },
            }}
            rows={dsrExecution?.dbResults?.map(dbResult => dbResult) || []}
            columns={[
              {
                minWidth: 180,
                flex: 1,
                field: 'name',
                headerName: 'Name',
                renderCell: (params: GridRenderCellParams<ExecutionResultAssetDTO>) => {
                  return (
                    <TableCell>
                      <Typography variant="body">{params?.row?.name}</Typography>
                    </TableCell>
                  )
                },
                sortable: false,
              },
              {
                width: 140,
                field: 'groupType',
                headerName: 'Type',
                renderCell: (params: GridRenderCellParams<ExecutionResultAssetDTO>) => {
                  return (
                    <TableCell>
                      <Chip
                        label={
                          <Typography variant="smallBody">
                            {params?.row?.groupType === 2 ? 'Custom SQL' : 'Configuration'}
                          </Typography>
                        }
                        size="small"
                      />
                    </TableCell>
                  )
                },
                sortable: false,
              },
              {
                width: 140,
                field: 'status',
                headerName: 'Status',
                renderCell: (params: GridRenderCellParams<ExecutionResultAssetDTO>) => {
                  let statusProps: StatusProps = {
                    label: 'Unknown',
                    variant: StatusVariant.ghost,
                    icon: IconDictionary.FPointer,
                    status: StatusState.draft,
                  }

                  switch (params?.row?.status) {
                    case 1:
                      statusProps = {
                        label: 'Completed',
                        icon: IconDictionary.FCheckRound,
                        variant: StatusVariant.ghost,
                        status: StatusState.success,
                      }
                      break
                    case 2:
                      statusProps = {
                        label: 'Error',
                        icon: IconDictionary.FImportant,
                        variant: StatusVariant.ghost,
                        status: StatusState.error,
                      }
                      break
                    case 3:
                      statusProps = {
                        label: "Didn't Execute",
                        icon: IconDictionary.ODecline,
                        variant: StatusVariant.ghost,
                        status: StatusState.draft,
                      }
                      break
                    case 5:
                      statusProps = {
                        label: 'Rolled Back',
                        icon: IconDictionary.FUndo,
                        variant: StatusVariant.ghost,
                        status: StatusState.draft,
                      }
                      break

                    case 6:
                      statusProps = {
                        label: 'In Progress',
                        icon: IconDictionary.FPointer,
                        variant: StatusVariant.ghost,
                        status: StatusState.inProgress,
                      }
                      break
                  }

                  return (
                    <TableCell>
                      <Status {...statusProps} />
                    </TableCell>
                  )
                },
                sortable: false,
              },
              {
                minWidth: 140,
                flex: 1,
                field: 'message',
                headerName: 'Message',
                renderCell: (params: GridRenderCellParams<ExecutionResultAssetDTO>) => {
                  return (
                    <TableCell>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}
                        variant="body"
                      >
                        {params?.row?.message}
                      </Typography>
                    </TableCell>
                  )
                },
                sortable: false,
              },
              {
                width: 120,
                field: 'processes',
                headerName: '',
                renderCell: (params: GridRenderCellParams<ExecutionResultAssetDTO>) => {
                  const isDsrInProgress = params?.row?.status === 6
                  return (
                    <TableCell width="100%">
                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} width="100%">
                        {(rightInvocation?.rightType === 'delete' || rightInvocation?.rightCode === 'test') &&
                        workflowStep?.status !== WorkflowExecutionStepStatus.SUCCESS &&
                        params?.row?.retryForQuery ? (
                          <Button
                            disabled={isRetryingSql || isResolving}
                            sx={{ width: 28, height: 28, padding: 0 }}
                            size="medium"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              retrySql({
                                params: {
                                  workflowExecutionId: stepDetails?.workflowExecutionID,
                                  stepId: stepDetails?.stepID,
                                  payload: {
                                    internalSystemDSR: {
                                      enactmentIDs: [params?.row?.enactmentID || ''],
                                      databaseName: dsrExecution?.dbName,
                                      connectionCode: dsrExecution?.connectionCode,
                                    },
                                  },
                                },
                              })
                            }}
                          >
                            <Icon
                              name={IconDictionary.ORefresh}
                              iconColor={theme.palette.primary.main}
                              width={20}
                              height={20}
                            />
                          </Button>
                        ) : null}
                        {!isDsrInProgress ? (
                          <Button
                            disabled={isRetryingSql || isResolving}
                            size="medium"
                            variant="icon"
                            children={
                              <Icon
                                name={IconDictionary.OImportant}
                                iconColor={theme.palette.primary.main}
                                width={20}
                                height={20}
                              />
                            }
                            color="tertiary"
                            onClick={() => {
                              setCurrentExecutionResult(params?.row)
                            }}
                          />
                        ) : null}
                      </Box>
                    </TableCell>
                  )
                },
                sortable: false,
              },
            ]}
            checkboxSelection={false}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            disableBorder
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            loading={false}
            disableRowHoverStates
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
          />
        </AccordionDetails>
      </Accordion>

      {currentExecutionResult ? (
        <InternalSystemDsrExecutionInfoModal
          currentExecutionResult={currentExecutionResult}
          onClose={() => {
            setCurrentExecutionResult(null)
          }}
        />
      ) : null}
    </>
  )
}
