import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'
import { RelationshipsCreatedTab } from './components/RelationshipsCreatedTab'
import { RelationshipsDiscoveredTab } from './components/RelationshipsDiscoveredTab'

export const RelationshipsViewRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<RelationshipsCreatedTab />} />
    <Route
      path={relativePath(
        RoutesManager.assetManager.relationships.list.created.pattern,
        RoutesManager.assetManager.relationships.list.pattern,
      )}
      element={<RelationshipsCreatedTab />}
    />
    <Route
      path={relativePath(
        RoutesManager.assetManager.relationships.list.discovered.pattern,
        RoutesManager.assetManager.relationships.list.pattern,
      )}
      element={<RelationshipsDiscoveredTab />}
    />
  </Routes>
)
