import { showToast } from 'components/modals/AlertComponent'
import { useCombinedOnboardingProgress } from 'pages/home/utils/useCombinedOnboardingProgress'
import { useState } from 'react'
import { useAuth } from 'utils/hooks/useAuth'

const clientToken = (window as any)?.figureheadConfig?.REACT_APP_CANDU_CLIENT_TOKEN

export const useInitCandu = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false)

  const { userData } = useAuth()
  const userOrgCode = userData?.organizationCode

  useCombinedOnboardingProgress({
    enabled: !!userData?.userId && !!userOrgCode,
    onSuccess: data => {
      const initCandu = () => {
        ;(window as any)?.Candu?.init({
          clientToken,
          userId: userData?.userId || '',
          traits: {
            tenant: userOrgCode,
            ...data,
          },
        })
        setIsMounted(true)
      }

      if (!(window as any)?.Candu && !isMounted) {
        const script = document.createElement('script')

        script.setAttribute('src', `https://cdn.candu.ai/sdk/latest/candu.umd.js?token=${clientToken}`)
        script.onload = initCandu
        document.head.appendChild(script)
      } else {
        initCandu()
      }
    },
    onError: () => {
      showToast({ content: 'Failed to update progress', type: 'error' })
    },
  })

  return { isMounted }
}
