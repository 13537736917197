import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { Link } from 'components/ui-kit/link/Link'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as WidgetIcon } from 'assets/icons/thirdPartyRisk.svg'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { PrivacyControls, UndisclosedSystems, UnknownTransfers } from './components'

const PREFIX = 'ThirdPartyRisksItems'

const classes = {
  thirdPartyRisksItemsContainer: `${PREFIX}-thirdPartyRisksItemsContainer`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
  tableHoverState: `${PREFIX}-tableHoverState`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.thirdPartyRisksItemsContainer}`]: {
    flex: '0 1 848px',
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    padding: spacing(3, 4),
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
    marginBottom: spacing(2),
  },

  [`& .${classes.tab}`]: {
    marginLeft: spacing(1.5),
  },

  [`& .${classes.tableHoverState}`]: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

enum ThirdPartyRiskItems {
  PRIVACY_CONTROLS = 'Privacy Controls',
  UNKNOWN_TRANSFERS = 'Unknown Transfers',
  UNDISCLOSED_SYSTEMS = 'Undisclosed Systems',
}

const getThirdPartyRisksItemTable = (thirdPartyRiskItem: string) => {
  let component = null

  switch (thirdPartyRiskItem) {
    case ThirdPartyRiskItems.PRIVACY_CONTROLS:
      component = <PrivacyControls />
      break
    case ThirdPartyRiskItems.UNDISCLOSED_SYSTEMS:
      component = <UndisclosedSystems />
      break
    case ThirdPartyRiskItems.UNKNOWN_TRANSFERS:
      component = <UnknownTransfers />
      break

    default:
      break
  }

  return component
}

export const ThirdPartyRisksItems: React.FC = () => {
  const [thirdPartyRiskItem, setThirdPartyRiskItem] = useState(ThirdPartyRiskItems.PRIVACY_CONTROLS)
  const component = getThirdPartyRisksItemTable(thirdPartyRiskItem)

  return (
    <StyledBox className={classes.thirdPartyRisksItemsContainer}>
      <Link variant="buttonWrap" to={RoutesManager.insights.dataRisks.thirdPartyRisk.root.getURL()}>
        <Box display="flex" alignItems="center" gap={1.5} mb={2} className={classes.tableHoverState}>
          <WidgetIcon width={48} height={48} />
          <Box display="flex" flexDirection="column" flex={1}>
            <Text size={20} weight={700} mb={1.25}>
              3rd Party Risk
            </Text>
            <Text size={14} color="darkDuskFaded">
              Identify risks associated with privacy controls among your third party data systems.
            </Text>
          </Box>
        </Box>
      </Link>
      <Tabs
        className={classes.tabs}
        tabClassName={classes.tab}
        config={[
          {
            key: ThirdPartyRiskItems.PRIVACY_CONTROLS,
            active: thirdPartyRiskItem === ThirdPartyRiskItems.PRIVACY_CONTROLS,
            title: ThirdPartyRiskItems.PRIVACY_CONTROLS,
            onClick: () => {
              setThirdPartyRiskItem(ThirdPartyRiskItems.PRIVACY_CONTROLS)
            },
          },
          {
            key: ThirdPartyRiskItems.UNKNOWN_TRANSFERS,
            active: thirdPartyRiskItem === ThirdPartyRiskItems.UNKNOWN_TRANSFERS,
            title: ThirdPartyRiskItems.UNKNOWN_TRANSFERS,
            onClick: () => {
              setThirdPartyRiskItem(ThirdPartyRiskItems.UNKNOWN_TRANSFERS)
            },
          },
          {
            key: ThirdPartyRiskItems.UNDISCLOSED_SYSTEMS,
            active: thirdPartyRiskItem === ThirdPartyRiskItems.UNDISCLOSED_SYSTEMS,
            title: ThirdPartyRiskItems.UNDISCLOSED_SYSTEMS,
            onClick: () => {
              setThirdPartyRiskItem(ThirdPartyRiskItems.UNDISCLOSED_SYSTEMS)
            },
          },
        ]}
      />
      {component}
    </StyledBox>
  )
}
