import { Box, styled } from '@mui/material'
export const SubTitle = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(0.5),
}))

export const ScrollContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  maxHeight: '350px',
  minHeight: '50px',
  overflowY: 'scroll',
  marginRight: -10, // Push scrollbar inside padding
  background: theme.palette.white.main,

  // Custom scrollbar
  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-button:end': {
    height: 10,
  },
  '&::-webkit-scrollbar-track-piece': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.fadedGrey.main,
    borderRadius: '100px',
  },
  '&::-webkit-scrollbar-corner': {
    display: 'none',
  },
  '&::-webkit-resizer': {
    display: 'none',
  },
}))
