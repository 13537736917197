import { GetTrackerManagedByResponse } from '@ketch-com/atlas/dist/apiTypes/lineage'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerLineageManagedBy = ({ encodedTrackerKey }: { encodedTrackerKey?: string }) => {
  return API.get<GetTrackerManagedByResponse>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/lineage/${encodedTrackerKey}/managed-by`,
      params: {
        filters: {},
      },
    }),
  )
}
