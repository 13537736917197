import React, { useContext } from 'react'
import { Typography, Box, Link } from '@mui/material'
import { DataGrid, Icon, TableCell, PopUp, Button, Chip, IconDictionary, CustomIconName } from '@ketch-com/deck'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { TrackerDiagnosticsContext } from 'pages/policyCenter/trackers/context'
import { DomainProvenance, DomainProvenanceProvenance } from '@ketch-com/atlas/dist/apiTypes/foundOn'
import dayjs from 'dayjs'

export const FoundOnModal: React.FC = () => {
  const { foundOnModalData, setFoundOnModalData, foundOnProperty, foundOnDynamicDateRange } =
    useContext(TrackerDiagnosticsContext)

  let iconName: CustomIconName = IconDictionary.OComputer

  switch (foundOnProperty?.platform) {
    case 2:
      iconName = IconDictionary.OMobile
      break
    case 5:
      iconName = IconDictionary.OComputer //TODO: update tv logo
      break
  }

  const rows = foundOnModalData?.domainProvenance?.map(dp => dp) || []
  const cols = [
    {
      width: 300,
      field: 'domain',
      headerName: 'Domain',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Typography variant="body">{params?.row?.domain}</Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 150,
      field: 'provenance',
      headerName: 'Provenance',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const isFirstParty = params?.row?.provenance === DomainProvenanceProvenance.first_party
        const isThirdParty = params?.row?.provenance === DomainProvenanceProvenance.third_party
        const label = (isFirstParty && '1st Party') || (isThirdParty && '3rd Party')
        return (
          <TableCell>
            <Chip size="small" label={label} />
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  return (
    <PopUp
      title="Found On"
      subTitle="Detailed information about the tracker's origin on specific URL"
      popUpWidth="600px"
      variant="dialog"
      onClose={() => setFoundOnModalData(null)}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => setFoundOnModalData(null)}>
            Close
          </Button>
        </>
      }
    >
      <Box>
        <Box display="flex" gap={2}>
          {foundOnProperty?.label ? (
            <Chip
              icon={<Icon name={iconName} />}
              label={<Typography variant="label">{foundOnProperty?.label}</Typography>}
              secondaryLabel="Property"
            />
          ) : null}

          {foundOnDynamicDateRange?.[0] && foundOnDynamicDateRange?.[1] ? (
            <Chip
              icon={<Icon name={IconDictionary.OCalendar} />}
              label={
                <Typography variant="label">
                  {dayjs(foundOnDynamicDateRange?.[0]).format('MMM DD, YYYY')} -{' '}
                  {dayjs(foundOnDynamicDateRange?.[1]).format('MMM DD, YYYY')}
                </Typography>
              }
              secondaryLabel=" Selected Period"
            />
          ) : null}
        </Box>
        <Box display="flex" gap={0.5} flexDirection="column" my={4}>
          <Typography variant="label">URL</Typography>
          <Link href={foundOnModalData?.url} rel="noopener noreferrer" target="_blank" color="darkDusk.main">
            {foundOnModalData?.url}
          </Link>
        </Box>
        <Box width="100%" height={250}>
          <DataGrid
            getRowHeight={() => 'auto'}
            getRowId={(row: DomainProvenance) => `${row?.domain}-${row?.provenance}`}
            sx={{
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
            }}
            rows={rows}
            columns={cols}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            disableRowHoverStates
            loading={false}
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
          />
        </Box>
      </Box>
    </PopUp>
  )
}
