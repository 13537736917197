import { useContext } from 'react'

import Box from '@mui/material/Box'
import { EndFlowContext } from '../../context/EndFlowContext'
import { Resolution, TaskDetails } from './components'

export const EndDetails: React.FC = () => {
  const { isComplete } = useContext(EndFlowContext)

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      <TaskDetails />

      {isComplete && <Resolution />}
    </Box>
  )
}
