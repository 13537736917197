import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchTrackerLineages } from '../fetchers/fetchTrackerLineages'
import { GetTrackerLineagesParams } from '@ketch-com/atlas/dist/apiTypes/lineage'

export const useTrackerLineagesInfinite = (
  params: GetTrackerLineagesParams & {
    encodedTrackerKey?: string
    enabled?: boolean
  },
) => {
  const { enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.trackerLineagesInfinite, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const pages = await fetchTrackerLineages({
        ...params,
        limit,
        after: pageParam.nextToken,
      })
      return pages
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : null
    },
  })

  return {
    ...resp,
    pages: resp?.data?.pages?.map(page => page?.data?.lineages || []).flat() || [],
  }
}
