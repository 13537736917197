import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { Plan } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

export type FetchPlanParams = { organizationCode?: string }

export const fetchPlan = ({ organizationCode }: FetchPlanParams) =>
  API.get<Plan>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/plan`,
    }),
  )
