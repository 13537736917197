import { GetTrackerFoundOnCountParams, GetTrackerFoundOnPagesCount } from '@ketch-com/atlas/dist/apiTypes/foundOn'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerFoundOnPagesCount = ({
  startDate,
  endDate,
  property_code,
  encodedTrackerKey,
}: GetTrackerFoundOnCountParams & {
  encodedTrackerKey?: string
}) => {
  return API.get<GetTrackerFoundOnPagesCount>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/found-on/${encodedTrackerKey}/count`,
      params: {
        filters: {
          ...(startDate && {
            startDate,
          }),
          ...(endDate && {
            endDate,
          }),
          ...(property_code && {
            property_code,
          }),
        },
      },
    }),
  )
}
