import React, { useContext } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { RoutesManager } from 'utils/routing/routesManager'
import { WorkflowCanvas } from 'pages/orchestration/workflows/edit/components/WorkflowCanvas'
import { EditWorkflowForm } from 'pages/orchestration/workflows/edit/components/EditWorkflowForm'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { WorkflowTypeSelection } from '../WorkflowTypeSelection'
import { ConfirmMultipleEndTilesModal } from 'components/modals/workflows/ConfirmMultipleEndTilesModal'
import { EditWorkflowContainerContext } from './context/EditWorkflowContainerContext'
import { UnRequiredDataSubjectVariablesWarningModal } from './components'
import { Spinner, theme } from '@ketch-com/deck'

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      wrapper: {
        position: 'relative',
        width: '100%',
        marginTop: -24,
        marginBottom: -24,
        height: `calc(100vh - 40px)`,
        boxSizing: 'border-box',
        background: `${theme.palette.bone.main} !important`,
      },
      layout: {
        display: 'flex',
      },
      headerRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        zIndex: 15,
        minHeight: 70,
        top: 0,
        left: 0,
        right: 0,
        padding: '16px 32px',
        background: 'white',
        boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10)',
      },
      createNewWorkflowContainer: {
        marginTop: spacing(3),
      },
    }),
  { name: 'EditWorkflow' },
)

export const EditWorkflowFormContainer: React.FC = () => {
  const classes = useStyles()

  const {
    handleSubmit,
    isEditMode,
    showMultipleEndTilesModal,
    setShowMultipleEndTilesModal,
    handleConfirmMultipleEndTilesModal,
    isReady,
    isWorkflowWithType,
    setCanonicalRightCode,
    setSubmitting,
    values,
    workflow,
    isDuplicateMode,
  } = useContext(EditWorkflowContainerContext)

  if (!isWorkflowWithType && !values.code && !isEditMode && !isDuplicateMode)
    return <WorkflowTypeSelection isEditMode={isEditMode} setCanonicalRightCode={setCanonicalRightCode} />

  if (!isReady)
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  return (
    <form className={classes.wrapper} onSubmit={handleSubmit}>
      {isEditMode ? (
        <NavigationBreadCrumbs
          type="light"
          items={[
            { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
            { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
            {
              title: workflow?.name || workflow?.code,
              link: RoutesManager.orchestration.workflows.view.root.getURL({ code: workflow?.code }),
            },
            { title: 'Edit' },
          ]}
        />
      ) : (
        <NavigationBreadCrumbs
          type="light"
          items={[
            { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
            { title: 'Workflows', link: RoutesManager.orchestration.workflows.root.getURL() },
            { title: 'Create' },
          ]}
        />
      )}
      {!isWorkflowWithType && !values.code ? null : (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'absolute',
              zIndex: 15,
              minHeight: 70,
              top: 0,
              left: 0,
              right: 0,
              padding: '16px 32px',
              background: 'white',
              boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10)',
            }}
          >
            <EditWorkflowForm isEditMode={isEditMode} />
          </Box>

          <WorkflowCanvas />
        </>
      )}
      {showMultipleEndTilesModal && (
        <ConfirmMultipleEndTilesModal
          onCancel={() => {
            setShowMultipleEndTilesModal(false)
            setSubmitting(false)
          }}
          onSubmit={() => handleConfirmMultipleEndTilesModal(values)}
        />
      )}

      <UnRequiredDataSubjectVariablesWarningModal />
    </form>
  )
}
