import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { DataMapPurposeRoutes } from './DataMapPurposeRoutes'
import { PurposeActions } from '../../components/PurposeActions'
import { useDataMapPurpose } from 'api/dataMap/queries/useDataMapPurpose'
import { relativePath } from 'utils/routing/relativePath'
import { Chip, ViewLayout } from '@ketch-com/deck'
import { useEffect, useMemo, useState } from 'react'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const DataMapPurposeContainer: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const { data: purpose, isLoading } = useDataMapPurpose({ params: { purposeCode: code } })

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Map', link: RoutesManager.insights.dataMap.purposes.root.getURL() },
    { title: purpose?.name || 'Loading...', link: RoutesManager.insights.dataMap.view.root.getURL({ code }) },
  ]

  const tabs = useMemo(
    () => [
      {
        id: 'overview',
        label: 'Overview',
        link: {
          pathname: relativePath(RoutesManager.insights.dataMap.view.overview.root.pattern),
          search: location.search,
        },
      },
      // TODO:JB - Re-enable when backend is ready
      // {
      //   id: 'dataSystems',
      //   title: 'Data Systems',
      //   link: {
      //     pathname: relativePath(RoutesManager.insights.dataMap.view.dataSystems.root.pattern),
      //     search,
      //   },
      // },
      {
        id: 'processingActivities',
        label: 'Processing Activities',
        link: {
          pathname: relativePath(RoutesManager.insights.dataMap.view.processingActivities.root.pattern),
          search: location.search,
        },
      },
    ],
    [location.search],
  )

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (location.pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [location.pathname, tabs])
  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: purpose.name || (isLoading ? 'Loading...' : 'Untitled Purpose'),
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.insights.dataMap.purposes.root.getURL()),
          },
          infoBlockComponent: <Chip size="small" label="Purpose" />,
          actionRightBlockComponent: <PurposeActions lastUpdated={purpose?.metadata?.updatedAt} />,
        }}
        tabsComponentProps={{
          value: currentTab,
          onChange: (_, value) => {
            navigate(tabs[value].link)
          },
          tabItems: tabs,
        }}
      >
        <DataMapPurposeRoutes purposeCode={code!} />
      </ViewLayout>
    </>
  )
}
