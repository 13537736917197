import { Button, EmptyState, Spinner, ListLayout } from '@ketch-com/deck'
import React, { useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as EmptyStateTeams } from 'assets/icons/emptyState/EmptyStateTeams.svg'
import { useTeams } from 'api/teams/queries/useTeams'
import { Box, Tooltip } from '@mui/material'
import { showToast } from 'components/modals/AlertComponent'
import { TeamsListItem } from './components/TeamsListItem'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

const teamRoutePathWithId = '/settings/teams/:id'

export const TeamsList: React.FC = () => {
  const navigate = useNavigate()
  const match = useMatch(teamRoutePathWithId)

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Teams', link: RoutesManager.settings.teams.root.getURL() },
  ]

  const { isPermitted } = useIsPermitted()
  const isTeamsWriter = isPermitted(PERMISSIONS.TEAM_WRITE)

  // Control child accordion open/close state at this level so that only one can be open at a time
  const [openTeam, setOpenTeam] = useState<string | undefined>(match?.params?.id || undefined)

  const { data, isLoading, error } = useTeams({
    onError: () => {
      showToast({ type: 'error', content: 'Error occured while loading teams list' })
    },
  })

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Teams"
        customHeaderRightActionComponent={
          <Tooltip placement="bottom" title={isTeamsWriter ? '' : 'You do not have permission to create a team.'}>
            <span>
              <Button
                size="medium"
                onClick={() => navigate(RoutesManager.settings.teams.upsert.root.getURL({}))}
                disabled={!isTeamsWriter}
              >
                Create Team
              </Button>
            </span>
          </Tooltip>
        }
        headerProps={{
          isDivider: true,
        }}
      >
        {error || !data?.teams?.length ? (
          <EmptyState
            customIcon={<EmptyStateTeams />}
            title="Go Team!"
            subTitle="Do big things together. Click the button below to create your first team for collaborative work."
            primaryButtonTitle="Create Team"
            primaryButtonProps={{
              onClick: () => navigate(RoutesManager.settings.teams.upsert.root.getURL({})),
              disabled: !isTeamsWriter,
            }}
            primaryButtonTooltipTitle={isTeamsWriter ? '' : 'You do not have permission to create a team.'}
          />
        ) : (
          <Box>
            <Box px={2.25} py={3} gap={3}>
              {data.teams.map(team => (
                <TeamsListItem
                  key={team.id}
                  team={team}
                  isOpen={openTeam === team.id}
                  setIsOpen={(isOpen: boolean) => setOpenTeam(isOpen ? team.id : undefined)}
                />
              ))}
            </Box>
          </Box>
        )}
      </ListLayout>
    </>
  )
}
