import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import clsx from 'clsx'
import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { formatCount, getRiskScoreDetails } from 'pages/insights/utils'
import { summaryMockData } from 'pages/insights/dataRisks/__mocks__/dataRisks'

const PREFIX = 'DataRisksIntelligenceSummary'

const classes = {
  dataRisksIntelligenceSummaryContainer: `${PREFIX}-dataRisksIntelligenceSummaryContainer`,
  dataRisksIntelligenceSummary: `${PREFIX}-dataRisksIntelligenceSummary`,
  dataRisksIntelligenceBarChart: `${PREFIX}-dataRisksIntelligenceBarChart`,
  dataRisksIntelligenceRiskCategory: `${PREFIX}-dataRisksIntelligenceRiskCategory`,
  dataRisksIntelligenceSummaryHeading: `${PREFIX}-dataRisksIntelligenceSummaryHeading`,
  dataRisksIntelligenceSummaryContent: `${PREFIX}-dataRisksIntelligenceSummaryContent`,
  dataRisksIntelligenceSummaryTabsContainer: `${PREFIX}-dataRisksIntelligenceSummaryTabsContainer`,
  dataRisksIntelligenceSummaryTabsRoot: `${PREFIX}-dataRisksIntelligenceSummaryTabsRoot`,
  dataRisksIntelligenceSummaryTabs: `${PREFIX}-dataRisksIntelligenceSummaryTabs`,
  dataRisksIntelligenceSummaryTabsContentContainer: `${PREFIX}-dataRisksIntelligenceSummaryTabsContentContainer`,
  dataRisksIntelligenceSummaryTabsContent: `${PREFIX}-dataRisksIntelligenceSummaryTabsContent`,
  dataRisksIntelligenceSummaryTabsContentHighlightText: `${PREFIX}-dataRisksIntelligenceSummaryTabsContentHighlightText`,
  dataRisksIntelligenceSummaryTabsContentText: `${PREFIX}-dataRisksIntelligenceSummaryTabsContentText`,
  dataRisksIntelligenceSummaryTabsContentSecText: `${PREFIX}-dataRisksIntelligenceSummaryTabsContentSecText`,
  dataRisksIntelligenceRiskCategoryItem: `${PREFIX}-dataRisksIntelligenceRiskCategoryItem`,
  dataRisksIntelligenceRiskCategoryText: `${PREFIX}-dataRisksIntelligenceRiskCategoryText`,
  dataRisksIntelligenceRiskCategoryValue: `${PREFIX}-dataRisksIntelligenceRiskCategoryValue`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.dataRisksIntelligenceSummaryContainer}`]: {
    flex: '0 1 823px',
    backgroundColor: palette.darkDusk.main,
    borderRadius: spacing(1.5),
    display: 'flex',
    justifyContent: 'stretch',
  },

  [`& .${classes.dataRisksIntelligenceSummary}`]: {
    flex: 2,
    padding: spacing(4),
    display: 'flex',
    flexFlow: 'column wrap',
  },

  [`& .${classes.dataRisksIntelligenceBarChart}`]: {
    flex: '0 1 170px',
    display: 'flex',
    flexFlow: 'column wrap',
  },

  [`& .${classes.dataRisksIntelligenceRiskCategory}`]: {
    flex: '0 1 170px',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
  },

  [`& .${classes.dataRisksIntelligenceSummaryHeading}`]: {
    flex: 1,
    color: palette.white.main,
    fontSize: 20,
    fontWeight: 700,
  },

  [`& .${classes.dataRisksIntelligenceSummaryContent}`]: {
    flex: 4,
    display: 'flex',
    flexFlow: 'column wrap',
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContainer}`]: {
    flex: 1,
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsRoot}`]: {
    backgroundColor: 'rgba(240, 241, 245, 0.15)',
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabs}`]: {
    color: palette.white.main,
    '&:not($dataRisksIntelligenceSummaryTabsRoot):hover': {
      color: palette.white.main,
    },
    '&:focus': {
      color: palette.darkDusk.main,
    },
    '&.Mui-selected': {
      color: palette.darkDusk.main,
    },
    '&.Mui-selected:hover': {
      color: palette.darkDusk.main,
    },
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContentContainer}`]: {
    display: 'flex',
    gap: spacing(4),
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContent}`]: {
    display: 'flex',
    flexFlow: 'column wrap',
    fontSize: 12,
    fontWeight: 600,
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContentHighlightText}`]: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 'normal',
    color: palette.white.main,
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContentText}`]: {
    color: palette.white.main,
    fontSize: 12,
    fontWeight: 600,
  },

  [`& .${classes.dataRisksIntelligenceSummaryTabsContentSecText}`]: {
    color: 'rgba(255, 255, 255, 0.6)',
  },

  [`& .${classes.dataRisksIntelligenceRiskCategoryItem}`]: {
    padding: spacing(0, 3),
  },

  [`& .${classes.dataRisksIntelligenceRiskCategoryText}`]: {
    fontSize: 12,
    fontWeight: 500,
    display: 'block',
  },

  [`& .${classes.dataRisksIntelligenceRiskCategoryValue}`]: {
    color: palette.white.main,
    fontSize: 14,
    fontWeight: 600,
  },
}))

enum SummaryMode {
  DATA = 'Data',
  SYSTEMS = 'Systems',
}

const SUMMARY_MODE_OPTIONS = [
  {
    id: SummaryMode.DATA,
    name: SummaryMode.DATA,
  },
  {
    id: SummaryMode.SYSTEMS,
    name: SummaryMode.SYSTEMS,
  },
]

//TODO: Real Data
export const DataRisksIntelligenceSummary: React.FC = () => {
  const [summaryMode, setSummaryMode] = useState(SummaryMode.DATA)
  const data = summaryMode === SummaryMode.DATA ? summaryMockData.data : summaryMockData.systems // TODO: replace with real data
  const { extreme = 0, critical = 0, tolerable = 0, acceptable = 0, dataAssets = 0, piiRecords = 0 } = data
  const total = extreme + critical + tolerable + acceptable
  const extremeRiskScorePercentage = (extreme / total) * 100
  const criticalRiskScorePercentage = (critical / total) * 100
  const tolerableRiskScorePercentage = (tolerable / total) * 100
  const acceptableRiskScorePercentage = (acceptable / total) * 100
  const extremeRiskScoreDetails = getRiskScoreDetails(100)
  const criticalRiskScoreDetails = getRiskScoreDetails(70)
  const tolerableRiskScoreDetails = getRiskScoreDetails(40)
  const acceptableRiskScoreDetails = getRiskScoreDetails(20)

  return (
    <StyledBox className={classes.dataRisksIntelligenceSummaryContainer}>
      <Box className={classes.dataRisksIntelligenceSummary}>
        <Text className={classes.dataRisksIntelligenceSummaryHeading}>Summary</Text>
        <Box className={classes.dataRisksIntelligenceSummaryContent}>
          <Box className={classes.dataRisksIntelligenceSummaryTabsContainer}>
            <SegmentTabs
              className={classes.dataRisksIntelligenceSummaryTabsRoot}
              tabClassName={classes.dataRisksIntelligenceSummaryTabs}
              tabs={SUMMARY_MODE_OPTIONS}
              value={summaryMode}
              onChange={setSummaryMode}
            />
          </Box>
          <Box className={classes.dataRisksIntelligenceSummaryTabsContentContainer}>
            <>
              <Box className={classes.dataRisksIntelligenceSummaryTabsContent}>
                <Text className={classes.dataRisksIntelligenceSummaryTabsContentHighlightText}>
                  {formatCount(dataAssets)}
                </Text>
                <Text className={classes.dataRisksIntelligenceSummaryTabsContentText}>Total Data Assets</Text>
              </Box>
              <Box className={classes.dataRisksIntelligenceSummaryTabsContent}>
                <Text
                  className={clsx(
                    classes.dataRisksIntelligenceSummaryTabsContentHighlightText,
                    classes.dataRisksIntelligenceSummaryTabsContentSecText,
                  )}
                >
                  {formatCount(piiRecords)}
                </Text>
                <Text
                  className={clsx(
                    classes.dataRisksIntelligenceSummaryTabsContentText,
                    classes.dataRisksIntelligenceSummaryTabsContentSecText,
                  )}
                >
                  PII Records
                </Text>
              </Box>
            </>
          </Box>
        </Box>
      </Box>
      <Box className={classes.dataRisksIntelligenceBarChart}>
        <Box
          sx={{
            flex: `0 1 ${extremeRiskScorePercentage}%`,
            backgroundColor: `${extremeRiskScoreDetails.secondaryTextColor}`,
          }}
        ></Box>
        <Box
          sx={{
            flex: `0 1 ${criticalRiskScorePercentage}%`,
            backgroundColor: `${criticalRiskScoreDetails.secondaryTextColor}`,
          }}
        ></Box>
        <Box
          sx={{
            flex: `0 1 ${tolerableRiskScorePercentage}%`,
            backgroundColor: `${tolerableRiskScoreDetails.secondaryTextColor}`,
          }}
        ></Box>
        <Box
          sx={{
            flex: `0 1 ${acceptableRiskScorePercentage}%`,
            backgroundColor: `${acceptableRiskScoreDetails.secondaryTextColor}`,
          }}
        ></Box>
      </Box>
      <Box className={classes.dataRisksIntelligenceRiskCategory}>
        <Box className={classes.dataRisksIntelligenceRiskCategoryItem}>
          <Text
            className={classes.dataRisksIntelligenceRiskCategoryText}
            color={extremeRiskScoreDetails.secondaryTextColorName}
          >
            Extreme
          </Text>
          <Text className={classes.dataRisksIntelligenceRiskCategoryValue}>{formatCount(extreme)}</Text>
        </Box>
        <Box className={classes.dataRisksIntelligenceRiskCategoryItem}>
          <Text
            className={classes.dataRisksIntelligenceRiskCategoryText}
            color={criticalRiskScoreDetails.secondaryTextColorName}
          >
            Critical
          </Text>
          <Text className={classes.dataRisksIntelligenceRiskCategoryValue}>{formatCount(critical)}</Text>
        </Box>
        <Box className={classes.dataRisksIntelligenceRiskCategoryItem}>
          <Text
            className={classes.dataRisksIntelligenceRiskCategoryText}
            color={tolerableRiskScoreDetails.secondaryTextColorName}
          >
            Tolerable
          </Text>
          <Text className={classes.dataRisksIntelligenceRiskCategoryValue}>{formatCount(tolerable)}</Text>
        </Box>
        <Box className={classes.dataRisksIntelligenceRiskCategoryItem}>
          <Text
            className={classes.dataRisksIntelligenceRiskCategoryText}
            color={acceptableRiskScoreDetails.secondaryTextColorName}
          >
            Acceptable
          </Text>
          <Text className={classes.dataRisksIntelligenceRiskCategoryValue}>{formatCount(acceptable)}</Text>
        </Box>
      </Box>
    </StyledBox>
  )
}
