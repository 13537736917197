import React from 'react'
import { Formik } from 'formik'
import { DateRange, DateRangeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { DatePickersActionBar, DatePickersActionBarProps, DateRangePicker } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type FormValues = {
  value: DateRange<Dayjs>
  previousValue: DateRange<Dayjs>
  isOpen: boolean
}

export const DateRangePickerExample: React.FC = () => {
  return (
    <Formik<FormValues>
      initialValues={{ value: [null, null], previousValue: [null, null], isOpen: false }}
      onSubmit={() => {}}
    >
      {formik => {
        const onCloseHandler = () => {
          formik.setFieldValue('isOpen', false)
        }

        const handleOnCancel = () => {
          formik.setFieldValue('value', formik.values.previousValue)
          formik.setFieldValue('isOpen', false)
        }

        const onChangeHandler = (
          newValue: DateRange<Dayjs> | undefined,
          context: PickerChangeHandlerContext<DateRangeValidationError>,
        ) => {
          if (newValue) {
            if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
              formik.setFieldValue('value', newValue)
            }
          }
        }

        const onClearHandler = () => {
          formik.setFieldValue('value', [null, null])
        }

        const onOpenHandler = () => {
          formik.setFieldValue('isOpen', true)
          formik.setFieldValue('previousValue', formik.values.value)
        }

        const onAcceptHandler = (newValue: DateRange<Dayjs>) => {
          formik.setFieldValue('previousValue', newValue)
          formik.setFieldValue('value', newValue)
          formik.setFieldValue('isOpen', false)
        }

        return (
          <>
            <Typography variant="h3" mb={2}>
              Date Range Picker component
            </Typography>

            <DateRangePicker
              open={formik.values.isOpen}
              onOpen={onOpenHandler}
              value={formik.values.value}
              onClose={onCloseHandler}
              onChange={onChangeHandler}
              onAccept={onAcceptHandler}
              slots={{
                actionBar: (props: DatePickersActionBarProps) => {
                  return (
                    <DatePickersActionBar {...props} handleOnClear={onClearHandler} handleOnCancel={handleOnCancel} />
                  )
                },
              }}
            />
            <pre>{JSON.stringify(formik.values, null, 2) || 'nothing to preview'}</pre>
          </>
        )
      }}
    </Formik>
  )
}
