import React, { useContext } from 'react'
import { Typography, Box } from '@mui/material'
import {
  IconDictionary,
  EmptyState,
  Spinner,
  theme,
  DropListButton,
  ActionSheetItem,
  ListItemText,
} from '@ketch-com/deck'
import { TrackerDiagnosticsContext, TrackerDetailsContext } from 'pages/policyCenter/trackers/context'
import { Waypoint } from 'react-waypoint'
import { LineageItem } from 'pages/policyCenter/trackers/details/components/diagnostics/components/LineageItem'

export const Lineages: React.FC = () => {
  const { properties } = useContext(TrackerDetailsContext)
  const {
    trackerLineages,
    isLineagesReady,
    isFetchingTrackerLineages,
    isFetchingNextTrackerLineages,
    hasNextTrackerLineages,
    fetchNextTrackerLineages,
    lineagesProperty,
    setLineagesProperty,
  } = useContext(TrackerDiagnosticsContext)
  const options = properties.map(property => ({
    label: property.name,
    value: property.code,
    platform: property.platform,
  }))
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={0.5} flexDirection="column">
        <Typography variant="h4">Common Lineages</Typography>
        <Typography variant="body" color="darkDuskFaded.main">
          Common paths that reveal if a specific tracker shares origins with others by linking it to the same parent
          entity or network, providing deeper insights into interconnected data flows, tracking behaviors, and potential
          cross-site tracking.
        </Typography>
      </Box>

      {!isLineagesReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={300}>
          <Spinner size={32} thickness={3} />
        </Box>
      ) : null}

      {isLineagesReady ? (
        <Box display="flex" flexDirection="column" gap={2}>
          <DropListButton
            value={lineagesProperty}
            width="200px"
            placeholder="All Properties"
            disabled={false}
            size="small"
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            getOptionLabel={option => option.label}
            renderOption={(props, option, { selected }) => {
              return (
                <ActionSheetItem key={option.value} selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.label}</ListItemText>
                </ActionSheetItem>
              )
            }}
            options={options}
            onChange={(event, newValue) => {
              setLineagesProperty(newValue)
            }}
          />

          {!trackerLineages?.length ? (
            <Box
              sx={{
                padding: '50px 0',
                borderRadius: '11px',
                backgroundColor: 'white.main',
                border: `1px solid ${theme.palette.Black.o24}`,
              }}
            >
              <EmptyState
                iconName={IconDictionary.OConnect}
                title="No data yet"
                subTitle="You'll see common lineages for the selected properties here."
              />
            </Box>
          ) : null}

          {trackerLineages?.length ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                maxHeight="800"
                overflow="auto"
                px={2}
                py={1}
                sx={{ border: `1px solid ${theme.palette.Black.o24}`, borderRadius: '11px' }}
              >
                {trackerLineages.map((lineage, i) => {
                  return <LineageItem key={`${lineage?.property?.code}${i}`} lineage={lineage} />
                })}

                {hasNextTrackerLineages ? (
                  <Box display="flex" justifyContent="center" alignItems="center" my={4}>
                    <Waypoint
                      onEnter={() => {
                        if (!isFetchingTrackerLineages && !isFetchingNextTrackerLineages && hasNextTrackerLineages)
                          fetchNextTrackerLineages()
                      }}
                    />
                    {isFetchingNextTrackerLineages ? <Spinner size={20} thickness={2} /> : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}
