import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  // input
  '& .MuiInputBase-input': {
    color: theme.palette.Text.Secondary,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: `1px solid ${theme.palette.iron.main}`,
  },

  // focus state
  '& .Mui-focused': {
    '& .MuiInputBase-input': {
      color: theme.palette.darkDusk.main,
      borderBottom: `1px solid ${theme.palette.sphere.main}`,
    },
  },

  // error state
  '& .Mui-error': {
    '& .MuiInputBase-input': {
      borderBottom: `1px solid ${theme.palette.chileanFire.main}`,
    },
  },
  // helper text
  '& .MuiFormHelperText-root': {
    color: `${theme.palette.chileanFire.main} !important`,
    fontSize: '12px',
    marginTop: '4px',
    fontWeight: '500',
  },
}))
