import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as NewSystemsIcon } from 'assets/icons/new_system_found.svg'
import { useNavigate } from 'react-router-dom'
import pluralize from 'pluralize'
import { useInstalledDataSystemsStats } from 'api/dataSystems/queries/useInstalledDataSystemsStats'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsEntitled, useIsPermitted } from 'utils/hooks'
import { Card, Icon, theme } from '@ketch-com/deck'
import { isUndefined } from 'lodash'
import { DiscoveredSystemsDrawer } from '../../../DiscoveredDataSystems/DiscoveredSystemsDrawer'
import { SystemsOverviewContext } from './Context/SystemsOverviewContext'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const OverviewHeader: React.FC = () => {
  const { data: installedDataSystemsStats } = useInstalledDataSystemsStats({
    params: {},
  })

  const { uninstalledDiscoveredSystems } = useContext(SystemsOverviewContext)

  const { installedDataSystemsCount, connectionCountByStatus } = installedDataSystemsStats

  const systemsCount = installedDataSystemsCount ?? 0
  const connectionsCount =
    (connectionCountByStatus?.[0] ?? 0) +
    (connectionCountByStatus?.[1] ?? 0) +
    (connectionCountByStatus?.[2] ?? 0) +
    (connectionCountByStatus?.[3] ?? 0)
  const connectionIssues = connectionCountByStatus?.[4] ?? 0

  const [showDiscoveredSystemsOverlay, setShowDiscoveredSystemsOverlay] = useState(false)

  const navigate = useNavigate()

  const { isPermitted } = useIsPermitted()
  const isDataSystemsWrite = isPermitted(PERMISSIONS.DATA_SYSTEMS_WRITE)

  const isDemo = isPermitted(PERMISSIONS.DEMO)

  const { isEntitled } = useIsEntitled()
  const isEntitledToDataMap = isEntitled(ENTITLEMENTS.DATA_MAPPING)

  return (
    <>
      <Box display="flex" gap={2.5} mb={6}>
        <Card
          cardVariant="stretchWidth"
          cardTitle={isUndefined(systemsCount) ? '0' : String(systemsCount)}
          subTitle={pluralize('System', systemsCount)}
          isNumerical
        />

        <Card
          cardVariant="stretchWidth"
          cardTitle={isUndefined(connectionIssues) ? '0' : String(connectionIssues)}
          subTitle={pluralize('Issue', connectionIssues)}
          backgroundColor={connectionIssues ? theme.palette.bleachWhite.main : theme.palette.white.main}
          textColor={connectionIssues ? theme.palette.chileanFire.main : theme.palette.darkDusk.main}
          primaryIcon={connectionIssues ? <Icon name="OImportant" width="32px" height="32px" /> : null}
          isNumerical
        />

        {/* Show new system found tile for demo */}
        {!isDemo ? (
          <Card
            cardVariant="stretchWidth"
            cardTitle={isUndefined(connectionsCount) ? '0' : String(connectionsCount)}
            subTitle={pluralize('Connection', connectionsCount)}
            isNumerical
          />
        ) : (
          isEntitledToDataMap &&
          !!uninstalledDiscoveredSystems.length && (
            <Card
              cardVariant="stretchWidth"
              cardTitle={String(uninstalledDiscoveredSystems.length)}
              subTitle="New Systems Found"
              backgroundColor={theme.palette.fadedHeliotrope.main}
              textColor={theme.palette.pink.main}
              isNumerical
              onClick={() => setShowDiscoveredSystemsOverlay(!showDiscoveredSystemsOverlay)}
              primaryIcon={<NewSystemsIcon />}
              isClickable
            />
          )
        )}

        {isDataSystemsWrite && (
          <Card
            cardVariant="fixedWidth"
            cardTitle="Add System"
            maxCardWidth="300px"
            textColor="white"
            isClickable
            onClick={() => {
              navigate(RoutesManager.systems.create.root.getURL())
            }}
          />
        )}
      </Box>

      {/* Discovered systems drawer */}
      {isDemo && (
        <DiscoveredSystemsDrawer
          open={showDiscoveredSystemsOverlay}
          onClose={() => setShowDiscoveredSystemsOverlay(false)}
        />
      )}
    </>
  )
}
