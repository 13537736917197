import { useState } from 'react'
import { useTrackerFoundOnDates } from 'api/trackers/queries/useTrackerFoundOnDates'
import { useTrackerFoundOnPagesCount } from 'api/trackers/queries/useTrackerFoundOnPagesCount'
import { useTrackerFoundOnPagesInfinite } from 'api/trackers/queries/useTrackerFoundOnPagesInfinite'
import { useTrackerLineagesInfinite } from 'api/trackers/queries/useTrackerLineagesInfinite'
import { useTrackerLineageManagedBy } from 'api/trackers/queries/useTrackerLineageManagedBy'
import { useTrackerLineageType } from 'api/trackers/queries/useTrackerLineageType'
import { useParams } from 'react-router-dom'
import { trackerURLParams } from 'utils/routing/routes/utils'
import dayjs, { Dayjs } from 'dayjs'
import { MaybeNull } from 'interfaces'
import { DateRange } from '@mui/x-date-pickers-pro'
import { PageDetail } from '@ketch-com/atlas/dist/apiTypes/foundOn'

export const useTrackerDiagnosticsUtils = () => {
  const [foundOnProperty, setFoundOnProperty] = useState<
    MaybeNull<{
      label: string
      value: string
      platform: number
    }>
  >(null)
  const [lineagesProperty, setLineagesProperty] = useState<
    MaybeNull<{
      label: string
      value: string
      platform: number
    }>
  >(null)
  const [foundOnStaticDateRange, setFoundOnStaticDateRange] = useState<DateRange<Dayjs>>([null, null])
  const [foundOnDynamicDateRange, setFoundOnDynamicDateRange] = useState<DateRange<Dayjs>>([null, null])
  const [foundOnModalData, setFoundOnModalData] = useState<MaybeNull<PageDetail>>(null)

  const { encodedTrackerKey } = useParams<trackerURLParams>()
  const { isLoading: isLoadingTrackerFoundOnPagesCount, data: trackerFoundOnPagesCount } = useTrackerFoundOnPagesCount({
    params: {
      encodedTrackerKey,
      property_code: foundOnProperty?.value,
      startDate: foundOnDynamicDateRange?.[0] ? dayjs(foundOnDynamicDateRange?.[0]).format('YYYY-MM-DD') : '',
      endDate: foundOnDynamicDateRange?.[1] ? dayjs(foundOnDynamicDateRange?.[1]).format('YYYY-MM-DD') : '',
    },
  })

  const { isLoading: isLoadingTrackerFoundOnDates, data: trackerFoundOnDates } = useTrackerFoundOnDates({
    params: {
      encodedTrackerKey,
      property_code: foundOnProperty?.value,
    },
    onSuccess: ({ data }) => {
      const trackerFoundOnDatesCount = data?.length || 0
      const startDate = dayjs(data?.[trackerFoundOnDatesCount - 1]) || null
      const endDate = dayjs(data?.[0]) || null
      setFoundOnStaticDateRange([startDate, endDate])
      setFoundOnDynamicDateRange([startDate, endDate])
    },
  })

  const {
    pages: trackerFoundOnPages,
    isLoading: isLoadingTrackerFoundOnPages,
    isFetching: isFetchingTrackerFoundOnPages,
    isFetchingNextPage: isFetchingNextTrackerFoundOnPages,
    hasNextPage: hasNextTrackerFoundOnPages,
    fetchNextPage: fetchNextTrackerFoundOnPages,
  } = useTrackerFoundOnPagesInfinite({
    enabled: Boolean(foundOnDynamicDateRange?.[0] && foundOnDynamicDateRange?.[1]),
    limit: 20,
    encodedTrackerKey,
    property_code: foundOnProperty?.value,
    startDate: foundOnDynamicDateRange?.[0] ? dayjs(foundOnDynamicDateRange?.[0]).format('YYYY-MM-DD') : '',
    endDate: foundOnDynamicDateRange?.[1] ? dayjs(foundOnDynamicDateRange?.[1]).format('YYYY-MM-DD') : '',
  })

  const {
    pages: trackerLineages,
    isLoading: isLoadingTrackerLineages,
    isFetching: isFetchingTrackerLineages,
    isFetchingNextPage: isFetchingNextTrackerLineages,
    hasNextPage: hasNextTrackerLineages,
    fetchNextPage: fetchNextTrackerLineages,
  } = useTrackerLineagesInfinite({
    limit: 20,
    encodedTrackerKey,
    property_code: lineagesProperty?.value,
  })

  const { isLoading: isLoadingTrackerLineageManagedBy, data: trackerLineageManagedBy } = useTrackerLineageManagedBy({
    params: {
      encodedTrackerKey,
    },
  })

  const { isLoading: isLoadingTrackerLineageType, data: trackerLineageType } = useTrackerLineageType({
    params: {
      encodedTrackerKey,
    },
  })

  const isFoundOnReady =
    !isLoadingTrackerFoundOnPages && !isLoadingTrackerFoundOnPagesCount && !isLoadingTrackerFoundOnDates
  const isLineagesReady = !isLoadingTrackerLineages
  const isTrackerLineageManagedByReady = !isLoadingTrackerLineageManagedBy
  const isTrackerLineageTypeReady = !isLoadingTrackerLineageType

  const isTrackerDiagnosticsReady =
    isFoundOnReady && isLineagesReady && isTrackerLineageManagedByReady && isTrackerLineageTypeReady

  return {
    isFoundOnReady,
    encodedTrackerKey,
    trackerFoundOnPages,
    trackerFoundOnPagesCount,
    trackerFoundOnDates,
    isLoadingTrackerFoundOnPages,
    isFetchingTrackerFoundOnPages,
    isFetchingNextTrackerFoundOnPages,
    hasNextTrackerFoundOnPages,
    fetchNextTrackerFoundOnPages,
    foundOnProperty,
    setFoundOnProperty,
    foundOnDynamicDateRange,
    setFoundOnDynamicDateRange,
    foundOnStaticDateRange,
    foundOnModalData,
    setFoundOnModalData,
    isLoadingTrackerFoundOnDates,
    isTrackerDiagnosticsReady,
    isLineagesReady,
    trackerLineages,
    isLoadingTrackerLineages,
    isFetchingTrackerLineages,
    isFetchingNextTrackerLineages,
    hasNextTrackerLineages,
    fetchNextTrackerLineages,
    lineagesProperty,
    setLineagesProperty,
    isTrackerLineageManagedByReady,
    trackerLineageManagedBy,
    isTrackerLineageTypeReady,
    trackerLineageType,
  }
}
