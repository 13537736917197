import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { FormSectionSwitch } from '../FormSectionSwitch'
import React, { SyntheticEvent, useContext } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { Tab, TabPanel, TabGroup, theme } from '@ketch-com/deck'
import { FormCheckbox } from '../FormCheckbox'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { UnsubscribeFromAllPosition } from '@ketch-sdk/ketch-types'
import { UnsubscribeFromAllPositionLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'

export const PreferenceExperienceSubscriptionsTabUnsubscribeAllForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Current tab of the unsubscribe from all form
  const [tab, setTab] = React.useState(0)

  const showForm = getIn(values, experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllVisible)
  const showCustomTitleForm = !getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitleUseDefaultText,
  )
  const showDescriptionForm = getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescriptionVisible,
  )
  const showSwitchButtonForm = getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsVisible,
  )
  const showCustomSwitchButtonLabelsForm = !getIn(
    values,
    experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsUseDefault,
  )

  const handleTitleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitle
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  const handleSwitchButtonDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset both the on and off text
      ;[
        experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOnText,
        experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOffText,
      ].forEach(englishFieldToReset => {
        // Reset to english default
        const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
        setFieldValue(englishFieldToReset, defaultEnglishValue)

        // Reset to default for each locale
        languages.forEach(({ language: { code } }) => {
          const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
          const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
          setFieldValue(localeFieldToReset, defaultLocaleValue)
        })
      })
    }
  }

  return (
    <>
      <FormSectionSwitch
        name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllVisible}
        title={'Unsubscribe From All'}
      />

      {showForm && (
        <SidebarFormGroup sx={{ pt: '12px' }}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
                <Tab size={'medium'} label="Title" />
                <Tab size={'medium'} label="Position" />
                <Tab size={'medium'} label="Description" />
                <Tab size={'medium'} label="Switch Button" />
                {/* TODO:JB - Impact descoped */}
                {/* <Tab size={'medium'} label="Impact" /> */}
              </TabGroup>
            </Box>

            {/* Title */}
            <TabPanel value={tab} index={0} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup hasBorderBottom={showCustomTitleForm && hasLanguages} sx={{ paddingTop: '16px' }}>
                <Box>
                  <FormControlLabel
                    control={
                      <FormCheckbox
                        name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitleUseDefaultText}
                        afterChange={handleTitleDefaultChange}
                      />
                    }
                    label={<Typography variant={'label'}>Use Default Text</Typography>}
                  />
                  <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
                    The default text “Unsubscribe from all” has translations for all available languages.
                  </Typography>
                </Box>
                {showCustomTitleForm && (
                  <FormTextInput
                    label={'Title Text'}
                    name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitle}
                    fullWidth
                    charLimit={30}
                  />
                )}
              </SidebarFormGroup>
              {showCustomTitleForm && (
                <SidebarFormTranslations
                  title={'Title Text Translations'}
                  name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllTitle}
                  variant={'text'}
                  charLimit={30}
                />
              )}
            </TabPanel>

            {/* Position */}
            <TabPanel value={tab} index={1} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup sx={{ paddingTop: '16px' }}>
                <SidebarFormRadioGroup
                  name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllPosition}
                  title={'Position'}
                  options={Object.values(UnsubscribeFromAllPosition).map(position => ({
                    label: UnsubscribeFromAllPositionLabels[position],
                    value: position,
                  }))}
                  row
                />
              </SidebarFormGroup>
            </TabPanel>

            {/* Description */}
            <TabPanel value={tab} index={2} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup sx={{ paddingTop: '16px', gap: '12px' }}>
                <FormSectionSwitch
                  name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescriptionVisible}
                  title={'Description'}
                  size={'small'}
                />
                {showDescriptionForm && (
                  <Box p={'0px 18px'}>
                    <SidebarFormGroup hasBorderBottom sx={{ paddingTop: 0 }}>
                      <FormTextInput
                        label={'Description Text'}
                        name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescription}
                        fullWidth
                        charLimit={200}
                      />
                    </SidebarFormGroup>
                    <SidebarFormTranslations
                      title={'Description Text Translations'}
                      name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllDescription}
                      variant={'text'}
                      charLimit={200}
                    />
                  </Box>
                )}
              </SidebarFormGroup>
            </TabPanel>

            {/* Switch Button */}
            <TabPanel value={tab} index={3} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup sx={{ paddingTop: '16px', gap: '8px' }}>
                <FormSectionSwitch
                  name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsVisible}
                  title={'Switch Button Label'}
                  size={'small'}
                />
                {showSwitchButtonForm && (
                  <Box p={'0px 18px'}>
                    <FormControlLabel
                      control={
                        <FormCheckbox
                          name={
                            experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchButtonLabelsUseDefault
                          }
                          afterChange={handleSwitchButtonDefaultChange}
                        />
                      }
                      label={<Typography variant={'label'}>Use Default Text</Typography>}
                    />
                    <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
                      The default text "On" and "Off" has translations for all available languages.
                    </Typography>
                    {showCustomSwitchButtonLabelsForm && (
                      <>
                        <SidebarFormGroup hasBorderBottom>
                          <FormTextInput
                            label={'Switch Button ON Text Label'}
                            name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOnText}
                            fullWidth
                            charLimit={30}
                          />
                          <FormTextInput
                            label={'Switch Button OFF Text Label'}
                            name={experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOffText}
                            fullWidth
                            charLimit={30}
                          />
                        </SidebarFormGroup>
                        <SidebarFormTranslations
                          title={'Switch Button Label Translations'}
                          switchButtonOnName={
                            experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOnText
                          }
                          switchButtonOffName={
                            experienceUpsertFormKeys.preferenceSubscriptionsTabUnsubscribeAllSwitchLabelsOffText
                          }
                          variant={'switchButtons'}
                          charLimit={30}
                        />
                      </>
                    )}
                  </Box>
                )}
              </SidebarFormGroup>
            </TabPanel>

            {/* Impact - Descoped */}
            {/* <TabPanel value={tab} index={3} sx={{ px: 0, pt: '12px' }}>
              <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '16px' }}>
                <Typography>Define how the Global Control feature impacts subscription statuses</Typography>
              </SidebarFormGroup>
            </TabPanel> */}
          </Box>
        </SidebarFormGroup>
      )}
    </>
  )
}
