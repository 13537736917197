import { useContext } from 'react'
import { InfoRowAssignUser } from 'pages/orchestration/rightsQueue/stepDetails/components/InfoRowAssignUser'
import { InternalSystemDsrContext } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/context'
import { Box, Typography } from '@mui/material'
import { Waypoint } from 'react-waypoint'
import { InternalSystemDsrExecution } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/InternalSystemDsr/components'
import { Banner, IconDictionary } from '@ketch-com/deck'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'

export const InternalSystemDsrDetails: React.FC = () => {
  const {
    workflowExecutionId,
    canReassign,
    stepDetails,
    stepId,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    dsrExecutionsV2,
    isTaskComplete,
    workflowStep,
  } = useContext(InternalSystemDsrContext)

  const resolvedAt = stepDetails?.executionData?.internalDSR?.resolvedAt
  const resolvedBy = stepDetails?.executionData?.internalDSR?.resolvedBy

  return (
    <Box>
      {workflowStep?.status === WorkflowExecutionStepStatus.FAIL ? (
        <Box mb={2}>
          <Banner isCompact severity="error" icon={IconDictionary.FImportant} title="Activity Needs Review">
            <Typography variant="body">
              This activity contains errors preventing it from being resolved automatically.
            </Typography>
          </Banner>
        </Box>
      ) : null}

      {workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS && resolvedAt && resolvedBy ? (
        <Box mb={2}>
          <Banner isCompact severity="warning" title="Manual Intervention">
            <Typography variant="body">This activity has been resolved manually.</Typography>
          </Banner>
        </Box>
      ) : null}

      <InfoRowAssignUser
        infoRowTitle="Assignee"
        assigneeInfo={stepDetails?.assigneeInfo || null}
        assignee={stepDetails.assignee}
        canEdit={canReassign}
        isComplete={isTaskComplete}
        workflowExecutionId={workflowExecutionId}
        workflowStepId={stepId}
      />

      <Box mt={6}>
        <Box mb={2}>
          {dsrExecutionsV2?.map(dsrExecution => {
            return <InternalSystemDsrExecution key={dsrExecution?.dbName} dsrExecution={dsrExecution} />
          })}
        </Box>
        <Waypoint
          onEnter={() => {
            if (!isFetchingNextPage && hasNextPage) fetchNextPage()
          }}
        />
      </Box>
    </Box>
  )
}
