import React, { useState, useEffect, MouseEvent } from 'react'
import { Box } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { useDebounce } from 'react-use'
import { TableCell, Button, Icon, TabSegmented, TabSegmentedGroup, DataGridCustomFilters } from '@ketch-com/deck'
import { useFiltersV3 } from 'utils/hooks'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useRightsQueueListTableUtils } from '../../orchestration/rightsQueue/listV2/components/RightsQueueListTable/hooks/useRightsQueueListTableUtils'

export const useRightsQueueSearchInputUtils = () => {
  const { removeSingleSearchParam, replaceParams, reduxSearchParams } = useFiltersV3()
  const [searchString, setSearchString] = useState<string>(() => {
    return reduxSearchParams?.query || ''
  })

  useDebounce(
    () => {
      if (searchString) {
        replaceParams({ query: searchString })
      }
      if (!searchString) {
        removeSingleSearchParam('query')
      }
    },
    500,
    [searchString],
  )

  useEffect(() => {
    if (!reduxSearchParams?.query) {
      setSearchString(reduxSearchParams?.query || '')
    }
  }, [reduxSearchParams?.query])

  return {
    setSearchString,
    searchString,
  }
}

const useDataGridData = () => {
  const rightsQueueListTableUtils = useRightsQueueListTableUtils()
  const { rightsQueueListTableColumnConfig, rightInvocations } = rightsQueueListTableUtils

  const columns = rightsQueueListTableColumnConfig.map(column => ({
    field: column.id,
    headerName: column.label,
    renderCell: (params: GridRenderCellParams) => (
      <TableCell>{column?.cellRenderer?.(params.row.originalRowData)}</TableCell>
    ),
    width: column.id === 'subject-type' ? 250 : column.minWidth,
    sortable: false,
  }))

  const rows = rightInvocations.map(row => ({
    id: row.id,
    request_status: row.requestStatus,
    'subject-name': row.rightName,
    'request-code': row.rightCode,
    'current-activity': row.requestStatusText,
    progress: row.status,
    workflow: row.workflowExecutionID,
    invoked_at: formatDateTimeFromUnix(row.invokedAt),
    'subject-type': row.rightName,
    originalRowData: row,
  }))

  return {
    columns,
    rows,
  }
}

export const DataGridCustomFiltersExample: React.FC = () => {
  const { rows, columns } = useDataGridData()
  const [dataPickerValue, setDataPickerValue] = useState<any>([null, null])
  const { setSearchString, searchString } = useRightsQueueSearchInputUtils()
  const [alignment, setAlignment] = useState('All')

  const onChangeHandler = (newValue: DateRange<Dayjs> | undefined) => {
    if (newValue) {
      if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
        setDataPickerValue(newValue)
      }
    }
  }

  const onChangeSegmentTabs = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }

  return (
    <Box style={{ width: 'auto' }}>
      <DataGridCustomFilters
        columns={columns}
        rows={rows}
        inputProps={{
          onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const nextSearchString = e.target.value
            setSearchString(nextSearchString)
          },
          value: searchString,
          placeholder: 'Search',
        }}
        datePickerProps={{
          value: dataPickerValue,
          onChange: onChangeHandler,
        }}
        buttonsBlock={() => (
          <>
            <Button color="tertiary">Action</Button>
            <Button color="tertiary">Action</Button>
            <Button color="tertiary">Action</Button>
            <Button color="secondary" endIcon={<Icon name="OArrowCDown" />}>
              Filter
            </Button>
          </>
        )}
        segmentTabs={() => (
          <TabSegmentedGroup value={alignment} onChange={onChangeSegmentTabs}>
            <TabSegmented value="All" key="1">
              All
            </TabSegmented>
            <TabSegmented value="A" key="2">
              A
            </TabSegmented>
            <TabSegmented value="B" key="3">
              B
            </TabSegmented>
            <TabSegmented value="C" key="4">
              C
            </TabSegmented>
          </TabSegmentedGroup>
        )}
      />
    </Box>
  )
}
