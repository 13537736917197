import { AssetSummaryDTO } from '@ketch-com/figurehead'
import moment from 'moment'
import { getAmPm } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/utils'
import { dayMapping, frequencyMapping } from '../constants'
import { ClassificationScheduleFormValues } from '../types'

export interface GetInitialSchedulingValuesArgs {
  assetSummary?: AssetSummaryDTO
}
export interface GetInitialSchedulingValuesPayload {
  schedule: ClassificationScheduleFormValues
}

export const getInitialSchedulingValues = ({
  assetSummary,
}: GetInitialSchedulingValuesArgs): GetInitialSchedulingValuesPayload => {
  const payload = {
    schedule: {
      time: assetSummary?.asset?.schedule?.time
        ? moment(new Date(assetSummary?.asset?.schedule?.time)).format('h:mm')
        : '',
      frequency: assetSummary?.asset?.schedule?.frequency
        ? frequencyMapping[`${assetSummary.asset.schedule.frequency}`]
        : frequencyMapping['1'],
      executionDuration: assetSummary?.asset?.schedule?.executionDuration
        ? assetSummary.asset.schedule.executionDuration
        : '6h',
      sampleSize: assetSummary?.asset?.schedule?.sampleSize
        ? assetSummary.asset.schedule.sampleSize.toString()
        : '1000',
      day: assetSummary?.asset?.schedule?.day ? dayMapping[`${assetSummary.asset.schedule.day}`] : undefined,
      period: assetSummary?.asset?.schedule?.time ? getAmPm(new Date(assetSummary?.asset?.schedule?.time)) : '1',
    },
  }

  return payload
}
