import React, { useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import {
  IconDictionary,
  theme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Chip,
  Button,
} from '@ketch-com/deck'
import { Lineage } from '@ketch-com/atlas/dist/apiTypes/lineage'
import { InitiatorTypeTextMap, PlatformTypeTextMap } from 'pages/policyCenter/trackers/constants'
import pluralize from 'pluralize'

type Props = {
  lineage: Lineage
}

export const LineageItem: React.FC<Props> = ({ lineage }) => {
  const trackerLineageInitiators = lineage?.initiators || []
  const totalInitiatorCount = trackerLineageInitiators.length
  const hiddenInitiatorDefaultCount = totalInitiatorCount > 5 ? totalInitiatorCount - 5 : 0
  const visibleTrackerLineageInitiators = hiddenInitiatorDefaultCount
    ? trackerLineageInitiators.slice(0, 5)
    : trackerLineageInitiators
  const [isExpanded, setIsExpanded] = useState(true)
  const [hiddenInitiatorCount, setHiddenInitiatorCount] = useState(hiddenInitiatorDefaultCount)

  return (
    <Box my={1}>
      <Accordion
        color={theme.palette.Black.o4}
        expanded={isExpanded}
        sx={{ border: `1px solid ${theme.palette.Black.o16}` }}
      >
        <AccordionSummary
          icon={IconDictionary.OArrowCRight}
          onClick={() => {
            setIsExpanded(isExpanded => !isExpanded)
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" flexBasis="100%">
            <Box display="flex" flexDirection="column" gap={0.25}>
              <Typography variant="h4">{lineage?.property?.name}</Typography>
              <Typography variant="smallBody" color={theme.palette.text.secondary}>
                {PlatformTypeTextMap[lineage?.property?.platform] ?? 'Unknown'}
              </Typography>
            </Box>
            <Chip
              size="small"
              label={
                <Typography variant="smallLabel">
                  {totalInitiatorCount} {pluralize('Event', totalInitiatorCount)}
                </Typography>
              }
            />
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {isExpanded ? (
            <>
              {visibleTrackerLineageInitiators.map((initiator, i) => {
                const currentInitiatorType = initiator?.initiatorType?.[0]
                return (
                  <Box key={`${initiator?.url}${i}`} display="flex" alignItems="center" gap={1} mb={2}>
                    <Icon
                      name={IconDictionary.OArrowCorner}
                      width={24}
                      height={24}
                      iconColor={theme.palette.Black.o100}
                    />

                    {initiator?.dataSystem?.logo_url ? (
                      <img
                        src={initiator?.dataSystem?.logo_url}
                        alt={initiator?.dataSystem?.name}
                        width={24}
                        height={24}
                      />
                    ) : null}

                    <Box component="div" display="flex" flexDirection="column" gap={0.5}>
                      {initiator?.dataSystem?.name ? (
                        <Typography component="div" variant="body">
                          {initiator?.dataSystem?.name}
                        </Typography>
                      ) : null}

                      {initiator?.url ? (
                        <Typography
                          component="div"
                          variant="footnote"
                          color={theme.palette.Text.Secondary}
                          width={700}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {initiator?.url}
                        </Typography>
                      ) : null}

                      {currentInitiatorType ? (
                        <Box component="div" display="flex" alignItems="center" gap={0.5}>
                          <Chip size="small" label={InitiatorTypeTextMap[currentInitiatorType]} />
                          {/* TODO: update tooltip text */}
                          <Tooltip
                            title={
                              <Typography variant="label">{InitiatorTypeTextMap[currentInitiatorType]}</Typography>
                            }
                          >
                            <span>
                              <Icon
                                name={IconDictionary.FUnknown}
                                iconColor={theme.palette.Black.o48}
                                width={16}
                                height={16}
                                sx={{ marginTop: '6px' }}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                )
              })}

              {hiddenInitiatorCount ? (
                <Button
                  color={'tertiary'}
                  onClick={() => {
                    setHiddenInitiatorCount(0)
                  }}
                >
                  View More ({hiddenInitiatorCount})
                </Button>
              ) : null}
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
