import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { FieldArray } from 'formik'
import Box from '@mui/material/Box'
import { emptyVariableMapping, VariableClaimMappingDestination, destinationItems } from '../constants'
import { Button, FormRow, Icon } from '@ketch-com/deck'
import { CreateAuthenticatorFormValues } from '../utils/getAuthenticatorInitialValues'
import { AuthenticatorUpsertContext } from '../context/AuthenticatorUpsertContext'
import { ensureArray } from 'utils/helpers/array'
import { Typography } from '@mui/material'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormError } from 'components/form/FormError'

const { DATA_SUBJECT, DATA_SUBJECT_VARIABLES, IDENTITY_SPACE } = VariableClaimMappingDestination

export const CreateAuthenticatorVariablesMappingFormSection: React.FC = () => {
  const { allFormFields, dataSubjectVariables, identitySpaces } = useContext(AuthenticatorUpsertContext)
  const { values, errors } = useFormikContext<CreateAuthenticatorFormValues>()

  const identitySpaceItems = identitySpaces
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map(identitySpace => ({
      id: identitySpace.code,
      name: identitySpace.name,
    }))

  const formFieldItems = ensureArray(allFormFields)?.map?.(field => ({
    id: field.code,
    name: field.name,
  }))

  const dataSubjectVariableItems = dataSubjectVariables.map(variable => ({
    id: variable.code,
    name: variable.code,
  }))

  return (
    <Box>
      <FormRow
        title="Variables Mapping"
        subTitle="Define mappings between JWT token claim keys and Ketch Forwarder object location"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="label" sx={{ flex: 5.1 }}>
            Variables
          </Typography>

          <Typography variant="label" sx={{ flex: 3.7 }}>
            Type
          </Typography>

          <Typography variant="label" sx={{ flex: 3.7 }}>
            Options
          </Typography>

          <Box flex={0.5} />
        </Box>
        <FieldArray name="variableClaimMappings">
          {({ push, remove }) => (
            <>
              {values?.variableClaimMappings?.map?.((mapping, index: number) => {
                const destinationKeyInputName = `variableClaimMappings[${index}].destinationKey`
                const sourceKeyInputName = `variableClaimMappings[${index}].sourceKey`
                const destinationInputName = `variableClaimMappings[${index}].destination`

                return (
                  <Box key={`${index}-variableClaimMappings`} mb={1.625}>
                    <Box display="flex" alignItems="center" gap={3}>
                      {/* Variables Input */}

                      <Box
                        sx={{
                          flex: 5.1,
                        }}
                      >
                        <FormInput
                          size="medium"
                          formPropertyName={sourceKeyInputName}
                          shouldUpdateDebounced
                          fullWidth
                          placeholder="Enter Variable"
                          required
                        />
                      </Box>

                      {/* Type Input */}
                      <Box
                        sx={{
                          flex: 3.7,
                        }}
                      >
                        <FormDroplistButton
                          required
                          disableClearable
                          valueKey="id"
                          formPropertyName={destinationInputName}
                          placeholder="Select Type"
                          fullWidth={true}
                          items={destinationItems}
                        />
                      </Box>

                      {/* Options Input, input determined by type */}

                      {mapping.destination === IDENTITY_SPACE ? (
                        <Box
                          sx={{
                            flex: 3.7,
                          }}
                        >
                          <FormDroplistButton
                            required
                            disableClearable
                            valueKey="id"
                            formPropertyName={destinationKeyInputName}
                            placeholder="Select Identifier"
                            fullWidth={true}
                            items={identitySpaceItems}
                          />
                        </Box>
                      ) : null}

                      {mapping.destination === DATA_SUBJECT ? (
                        <Box
                          sx={{
                            flex: 3.7,
                          }}
                        >
                          <FormDroplistButton
                            required
                            disableClearable
                            valueKey="id"
                            formPropertyName={destinationKeyInputName}
                            placeholder="Select Subject Form Data"
                            fullWidth={true}
                            items={formFieldItems}
                          />
                        </Box>
                      ) : null}

                      {mapping.destination === DATA_SUBJECT_VARIABLES ? (
                        <Box
                          sx={{
                            flex: 3.7,
                          }}
                        >
                          <FormDroplistButton
                            required
                            disableClearable
                            valueKey="id"
                            formPropertyName={destinationKeyInputName}
                            placeholder="Select Type"
                            fullWidth={true}
                            items={dataSubjectVariableItems}
                          />
                        </Box>
                      ) : null}

                      {!mapping.destination ? (
                        <Box
                          sx={{
                            flex: 3.7,
                          }}
                        >
                          <FormDroplistButton
                            required
                            disableClearable
                            valueKey="id"
                            formPropertyName="placeholder"
                            placeholder="Select Type First"
                            fullWidth={true}
                            disabled
                            items={[]}
                          />
                        </Box>
                      ) : null}

                      {/* Delete Button */}

                      <Box display="flex" alignItems="center" justifyContent="center" flex={0.5}>
                        {values?.variableClaimMappings?.length === 1 ? null : (
                          <Button
                            id={index.toString()}
                            variant="iconCustomRounded"
                            color="tertiary"
                            onClick={() => remove(index)}
                            disabled={values?.variableClaimMappings?.length === 1}
                          >
                            <Icon name="OBin" />
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}

              {typeof errors?.variableClaimMappings === 'string' ? (
                <Box display="flex" alignItems="center" mt={1.625}>
                  <FormError msg={errors?.variableClaimMappings} />
                </Box>
              ) : null}

              <Box display="flex" alignItems="center" mt={1.625} mb={6}>
                <Button color="secondary" onClick={() => push(emptyVariableMapping)}>
                  Add
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      </FormRow>
    </Box>
  )
}
