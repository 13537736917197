import React, { useState } from 'react'
import pluralize from 'pluralize'
import { Box, Popover, Typography } from '@mui/material'
import { Chip, Icon, IconDictionary, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { AddRemovePurposes } from 'pages/policyCenter/trackers/components'

type Props = {
  tracker: UnapprovedTracker
}

export const Purposes: React.FC<Props> = ({ tracker = {} as UnapprovedTracker }) => {
  const { purposes: trackerPurposesItems = [] } = tracker
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const trackerPurposesItemsLength = trackerPurposesItems?.length
  const hasOnlyAutoAssignedPurposes = tracker?.purposes?.every(purpose => purpose?.isAutoAssigned)
  const hasNoAutoAssignedPurposes = tracker?.purposes?.every(purpose => !purpose?.isAutoAssigned)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (isPermittedToWriteCookie) setAnchorEl(event.currentTarget)
  }

  return (
    <Box>
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            {trackerPurposesItemsLength
              ? `${trackerPurposesItemsLength} ${pluralize('Purpose', trackerPurposesItemsLength)}`
              : 'No purposes'}
          </Typography>
        }
        clickable={isPermittedToWriteCookie}
        onClick={handleClick}
        deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
        onDelete={handleClick}
        icon={
          trackerPurposesItemsLength && !hasNoAutoAssignedPurposes ? (
            <Box mt={1}>
              <Icon
                name={IconDictionary.FAutomation}
                iconColor={hasOnlyAutoAssignedPurposes ? theme.palette.greenHaze.main : theme.palette.Text.Secondary}
                width={16}
                height={16}
              />
            </Box>
          ) : (
            <></>
          )
        }
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box width={460} p={2}>
          <AddRemovePurposes tracker={tracker} boltStatusEnabled={true} />
        </Box>
      </Popover>
    </Box>
  )
}
