import { getIn, useFormikContext } from 'formik'
import { ItemStyle } from '@ketch-sdk/ketch-types'
import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Button, Icon, Tab, TabPanel, TabGroup, theme } from '@ketch-com/deck'
import { ItemStyleLabels } from '../../../../../../experiences-v2/upsert/utils/labels'
import { FormColorInput } from '../FormColorInput'
import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { Typography } from '@mui/material'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import {
  BuilderTextInputMode,
  FormTextInput,
} from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormTextInput'
import { SidebarFormRadioGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormRadioGroup'
import { ThemeUpsertContext } from 'pages/consentAndRights/themes-v3/upsert/context/ThemeUpsertContext'
import { BannerThemeBuilderSection } from 'pages/consentAndRights/experiences-v2/upsert/components/builder/utils/enums'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { handleButtonStyleChange } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'

const themeBuilderSectionTabMap: { [section: string]: number } = {
  [BannerThemeBuilderSection.ActionButtons]: 0,
  [BannerThemeBuilderSection.ActionButtonsSecondary]: 1,
  [BannerThemeBuilderSection.ActionButtonsTertiary]: 2,
}

export const BannerThemeButtonsForm: React.FC = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<ThemeV3DTO>()
  const { themeBuilderSection } = useContext(ThemeUpsertContext)

  // Current tab of the button form
  const [tab, setTab] = React.useState(0)

  // Copy the primary button theme to the secondary or tertiary one
  const handleApplyPrimaryClick = (copyTo: string) => {
    const primaryButtonTheme = getIn(values, themeUpsertFormKeys.bannerPrimaryButton)
    setFieldValue(copyTo, primaryButtonTheme)
  }

  // Update the current tab of this form when the theme builder section changes
  useEffect(() => {
    if (themeBuilderSection) {
      setTab(themeBuilderSectionTabMap[themeBuilderSection])
    } else {
      setTab(0)
    }
  }, [themeBuilderSection, isSubmitting])

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
          <Tab size={'medium'} label="Primary" />
          <Tab size={'medium'} label="Secondary" />
          <Tab size={'medium'} label="Tertiary" />
        </TabGroup>
      </Box>
      {/* Primary button form */}
      <TabPanel value={tab} index={0} sx={{ px: 0 }}>
        <SidebarFormGroup sx={{ paddingTop: 0 }}>
          <SidebarFormRadioGroup
            name={themeUpsertFormKeys.bannerPrimaryButtonStyle}
            title={'Style'}
            options={Object.values(ItemStyle).map(style => ({
              label: ItemStyleLabels[style],
              value: style,
            }))}
            row
            onChange={newStyle =>
              handleButtonStyleChange(values, setFieldValue, newStyle, themeUpsertFormKeys.bannerPrimaryButtonTextColor)
            }
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerPrimaryButtonBackgroundColor}
            label={
              getIn(values, themeUpsertFormKeys.bannerPrimaryButtonStyle) === ItemStyle.Filled
                ? 'Background Color'
                : 'Border Color'
            }
            fullWidth
            required
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerPrimaryButtonTextColor}
            label={'Button Text Color'}
            fullWidth
            required
          />
          <FormTextInput
            name={themeUpsertFormKeys.bannerPrimaryButtonCornerRadius}
            label={'Corner Radius (px)'}
            inputWidth="92px"
            mode={BuilderTextInputMode.CornerRadius}
          />
        </SidebarFormGroup>
      </TabPanel>

      {/*Secondary button form*/}
      <TabPanel value={tab} index={1} sx={{ px: 0 }}>
        <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom>
          <SidebarFormRadioGroup
            name={themeUpsertFormKeys.bannerSecondaryButtonStyle}
            title={'Style'}
            options={Object.values(ItemStyle).map(style => ({
              label: ItemStyleLabels[style],
              value: style,
            }))}
            row
            onChange={newStyle =>
              handleButtonStyleChange(
                values,
                setFieldValue,
                newStyle,
                themeUpsertFormKeys.bannerSecondaryButtonTextColor,
              )
            }
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerSecondaryButtonBackgroundColor}
            label={
              getIn(values, themeUpsertFormKeys.bannerSecondaryButtonStyle) === ItemStyle.Filled
                ? 'Background Color'
                : 'Border Color'
            }
            fullWidth
            required
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerSecondaryButtonTextColor}
            label={'Button Text Color'}
            fullWidth
            required
          />
          <FormTextInput
            name={themeUpsertFormKeys.bannerSecondaryButtonCornerRadius}
            label={'Corner Radius (px)'}
            inputWidth="92px"
            mode={BuilderTextInputMode.CornerRadius}
          />
        </SidebarFormGroup>
        <SidebarFormGroup>
          <Box>
            <Button
              color={'tertiary'}
              endIcon={<Icon name={'OPaintbrush'} iconColor={theme.palette.sphere.main} />}
              fullWidth={false}
              onClick={() => handleApplyPrimaryClick(themeUpsertFormKeys.bannerSecondaryButton)}
            >
              Apply Primary Button Style
            </Button>
            <Typography component={'p'} variant={'smallBody'} color={theme.palette.Text.Secondary} mt={'6px'}>
              Click here to apply the Primary Button style to this button.
            </Typography>
          </Box>
        </SidebarFormGroup>
      </TabPanel>

      {/* Tertiary button form*/}
      <TabPanel value={tab} index={2} sx={{ px: 0 }}>
        <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom>
          <SidebarFormRadioGroup
            name={themeUpsertFormKeys.bannerTertiaryButtonStyle}
            title={'Style'}
            options={Object.values(ItemStyle).map(style => ({
              label: ItemStyleLabels[style],
              value: style,
            }))}
            row
            onChange={newStyle =>
              handleButtonStyleChange(
                values,
                setFieldValue,
                newStyle,
                themeUpsertFormKeys.bannerTertiaryButtonTextColor,
              )
            }
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerTertiaryButtonBackgroundColor}
            label={
              getIn(values, themeUpsertFormKeys.bannerTertiaryButtonStyle) === ItemStyle.Filled
                ? 'Background Color'
                : 'Border Color'
            }
            fullWidth
            required
          />
          <FormColorInput
            name={themeUpsertFormKeys.bannerTertiaryButtonTextColor}
            label={'Button Text Color'}
            fullWidth
            required
          />
          <FormTextInput
            name={themeUpsertFormKeys.bannerTertiaryButtonCornerRadius}
            label={'Corner Radius (px)'}
            inputWidth="92px"
            mode={BuilderTextInputMode.CornerRadius}
          />
        </SidebarFormGroup>
        <SidebarFormGroup>
          <Box>
            <Button
              color={'tertiary'}
              endIcon={<Icon name={'OPaintbrush'} iconColor={theme.palette.sphere.main} />}
              fullWidth={false}
              onClick={() => handleApplyPrimaryClick(themeUpsertFormKeys.bannerTertiaryButton)}
            >
              Apply Primary Button Style
            </Button>
            <Typography component={'p'} variant={'smallBody'} color={theme.palette.Text.Secondary} mt={'6px'}>
              Click here to apply the Primary Button style to this button.
            </Typography>
          </Box>
        </SidebarFormGroup>
      </TabPanel>
    </Box>
  )
}
