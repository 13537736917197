import { FC, MouseEvent, SyntheticEvent, useContext, useState } from 'react'
import { FormSectionSwitch } from '../FormSectionSwitch'
import { RightsMappingDragList } from '../RightsMappingDragList'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box, Typography } from '@mui/material'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { RightsFormModeLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { RightsFormMode } from '@ketch-sdk/ketch-types'
import {
  ActionSheetWrapper,
  ActionSheetItem,
  Button,
  DropListButton,
  TabGroup,
  Tab,
  TabPanel,
  Icon,
  theme,
} from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { FormMode } from 'interfaces/formModes/formMode'
import { NEW_ITEM_ID } from 'utils/constants/misc'

interface DefaultFormDropdownActionProps {
  onView: () => void
  onEdit: () => void
}

const DefaultFormDropdownAction: FC<DefaultFormDropdownActionProps> = ({ onView, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onOpenClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const onCloseClick = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button variant="icon" color="tertiary" onClick={onOpenClick}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseClick}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ActionSheetItem
          onClick={() => {
            onView()
            onCloseClick()
          }}
        >
          View
        </ActionSheetItem>
        <ActionSheetItem
          onClick={() => {
            onEdit()
            onCloseClick()
          }}
        >
          Edit
        </ActionSheetItem>
      </ActionSheetWrapper>
    </div>
  )
}

export const PreferenceExperienceRequestsTabFormContentForm = () => {
  const { values, errors, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading, formTemplateOptions, isFormTemplatesLoading, customFormID, setCustomFormID } =
    useContext(ExperienceUpsertContext)
  const [tab, setTab] = useState(0)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const isSingleMode =
    getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode) === RightsFormMode.Single
  const singleFormID = getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID)
  const singleFormValue = formTemplateOptions.find(form => form.value === singleFormID)
  const showRecaptchaForm = getIn(values, experienceUpsertFormKeys.preferenceRequestsTabRightFormEnableRecaptcha)
  const singleFormError = getIn(errors, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID)

  const handleFormsClick = () => {
    window.open(RoutesManager.deployment.forms.templates.root.getURL(), '_blank')
  }

  const onTabChange = (tabIndex: number) => {
    setTab(tabIndex)
  }

  const onCreateFormClick = () => {
    window.open(
      RoutesManager.deployment.forms.templates.upsert.root.getURL({
        id: NEW_ITEM_ID,
        formMode: FormMode.CREATE,
      }),
      '_blank',
    )
  }

  const onEditFormClick = (formId?: string) => () => {
    if (!formId) return
    window.open(
      RoutesManager.deployment.forms.templates.upsert.root.getURL({
        id: formId,
        formMode: FormMode.EDIT,
      }),
      '_blank',
    )
  }

  if (isRelease(Release.FormsWithinExperienceServer)) {
    return (
      <SidebarFormGroup sx={{ pt: 0 }}>
        <Box>
          <TabGroup value={tab} onChange={(event: SyntheticEvent, newValue: number) => onTabChange(newValue)}>
            <Tab label="Forms" />
            <Tab label="reCAPTCHA" sx={{ textTransform: 'initial' }} />
          </TabGroup>

          {/* Forms */}
          <TabPanel value={tab} index={0} sx={{ px: 0 }}>
            <SidebarFormGroup hasBorderBottom sx={{ pt: 0, pb: 4 }}>
              <Box mb={-1} sx={{ '& div > div': { gap: 2 } }}>
                <SidebarFormRadioGroup
                  row
                  name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode}
                  options={Object.values(RightsFormMode).map(mode => ({
                    label: RightsFormModeLabels[mode],
                    value: mode,
                  }))}
                  onChange={() => setCustomFormID(undefined)}
                />
              </Box>
              {isSingleMode ? (
                <Box display="flex" alignItems="flex-end" gap={1} sx={{ '& > div': { width: '100%' } }}>
                  <DropListButton
                    disableClearable
                    label="Form"
                    placeholder="Select Form"
                    options={formTemplateOptions}
                    value={singleFormValue}
                    loading={isFormTemplatesLoading}
                    onChange={(e, newValue) => {
                      setFieldValue(
                        experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID,
                        newValue?.value,
                      )
                    }}
                    error={!!singleFormError}
                    errorMessage={singleFormError}
                    sx={{
                      '& div': {
                        maxHeight: 44,
                      },
                    }}
                  />
                  <Button variant="icon" color="tertiary" size="large" onClick={onEditFormClick(singleFormID)}>
                    <Icon name="FEdit" />
                  </Button>
                </Box>
              ) : (
                // Custom form mapping
                <RightsMappingDragList
                  variant={RightsFormMode.Custom}
                  defaultFormDropdownRender={formId => (
                    <DefaultFormDropdownAction
                      onEdit={onEditFormClick(formId)}
                      onView={() => setCustomFormID(formId)}
                    />
                  )}
                  onPreviewForm={formId => {
                    if (!!customFormID) setCustomFormID(formId)
                  }}
                />
              )}
            </SidebarFormGroup>
            <Box mt={4}>
              <Typography component="p" mb={1}>
                Need to create a form? Click here:
              </Typography>
              <Button color="tertiary" onClick={onCreateFormClick}>
                Create Form
              </Button>
            </Box>
          </TabPanel>

          {/* reCAPTCHA */}
          <TabPanel value={tab} index={1} sx={{ px: 0 }}>
            <>
              <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
                <Box>
                  <Typography component="p" mb={1}>
                    To edit existing forms or to create new ones, please follow the link below:
                  </Typography>
                  <Button
                    onClick={handleFormsClick}
                    color="tertiary"
                    endIcon={<Icon name="OShare" iconColor={theme.palette.sphere.main} />}
                  >
                    Configure Forms
                  </Button>
                </Box>
              </SidebarFormGroup>
              <SidebarFormGroup sx={{ pb: '4px' }}>
                <FormSectionSwitch
                  name={experienceUpsertFormKeys.preferenceRequestsTabRightFormEnableRecaptcha}
                  title="reCAPTCHA"
                />
              </SidebarFormGroup>
              {showRecaptchaForm && (
                <SidebarFormGroup sx={{ pt: '12px' }}>
                  <Box px={2.25}>
                    <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0, gap: '8px' }}>
                      <FormEditor
                        label="reCAPTCHA Validation Failure Text"
                        id={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText.replaceAll(
                          '.',
                          '_',
                        )}
                        name={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText}
                        linkChangeModal={renderDocumentLinkerModal}
                        charLimit={1600}
                      />
                      <Typography color={theme.palette.Text.Secondary}>
                        Displays to users who fail the reCAPTCHA verification process.
                      </Typography>
                    </SidebarFormGroup>
                    <SidebarFormTranslations
                      title="reCAPTCHA Validation Failure Text Translations"
                      name={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText}
                      variant="richText"
                      charLimit={1600}
                    />
                  </Box>
                </SidebarFormGroup>
              )}
            </>
          </TabPanel>
        </Box>
      </SidebarFormGroup>
    )
  }

  return (
    <>
      <SidebarFormGroup hasBorderBottom sx={{ pt: 0 }}>
        <Box>
          <Typography component={'p'} variant={'body'} mb={1}>
            To edit existing forms or to create new ones, please follow the link below:
          </Typography>
          <Button
            onClick={handleFormsClick}
            color={'tertiary'}
            endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
          >
            Configure Forms
          </Button>
        </Box>
      </SidebarFormGroup>
      <SidebarFormGroup sx={{ pb: '4px' }}>
        <FormSectionSwitch
          name={experienceUpsertFormKeys.preferenceRequestsTabRightFormEnableRecaptcha}
          title={'reCAPTCHA'}
        />
      </SidebarFormGroup>
      {showRecaptchaForm && (
        <SidebarFormGroup sx={{ pt: '12px' }}>
          <Box px={2.25}>
            <SidebarFormGroup hasBorderBottom={hasLanguages} sx={{ pt: 0, gap: '8px' }}>
              <FormEditor
                label={'reCAPTCHA Validation Failure Text'}
                required={false}
                id={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText.replaceAll('.', '_')}
                name={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText}
                linkChangeModal={renderDocumentLinkerModal}
                charLimit={1600}
              />
              <Typography variant={'body'} color={theme.palette.Text.Secondary}>
                Displays to users who fail the reCAPTCHA verification process.
              </Typography>
            </SidebarFormGroup>
            <SidebarFormTranslations
              title={'reCAPTCHA Validation Failure Text Translations'}
              name={experienceUpsertFormKeys.preferenceRequestsTabRightFormRecaptchaFailureText}
              variant={'richText'}
              charLimit={1600}
            />
          </Box>
        </SidebarFormGroup>
      )}
    </>
  )
}
