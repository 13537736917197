import { Button, AutocompleteInlineEdit, ActionSheetItem, ListItemText } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useFormFields } from 'api/formFields/queries/useFormFields'
import { FormFieldDTO } from '@ketch-com/figurehead'

export const getValidationSchema = () =>
  Yup.object().shape({
    inlineEditField: Yup.string().required(),
    inlineEditActionsField: Yup.string(),
  })

export const SingleSelectAutocompleteInlineEditExample: React.FC = () => {
  const { data: allFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })
  return (
    <Formik
      initialValues={{ autocompleteField: '' }}
      onSubmit={values => console.info('Form submitted with values:', values)}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { values, setFieldValue } = formik
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant="h3" mb={2}>
              Single Select Inline Edit Autocomplete
            </Typography>
            <AutocompleteInlineEdit
              sx={{
                width: 300,
              }}
              placeholder="Single select"
              multiple={false}
              handleOnChange={newValue => {
                setFieldValue('autocompleteField', newValue ? newValue.code : '')
              }}
              handleOnClose={() => {}}
              getOptionLabel={option => option.name || ''}
              value={allFormFields.find(opt => opt.code === values.autocompleteField) || null}
              noOptionsText={'Nothing found message text'}
              options={allFormFields}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.name}</ListItemText>
                </ActionSheetItem>
              )}
            />

            <Button sx={{ width: 100 }} type="submit">
              Submit
            </Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}

export const MultiSelectAutocompleteInlineEditExample: React.FC = () => {
  const { data: allFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })
  return (
    <Formik
      initialValues={{ autocompleteField: [] as FormFieldDTO[] }}
      onSubmit={values => console.info('Form submitted with values:', values)}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { values, setFieldValue } = formik
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant="h3" mb={2}>
              Multi SelectInline Edit Autocomplete
            </Typography>
            <AutocompleteInlineEdit
              sx={{
                width: 300,
              }}
              textInputVariant="ghost"
              placeholder="Multi Select"
              multiple={true}
              handleOnChange={newValue => {
                setFieldValue(
                  'autocompleteField',
                  newValue.map(elem => elem.code),
                )
              }}
              getOptionLabel={option => option.name || ''}
              value={allFormFields.filter((elem, i) => values.autocompleteField.find(opt => opt === elem.code))}
              noOptionsText={'Nothing found message text'}
              options={allFormFields}
              renderOption={(props, option, { selected }) => (
                <ActionSheetItem selected={selected} {...props}>
                  <ListItemText selected={selected}>{option.name}</ListItemText>
                </ActionSheetItem>
              )}
            />

            <Button sx={{ width: 100 }} type="submit">
              Submit
            </Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}

export const AutocompleteInlineEditExample: React.FC = () => {
  return (
    <Box display="flex">
      <Box flex={1}>
        <SingleSelectAutocompleteInlineEditExample />
      </Box>
      <Box flex={1} width={300}>
        <MultiSelectAutocompleteInlineEditExample />
      </Box>
    </Box>
  )
}
