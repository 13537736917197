import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { Checkbox as MUICheckbox, SvgIcon, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { makeStyles, createStyles } from '@mui/styles'
import { uniqueId } from 'lodash'
import { TooltipV2 } from '../tooltip/TooltipV2'

const useStyles = makeStyles(
  ({ typography, palette }) =>
    createStyles({
      baseCheckboxWrapper: {
        display: 'inline-flex',
        alignItems: 'center',

        '&:not($disabled)': {
          cursor: 'pointer',

          '& $baseCheckboxText': {
            cursor: 'pointer',
          },

          '&:hover': {
            '& $baseCheckbox': {
              color: palette.sphere.main,
            },

            '&$checked': {
              '& $baseCheckbox': {
                color: palette.royalBlue.main,
              },
              '& $baseCheckboxText': {
                color: palette.royalBlue.main,
              },
            },
          },

          '&:active': {
            '& $baseCheckbox': {
              color: palette.persianBlue.main,
            },

            '&$checked': {
              '& $baseCheckbox': {
                color: palette.persianBlue.main,
              },
              '& $baseCheckboxText': {
                color: palette.persianBlue.main,
              },
            },
          },
        },
      },
      baseCheckbox: {
        borderRadius: 3,
        padding: 0,

        '&:hover': {
          background: 'none',
        },

        '&.Mui-checked': {
          color: palette.sphere.main,

          '&:hover': {
            background: 'none',
          },
        },

        '&:not(.Mui-checked)': {
          color: palette.fadedDarkGrey.main,
        },
      },
      baseCheckboxText: {
        fontFamily: typography.fontFamily,
        letterSpacing: '0.01em',
        color: palette.darkDusk.main,
        marginLeft: 10,
      },

      // Sizes:
      sizeSmall: {
        '& $baseCheckboxText': {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
        },
        '& .MuiSvgIcon-root': {
          width: 18,
          height: 18,
        },
      },
      sizeRegular: {
        '& $baseCheckboxText': {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
        },
        '& .MuiSvgIcon-root': {
          width: 22,
          height: 22,
        },
      },

      // States:
      disabled: {
        '& $baseCheckbox': {
          color: palette.fadedDarkGrey.main,
        },
        '& $baseCheckboxText': {
          color: palette.fadedDarkGrey.main,
        },
      },
      checked: {
        '& $baseCheckboxText': {
          fontWeight: 600,
        },
      },
      focusVisible: {},
    }),
  { name: 'Checkbox' },
)

export interface Props {
  /** ID of an element */
  id?: string
  /** Name of an element */
  name?: string
  /** Test ID of an element for selectors */
  testId?: string
  /** Custom element className */
  className?: string
  /** Component title */
  title?: React.ReactNode
  /** Component value (controlled) */
  value: true | false | 'indeterminate'
  /** Component value change method */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  /** Component blur method */
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void
  /** Component size */
  size?: 'regular' | 'small'
  /** Disable state */
  disabled?: boolean
  /** Container styles */
  containerSx?: SxProps<Theme>
  /** Tooltip text */
  tooltip?: string
}

/**
 * -
 */
export const Checkbox: React.FC<Props> = ({
  id = uniqueId(),
  name,
  testId,
  className,
  title,
  value,
  size = 'regular',
  disabled = false,
  onChange,
  onBlur,
  containerSx,
  tooltip,
}) => {
  const classes = useStyles()

  const wrapperClassName = clsx(classes.baseCheckboxWrapper, {
    [classes.sizeSmall]: size === 'small',
    [classes.sizeRegular]: size === 'regular',
    [classes.checked]: value !== false,
    [classes.disabled]: disabled,
  })

  const componentClassName = clsx(
    classes.baseCheckbox,
    {
      [classes.sizeSmall]: size === 'small',
      [classes.sizeRegular]: size === 'regular',
      [classes.disabled]: disabled,
    },
    className,
  )

  return (
    <Box className={wrapperClassName} sx={containerSx}>
      <MUICheckbox
        id={id}
        name={name}
        data-test-id={`Checkbox-${testId}`}
        disableRipple
        checked={value === true}
        indeterminate={value === 'indeterminate'}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        classes={{
          root: componentClassName,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
      />

      {!!title && (
        <label htmlFor={id} className={classes.baseCheckboxText}>
          {title}
        </label>
      )}

      {!!tooltip ? (
        <TooltipV2 arrow={true} title={<Typography variant="body">{tooltip}</Typography>}>
          <SvgIcon
            inheritViewBox
            style={{
              height: 14,
              width: 14,
            }}
            component={InfoIcon}
            sx={{
              ml: 1,
              '& > path': {
                fill: theme => theme.palette.Text.Secondary,
              },
            }}
          />
        </TooltipV2>
      ) : null}
    </Box>
  )
}
