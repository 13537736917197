import Box from '@mui/material/Box'

import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueMegaFilterButtonPanelIds, stepStatusFilters } from '../constants'
import { useContext } from 'react'
import { Chip, MegaFilterContext } from '@ketch-com/deck'

const panelId = RightsQueueMegaFilterButtonPanelIds.stepStatus

export const StepStatusFilter = ({ handlePaginationReset }: { handlePaginationReset: () => void }) => {
  const { clearAllEntriesForKey, removeQueryParamFromList, replaceParams, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()

  const context = useContext(MegaFilterContext)
  const {
    handleAddSelectedValue,
    handleDeleteValue,
    getSelectedValuesArrayByCategory,
    handleClearByCategory,
    handleSetNewValuesByCategory,
  } = context

  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)
  const activeStatusFilterCount = panelParamValuesArray.length
  const isAllStatusesSelected = activeStatusFilterCount === stepStatusFilters.length

  const currentValues = getSelectedValuesArrayByCategory(panelId)
  const getIsSelected = (option: string) =>
    !!(currentValues && currentValues.find(selectedValue => selectedValue.value === option))

  return (
    <Box pl={1.5}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          pb: 1.25,
          pt: 1.25,
          borderBottom: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Chip
          label="All"
          isSelected={isAllStatusesSelected}
          selectable
          clickable
          onClick={() => {
            if (isAllStatusesSelected) {
              clearAllEntriesForKey(panelId)
              handleClearByCategory(panelId)
            } else {
              replaceParams({
                [panelId]: stepStatusFilters.map(stepStatusFilter => stepStatusFilter.value).join(','),
                page: '0',
              })
              handleSetNewValuesByCategory(
                panelId,
                stepStatusFilters.map(filter => ({
                  value: filter.value,
                  category: panelId,
                  label: filter.label,
                })),
              )
            }
            handlePaginationReset()
          }}
        />
      </Box>

      {/* Individual Statuses Row */}

      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap" pt={1}>
        {stepStatusFilters.map(stepStatusFilter => {
          const isSelected = getIsSelected(stepStatusFilter.value)

          return (
            <Chip
              key={stepStatusFilter.value}
              label={stepStatusFilter.label}
              selectable
              clickable
              isSelected={getIsSelected(stepStatusFilter.value)}
              onClick={() => {
                if (isSelected) {
                  handleDeleteValue({
                    value: stepStatusFilter.value,
                    category: panelId,
                    label: stepStatusFilter.label,
                  })
                  removeQueryParamFromList({ key: panelId, value: stepStatusFilter.value })
                } else {
                  handleAddSelectedValue({
                    value: stepStatusFilter.value,
                    category: panelId,
                    label: stepStatusFilter.label,
                  })
                  replaceParams({
                    [panelId]: [...panelParamValuesArray, stepStatusFilter.value].join(','),
                    page: '0',
                  })
                }
                handlePaginationReset()
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}
