import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { Link } from 'components/ui-kit/link/Link'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as WidgetIcon } from 'assets/icons/policiesAndControls.svg'
import { Tabs } from 'components/ui-kit/tabs/Tabs'
import { Policies, SecurityControls } from './components'

const PREFIX = 'SecurityRisksPolicyControl'

const classes = {
  securityRisksPolicyControlContainer: `${PREFIX}-securityRisksPolicyControlContainer`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
  tableHoverState: `${PREFIX}-tableHoverState`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.securityRisksPolicyControlContainer}`]: {
    flex: '0 1 848px',
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.5),
    padding: spacing(3, 4),
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
    marginBottom: spacing(2),
  },

  [`& .${classes.tab}`]: {
    marginLeft: spacing(1.5),
  },

  [`& .${classes.tableHoverState}`]: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

enum PolicyControlItems {
  POLICIES = 'Policies',
  SECURITY_CONTROLS = 'Security Controls',
}

const getPolicyControlTable = (policyControlItem: string) => {
  let component = null

  switch (policyControlItem) {
    case PolicyControlItems.POLICIES:
      component = <Policies />
      break
    case PolicyControlItems.SECURITY_CONTROLS:
      component = <SecurityControls />
      break

    default:
      break
  }

  return component
}

export const SecurityRisksPolicyControl: React.FC = () => {
  const [policyControlItem, setPolicyControlItem] = useState(PolicyControlItems.POLICIES)
  const component = getPolicyControlTable(policyControlItem)

  return (
    <StyledBox className={classes.securityRisksPolicyControlContainer}>
      <Link variant="buttonWrap" to={RoutesManager.insights.dataRisks.policiesControls.root.getURL()}>
        <Box display="flex" alignItems="center" gap={1.5} mb={2}>
          <WidgetIcon width={48} height={48} />
          <Box display="flex" flexDirection="column" flex={1} className={classes.tableHoverState}>
            <Text size={20} weight={700} mb={1.25}>
              Policies & Controls
            </Text>
            <Text size={14} color="darkDuskFaded">
              Identify data assets missing from DSR processes or with insufficient security controls.
            </Text>
          </Box>
        </Box>
      </Link>
      <Tabs
        className={classes.tabs}
        tabClassName={classes.tab}
        config={[
          {
            key: PolicyControlItems.POLICIES,
            active: policyControlItem === PolicyControlItems.POLICIES,
            title: PolicyControlItems.POLICIES,
            onClick: () => {
              setPolicyControlItem(PolicyControlItems.POLICIES)
            },
          },
          {
            key: PolicyControlItems.SECURITY_CONTROLS,
            active: policyControlItem === PolicyControlItems.SECURITY_CONTROLS,
            title: PolicyControlItems.SECURITY_CONTROLS,
            onClick: () => {
              setPolicyControlItem(PolicyControlItems.SECURITY_CONTROLS)
            },
          },
        ]}
      />
      {component}
    </StyledBox>
  )
}
