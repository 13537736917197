import React from 'react'
import clsx from 'clsx'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography, Tooltip, styled } from '@mui/material'
import { useCopyToClipboard } from 'react-use'
import { showToast } from 'components/modals/AlertComponent'
import { useNavigate } from 'react-router-dom'

const StyledRoot = styled(Box)(() => ({
  whiteSpace: 'normal',
}))

const StyledNameText = styled(Box)(({ theme }) => ({
  display: 'block',
  color: theme.palette.darkDusk.main,

  '&.nameTextRegular': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '-0.01em',
  },

  '&.nameTextBig': {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },

  '&.capitalize': {
    textTransform: 'capitalize',
  },

  '&.boldName': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.01em',
  },
}))

const StyledCodeText = styled(Box)(({ theme }) => ({
  display: 'block',
  fontWeight: 500,
  color: theme.palette.Text.Secondary,

  '&.codeTextBig': {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.43,
    letterSpacing: '-0.01em',
  },

  '&.codeTextRegular': {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: 1.36,
    letterSpacing: '0.01em',
  },
}))

export interface Props {
  /** Entity name */
  name?: React.ReactNode
  /** Entity code */
  code?: React.ReactNode
  /** Component size code */
  size?: 'big' | 'regular' | 'normal'
  /** Custom wrapper className */
  className?: string
  /** Optional shortcut link */
  shortCutLink?: string | null
  /** Optional capitalize name */
  shouldCapitalizeName?: boolean
  /** Optional max width with ellipsis */
  maxWidth?: number
  /** Is name bold */
  isNameBold?: boolean
  /** truncation function */
  truncateCodeFunction?: (id?: string) => string
}

export const NameAndCodeCellRenderer: React.FC<Props> = ({
  name,
  code,
  size = 'regular',
  className,
  shortCutLink = null,
  shouldCapitalizeName = false,
  maxWidth,
  isNameBold = false,
  truncateCodeFunction,
}) => {
  const navigate = useNavigate()
  const [, copyToClipboard] = useCopyToClipboard()

  const isRegularSize = size === 'regular'
  const isBigSize = size === 'big'

  const maxWidthStyle = maxWidth
    ? { maxWidth, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }
    : undefined

  return (
    <StyledRoot className={clsx(className)} sx={maxWidthStyle}>
      {!name && !code && <Typography color="fadedDarkGrey">None</Typography>}
      {!!name && (
        <StyledNameText
          display="inline"
          sx={maxWidthStyle}
          title={typeof name === 'string' ? name : undefined}
          className={clsx({
            nameTextRegular: isRegularSize,
            nameTextBig: isBigSize,
            capitalize: shouldCapitalizeName,
            boldName: isNameBold,
          })}
        >
          {name}

          {!!shortCutLink && (
            <Tooltip title="View Experience">
              <InfoOutlinedIcon sx={{ gap: 2, fontSize: '14px' }} onClick={() => navigate(shortCutLink)} />
            </Tooltip>
          )}
        </StyledNameText>
      )}
      {!!code && !truncateCodeFunction && (
        <StyledCodeText
          display="inline"
          sx={maxWidthStyle}
          title={typeof code === 'string' ? code : undefined}
          className={clsx({
            codeTextRegular: isRegularSize,
            codeTextBig: isBigSize,
          })}
        >
          {code}
        </StyledCodeText>
      )}

      {!!code && !!truncateCodeFunction && typeof code === 'string' && (
        <StyledCodeText
          display="inline"
          sx={maxWidthStyle}
          onClick={e => {
            e.stopPropagation()
            copyToClipboard(code)
            showToast({ content: 'Copied to clipboard', type: 'success' })
          }}
          className={clsx({
            codeTextRegular: isRegularSize,
            codeTextBig: isBigSize,
          })}
        >
          <Tooltip
            title={
              <Box display="flex" p={1} flexDirection="column">
                <Typography variant="label" color="white.main" mb={1}>
                  {code}
                </Typography>

                <Typography variant="smallLabel" color="white.main">
                  Click to copy to clipboard
                </Typography>
              </Box>
            }
          >
            <Box component="span">{truncateCodeFunction ? truncateCodeFunction(code) : code}</Box>
          </Tooltip>
        </StyledCodeText>
      )}
    </StyledRoot>
  )
}
