import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Chip, ContentGroup, Icon, Spinner, theme } from '@ketch-com/deck'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { useContext } from 'react'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { ProcessingActivityDTO } from '@ketch-com/figurehead'

export const VersionsTab = () => {
  const { isVersionsLoading, versions, setQueryParam, removeQueryParam } = useContext(ProcessingActivityContext)

  // Switch version (will re-fetch)
  const handleVersionClick = async (version: ProcessingActivityDTO, versionNumber: number) => {
    if (versionNumber === 0) {
      removeQueryParam('versionId')
    } else {
      setQueryParam('versionId', version.id || '')
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {isVersionsLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner thickness={2.5} size={32} />
        </Box>
      ) : (
        <>
          {/* Heading text */}
          <ContentGroup
            title="Versions"
            subTitle="If this processing activity has been approved before, you can locate its previous versions here."
            titleVariant="h3"
            variant="inner-page-header"
          />

          <Box display="flex" pt={2} borderTop={`1px solid ${theme.palette.iron.main}`}>
            <Typography variant="body" width="232px" color={theme.palette.Text.Secondary}>
              Previous Versions
            </Typography>
            <Box display="flex" flexDirection="column" gap={1.5}>
              {versions.totalResults && versions.processingActivities && versions.totalResults > 0 ? (
                versions.processingActivities
                  // Sort with most recent version first
                  .sort((v1, v2) => (v2.version || 0) - (v1.version || 0))
                  .map((version, versionNumber) => (
                    <Chip
                      key={version.version}
                      label={
                        <Typography variant="label">
                          Version {versions.totalResults! - versionNumber}
                          <Typography variant="body">
                            {' '}
                            |{' '}
                            {version?.metadata?.createdAt
                              ? formatDateTimeFromUnix(version.metadata.createdAt)
                              : 'Date Unknown'}
                          </Typography>
                        </Typography>
                      }
                      clickable
                      deleteIcon={<Icon name="OArrowCRight" />}
                      onDelete={() => handleVersionClick(version, versionNumber)}
                      onClick={() => handleVersionClick(version, versionNumber)}
                    />
                  ))
              ) : (
                <Typography variant="body" color={theme.palette.Text.Secondary}>
                  There are no versions of this processing activity as it is yet to be approved.
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
