import { useInfiniteQuery } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchTrackerRecentScanResults } from '../fetchers/fetchTrackerRecentScanResults'
import { GetTrackerFoundOnParams } from '@ketch-com/atlas/dist/apiTypes/foundOn'

export const useTrackerRecentScanResultsInfinite = (
  params: GetTrackerFoundOnParams & {
    encodedTrackerKey?: string
    enabled?: boolean
  },
) => {
  const { enabled = true, limit = 20 } = params
  const resp = useInfiniteQuery({
    enabled,
    queryKey: [ApiQueryKeys.trackerRecentScanResultsInfinite, { ...params }],
    queryFn: async ({ pageParam = {} }) => {
      const scanResults = await fetchTrackerRecentScanResults({
        ...params,
        limit,
        after: pageParam.nextToken,
      })
      return scanResults
    },
    getNextPageParam: lastPage => {
      const nextToken = lastPage?.data?.nextToken
      return nextToken ? { nextToken } : null
    },
  })

  return {
    ...resp,
    scanResults: resp?.data?.pages?.map(scanResult => scanResult?.data?.results || []).flat() || [],
  }
}
