import React, { useContext, useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Icon, IconDictionary, theme, TextInput, DropListButton } from '@ketch-com/deck'
import { useDebounce } from 'react-use'
import { ApprovedTrackersContext, TrackersFilterContext } from 'pages/policyCenter/trackers/context'

export const TrackersSearchAndFilter: React.FC = () => {
  const {
    purposes,
    isLoadingPurposes,
    properties,
    isLoadingProperties,
    isLoadingTrackerCategories,
    trackerCategories,
    dataSystems = [],
    isLoadingDataSystems,
  } = useContext(TrackersFilterContext)
  const { getApprovedTrackersParams, setGetApprovedTrackersParams } = useContext(ApprovedTrackersContext)
  const [searchInputValue, setSearchInputValue] = useState(getApprovedTrackersParams?.searchString || '')

  useEffect(() => {
    if (!getApprovedTrackersParams?.searchString) setSearchInputValue('')
  }, [getApprovedTrackersParams])

  useDebounce(
    () => {
      setGetApprovedTrackersParams(Object.assign({}, getApprovedTrackersParams, { searchString: searchInputValue }))
    },
    500,
    [searchInputValue],
  )
  return (
    <Box mb={3} display="flex" gap={1}>
      <TextInput
        inputWidth="180px"
        size="small"
        placeholder="Search"
        startIcon={<Icon name={IconDictionary.OMag} iconColor={theme.palette.Text.Secondary} />}
        rows={2}
        value={searchInputValue}
        onChange={e => {
          setSearchInputValue(e?.currentTarget?.value)
        }}
      />

      <DropListButton
        options={properties}
        size="small"
        loading={isLoadingProperties}
        value={
          properties?.filter(property => getApprovedTrackersParams?.propertyIDs?.[0] === property?.ID)?.[0] || null
        }
        clearOnBlur
        noOptionsText="No Properties"
        getOptionDisabled={option => option?.ID === getApprovedTrackersParams?.propertyIDs?.[0]}
        onChange={(e, option, reason) => {
          setGetApprovedTrackersParams(Object.assign({}, getApprovedTrackersParams, { propertyIDs: [option?.ID] }))
        }}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="All Properties"
            variant="outlined"
          />
        )}
        renderOption={(props, property) => {
          return (
            <Box component="li" {...props} key={property?.ID} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {property?.name}
              </Typography>
              <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                {property?.code}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.ID === value?.ID}
      />

      <DropListButton
        options={purposes}
        size="small"
        loading={isLoadingPurposes}
        value={purposes?.filter(purpose => getApprovedTrackersParams?.purposeIDs?.[0] === purpose?.ID)?.[0] || null}
        clearOnBlur
        noOptionsText="No Purposes"
        getOptionDisabled={option => option?.ID === getApprovedTrackersParams?.purposeIDs?.[0]}
        onChange={(e, option, reason) => {
          setGetApprovedTrackersParams(Object.assign({}, getApprovedTrackersParams, { purposeIDs: [option?.ID] }))
        }}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="All Purposes"
            variant="outlined"
          />
        )}
        renderOption={(props, purpose) => {
          return (
            <Box component="li" {...props} key={purpose?.ID} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {purpose?.name}
              </Typography>
              <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                {purpose?.code}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.ID === value?.ID}
      />

      <DropListButton
        options={dataSystems}
        size="small"
        loading={isLoadingDataSystems}
        value={
          dataSystems?.filter(system => getApprovedTrackersParams?.dataSystemCodes?.[0] === system?.code)?.[0] || null
        }
        clearOnBlur
        noOptionsText="No Purposes"
        getOptionDisabled={option => option?.code === getApprovedTrackersParams?.dataSystemCodes?.[0]}
        onChange={(e, option, reason) => {
          setGetApprovedTrackersParams(
            Object.assign({}, getApprovedTrackersParams, { dataSystemCodes: [option?.code] }),
          )
        }}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="All Systems"
            variant="outlined"
          />
        )}
        renderOption={(props, system) => {
          return (
            <Box component="li" {...props} key={system?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {system?.name}
              </Typography>
              <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                {system?.code}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
      />

      <DropListButton
        options={trackerCategories || []}
        size="small"
        loading={isLoadingTrackerCategories}
        value={
          trackerCategories?.filter(
            category => getApprovedTrackersParams?.categoryCodes?.[0] === category?.code,
          )?.[0] || null
        }
        clearOnBlur
        noOptionsText="No Categories"
        getOptionDisabled={option => option?.code === getApprovedTrackersParams?.categoryCodes?.[0]}
        onChange={(e, option, reason) => {
          setGetApprovedTrackersParams(Object.assign({}, getApprovedTrackersParams, { categoryCodes: [option?.code] }))
        }}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="Categories"
            variant="outlined"
          />
        )}
        renderOption={(props, category) => {
          return (
            <Box component="li" {...props} key={category?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {category?.name}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
      />

      <DropListButton
        options={[
          { label: 'Disclosed', value: 'disclosed' },
          { label: 'Hidden', value: 'hidden' },
        ]}
        size="small"
        value={
          typeof getApprovedTrackersParams?.disclosureStatus === 'boolean'
            ? getApprovedTrackersParams?.disclosureStatus
              ? { label: 'Disclosed', value: 'disclosed' }
              : { label: 'Hidden', value: 'hidden' }
            : null
        }
        clearOnBlur
        noOptionsText="No Disclosure Status"
        getOptionDisabled={option => {
          if (typeof getApprovedTrackersParams?.disclosureStatus === 'boolean')
            return option.value === (getApprovedTrackersParams?.disclosureStatus ? 'disclosed' : 'hidden')
          return false
        }}
        onChange={(e, option, reason) => {
          if (reason === 'clear') {
            setGetApprovedTrackersParams(Object.assign({}, getApprovedTrackersParams, { disclosureStatus: null }))
          } else {
            setGetApprovedTrackersParams(
              Object.assign({}, getApprovedTrackersParams, {
                disclosureStatus: option?.value === 'disclosed' ? true : false,
              }),
            )
          }
        }}
        renderInput={params => (
          <TextInput
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="Disclosure Status"
            variant="outlined"
          />
        )}
        renderOption={(props, status) => {
          return (
            <Box component="li" {...props} key={status?.label} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {status?.label}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
      />
    </Box>
  )
}
