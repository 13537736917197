import * as Yup from 'yup'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'

export const getValidationSchema = () =>
  Yup.object().shape({
    schedule: Yup.object().shape({
      time: Yup.string().when('isCustomizeStartingTimeChecked', {
        is: true,
        then: Yup.string()
          .max(5, 'Must be 5 characters or less')
          .matches(validationRegExp.TIME, 'invalid')
          .required('Required'),
        otherwise: Yup.string(),
      }),
      period: Yup.string(),
      frequency: Yup.string(),
      day: Yup.string().when('frequency', {
        is: '2',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      isCustomizeStartingTimeChecked: Yup.boolean(),
      sampleSize: Yup.number()
        .optional()
        .min(100, 'Sample size must be at least 100')
        .max(100000, 'Sample size must not exceed 100000'),
      executionDuration: Yup.string()
        .optional()
        .matches(
          /^(1[0-9]|2[0-4]|[1-9])h$/,
          'Execution duration must be a valid hour string inclusively between 1h and 24h',
        ),
    }),
    name: Yup.string().required(),
    description: Yup.string(),
    dataCategory: Yup.string(),
    labels: Yup.array().of(
      Yup.object().shape({
        code: Yup.string().required('Required'),
        value: Yup.string(),
      }),
    ),
    hooks: Yup.array().of(Yup.object().shape({})),
  })
