import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { PolicyScopeDTO } from 'interfaces/policyScopes-v2/policyScope'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { PreviewDisplayMode } from '../../../utils/enums'
import { withDefaultFirst } from '.'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { useExperiences } from 'api/experiences-v2/queries/useExperiences'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'

export const useExperiencePreviewUtils = () => {
  // Utils
  const location = useLocation()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  // Dropdown options - Experience
  const [languageOptions, setLanguageOptions] = useState<LanguageWithStatusDTO[]>()
  const [jurisdictionOptions, setJurisdictionOptions] = useState<PolicyScopeDTO[]>()
  const [themeOptions, setThemeOptions] = useState<ThemeV3DTO[]>()
  const [allExperienceOptions, setAllExperienceOptions] = useState<ExperienceV2DTO[]>()
  const [experienceOptions, setExperienceOptions] = useState<ExperienceV2DTO[]>()
  // Dropdown options - Theme
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.Banner)

  // Preview data
  const { isLoading: isLanguagesLoading } = useOrganizationLanguages({
    onSuccess: ({ data: { languages } }) => {
      setLanguageOptions([
        { language: { englishName: 'English', code: 'en' }, enabled: true } as LanguageWithStatusDTO,
        ...(languages?.length ? languages : []),
      ])
    },
  })
  const { isLoading: isThemesLoading } = useThemes({
    onSuccess: ({ data: { themes } }) => {
      setThemeOptions(withDefaultFirst(themes, 'id'))
    },
  })
  const { isLoading: isJurisdictionsLoading } = usePolicyScopes({
    onSuccess: ({ data: { policyScopes } }) => {
      setJurisdictionOptions(withDefaultFirst(policyScopes ?? [], 'code'))
    },
  })
  const { isLoading: isExperiencesLoading } = useExperiences({
    onSuccess: ({ data: { experiences } }) => {
      // Include only experience server v2 experiences
      setAllExperienceOptions(experiences.filter(experience => !!experience.summary))
    },
  })

  // Preview state
  const [consentExperienceType, setConsentExperienceType] = useState<ConsentExperienceType>(
    ConsentExperienceType.Banner,
  )
  const [displayMode, setDisplayMode] = useState<PreviewDisplayMode>(PreviewDisplayMode.Desktop)
  const [preferenceCenterPage, setPreferenceCenterPage] = useState<PreferenceCenterPage>(PreferenceCenterPage.Welcome)
  const [language, setLanguage] = useState<LanguageWithStatusDTO>()
  const [jurisdiction, setJurisdiction] = useState<PolicyScopeDTO>()
  const [theme, setTheme] = useState<ThemeV3DTO>()
  const [experience, setExperience] = useState<ExperienceV2DTO | null>(null)
  const [isPreviewReady, setIsPreviewReady] = useState(false)
  const isReady = !isLanguagesLoading && !isJurisdictionsLoading && !isThemesLoading

  // Set the default experience in the dropdown (for theme preview)
  useEffect(() => {
    if (experienceOptions?.length && !isExperiencesLoading) {
      setExperience(experienceOptions[0])
    }
  }, [experienceOptions, isExperiencesLoading])

  // Update the available experiences in the dropdown based on theme preview type
  useEffect(() => {
    if (allExperienceOptions?.length && !isExperiencesLoading) {
      const experiencesForThemeType = allExperienceOptions.filter(e =>
        themeType === ThemeType.Preference ? e.type === ExperienceType.Preference : e.type === ExperienceType.Consent,
      )
      setExperienceOptions(experiencesForThemeType)
    }
  }, [allExperienceOptions, isExperiencesLoading, themeType])

  return {
    location,
    navigate,
    isPermitted,
    isReady,
    consentExperienceType,
    setConsentExperienceType,
    displayMode,
    setDisplayMode,
    preferenceCenterPage,
    setPreferenceCenterPage,
    isLanguagesLoading,
    languageOptions,
    language,
    setLanguage,
    isJurisdictionsLoading,
    jurisdictionOptions,
    setJurisdictionOptions,
    jurisdiction,
    setJurisdiction,
    isThemesLoading,
    themeOptions,
    theme,
    setTheme,
    themeType,
    setThemeType,
    isExperiencesLoading,
    experienceOptions,
    experience,
    setExperience,
    isPreviewReady,
    setIsPreviewReady,
  }
}

export type ExperiencePreviewUtils = ReturnType<typeof useExperiencePreviewUtils>
