import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Tooltip, Typography } from '@mui/material'
import { CircularProgress, CircularProgressSize, EmptyState, theme, Icon, Button } from '@ketch-com/deck'
import { Bar, ComposedChart, Tooltip as RechartsTooltip, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts'
// TODO:BAC - de-scoped, coming soon...
// import { CSVLink } from 'react-csv'

import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { Legend, ConsentTrendChartTooltip } from './components'
import { addDays, differenceInDays } from 'date-fns'
import { getXAxisDataKey, getXAxisInterval } from './utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import { formatCountWithoutDecimals } from 'pages/insights/permitsStatistics/components/PermitsSummary/components/PermitStatisticsOverall/utils'
import moment from 'moment'
import { useConsentTrendChartUtils } from './hooks/useConsentTrendChartUtils'
import { RoutesManager } from 'utils/routing/routesManager'
import { EntitledBlurWrapper } from 'components/entitledBlurWrapper/EntitledBlurWrapper'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useNavigateGated } from 'utils/routing/hooks/useNavigateGated'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

const PREFIX = 'PermitsTrendChart'

const classes = {
  chartWrapper: `${PREFIX}-chartWrapper`,
  legend: `${PREFIX}-legend`,
  legendIcon: `${PREFIX}-legendIcon`,
  legendIconSquare: `${PREFIX}-legendIconSquare`,
  legendIconCircle: `${PREFIX}-legendIconCircle`,
  legendIconLine: `${PREFIX}-legendIconLine`,
  legendIconDashedLine: `${PREFIX}-legendIconDashedLine`,
  legendText: `${PREFIX}-legendText`,
  legendItem: `${PREFIX}-legendItem`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.chartWrapper}`]: {
    display: 'flex',
    flex: 1,
    height: '297px',
    marginRight: 128,
    marginLeft: 16,
  },

  [`& .${classes.legend}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 560,
    height: 22,
  },

  [`& .${classes.legendIcon}`]: {
    marginRight: 10,
  },

  [`& .${classes.legendIconSquare}`]: {
    width: 16,
    height: 16,
    borderRadius: 2,
  },

  [`& .${classes.legendIconCircle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },

  [`& .${classes.legendIconLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px solid',
  },

  [`& .${classes.legendIconDashedLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px dashed',
  },

  [`& .${classes.legendText}`]: {},

  [`& .${classes.legendItem}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.tab}`]: {
    paddingLeft: '10px',
  },
}))

export const ConsentTrendChart: React.FC = () => {
  const navigateGated = useNavigateGated()
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const currentDate = new Date()
  const startDate = moment(addDays(currentDate, -90)).toISOString()
  const endDate = `${moment(currentDate).toISOString()}`
  const trendPeriod = ChartTrendPeriod.DAILY as ChartTrendPeriod
  const startEndDifferenceInDays = Math.abs(differenceInDays(new Date(startDate), new Date(endDate)))

  const {
    monthlyData,
    aggregateAllowanceRate,
    reshapedDailyTrendData,
    dailyAggregateData,
    aggregateTotal,
    aggregateAllowed,
    isLoadingTrendData,
    shouldShowEmptyState,
  } = useConsentTrendChartUtils(startDate, endDate, trendPeriod)

  const shouldHideTrendLines =
    (trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE && monthlyData.length === 1) ||
    (trendPeriod === ChartTrendPeriod.DAILY && reshapedDailyTrendData.length === 1)

  return (
    <StyledBox
      sx={{
        background: theme => theme.palette.white.main,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 20px 24px 20px',
        flex: 1,
      }}
    >
      <Box display="flex" flexDirection="column" mb={1}>
        {/* Row 1: Header */}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h3">Consent Trend</Typography>
          </Box>
          <Button
            variant="icon"
            color="tertiary"
            onClick={() =>
              navigateGated(
                RoutesManager.insights.permits.root.getURL(),
                PERMISSIONS.PERMIT_INSIGHTS_READ,
                ENTITLEMENTS.STATS_CONSENT,
              )
            }
          >
            <Icon name="OArrowCRight" />
          </Button>
        </Box>
      </Box>

      {/* Row 2: Tabs and Date Range Input */}

      <EntitledBlurWrapper entitlements={ENTITLEMENTS.STATS_CONSENT}>
        {isLoadingTrendData ? (
          <Box
            sx={{
              alignItems: 'center',
              borderRadius: 2,
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              minHeight: '220px',
            }}
          >
            <CircularProgress size={CircularProgressSize.xLarge} />
          </Box>
        ) : shouldShowEmptyState || (reshapedDailyTrendData || [])?.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              minHeight: '220px',
            }}
          >
            <EmptyState colorTheme="darkDuskFaded" iconName="OFilter" title="No data for selected filter criteria" />
          </Box>
        ) : (
          <>
            {/* Row 3: Legend */}

            <Legend reshapedDailyTrendData={reshapedDailyTrendData} />

            {/* Row 3: Chart and Summary */}

            <Box
              sx={{
                flex: 1,
                display: 'flex',
              }}
            >
              {/* Column 1: Chart */}

              <Box className={classes.chartWrapper}>
                <ResponsiveContainer>
                  <ComposedChart
                    data={trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE ? monthlyData : reshapedDailyTrendData}
                    margin={{
                      top: 10,
                      right: 0,
                      left: -20,
                      bottom: 0,
                    }}
                    barSize={40}
                  >
                    <XAxis
                      tickLine={false}
                      padding={{ left: 30, right: 30 }}
                      dataKey={getXAxisDataKey({ trendPeriod, startEndDifferenceInDays })}
                      stroke={theme.palette.Text.Secondary}
                      interval={getXAxisInterval({ startEndDifferenceInDays, trendPeriod })}
                    />
                    <YAxis
                      tickLine={false}
                      width={65}
                      domain={[
                        0,
                        (dataMax: any) => {
                          const roundedDataMax = Math.round(dataMax / 10) * 10
                          const roundedYAxisBuffer = Math.round(Math.floor(dataMax * 0.3) / 10) * 10
                          return roundedDataMax + roundedYAxisBuffer
                        },
                      ]}
                      stroke={theme.palette.Text.Secondary}
                      tickFormatter={value => {
                        return formatCountWithoutDecimals(value).toString()
                      }}
                    />

                    <Bar dataKey="consent" stackId="bars" fill={theme.palette.Infographics.GreenA.Primary} />
                    <Bar dataKey="noConsentRequired" stackId="bars" fill={theme.palette.Infographics.BlueC.Primary} />
                    <Bar dataKey="noConsent" stackId="bars" fill={theme.palette.Black.o16} />

                    {!shouldHideTrendLines && (
                      <Line
                        type="linear"
                        dataKey="consentLine"
                        stroke={theme.palette.Infographics.VioletA.Primary}
                        strokeWidth={2}
                        dot={false}
                      />
                    )}

                    {/* Total, dashed line */}

                    {!shouldHideTrendLines && (
                      <Line type="linear" dataKey="overallLine" stroke="#9C64FF" strokeWidth={2} dot={false} />
                    )}

                    <RechartsTooltip
                      content={props => {
                        return (
                          <ConsentTrendChartTooltip
                            active={props.active}
                            label={props.label}
                            payload={props.payload}
                            trendPeriod={trendPeriod}
                          />
                        )
                      }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>

              {/* Column 2: Summary */}

              <Box
                sx={{
                  width: 216,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {/* Allowance Rate */}

                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography variant="h1" sx={{ mb: 0.5 }}>
                    {aggregateAllowanceRate.toFixed(1)}%
                  </Typography>
                  <Box display="flex" alignItems="center" gap="8px">
                    <Typography variant="label" color={theme.palette.Text.Secondary}>
                      {t('Allowance Rate')}
                    </Typography>

                    <Tooltip
                      title={t(
                        'The percentage of permits that allow you to use the data for a given purpose across all legal bases.',
                      )}
                      placement="bottom"
                    >
                      <span style={{ marginTop: '4px' }}>
                        <Icon name="FUnknown" width={16} height={16} iconColor={theme.palette.Black.o48} />
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                {/* Total */}

                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography variant="h2" sx={{ mb: 0.5 }}>
                    {aggregateTotal.toLocaleString()}
                  </Typography>
                  <Typography variant="label" color={theme.palette.Text.Secondary}>
                    {t('Total')}
                  </Typography>
                </Box>

                {/* Allowed */}

                <Box display="flex" flexDirection="column">
                  <Typography variant="h2" sx={{ mb: 1 }}>
                    {aggregateAllowed.toLocaleString()}
                  </Typography>
                  <Typography variant="label" color={theme.palette.Text.Secondary} sx={{ mb: 0.5 }}>
                    {t('Allowed')}
                  </Typography>
                  <Typography
                    variant="label"
                    color={theme.palette.Infographics.BlueC.Primary}
                    sx={{ mb: 0.5, whiteSpace: 'nowrap' }}
                  >
                    {t('Consent Not Required')}: {(dailyAggregateData?.consentNotRequired || 0).toLocaleString()}
                  </Typography>
                  <Typography variant="label" color={theme.palette.Infographics.GreenA.Primary}>
                    {t('Consent')}: {(dailyAggregateData?.consent || 0).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </EntitledBlurWrapper>
    </StyledBox>
  )
}
