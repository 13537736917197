import React from 'react'
import Box from '@mui/material/Box'
import { FormFieldViewItems, FormFieldViewTranslations } from '.'
import { ContentGroup } from '@ketch-com/deck'

export const FormFieldDetailViewTranslationsTab: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4} pb={6}>
      <ContentGroup variant="inner-page-header" titleVariant="h3" title="Translations" />
      {/* Translations */}

      <FormFieldViewTranslations />

      {/* Item Translations */}

      <FormFieldViewItems />
    </Box>
  )
}
