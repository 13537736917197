import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { DataRisksIntelligenceStats, DataRisksIntelligenceSummary } from './components'

const PREFIX = 'DataRisksIntelligence'

const classes = {
  dataRisksIntelligenceContainer: `${PREFIX}-dataRisksIntelligenceContainer`,
  dataRisksIntelligenceLabel: `${PREFIX}-dataRisksIntelligenceLabel`,
  dataRisksIntelligenceItemsContainer: `${PREFIX}-dataRisksIntelligenceItemsContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.dataRisksIntelligenceContainer}`]: {
    padding: spacing(4, 0),
  },

  [`& .${classes.dataRisksIntelligenceLabel}`]: {
    fontSize: 32,
    fontWeight: 800,
    borderBottom: `1px solid ${palette.iron.main}`,
    display: 'block',
    paddingBottom: spacing(4),
  },

  [`& .${classes.dataRisksIntelligenceItemsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 320,
    marginTop: spacing(4),
  },
}))

//TODO: Complete UI
export const DataRisksIntelligence: React.FC = () => {
  return (
    <StyledBox className={classes.dataRisksIntelligenceContainer}>
      <Text className={classes.dataRisksIntelligenceLabel}>Data Risks Intelligence</Text>
      <Box className={classes.dataRisksIntelligenceItemsContainer}>
        <DataRisksIntelligenceSummary />
        <DataRisksIntelligenceStats />
      </Box>
    </StyledBox>
  )
}
