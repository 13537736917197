import React, { Fragment, useEffect, useRef, useContext } from 'react'
import Box from '@mui/material/Box'
import moment from 'moment'

import { ComposeMessageInput, Message, SubjectDetailsCard, DatePill } from './components'
import { RightsQueueViewContext } from 'pages/orchestration/rightsQueue/viewV2/context/RightsQueueViewContext'
import { Button, EmptyState, Icon, theme } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

export const SubjectCommunicationTab: React.FC = () => {
  const {
    avatarColorMap,
    hasTwilioClientInitTimedOut,
    rightInvocation,
    setUnreadCount,
    twilioChatClient,
    twilioClientConnectionState,
    twilioConversation,
    twilioMessages,
    rightInvocationId,
  } = useContext(RightsQueueViewContext)

  const bottomRef = useRef<HTMLDivElement | null>(null)

  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  useEffect(() => {
    if (!twilioConversation) return
    const setRead = async (): Promise<void> => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
      // Mark all messages read
      await twilioConversation.setAllMessagesRead()
      setUnreadCount(0)
    }

    setRead()
  }, [twilioMessages, twilioConversation, setUnreadCount])

  const isThreadClosed = rightInvocation?.status === 'fulfilled' || rightInvocation?.status === 'rejected'
  const hasTwilioClientCatastrophicallyFailed =
    hasTwilioClientInitTimedOut && twilioClientConnectionState !== 'connected'
  const daysOld = moment().diff(moment((rightInvocation?.metadata?.createdAt || 0) * 1000), 'days')
  const isOver400DaysOld = daysOld > 400

  const emptyStateTitle = isOver400DaysOld
    ? 'No Messages Available'
    : hasTwilioClientCatastrophicallyFailed
    ? 'Unable to connect to chat'
    : 'No Messages'
  const emptyStateSubtitle = isOver400DaysOld
    ? 'Messages are archived after 400 days. Please contact Customer Success if you need access to this thread.'
    : hasTwilioClientCatastrophicallyFailed
    ? isThreadClosed
      ? undefined
      : 'Please refresh the page. If chat is still unavailable, please contact support.'
    : isThreadClosed
    ? undefined
    : 'Start your conversation here.'
  return (
    <Box display="flex" gap={4.5} sx={{ backgroundColor: 'transparent' }} flex={1}>
      {/* Left Col */}

      <Box
        display="grid"
        gridTemplateColumns="auto"
        gridTemplateRows="auto auto"
        flexDirection="column"
        sx={{
          flex: 2.2,
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Message Display Container */}

        {twilioMessages.length > 0 ? (
          <Box
            sx={{
              overflowY: 'auto',
              maxHeight: '60vh',
            }}
          >
            {twilioMessages.map((message, index) => {
              const createdDate = message.dateCreated
              const previousMessageCreatedDate = index !== 0 ? twilioMessages[index - 1]?.dateCreated : null
              const formattedDate = createdDate?.toLocaleDateString?.('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
              const previousMessageFormattedDate = previousMessageCreatedDate?.toLocaleDateString?.('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
              return (
                <Fragment key={message.sid}>
                  {formattedDate !== previousMessageFormattedDate && <DatePill date={formattedDate} />}

                  <Message
                    message={message}
                    isLast={index === twilioMessages.length - 1}
                    avatarColorMap={avatarColorMap}
                    isThreadClosed={isThreadClosed}
                  />
                </Fragment>
              )
            })}

            <Box ref={bottomRef} />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: 'tertiary.main',
              padding: '34px',
              borderRadius: '11px',
            }}
          >
            <EmptyState title={emptyStateTitle} subTitle={emptyStateSubtitle} iconName="OChatAlert" />
          </Box>
        )}

        {/* Message Input */}

        {isOver400DaysOld || isThreadClosed ? (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              borderTop: ({ palette }) => `1px solid ${palette.iron.main}`,
              pt: 4,
              mt: 4,
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography mr={0.625} color="darkDuskFaded.main">
                This thread is closed.
              </Typography>

              <Tooltip
                placement="bottom"
                title={<Typography>You cannot send messages once the request has been closed.</Typography>}
              >
                <Box>
                  <Icon name="FUnknown" height={14} width={14} iconColor={theme.palette.Text.Secondary} />
                </Box>
              </Tooltip>
            </Box>

            {isRelease(Release.SendbirdChat) && (
              <Button
                variant="contained"
                color="tertiary"
                pending={isDownloadingFile}
                startIcon={<Icon name="OShare" />}
                onClick={async () => {
                  const { data } = await handleDownloadFile({
                    params: {
                      fileUrl: `/api/v2/files?bucket=&folder=chat-archive&key=ChatArchive.zip&version=${rightInvocationId}&generatePresignedURL=true`,
                      responseType: 'json',
                    },
                  })
                  window.open(data?.object?.internalUrl, '_blank')
                }}
                sx={{ width: 'fit-content', mt: 1 }}
              >
                Export Conversation
              </Button>
            )}
          </Box>
        ) : (
          <ComposeMessageInput
            twilioChatClient={twilioChatClient}
            twilioConversation={twilioConversation}
            hasTwilioClientCatastrophicallyFailed={hasTwilioClientCatastrophicallyFailed}
            rightInvocation={rightInvocation || undefined}
          />
        )}
      </Box>

      {/* Right Col */}

      <Box display="flex" flexDirection="column" maxWidth="358px">
        <SubjectDetailsCard rightInvocation={rightInvocation} />
      </Box>
    </Box>
  )
}
