import { Avatar, AvatarSize, Button, PopUp, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useDataSystemsContext } from '../context/DataSystemContext'
import { useCallback, useState } from 'react'
import { showToast } from 'components/modals/AlertComponent'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { AssigneeOrTeamSelector } from 'components/AssigneeSelector/AssigneeOrTeamSelector'
import { EditDataOwnerModalProps } from '../types'
import { Logger } from 'utils/Logger'
import { useAssetsTabFilterContext } from 'pages/dataSystems/DataSystem/assetsTab/context/AssetsTabFilterContext'

export const EditDataOwnerModal: React.FC<EditDataOwnerModalProps> = ({
  assetCode,
  resourceTypeCode,
  onClose,
  dataOwner,
}) => {
  const { appDescriptor } = useDataSystemsContext()
  const { refetchInstalledDatasystemAssets } = useAssetsTabFilterContext()
  const [selectedOwnerUserId, setSelectedOwnerUserId] = useState<string | undefined>(dataOwner)
  const { data: assetSummary } = useAssetV2({
    params: {
      assetCode,
      resourceTypeCode,
    },
  })
  const { mutate: handleUpdateAsset } = useUpdateAssetV2({
    onError: err => {
      Logger.error('Failed to update asset', err)
      showToast({ content: 'Failed to update asset', type: 'error' })
    },
    onSuccess: async () => {
      await refetchInstalledDatasystemAssets()
      if (onClose) {
        onClose()
      }
      showToast({ content: 'Asset updated', type: 'success' })
    },
  })

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleEdit = useCallback(
    (id?: string) => {
      if (id) {
        const updatedAssetPayload = { ...assetSummary }
        if (updatedAssetPayload.asset) {
          updatedAssetPayload.asset.owner = id
        }

        handleUpdateAsset({
          params: {
            assetCode,
            resourceTypeCode,
            formData: { assetSummary: updatedAssetPayload },
          },
        })
      }
    },
    [assetCode, assetSummary, handleUpdateAsset, resourceTypeCode],
  )

  return (
    <PopUp
      variant="modal"
      onClose={handleClose}
      popUpCustomTitle={
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h3">Edit Data Owner</Typography>
          <Typography variant="h3" color={theme.palette.Text.Secondary}>
            in
          </Typography>
          <Avatar
            isLogo
            src={appDescriptor?.dataSystem?.logoUrl}
            alt={appDescriptor?.dataSystem?.logoUrl}
            variant="rounded"
            size={AvatarSize['medium']}
            backgroundColor={theme.palette.tertiary.main}
          />
          <Typography variant="h3">{appDescriptor?.dataSystem?.name}</Typography>
        </Box>
      }
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              handleEdit(selectedOwnerUserId)
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Box paddingY={1} paddingBottom={1}>
        <AssigneeOrTeamSelector
          size="medium"
          label="System Owner"
          selectedAssigneeId={selectedOwnerUserId}
          onChange={setSelectedOwnerUserId}
          hasAssignToMeButton
        />
      </Box>
    </PopUp>
  )
}
