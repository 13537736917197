import React from 'react'
import clsx from 'clsx'
import { Text } from 'components/ui-kit/typography/Text'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      signUp: {
        fontFamily: "'Inter'",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
        color: 'rgba(7, 26, 36, 0.5)',
      },
      link: {
        color: palette.sphere.main,
        textDecoration: 'none',
      },
    }),
  { name: 'SignUp' },
)

export const RecaptchaDisclosureSignUp: React.FC = () => {
  const classes = useStyles()

  return (
    <Text className={clsx(classes.signUp)}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" className={clsx(classes.link)} rel={'noreferrer'} target={'_blank'}>
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" className={clsx(classes.link)} rel={'noreferrer'} target={'_blank'}>
        Terms of Service
      </a>{' '}
      apply.
    </Text>
  )
}
