import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import pluralize from 'pluralize'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import {
  withApprovedTrackerPropertiesContext,
  withTrackerDetailsContext,
  TrackerDetailsContext,
  ApprovedTrackerPropertiesContext,
} from 'pages/policyCenter/trackers/context'
import { Spinner, Icon, IconDictionary, theme, ViewLayout, Chip, Widget, Button, PopUp } from '@ketch-com/deck'
import { Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Disclosure } from 'pages/policyCenter/trackers/list/approvedTrackers/components'
import { useIsPermitted, useIsEntitled } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { TrackerType, ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { AddRemovePurposes, ProvenanceVariant } from 'pages/policyCenter/trackers/components'
import { BlockedTracker } from '../list/approvedTrackers/components/BlockedTracker'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RecentScanResults } from 'pages/policyCenter/trackers/details/components/diagnostics/components/RecentScanResults'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { UnlockDiagnostics } from 'pages/policyCenter/trackers/details/components/diagnostics/components/UnlockDiagnostics'
import { DiagnosticsModal } from 'pages/policyCenter/trackers/details/components/diagnostics/components/DiagnosticsModal'

const TrackerDetailsWithContext: React.FC = () => {
  const { search, pathname } = useLocation()
  const [isPurposeModalEnabled, setIsPurposeModalEnabled] = useState(false)
  const [shouldShowTrackerDiagnosticsModal, setShouldShowTrackerDiagnosticsModal] = useState(false)
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )
  const isEntitledToTrackerDiagnostics = isEntitled(ENTITLEMENTS.TRACKER_DIAGNOSTICS)
  const isTrackerDiagnosticsEnabled = isRelease(Release.TrackerDiagnostics)
  const {
    isReady: isTrackerDetailsReady,
    trackerDetails,
    currentTab,
    setCurrentTab,
    encodedTrackerKey = '',
  } = useContext(TrackerDetailsContext)
  const { setTrackerKeys, isTrackerPropertiesReady } = useContext(ApprovedTrackerPropertiesContext)
  const tabs = useMemo(() => {
    const items = [
      {
        label: 'Overview',
        link: {
          pathname: RoutesManager.policyCenter.trackers.details.overview.root.getURL({ encodedTrackerKey }),
          search,
        },
      },
      {
        label: 'Properties & Visibility',
        link: {
          pathname: RoutesManager.policyCenter.trackers.details.propertiesAndVisibility.root.getURL({
            encodedTrackerKey,
          }),
          search,
        },
      },
    ]

    if (isTrackerDiagnosticsEnabled)
      items.push({
        label: 'Diagnostics',
        link: {
          pathname: RoutesManager.policyCenter.trackers.details.trackerDiagnostics.root.getURL({
            encodedTrackerKey,
          }),
          search,
        },
      })

    return items
  }, [search, encodedTrackerKey, isTrackerDiagnosticsEnabled])

  useEffect(() => {
    if (encodedTrackerKey)
      setTrackerKeys({
        trackerKey: trackerDetails?.key || '',
        encodedTrackerKey,
      })
  }, [encodedTrackerKey, setTrackerKeys, trackerDetails])

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (pathname.includes(tab.link.pathname)) {
        setCurrentTab(index)
      }
    })
  }, [tabs, setCurrentTab, pathname])

  if (!isTrackerDetailsReady || !isTrackerPropertiesReady)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: trackerDetails?.key },
        ]}
      />
      <ViewLayout
        contentGroupProps={{
          title: trackerDetails?.key,
          imageBlockComponent: (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={64}
              height={64}
              borderRadius={1.5}
              sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
            >
              <ProvenanceVariant
                provenance={trackerDetails?.provenance}
                type={ProvenanceType.Icon}
                iconWidth={42}
                iconHeight={42}
              />
            </Box>
          ),
          infoBlockComponent: (
            <Box display="flex" alignItems="center" gap={1}>
              {trackerDetails?.dataSystem?.logoURL ? (
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Box
                    sx={{
                      backgroundColor: ({ palette }) => palette.fadedGrey.main,
                      borderRadius: '50%',
                      width: 25,
                      height: 25,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={trackerDetails?.dataSystem?.logoURL}
                      alt={trackerDetails?.dataSystem?.name}
                      width={14}
                      height={14}
                    />
                  </Box>
                  <Typography variant="smallLabel">{trackerDetails?.dataSystem?.name}</Typography>
                </Box>
              ) : null}
              {trackerDetails?.type ? <Chip label={TrackerType[trackerDetails?.type]} size="small" /> : null}
              <Disclosure tracker={trackerDetails || ({} as Tracker)} />
              {isTrackerBlockingRelease && <BlockedTracker tracker={trackerDetails || ({} as Tracker)} />}
            </Box>
          ),
          showBackButton: true,

          propsBackButton: {
            onClick: e => {
              navigate(RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL())
            },
          },
        }}
        tabsComponentProps={{
          tabItems: tabs,
          value: currentTab,
          onChange: (event: React.SyntheticEvent, newValue: number) => {
            if (newValue === 2 && !isEntitledToTrackerDiagnostics && isTrackerDiagnosticsEnabled) {
              setShouldShowTrackerDiagnosticsModal(true)
              return
            }
            setCurrentTab(newValue)
            navigate(tabs[newValue].link)
          },
        }}
        widgetContentComponent={
          <>
            {!isEntitledToTrackerDiagnostics && isTrackerDiagnosticsEnabled ? <UnlockDiagnostics /> : null}

            <Widget
              expandable
              title="Purposes"
              subTitle={`${trackerDetails?.purposes?.length || 'No'} ${pluralize(
                'Purpose',
                trackerDetails?.purposes?.length,
              )} Assigned`}
              actionButton={
                <Button
                  disabled={!isPermittedToWriteCookie}
                  color="tertiary"
                  variant="icon"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    if (!isPermittedToWriteCookie) return
                    setIsPurposeModalEnabled(true)
                  }}
                >
                  <Icon name="FEdit" />
                </Button>
              }
              emptyStateProps={{
                title: 'No Purposes Assigned',
                subTitle: 'This tracker is not associated with any purpose.',
                customIcon: (
                  <Icon name={IconDictionary.OScales} iconColor={theme.palette.Text.Secondary} width={64} height={64} />
                ),
              }}
              content={
                trackerDetails?.purposes?.length ? (
                  <Box display="flex" flexDirection="column" gap={1} maxHeight={260} overflow="auto" width="100%">
                    {trackerDetails?.purposes?.map(purpose => {
                      return (
                        <Box key={purpose?.id}>
                          <Chip
                            label={purpose?.name}
                            clickable
                            onClick={() => {
                              navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                            }}
                            deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
                            onDelete={() => {
                              navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose?.code }))
                            }}
                            selectable={false}
                          />

                          {purpose?.isAutoAssigned ? (
                            <Box display="flex" alignItems="center" gap={0.5} mb={0.5}>
                              <Icon
                                name={IconDictionary.FAutomation}
                                iconColor={theme.palette.Text.Secondary}
                                width={12}
                                height={12}
                              />
                              <Typography variant="label" color="darkDuskFaded.main">
                                Auto-Assigned
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      )
                    })}
                  </Box>
                ) : null
              }
            />

            {isEntitledToTrackerDiagnostics && isTrackerDiagnosticsEnabled ? <RecentScanResults /> : null}
          </>
        }
      >
        <Outlet />
      </ViewLayout>
      {isPurposeModalEnabled ? (
        <PopUp
          variant="modal"
          isOpen={isPurposeModalEnabled}
          onClose={() => {
            setIsPurposeModalEnabled(false)
          }}
          popUpActionChildren={
            <Button
              color="secondary"
              size="large"
              onClick={() => {
                setIsPurposeModalEnabled(false)
              }}
            >
              Close
            </Button>
          }
          title="Purposes"
          subTitle="Search for a purpose, or remove already assigned."
          popUpWidth="825px"
        >
          <AddRemovePurposes tracker={trackerDetails || ({} as Tracker)} boltStatusEnabled={false} />
        </PopUp>
      ) : null}

      {shouldShowTrackerDiagnosticsModal ? (
        <DiagnosticsModal
          shouldShowTrackerDiagnosticsModal={shouldShowTrackerDiagnosticsModal}
          setShouldShowTrackerDiagnosticsModal={setShouldShowTrackerDiagnosticsModal}
        />
      ) : null}
    </>
  )
}

export const TrackerDetails = withApprovedTrackerPropertiesContext(withTrackerDetailsContext(TrackerDetailsWithContext))
