import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { UpsertApplicationDTO } from 'interfaces/applications/application'
import {
  ApplicationFormSpecialValues,
  ApplicationFormValues,
  ApplicationIdentitySpace,
} from 'interfaces/applications/ApplicationFormValues'
import { ApplicationIdentitySpaceType } from 'interfaces/applications/applicationIdentitySpaceType'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type Args = {
  values: ApplicationFormValues
  dataSubjectTypes: DataSubjectTypeDTO[]
}

const configureMobileIdentitySpaces = (identitySpaces: ApplicationIdentitySpace[]) => {
  return identitySpaces.map(space => ({ ...space, type: ApplicationIdentitySpaceType.QUERY_STRING }))
}

export const mapApplicationValuesToPayload = ({ values, dataSubjectTypes }: Args): UpsertApplicationDTO => {
  const {
    platform,
    defaultPolicyScopeVariable,
    identitySpaces,
    customIdentitySpaces,
    customPolicyScope,
    supportProxy,
    managedIdentity,
    proxy,
    accessibilityButton,
    privacyPolicyCode,
    termsOfServiceCode,
    ...rest
  } = values

  const isMobile = platform === ApplicationPlatform.MOBILE

  return {
    ...rest,
    platform,
    environments: (values?.environments || []).map(environment => ({
      ...environment,
      domain: platform === ApplicationPlatform.CTV ? environment.domain : undefined,
      themeID: isRelease(Release.PropertyThemeDocuments) ? environment.themeID : '',
    })),
    managedIdentity: managedIdentity && !isMobile,
    dataSubjectTypes: (values?.dataSubjectTypeCodes || [])
      ?.map(dstCode => dataSubjectTypes.find(dst => dst.code === dstCode))
      .filter(Boolean) as DataSubjectTypeDTO[],
    ...{
      ...(customIdentitySpaces && {
        identitySpaces: isMobile ? configureMobileIdentitySpaces(identitySpaces) : identitySpaces,
      }),
      ...(customPolicyScope &&
        !isMobile && {
          defaultPolicyScopeVariable,
        }),
      ...(supportProxy &&
        !isMobile && {
          proxy,
        }),
    },
    ...(accessibilityButton.enabled &&
      platform !== ApplicationPlatform.CTV && {
        accessibilityButton: {
          color: accessibilityButton.color,
          icon: accessibilityButton.icon,
          position: accessibilityButton.position,
          experienceType: accessibilityButton.experienceType,
        },
      }),
    ...(isRelease(Release.PropertyThemeDocuments) && {
      privacyPolicyCode:
        values.privacyPolicyCode === ApplicationFormSpecialValues.NO_DOCUMENT ? '' : values.privacyPolicyCode,
    }),
    ...(isRelease(Release.PropertyThemeDocuments) && {
      termsOfServiceCode:
        values.termsOfServiceCode === ApplicationFormSpecialValues.NO_DOCUMENT ? '' : values.termsOfServiceCode,
    }),
  }
}
