import React, { useContext, useState } from 'react'
import { SwitchText } from '../../components/SwitchText'
import { useFormikContext } from 'formik'
import Typography from '@mui/material/Typography'
import { Button, theme } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { MSPA_CHOICE_OPTIONS } from 'interfaces/privacyProtocols'
import { PrivacyProtocolConfigurationPutRequestBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { enableAdvertiserConsentModeProtocols, lspaProtocols } from '../../constants'
import { PrivacyProtocolUpsertContext } from '../context/PrivacyProtocolUpsertContext'
import { FormRadioGroup } from 'components/form/FormRadioGroup'

/**
 * Form section for displaying a list of toggles with protocol specific options
 */
export const OtherOptionsFormSection: React.FC = () => {
  const { protocol } = useContext(PrivacyProtocolUpsertContext)
  const isLspa = lspaProtocols.includes(protocol.id)
  const isEnableAdvertiserConsentMode = enableAdvertiserConsentModeProtocols.includes(protocol.id)
  const { setFieldValue, values } = useFormikContext<PrivacyProtocolConfigurationPutRequestBody>()
  const [open, setOpen] = useState(
    values.options
      ? values.options[
          isLspa ? 'lspaCovered' : isEnableAdvertiserConsentMode ? 'enableAdvertiserConsentMode' : 'mspaCovered'
        ] || false
      : false,
  )

  const handleMspaToggleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setOpen(checked)
    setFieldValue(`options.mspaCovered`, checked)
  }

  const handleLspaToggleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setOpen(checked)
    setFieldValue(`options.lspaCovered`, checked)
  }

  const handleEnableAdvertiserConsentModeToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setOpen(checked)
    setFieldValue(`options.enableAdvertiserConsentMode`, checked)
  }

  const mspaFormSection = (
    <Box pb={6}>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 3,
        }}
      >
        <Typography variant="h3">MSPA Configuration</Typography>
        <Typography variant="body" color={theme.palette.Text.Secondary}>
          Please indicate if your transactions are covered by the MSPA, the IAB Multi-State Privacy Agreement designed
          to help comply with five state privacy laws. For more information, visit{' '}
          <Button
            variant="link"
            color="secondary"
            size="large"
            sx={{ mt: '-2px' }}
            onClick={() => window.open('https://www.iab.com/mspa')}
          >
            iab.com/mspa
          </Button>{' '}
          .
        </Typography>
      </Box>
      <Box
        py={2.25}
        px={2.25}
        borderRadius="11px"
        bgcolor={open ? theme.palette.ironLight.main : theme.palette.white.main}
      >
        {/* MSPA covered switch */}
        <SwitchText
          value={values.options ? Boolean(values.options['mspaCovered']) || false : false}
          title={
            <Typography variant="h4" color={open ? 'greenHaze.main' : ''}>
              MSPA Covered Transaction
            </Typography>
          }
          onChange={handleMspaToggleChange}
        />

        {/* MSPA mode radios */}
        {open && (
          <FormRadioGroup
            formPropertyName="options.mspaMode"
            row={false}
            hideOptionalLabel
            valueKey="id"
            renderLabel={({ name, description }) => (
              <Box paddingRight="50px">
                {/* Make selected element bold */}
                <Typography variant="body">{name}</Typography>
                <Typography display="block" variant="footnote" color={theme.palette.Text.Secondary}>
                  {description}
                </Typography>
              </Box>
            )}
            options={MSPA_CHOICE_OPTIONS}
            onChange={(event, value) => {
              setFieldValue(`options.mspaMode`, value)
            }}
            sx={{ p: '43px 46px 32px 46px' }}
          />
        )}
      </Box>
    </Box>
  )

  const lspaFormSection = (
    <Box pb={6}>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 3,
        }}
      >
        <Typography variant="h3">LSPA Configuration</Typography>
        <Typography variant="body" color={theme.palette.Text.Secondary}>
          Please indicate if your transactions are covered by the LSPA, the IAB Limited Service Provider Agreement. For
          more information, visit{' '}
          <Button
            variant="link"
            color="secondary"
            size="large"
            sx={{ mt: '-2px' }}
            onClick={() => window.open('https://www.iabprivacy.com/lspa-2019-12.pdf')}
          >
            iab.com/lspa
          </Button>{' '}
          .
        </Typography>
      </Box>

      <Box py={2.25} px={2.25} borderRadius="11px" bgcolor={theme.palette.white.main}>
        {/* LSPA covered switch */}
        <SwitchText
          value={values.options ? Boolean(values.options['lspaCovered']) || false : false}
          title={
            <Typography variant="h4" color={open ? 'greenHaze.main' : ''}>
              LSPA Covered Transaction
            </Typography>
          }
          onChange={handleLspaToggleChange}
        />
      </Box>
    </Box>
  )

  const enableAdvertiserConsentModeFormSection = (
    <Box pb={6}>
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 3,
        }}
      >
        <Typography variant="h3">Enable Advertiser Consent Mode Configuration</Typography>
        <Typography variant="body" color={theme.palette.Text.Secondary}>
          Please indicate if you would like to enable the Google Consent Mode TCF integration. For more information,
          visit{' '}
          <Button
            variant="link"
            color="secondary"
            size="large"
            sx={{ mt: '-2px' }}
            onClick={() =>
              window.open('https://developers.google.com/tag-platform/security/guides/implement-TCF-strings#cmp-api')
            }
          >
            developers.google.com/implement-TCF-strings
          </Button>{' '}
          .
        </Typography>
      </Box>

      <Box py={2.25} px={2.25} borderRadius="11px" bgcolor={theme.palette.white.main}>
        {/* Enable advertiser consent mode switch */}
        <SwitchText
          value={values.options ? Boolean(values.options['enableAdvertiserConsentMode']) || false : false}
          title={
            <Typography variant="h4" color={open ? 'greenHaze.main' : ''}>
              Enable Advertiser Consent Mode
            </Typography>
          }
          onChange={handleEnableAdvertiserConsentModeToggleChange}
        />
      </Box>
    </Box>
  )

  return isLspa
    ? lspaFormSection
    : isEnableAdvertiserConsentMode
    ? enableAdvertiserConsentModeFormSection
    : mspaFormSection
}
