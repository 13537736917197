import React from 'react'
import Box from '@mui/material/Box'
import { Tab, TabPanel, TabGroup } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'

export const TabsExample: React.FC = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabGroup value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Tab" icon="FCalendar" iconPosition="start" />
              <Tab label="Tab" icon="FCalendar" iconPosition="start" />
              <Tab label="Tab" icon="FCalendar" iconPosition="start" />
            </TabGroup>
          </Box>
          <TabPanel value={value} index={0}>
            Item One
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </Box>

        <Typography variant="label">
          label='Tab' <br /> icon='FCalendar' <br /> iconPosition='start'
        </Typography>
      </Box>
    </Box>
  )
}
