import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { ActionSheetItem, Alert, ContentGroup, DropListButton, Icon, Spinner, theme } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { ScenarioList } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/ScenarioList'
import { Logger } from 'utils/Logger'
import { useInstalledDataSystemAssets } from 'api/dataSystems/queries/useInstalledDataSystemsAssets'
import React from 'react'
import { CanonicalResourceTypeDTO, ListAssetItemDTO } from '@ketch-com/figurehead'
import { Typography } from '@mui/material'

export const SelectedAsset = React.createContext<ListAssetItemDTO>({})
export default function RightsOrchestrationTab() {
  const [selectedAsset, setSelectedAsset] = React.useState<ListAssetItemDTO>()

  const { appDescriptor } = useDataSystemsContext()
  const appDescriptorId = appDescriptor?.id as string
  const dataSystemAssetResponse = useInstalledDataSystemAssets({ params: { id: appDescriptorId } })
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: appDescriptor?.dataSystem?.name, link: RoutesManager.systems.id.root.getURL({ id: appDescriptor?.id }) },
    { title: 'Rights Orchestration' },
  ]

  if (dataSystemAssetResponse.error) {
    Logger.error('Failed to fetch assets', dataSystemAssetResponse.error)
  }
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3} mb={6}>
        <ContentGroup title="Rights Orchestration" titleVariant="h3" />
        {dataSystemAssetResponse.error ? (
          <Alert
            severity="warning"
            title="Failed to fetch asset list. Trying again..."
            message="Failed to fetch assets. Contact support if the problem persists"
          />
        ) : null}
        {dataSystemAssetResponse.isLoading ? <Spinner size="24px" thickness={2.5} /> : null}
        {dataSystemAssetResponse.data ? (
          <DropListButton
            options={dataSystemAssetResponse.data.assets || []}
            getOptionLabel={option => `${option.resource?.name} ( via: ${option.resource?.connection?.name})`}
            isOptionEqualToValue={(option, value) => option.resource?.id === value.resource?.id}
            label="Select Asset"
            value={selectedAsset || null}
            onChange={(e, v) => {
              v && setSelectedAsset(v)
            }}
            renderOption={(props, option, { selected }) => (
              <ActionSheetItem key={option.resource?.id} selected={selected} {...props}>
                <Box display="flex" gap={0.5} flexDirection="column">
                  <Box alignSelf="flex-start">
                    <Typography variant="body">{option.resource?.name}</Typography>
                  </Box>
                  <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
                    <Icon fontSize={'small'} name={'OConnectionTrue'} iconColor={theme.palette.greenHaze.main} />
                    <Typography variant="footnote">{`${option.resource?.connection?.name}`}</Typography>
                  </Box>
                </Box>
              </ActionSheetItem>
            )}
          />
        ) : null}
        {selectedAsset && (
          <SelectedAsset.Provider value={selectedAsset}>
            <ScenarioList />
          </SelectedAsset.Provider>
        )}
      </Box>
    </>
  )
}
