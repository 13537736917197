import { Banner, Button, Icon, PopUp, SwitchToggle, theme } from '@ketch-com/deck'
import { Box, Link, styled, Tooltip, Typography } from '@mui/material'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { matchSearch } from 'utils/helpers/matchSearch'
import { useMemo, useState } from 'react'
import { WebTagDTO, WebTagPurposeBulkUpdateFormDTO } from 'interfaces/webtags/WebTags'
import { TransferList } from 'components/transferList/TransferList'
import { TransferListItemContentComponent } from 'components/modals/purposes/MapPurposesToWebTagModal'
import { find, isEmpty } from 'lodash'
import { useBulkUpdateWebTagPurposes } from 'api/webtags/mutations/useBulkUpdateWebTagPurposes'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { AdvancedConsentModeAppCodes, AppCode } from '../utils/enums'

type Props = {
  tagIds: string[]
  webTags: WebTagDTO[]
  onClose: () => void
}

const AdvancedConsentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 0px',
  width: 'fit-content',
})

/**
 * Modal to handle updating the mapped purposes for multiple web tags at once
 */
export const BulkUpdateTagPurposesModal: React.FC<Props> = ({ tagIds, webTags, onClose }) => {
  const queryClient = useQueryClient()
  const [mappedPurposes, setMappedPurposes] = useState<WebTagDTO['purposes']>([])
  const [mappedPurposesSearchString, setMappedPurposesSearchString] = useState('')
  const [availablePurposesSearchString, setAvailablePurposesSearchString] = useState('')

  const includedTags = useMemo(() => webTags.filter(({ ID }) => tagIds.includes(ID)), [webTags, tagIds])

  // Do some tags allow advanced consent mode
  const someTagsAllowAdvancedConsentMode = useMemo(
    () => includedTags.some(({ appCode }) => AdvancedConsentModeAppCodes.includes(appCode as AppCode)),
    [includedTags],
  )

  // Do all tags allow advanced consent mode
  const allTagsAllowAdvancedConsentMode = useMemo(
    () => includedTags.every(({ appCode }) => AdvancedConsentModeAppCodes.includes(appCode as AppCode)),
    [includedTags],
  )

  // Do all tags have advanced consent mode enabled
  const allTagsHaveAdvancedConsentModeEnabled = useMemo(
    () => includedTags.every(({ advancedConsentModeEnabled }) => !!advancedConsentModeEnabled),
    [includedTags],
  )

  const [advancedConsentModeEnabled, setAdvancedConsentModeEnabled] = useState(allTagsHaveAdvancedConsentModeEnabled)

  const advancedConsentModeToggleDisabled =
    !advancedConsentModeEnabled && (mappedPurposes.length > 0 || !allTagsAllowAdvancedConsentMode)

  const advancedConsentModeToggleTooltip = advancedConsentModeToggleDisabled
    ? mappedPurposes.length > 0
      ? 'Bulk enabling Built-in Consent Checks is not allowed when purposes are selected.'
      : `Bulk enabling Built-in Consent Checks is not allowed because ${
          someTagsAllowAdvancedConsentMode ? 'some' : 'all'
        } of the selected tags do not support it.`
    : ''

  const { mutateAsync: bulkUpdatePurposes, isLoading: isBulkUpdating } = useBulkUpdateWebTagPurposes({
    onSuccess: () => {
      showToast({ content: 'Tag purposes update', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Tag purposes update failed', type: 'error' })
    },
  })

  const {
    data: purposesList,
    isLoading: isPurposesListLoading,
    isFetching: isPurposesListFetching,
  } = usePurposes({
    onError: () => {
      showToast({ content: 'Failed to fetch purposes', type: 'error' })
      onClose()
    },
  })

  const isLoading = isBulkUpdating || isPurposesListLoading || isPurposesListFetching

  // Already mapped purposes for this tag
  const mappedPurposesList =
    mappedPurposes?.filter(purpose => matchSearch(purpose.name, mappedPurposesSearchString)) || []

  // Purposes which are not currently mapped to this tag
  const availablePurposesList =
    purposesList?.filter(
      purpose =>
        purpose.enabled &&
        isEmpty(find(mappedPurposes, { code: purpose.code })) &&
        matchSearch(purpose.name, availablePurposesSearchString),
    ) || []

  // Built-in consent checks switch
  const handleAdvancedConsentModeChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAdvancedConsentModeEnabled(checked)
  }

  // Format request body and make update status call, then exit
  const handleSave = async () => {
    const purposeIDs = mappedPurposesList.map(purpose => purpose.ID)
    const tagPurposeMappings: WebTagPurposeBulkUpdateFormDTO['tagPurposeMappings'] = includedTags.map(
      ({ ID: tagID, appCode, appInstanceID }) => ({
        tagID,
        appCode,
        appInstanceID,
        purposeIDs: advancedConsentModeEnabled ? undefined : purposeIDs,
        // Send advanced consent mode field only if all tags allow it
        advancedConsentModeEnabled: allTagsAllowAdvancedConsentMode ? advancedConsentModeEnabled : undefined,
      }),
    )
    await bulkUpdatePurposes({ params: { formData: { tagPurposeMappings } } })

    // Refresh tags list and close
    await queryClient.invalidateQueries(ApiQueryKeys.webTagsPaginated)
    onClose()
  }

  return (
    <PopUp
      title="Update Purposes"
      popUpWidth="960px"
      variant="modal"
      onClose={onClose}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" pending={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isLoading} onClick={handleSave}>
            Apply
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap="12px">
        <Banner
          severity="info"
          title="All currently assigned purposes will be replaced with the selected ones from the list."
          children="Built-in consent checks will be turned off if applicable and purposes are assigned."
          sx={{ mb: '6px', width: '912px !important' }}
        />
        {/* Advanced consent mode */}
        <Tooltip title={advancedConsentModeToggleTooltip} placement="bottom">
          <span style={{ width: 'fit-content' }}>
            <AdvancedConsentBox sx={advancedConsentModeToggleDisabled ? { opacity: 0.3, pointerEvents: 'none' } : {}}>
              <SwitchToggle
                size="small"
                checked={advancedConsentModeEnabled}
                onChange={handleAdvancedConsentModeChange}
              />
              <Typography variant={advancedConsentModeEnabled ? 'label' : 'body'} pt="2px">
                Use Built-In Consent Checks
              </Typography>
              <Tooltip
                placement="bottom-start"
                title={
                  <Typography variant="body">
                    Google Tags will use built-In consent checks. Read more{' '}
                    <Link
                      href="https://docs.ketch.com/ketch/docs/google-tag-manager-integration#for-advanced-consent-mode"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: 'white !important', textDecorationColor: 'white !important ', fontWeight: 600 }}
                    >
                      here
                    </Link>
                    .
                  </Typography>
                }
              >
                <span>
                  <Button
                    variant="iconCustomRounded"
                    size="small"
                    sx={{
                      width: '24px !important',
                      height: '24px',
                      minWidth: '24px',
                      ':hover': { background: 'none' },
                    }}
                  >
                    <Icon name="FUnknown" iconColor={theme.palette.Black.o48} width={16} height={16} />
                  </Button>
                </span>
              </Tooltip>
            </AdvancedConsentBox>
          </span>
        </Tooltip>

        {/* Purpose selector */}
        <TransferList
          excludedTitle="All Purposes"
          excludedItems={availablePurposesList}
          excludedItemsSearchString={availablePurposesSearchString}
          includedTitle="Selected Purposes"
          includedItems={mappedPurposesList}
          includedItemsSearchString={mappedPurposesSearchString}
          onChange={nextIncludedItems => setMappedPurposes(nextIncludedItems)}
          onSearchIncludedItems={nextSearchString => setMappedPurposesSearchString(nextSearchString)}
          onSearchExcludedItems={nextSearchString => setAvailablePurposesSearchString(nextSearchString)}
          ContentComponent={TransferListItemContentComponent}
          disabled={advancedConsentModeEnabled}
        />
      </Box>
    </PopUp>
  )
}
