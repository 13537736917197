import React, { useContext, useState } from 'react'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { ChipList } from 'components/chipList/ChipList'
import { SearchListModal } from '../../components/SearchListModal'
import { Button, Chip, ContentGroup, Icon, InfoRow, theme } from '@ketch-com/deck'
import { MSPA_CHOICE_NAME, MSPAChoice } from 'interfaces/privacyProtocols'
import { PrivacyProtocolContext } from '../context/PrivacyProtocolContext'
import { Vendor } from '@ketch-com/supercargo/dist/gvl_gen.schemas'
import { Purpose } from '@ketch-com/supercargo/dist/purpose_gen.schemas'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

/**
 * Main section for the details of a protocol configuration, including
 * jurisdiction, purpose mapping, vendors, and other options
 */
export const PrivacyProtocolViewOverview: React.FC = () => {
  const {
    navigate,
    protocol: initialProtocol,
    jurisdictions,
    globalVendorList,
    googleVendorList,
  } = useContext(PrivacyProtocolContext)

  const [protocol] = useState(initialProtocol)
  const [showGvlModal, setShowGvlModal] = useState(false)
  const [showGoogleVendorsModal, setShowGoogleVendorsModal] = useState(false)

  // Use either the selected vendors or all vendors from the gvl, and map to proper format for the modal
  const modalVendors = (
    protocol.config?.selectAllVendors ? globalVendorList.vendors || [] : protocol.config?.vendors || []
  ).map((vendor: Vendor) => ({ id: vendor.id || '', label: vendor.name || '' }))

  const modalGoogleVendors = (
    protocol.config?.selectAllGoogleVendors ? googleVendorList.vendors || [] : protocol.config?.googleVendors || []
  ).map((vendor: Vendor) => ({ id: vendor.id || '', label: vendor.name || '' }))

  // Filter out jurisdictions that have been deleted but still exist in the protocol config
  const displayedJurisdictions =
    protocol.config?.jurisdictions?.filter(jurisdiction =>
      jurisdictions.some(customerJurisdiction => customerJurisdiction.code === jurisdiction.code),
    ) ?? []

  return (
    <Box>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          {
            title: 'Consent & Rights',
            link: RoutesManager.orchestration.root.getURL(),
          },
          {
            title: 'Consent',
            link: RoutesManager.orchestration.consent.systems.root.getURL(),
          },
          {
            title: 'Privacy Protocols',
            link: RoutesManager.orchestration.consent.privacyProtocols.root.getURL(),
          },
          {
            title: `${protocol.name}`,
            link: RoutesManager.orchestration.consent.privacyProtocols.view.root.getURL({ id: protocol.id }),
          },
        ]}
      />
      <Box display="flex" flexDirection="column" gap={4}>
        {/* About */}
        <ContentGroup title="About" subTitle={protocol.description} titleVariant="h3" variant="inner-page-header" />

        {/* Jurisdiction */}
        {protocol.supportsJurisdictionSelection && (
          <InfoRow title="Jurisdictions">
            {protocol.config?.selectAllJurisdictions ? (
              <Typography variant="label">All Jurisdictions</Typography>
            ) : displayedJurisdictions.length > 0 ? (
              <Box display="inline-flex" alignItems="flex-start" gap={1} flexWrap="wrap">
                {displayedJurisdictions.map(protocolJurisdiction => (
                  <Chip
                    key={protocolJurisdiction.code}
                    label={
                      jurisdictions?.find(
                        customerJurisdiction => customerJurisdiction.code === protocolJurisdiction.code,
                      )?.name || ''
                    }
                    onClick={() =>
                      navigate(
                        RoutesManager.policyCenter.policyScopes.view.root.getURL({
                          code: protocolJurisdiction.code,
                        }),
                      )
                    }
                    onDelete={() =>
                      navigate(
                        RoutesManager.policyCenter.policyScopes.view.root.getURL({
                          code: protocolJurisdiction.code,
                        }),
                      )
                    }
                    deleteIcon={<Icon name={'OArrowCRight'} />}
                    clickable
                  />
                ))}
              </Box>
            ) : (
              <Typography variant="body">None Configured</Typography>
            )}
          </InfoRow>
        )}

        {/* Mapped purposes */}
        {protocol.supportsPurposeMapping && (
          <InfoRow title="Purpose Mapping">
            <Box display="flex" flexDirection="column">
              {/* Header */}
              <Box display="flex" alignItems="center" pb={2}>
                <Box flex="1">
                  <Typography variant="label" fontWeight={400} color={theme.palette.Text.Secondary}>
                    {protocol.name} Purpose
                  </Typography>
                </Box>
                <Box flex="1">
                  <Typography variant="label" fontWeight={400} color={theme.palette.Text.Secondary}>
                    Purpose
                  </Typography>
                </Box>
              </Box>
              {/* content */}
              {Object.entries(protocol.config?.purposeMapping || {}).map(
                ([protocolPurposeId, customerPurposes], index, items) => (
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        pb: 4,
                        pt: index === 0 ? 0 : 1.875,
                        flex: '1',
                        alignSelf: 'flex-start',
                      }}
                    >
                      <Typography variant="label" color={theme.palette.Text.Secondary}>
                        {protocol.purposes.find((pp: Purpose) => pp.code === protocolPurposeId)?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                        pb: 4,
                        pt: index === 0 ? 0 : 1.875,
                      }}
                    >
                      {Array.isArray(customerPurposes) && customerPurposes.length ? (
                        customerPurposes.map(purpose => (
                          <Typography variant="label" display="block">
                            {purpose.name}
                          </Typography>
                        ))
                      ) : (
                        <EmptyValueRenderer />
                      )}
                    </Box>
                  </Box>
                ),
              )}
            </Box>
          </InfoRow>
        )}

        {/* Vendors */}
        {protocol.supportsVendorSelection && (
          <>
            <InfoRow title="Vendors">
              <Box>
                {/* Vendors list or all/none text */}
                {protocol.config?.selectAllVendors ? (
                  <Typography variant="label">
                    All Vendors
                    <br />
                  </Typography>
                ) : globalVendorList?.vendors && protocol.config?.vendors && protocol.config?.vendors.length > 0 ? (
                  <Box display="flex" flexDirection="column" alignContent="flex-start" gap="12px">
                    <ChipList
                      verticalChips
                      data={protocol.config.vendors.slice(0, 5).map((vendor: Vendor) => ({
                        key: vendor.id || '',
                        label: vendor.name || '',
                      }))}
                    />
                  </Box>
                ) : (
                  <Typography variant="body">None Configured</Typography>
                )}

                {/* Link to open vendors modal */}
                {((protocol.config?.vendors && protocol.config?.vendors.length > 5) ||
                  protocol?.config?.selectAllVendors) && (
                  <Box mt={1}>
                    <Button variant="link" color="secondary" size="large" onClick={() => setShowGvlModal(true)}>
                      View All &nbsp;
                      {!protocol.config.selectAllVendors && (
                        <Typography display="inline" variant="body">
                          {protocol.config?.vendors?.length || globalVendorList?.vendors?.length || ''}
                        </Typography>
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </InfoRow>
          </>
        )}

        {/* Vendors Modal */}
        {showGvlModal && (
          <SearchListModal
            items={modalVendors}
            title="Vendors"
            subtitle="Additional Information"
            cancelButtonLabel="Close"
            onCancel={() => setShowGvlModal(false)}
          />
        )}

        {/* Google Vendors */}
        {protocol.supportsGoogleVendorSelection && (
          <>
            <InfoRow title="Google Additional Consent Vendors">
              <Box>
                {/* Google Vendors List or all/none text */}
                {protocol.config?.selectAllGoogleVendors ? (
                  <Typography variant="label">
                    All Google Additional Consent Vendors
                    <br />
                  </Typography>
                ) : googleVendorList?.vendors &&
                  protocol.config?.googleVendors &&
                  protocol.config?.googleVendors.length > 0 ? (
                  <Box display="flex" flexDirection="column" alignContent="flex-start" gap="12px">
                    <ChipList
                      verticalChips
                      data={protocol.config.googleVendors.slice(0, 5).map((vendor: Vendor) => ({
                        key: vendor.id || '',
                        label: vendor.name || '',
                      }))}
                    />
                  </Box>
                ) : (
                  <Typography variant="body">None Configured</Typography>
                )}

                {((protocol.config?.googleVendors && protocol.config?.googleVendors.length > 5) ||
                  protocol?.config?.selectAllGoogleVendors) && (
                  <Box mt={1}>
                    <Button
                      variant="link"
                      color="secondary"
                      size="large"
                      onClick={() => setShowGoogleVendorsModal(true)}
                    >
                      View All &nbsp;
                      {!protocol.config.selectAllGoogleVendors && (
                        <Typography display="inline" variant="body">
                          {protocol.config?.googleVendors?.length || googleVendorList?.vendors?.length || ''}
                        </Typography>
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </InfoRow>
          </>
        )}

        {showGoogleVendorsModal && (
          <SearchListModal
            items={modalGoogleVendors}
            title="Google Additional Consent Vendors"
            subtitle="Additional Information"
            cancelButtonLabel="Close"
            onCancel={() => setShowGoogleVendorsModal(false)}
          />
        )}

        {/* MSPA */}
        {protocol.config?.options && protocol.config?.options['mspaCovered'] ? (
          <InfoRow title="MSPA Mode">
            <Typography variant="body">
              {protocol.config?.options['mspaMode'] === MSPAChoice.optOut
                ? MSPA_CHOICE_NAME[MSPAChoice.optOut]
                : MSPA_CHOICE_NAME[MSPAChoice.serviceProvider]}
            </Typography>
          </InfoRow>
        ) : null}

        {/* LSPA */}
        {protocol.config?.options && protocol.config?.options['lspaCovered'] ? (
          <InfoRow title="LSPA Covered">
            <Typography variant="body">Yes</Typography>
          </InfoRow>
        ) : null}

        {/* Enable Advertiser Consent Mode */}
        {protocol.config?.options && protocol.config?.options['enableAdvertiserConsentMode'] ? (
          <InfoRow title="Enable Advertiser Consent Mode">
            <Typography variant="body">Yes</Typography>
          </InfoRow>
        ) : null}

        {/* Last Updated */}
        <InfoRow title="Last Update">
          <Typography variant="body">{formatDateTimeFromUnix(protocol.config?.updatedAt)}</Typography>
        </InfoRow>
      </Box>
    </Box>
  )
}
