import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

import { joinNonEmpty } from 'utils/helpers/joinNonEmpty'
import { RightInvocationIdentitySpaces } from 'pages/orchestration/rightsQueue/viewV2/components/RightInvocationIdentitySpaces'
import { ContextVariableChip } from '../../ContextVariableChip'
import { FormData } from '.'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { Chip, ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export const SubjectDetails: React.FC = () => {
  const { rightInvocation } = useContext(RightsQueueViewContext)
  const executionToUse = rightInvocation?.appeal?.isAppealed
    ? rightInvocation?.appealWorkflowExecution
    : rightInvocation?.workflowExecution
  const subjectContext = executionToUse?.results?.context || ({} as { [key: string]: string })

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <ContentGroup title="Subject Details" titleVariant="h3" variant="inner-page-header" />
        {/* Static/Mandatory DSR Form Data */}
        <InfoRow title="Name">
          <Typography>{joinNonEmpty([rightInvocation?.user?.first, rightInvocation?.user?.last])}</Typography>
        </InfoRow>

        <InfoRow title="Email">
          <Typography>{rightInvocation?.user?.email || <EmptyValueRenderer />}</Typography>
        </InfoRow>

        {/* Request Details
        
        Note: 
        
        There is some impending BE work that will relocate `description`, `phone`, `addressLine1/addressLine2`, and
        `postalCode` to `<FormData />` because they are no longer static, but, dynamic depending on how the formTemplate
        used to invoke a right was configured.  Because of this, it was decided that for now FE should simply not render
        the row if no data is present. 

        */}

        {rightInvocation?.user?.description && (
          <InfoRow title="Request Details">
            <Typography>{rightInvocation?.user?.description}</Typography>
          </InfoRow>
        )}

        {rightInvocation?.user?.phone && (
          <InfoRow title="Phone">
            <Typography>{rightInvocation?.user?.phone}</Typography>
          </InfoRow>
        )}

        {(rightInvocation?.user?.addressLine1 || rightInvocation?.user?.addressLine2) && (
          <InfoRow title="Address">
            <Typography>
              {joinNonEmpty([rightInvocation?.user?.addressLine1, rightInvocation?.user?.addressLine2], ', ') || (
                <EmptyValueRenderer />
              )}
            </Typography>
          </InfoRow>
        )}

        {rightInvocation?.user?.city && (
          <InfoRow title="City">
            <Typography>{rightInvocation?.user?.city}</Typography>
          </InfoRow>
        )}

        {rightInvocation?.user?.stateRegion && (
          <InfoRow title="State/Region">
            <Typography>{rightInvocation?.user?.stateRegion}</Typography>
          </InfoRow>
        )}

        {rightInvocation?.user?.postalCode && (
          <InfoRow title="Postal Code">
            <Typography>{rightInvocation?.user?.postalCode}</Typography>
          </InfoRow>
        )}

        {rightInvocation?.user?.country && (
          <InfoRow title="Country">
            <Typography>{rightInvocation?.user?.country}</Typography>
          </InfoRow>
        )}

        {/* Subject Type */}

        <InfoRow title="Subject Type">
          <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
            {rightInvocation?.user?.dataSubjectTypeCode || rightInvocation?.user?.dataSubjectTypeName ? (
              <Chip
                key={rightInvocation?.user?.dataSubjectTypeCode}
                label={rightInvocation?.user?.dataSubjectTypeName}
                secondaryLabel={rightInvocation?.user?.dataSubjectTypeCode}
              />
            ) : (
              <EmptyValueRenderer />
            )}
          </Box>
        </InfoRow>

        {rightInvocation?.user?.dataSubjectTypeRelationshipDetails && (
          <InfoRow title="Relationship to Company">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {rightInvocation?.user?.dataSubjectTypeRelationshipDetails}
              </Typography>
            </Box>
          </InfoRow>
        )}

        {/* Form Data */}

        <FormData />

        {/* Identity */}

        <InfoRow title="Identity">
          {rightInvocation?.identities?.length ? (
            <RightInvocationIdentitySpaces identities={rightInvocation?.identities} max={4} />
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>

        {/* Subject Context */}

        {!!Object.entries(subjectContext).length && (
          <InfoRow title="Data Subject Variable">
            <Box
              display="flex"
              gap={1}
              flexWrap="wrap"
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {Object.entries(subjectContext).map(([key, value]) => (
                <ContextVariableChip code={key} value={value} key={key} />
              ))}
            </Box>
          </InfoRow>
        )}
      </Box>
    </>
  )
}
