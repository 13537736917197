import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { Button, InlineEdit, InlineEditSize, TabSegmentedGroup, TabSegmented, theme } from '@ketch-com/deck'
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import { ThemeType } from '../utils/enums'
import { ThemeTypeLabels } from '../utils/labels'
import { RoutesManager } from '../../../../../utils/routing/routesManager'
import { ThemeUpsertContext } from '../context/ThemeUpsertContext'
import { ColorPicker } from './ColorPicker'
import { getIn, useFormikContext } from 'formik'
import { themeUpsertFormKeys } from '../utils/useThemeValidationSchema'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { extractUniqueColors } from '../utils/utils'
import { ExperienceServerDropdownButton } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/ExperienceServerDropdownButton'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { PreferencePageLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'

const COLOR_PICKER_SHRINK_THRESHOLD = 40

const HeaderBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: 62,
  maxHeight: 80,
  padding: '12px 32px',
  marginTop: -24,
  background: theme.palette.white.main,
  boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10);',
  zIndex: 'auto',
}))

const HeaderContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: theme.palette.white.main,
}))

const RightHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: 32,
}))

const ButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 12,
}))

const PageSelectionBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
})

export const UpsertThemeInlineLayoutHeader = () => {
  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // States to store info about component widths
  const nameInlineEditRef = useRef<HTMLDivElement>(null)
  const rightHeaderBoxRef = useRef<HTMLDivElement>(null)
  const [nameInlineEditWidth, setNameInlineEditWidth] = useState(0)
  const [rightHeaderBoxWidth, setRightHeaderBoxWidth] = useState(0)

  // Compute if we should show the condensed color picker
  const useCondensedColorPicker = useMemo(() => {
    return windowWidth - rightHeaderBoxWidth - nameInlineEditWidth < COLOR_PICKER_SHRINK_THRESHOLD
  }, [windowWidth, rightHeaderBoxWidth, nameInlineEditWidth])

  const { submitForm, setFieldValue, errors, values } = useFormikContext<ThemeV3DTO>()

  const {
    themeType,
    setThemeType,
    navigate,
    id,
    setThemeBuilderSection,
    preferencePage,
    setPreferencePage,
    entitledPreferencePages,
  } = useContext(ThemeUpsertContext)
  const omitPages = [PreferenceCenterPage.PrivacyPolicy]
  const showPageDropdown = themeType === ThemeType.Preference

  const handleThemeTypeChange = (event: React.MouseEvent<HTMLElement>, newType: ThemeType) => {
    if (newType !== null) {
      setThemeType(newType)
      setThemeBuilderSection(undefined)
    }
  }

  const handleCancelClick = () => {
    if (id) {
      navigate(RoutesManager.deployment.themesV3.view.root.getURL({ id }))
    } else {
      navigate(RoutesManager.deployment.themesV3.root.getURL())
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(themeUpsertFormKeys.themeName, e.target.value)
  }

  const handlePreferencePageChange = (page: PreferenceCenterPage) => {
    setPreferencePage(page)
    setThemeBuilderSection(undefined)
  }

  const uniqueColors = useMemo(() => extractUniqueColors(values), [values])

  // Effect to update the window width state on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Effect to get the right header box width, updated when page dropdown is shown or hidden
  useLayoutEffect(() => {
    if (rightHeaderBoxRef.current) {
      setRightHeaderBoxWidth(rightHeaderBoxRef.current?.offsetWidth)
    }
  }, [showPageDropdown])

  // Effect to setup an observer of the name inline edit component
  useLayoutEffect(() => {
    // Initialize observer
    const inlineEditResizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        // Assuming you want to track the width of the content-box
        if (entry.contentBoxSize) {
          // contentBoxSize is an array in some browsers
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
          setNameInlineEditWidth(contentBoxSize.inlineSize)
        }
      }
    })

    if (nameInlineEditRef.current) {
      inlineEditResizeObserver.observe(nameInlineEditRef.current)
    }

    // Copy ref for use in cleanup
    const refCopy = nameInlineEditRef

    // Cleanup
    return () => {
      if (refCopy.current) {
        inlineEditResizeObserver.unobserve(refCopy.current)
      }
    }
  }, [])

  return (
    <HeaderBox>
      <HeaderContentBox>
        <div ref={nameInlineEditRef}>
          <InlineEdit
            placeholder="Theme Name"
            size={InlineEditSize.large}
            onChange={handleNameChange}
            validationText={getIn(errors, themeUpsertFormKeys.themeName)}
            onFocus={() => setThemeBuilderSection(undefined)}
            value={getIn(values, themeUpsertFormKeys.themeName)}
            maxLength={55}
          />
        </div>
        <RightHeaderBox ref={rightHeaderBoxRef}>
          {/* Color picker */}
          <ColorPicker colors={uniqueColors} backgroundColors={['#FFFFFF']} listOnly={useCondensedColorPicker} />

          {/* Theme type picker */}
          <TabSegmentedGroup value={themeType} onChange={handleThemeTypeChange} exclusive size={'small'}>
            {Object.values(ThemeType).map((themeTypeValue: ThemeType) => (
              <TabSegmented value={themeTypeValue} key={themeTypeValue}>
                <Typography
                  // Different text variant for selected theme
                  variant={themeType === themeTypeValue ? 'smallLabel' : 'smallBody'}
                >
                  {ThemeTypeLabels[themeTypeValue]}
                </Typography>
              </TabSegmented>
            ))}
          </TabSegmentedGroup>

          {/* Preference experience page picker */}
          {showPageDropdown && (
            <PageSelectionBox>
              <Typography variant="label" color={theme.palette.Text.Secondary}>
                Page:
              </Typography>
              <ExperienceServerDropdownButton
                items={entitledPreferencePages.filter(p => !omitPages.includes(p))}
                borderBottomItems={[PreferenceCenterPage.RequestsHome]}
                value={preferencePage}
                renderItem={p => PreferencePageLabels[p]}
                onChange={handlePreferencePageChange}
              />
            </PageSelectionBox>
          )}

          {/* Action buttons */}
          <ButtonsBox>
            <Button color={'secondary'} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button color={'primary'} onClick={submitForm} sx={{ minWidth: '95px' }}>
              Save & Exit
            </Button>
          </ButtonsBox>
        </RightHeaderBox>
      </HeaderContentBox>
    </HeaderBox>
  )
}
