import React, { useContext } from 'react'
import {
  ApiCall,
  DecisionGateway,
  Task,
  App,
  Email,
  SetPermit,
  IdentityVerification,
  ImportContext,
  EndFlow as EndV2,
  InternalSystemDsr,
} from './components'
import { WorkflowActivityCategory } from 'interfaces/workflowActivities/workflowActivity'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { RetryLoadingState } from './components/RetryLoadingState'
import { useDerivedWorkflowStepDetails } from './hooks'
import {
  RightInvocationWorkflowStepDetailViewContext,
  withRightInvocationWorkflowStepDetailViewContext,
} from './context/RightInvocationWorkflowStepDetailViewContext'
import { Spinner } from '@ketch-com/deck'
import { Box } from '@mui/material'

const isAppCategory = (category: WorkflowActivityCategory) =>
  [WorkflowActivityCategory.APP, WorkflowActivityCategory.APP_LEVEL_2, WorkflowActivityCategory.CONNECTOR].includes(
    category,
  )

export const WorkflowStepDetailsWithContext: React.FC = () => {
  const { isReady } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const {
    category,
    isStepFailed,
    isEndTile,
    isDecisionGateway,
    isApiCall,
    isTask,
    isIdv,
    isSetPermits,
    isEmail,
    isImportContext,
    isInternalSystemDSR,
  } = useDerivedWorkflowStepDetails()

  if (!isReady)
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  if (isEndTile) return <EndV2 />

  if (isAppCategory(category as WorkflowActivityCategory)) return <App />

  if (isIdv) return <IdentityVerification />

  if (isApiCall) return <ApiCall />

  if (isDecisionGateway) return <DecisionGateway />

  if (isTask) return <Task />

  if (isImportContext) return <ImportContext />

  if (isInternalSystemDSR) return <InternalSystemDsr />

  // these remain behind feature flag until ready for release
  const isManualInterventionReleased = isRelease(Release.RightsQueueManualIntervention)
  if (isManualInterventionReleased) {
    if (isSetPermits && isStepFailed) return <SetPermit />

    if (isEmail && isStepFailed) return <Email />
  }

  return <RetryLoadingState />
}

export const WorkflowStepDetails = withRightInvocationWorkflowStepDetailViewContext(WorkflowStepDetailsWithContext)
