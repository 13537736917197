import { Icon, TextInput, TextInputProps, theme } from '@ketch-com/deck'
import { ChangeEvent, ReactNode, useMemo, useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import { ExperienceConfig, ThemeConfig } from '@ketch-sdk/ketch-types'
import { isUndefined } from 'lodash'
import { Box, Tooltip, Typography } from '@mui/material'
import { MaybeNull } from 'interfaces'

export enum BuilderTextInputMode {
  Standard = 'standard',
  CornerRadius = 'cornerRadius',
  Opacity = 'opacity',
}

const ModeTypeMap: { [mode: string]: string } = {
  [BuilderTextInputMode.Standard]: '',
  [BuilderTextInputMode.CornerRadius]: 'number',
  [BuilderTextInputMode.Opacity]: 'number',
}

type Props = {
  name: string
  mode?: BuilderTextInputMode
  min?: number
  max?: number
  charLimit?: number
  tooltip?: ReactNode
} & TextInputProps

export const FormTextInput: React.FC<Props> = ({
  name,
  mode = 'standard',
  min = 0,
  max = 36,
  charLimit = 250,
  helperText,
  label,
  tooltip,
  ...props
}) => {
  const { values, errors, setFieldValue } = useFormikContext<ExperienceConfig | ThemeConfig>()
  const value = getIn(values, name)
  const error = getIn(errors, name)

  const [manualErrorMessage, setManualErrorMessage] = useState('')

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value

    // Handle character limit
    const overCharLimit = mode === BuilderTextInputMode.Standard && newValue && newValue.length > charLimit
    setManualErrorMessage(overCharLimit ? `The maximum character count is ${charLimit}.` : '')

    if (name && !overCharLimit) setFieldValue(name, newValue)
  }

  // Clamp to min or max when user clicks away from field with value outside of range
  const handleBlur = () => {
    // Handle corner radius min/max
    if ((mode === BuilderTextInputMode.CornerRadius || mode === BuilderTextInputMode.Opacity) && name) {
      if (value < min) {
        setFieldValue(name, min)
      } else if (value > max) {
        setFieldValue(name, max)
      }
    }
  }

  const ModeHelperTextMap: { [mode: string]: MaybeNull<string | React.ReactElement> } = useMemo(
    () => ({
      [BuilderTextInputMode.Standard]: '',
      [BuilderTextInputMode.CornerRadius]: (
        <Typography variant={'smallBody'} color={theme.palette.Text.Secondary} pt={'4px'}>
          {max} is a maximum value.
        </Typography>
      ),
    }),
    [max],
  )

  return (
    <TextInput
      value={isUndefined(value) ? '' : value}
      onChange={handleChange}
      label={
        <Box display={'flex'} gap="4px">
          <Typography variant={'label'}>{label}</Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="FUnknown" width={16} height={16} iconColor={theme.palette.Black.o48} />
              </span>
            </Tooltip>
          )}
        </Box>
      }
      {...props}
      type={ModeTypeMap[mode]}
      error={!!error || !!manualErrorMessage}
      errorMessage={error || manualErrorMessage}
      helperText={helperText || ModeHelperTextMap[mode]}
      onBlur={handleBlur}
      sx={{ overflow: 'hidden' }}
    />
  )
}
