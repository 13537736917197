import { GridRenderCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { Avatar, AvatarSize, Button, Chip, Icon, IconMap, TableCell, theme } from '@ketch-com/deck'
import { Box, Tooltip, Typography } from '@mui/material'
import {
  ProcessingActivityAssetDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityDataSystemProcessingStageDTO,
} from '@ketch-com/figurehead'
import { personalDataTypes } from '../../../constants'
import { toNumber } from 'lodash'
import { styled } from '@mui/styles'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

const StageIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(4),
  height: theme.spacing(4),
  padding: theme.spacing(0.5),
  backgroundColor: theme.palette.fadedGrey.main,
  borderRadius: 100,
}))

export interface DataGridObjectType extends ProcessingActivityDataSystemDTO {
  path: string[]
  handleDataCategoriesClick: (object: ProcessingActivityDataSystemDTO) => void
}

export interface DataGridAssetObjectType extends ProcessingActivityAssetDTO {
  path: string[]
  handleDataCategoriesClick: (object: ProcessingActivityAssetDTO) => void
  logoUrl?: string
}

export function isDataGridObjectType(object: any): object is DataGridObjectType {
  return 'type' in object
}

export const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'System',
  sortable: false,
  resizable: false,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  renderCell: (params: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
    const { row, rowNode } = params
    const isDataGridObj = isDataGridObjectType(row)
    return (
      <TableCell sx={{ padding: '8px 0 8px 15px' }}>
        {rowNode.type === 'group' && <Icon name={rowNode.childrenExpanded ? 'OArrowCDown' : 'OArrowCRight'} />}
        <Avatar
          size={rowNode.depth === 1 ? AvatarSize.small : AvatarSize.xlarge}
          isLogo
          variant="rounded"
          sx={{
            backgroundColor: theme => theme.palette.tertiary.main,
          }}
          src={isDataGridObj ? row.dataSystem?.logoUrl : row.logoUrl}
        />
        <Typography variant="label">{isDataGridObj ? row.dataSystem?.name : row.resourceTypeCode}</Typography>
      </TableCell>
    )
  },
}
export const dataSourcesListColumns = (isModelType: boolean): GridColDef<DataGridObjectType>[] => [
  {
    align: 'left',
    field: 'personalDataType',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Data Sensitivity',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
      return <TableCell sx={{ margin: '8px 0' }} title={personalDataTypes[toNumber(row.personalDataType)]} />
    },
  },
  {
    align: 'left',
    field: 'dataCategories',
    flex: 0.6,
    headerAlign: 'left',
    headerName: 'Data Categories',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
      return (
        <TableCell onClick={e => e.stopPropagation()}>
          {row?.dataCategories?.length ? (
            <Chip
              label={<Typography variant="body">{row?.dataCategories?.length || 'None'} categories</Typography>}
              clickable
              deleteIcon={<Icon name="OArrowCRight" />}
              onDelete={e => {
                e.stopPropagation()
                row.handleDataCategoriesClick(row)
              }}
              onClick={e => {
                e.stopPropagation()
                row.handleDataCategoriesClick(row)
              }}
            />
          ) : (
            <Typography color={'lightGrey.main'} variant="body">
              None
            </Typography>
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'processingStages',
    flex: 0.6,
    headerAlign: 'left',
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams<DataGridObjectType>) => {
      return (
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="smallLabel">Stage of Processing</Typography>
          <Tooltip
            placement="left"
            title={
              <Box display="flex" flexDirection="column" gap={2} px={0.5} py={1}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Icon name="OInput" iconColor="white" />
                  <Typography variant="label">Input</Typography>
                  <Typography variant="smallBody">
                    Either a source collection point or an input intended for subsequent processing.
                  </Typography>
                </Box>
                {!isModelType && (
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Icon name="ORefresh" iconColor="white" />
                    <Typography variant="label">Processing</Typography>
                    <Typography variant="smallBody">
                      A system or technology where the processing actually takes place.
                    </Typography>
                  </Box>
                )}

                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Icon name="OOutput" iconColor="white" />
                  <Typography variant="label">Output</Typography>
                  <Typography variant="smallBody">
                    A destination, storage location, or recipient of the processed output.
                  </Typography>
                </Box>
              </Box>
            }
          >
            <Box display="flex" alignItems="center">
              <Icon name="FUnknown" fontSize="small" iconColor={theme.palette.Text.Secondary} />
            </Box>
          </Tooltip>
        </Box>
      )
    },
    renderCell: ({ row }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
      const isDataGridObject = isDataGridObjectType(row)
      const shouldShowProcessingStagesContent = !isDataGridObject || (isModelType && isDataGridObject)
      const renderedItems: Array<keyof typeof IconMap> = []
      if (
        row.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
        )
      )
        renderedItems.push('OInput')
      if (
        row.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
        )
      )
        renderedItems.push('ORefresh')
      if (
        row.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
        )
      )
        renderedItems.push('OOutput')

      if (shouldShowProcessingStagesContent) {
        return (
          <TableCell>
            <Box display="flex" gap={1}>
              {renderedItems.map(elem => (
                <StageIconContainer>
                  <Icon name={elem} />
                </StageIconContainer>
              ))}
            </Box>
          </TableCell>
        )
      }
      return null
    },
  },
  {
    align: 'left',
    field: 'dataSystemId',
    headerAlign: 'left',
    headerName: '',
    minWidth: 150,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
      return <DataSourcesButtonCell row={row} />
    },
  },
]

const DataSourcesButtonCell = ({ row }: { row: DataGridObjectType | DataGridAssetObjectType }) => {
  const navigate = useNavigate()
  return (
    <TableCell>
      <Button
        color="tertiary"
        onClick={() =>
          isDataGridObjectType(row)
            ? navigate(RoutesManager.systems.id.overview.root.getURL({ id: row.dataSystemId }))
            : navigate(
                RoutesManager.assetManager.assets.detail.root.getURL({
                  code: row.assetCode,
                  resourceTypeCode: row.resourceTypeCode,
                }),
              )
        }
      >
        {`View ${isDataGridObjectType(row) ? 'System' : 'Asset'}`}
      </Button>
    </TableCell>
  )
}
