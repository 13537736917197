import { useEffect, useMemo, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpdateClassificationSchedule } from 'api/assets/mutations/useUpdateClassificationSchedule'
import {
  ClassificationRefreshSchedule,
  ClassificationRefreshScheduleFrequency,
} from '@ketch-com/windlass/dist/appliance/databases'
import { frequencyMapping } from '../constants'
import { getInitialSchedulingValues, GetInitialSchedulingValuesPayload } from '../utils/getInitialSchedulingValues'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getScheduleValidationSchema } from '../utils/getScheduleValidationSchema'
import { Logger } from 'utils/Logger'

export interface UseEditClassificationScheduleArgs {
  assetSummary?: AssetSummaryDTO
  connectionId: string
  onSubmitSuccess?: () => Promise<any>
  onSubmitError?: (errors: FormikErrors<GetInitialSchedulingValuesPayload>) => void
}

export const useEditClassificationSchedule = ({
  assetSummary,
  connectionId,
  onSubmitSuccess,
  onSubmitError,
}: UseEditClassificationScheduleArgs) => {
  const [disableSchedule, setDisableSchedule] = useState(
    assetSummary?.asset?.schedule?.frequency && assetSummary?.asset?.schedule?.frequency !== 0 ? false : true,
  )

  useEffect(() => {
    if (assetSummary?.asset?.schedule?.frequency && assetSummary?.asset?.schedule?.frequency !== 0) {
      setDisableSchedule(false)
    } else {
      setDisableSchedule(true)
    }
  }, [assetSummary?.asset?.schedule?.frequency])

  const queryClient = useQueryClient()

  const initialValues = useMemo(
    () => getInitialSchedulingValues({ assetSummary: assetSummary || undefined }),
    [assetSummary],
  )

  const validationSchema = useMemo(getScheduleValidationSchema, [])

  const { mutate: handleUpdateAsset, isLoading: isUpdatingDatabase } = useUpdateClassificationSchedule({
    onError: error => {
      Logger.error('Failed to update classification schedule', error)
      showToast({ content: 'Failed to update classification schedule', type: 'error' })
      if (onSubmitError) {
        onSubmitError(errors)
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.resource,
        {
          assetCode: assetSummary?.asset?.resource?.id,
        },
      ])
      if (onSubmitSuccess) {
        await onSubmitSuccess()
      }
      showToast({ content: 'Classification schedule updated', type: 'success' })
    },
  })

  const convertTimeToISOString = (time: string | undefined, period: string | undefined): string | undefined => {
    if (!time) return

    const [hours, minutes] = time.split(':').map(Number)
    const isPM = period === '2'

    let adjustedHours = hours
    if (isPM && hours !== 12) adjustedHours += 12
    if (!isPM && hours === 12) adjustedHours = 0

    const date = new Date()
    date.setHours(adjustedHours, minutes, 0, 0)
    return date.toISOString()
  }

  const onSubmit = async (values: GetInitialSchedulingValuesPayload) => {
    const frequencyIsNumber = !isNaN(Number(values.schedule.frequency))
    if (
      disableSchedule ||
      values.schedule.frequency === '0' ||
      values.schedule.frequency === ClassificationRefreshScheduleFrequency.Disabled
    ) {
      return handleUpdateAsset({
        formData: {
          day: undefined,
          frequency: frequencyMapping['0'],
          sampleSize: undefined,
          time: undefined,
          executionDuration: undefined,
        },
        connectionId: connectionId,
        assetId: assetSummary?.asset?.resource?.id!,
      })
    }

    const formData: ClassificationRefreshSchedule = {
      day:
        values.schedule.frequency === '2' || values.schedule.frequency === ClassificationRefreshScheduleFrequency.Weekly
          ? values.schedule.day
          : undefined,
      frequency: values.schedule.frequency
        ? frequencyIsNumber
          ? frequencyMapping[values.schedule.frequency]
          : (values.schedule.frequency as ClassificationRefreshScheduleFrequency)
        : undefined,
      sampleSize: values.schedule.sampleSize ? parseInt(values.schedule.sampleSize) : 1000,
      time: convertTimeToISOString(values.schedule.time, values.schedule.period),
      executionDuration: values.schedule.executionDuration,
    }

    handleUpdateAsset({
      formData,
      connectionId: connectionId,
      assetId: assetSummary?.asset?.resource?.id!,
    })
  }

  const { values, handleChange, errors, setFieldValue, touched, setTouched, resetForm } =
    useFormik<GetInitialSchedulingValuesPayload>({
      initialValues,
      onSubmit,
      validationSchema,
    })

  return {
    errors,
    disableSchedule,
    handleChange,
    isUpdatingDatabase,
    setDisableSchedule,
    resetForm,
    setFieldValue,
    setTouched,
    touched,
    values,
    onSubmit,
  }
}

export type UseEditClassificationScheduleReturnType = ReturnType<typeof useEditClassificationSchedule>
