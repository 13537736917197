import { Box, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Button, Icon, SwitchToggle } from '@ketch-com/deck'
import { ProtocolIcon } from '../../components/ProtocolIcon'
import { theme } from '@ketch-com/deck'
import {
  PrivacyProtocol,
  PrivacyProtocolConfigurationResponseBodyStatus,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import React, { useContext } from 'react'
import { PrivacyProtocolsContext } from '../context/PrivacyProtocolsContext'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from '../../../../../../utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { SYSTEM_PROTOCOL_MAXIMUM } from 'pages/dataSystems/DataSystem/constants'

type Props = {
  /** Protocol contained within this tile */
  protocol: PrivacyProtocol
  /** Function to call when the enable button is clicked */
  onEnableClick: () => void
  /** Function to call when the enable switch is toggled  */
  onStatusToggle: () => void
  /** Function to call when the tile is clicked anywhere other than button or switch */
  onViewClick: () => void
}

/**
 * Tile that displays details for a single protocol and a button for initial
 * enablement, then a switch for all subsequent enables and disables
 */
export const PrivacyProtocolTile: React.FC<Props> = ({ protocol, onEnableClick, onStatusToggle, onViewClick }) => {
  const { isEntitled } = useIsEntitled()
  const { canWrite, isFetchingProtocols } = useContext(PrivacyProtocolsContext)
  const isEntitledToEnablePrivacyProtocols = isEntitled(ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS)
  const hasNoProtocolConfig = protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.none
  const isProtocolEnabled = protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled
  const isProtocolDisabled = protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.disabled

  return (
    <Box
      p={3}
      borderRadius="11px"
      mb={2}
      position="relative"
      onClick={onViewClick}
      sx={({ palette }) => ({
        backgroundColor: palette.white.main,
        ':hover': {
          cursor: 'pointer',
        },
      })}
    >
      <Box display="flex" gap={1.5} justifyContent="space-between" alignItems="center">
        <Box display="flex" gap="16px" alignItems="center">
          {/* Protocol Icon */}
          <Box display="flex" alignItems="center">
            <ProtocolIcon protocolId={protocol.id || ''} variant="small" />
          </Box>
          <Box maxWidth="900px">
            {/* Protocol Name */}
            <Typography variant="h4">{protocol.name}</Typography>

            {/* Protocol Description */}
            <Typography component="div" variant="body" color={theme.palette.Text.Secondary} mt={0.625}>
              {protocol.description}
            </Typography>
          </Box>
        </Box>

        {/* Create / Enable / Disable */}
        {canWrite ? (
          <Box display="flex" gap={1.25} alignItems="center">
            {!hasNoProtocolConfig ? (
              <>
                <Tooltip
                  title={
                    !isEntitledToEnablePrivacyProtocols && !isFetchingProtocols && isProtocolDisabled
                      ? SYSTEM_PROTOCOL_MAXIMUM
                      : ''
                  }
                  placement={'bottom'}
                >
                  <Box>
                    <SwitchToggle
                      disabled={!isEntitledToEnablePrivacyProtocols && !isFetchingProtocols && isProtocolDisabled}
                      checked={isProtocolEnabled}
                      onChange={e => onStatusToggle()}
                      onClick={e => e.stopPropagation()}
                      size="medium"
                    />
                  </Box>
                </Tooltip>
                {isProtocolEnabled ? (
                  <Typography variant="label" sx={{ color: theme.palette.greenHaze.main }}>
                    Enabled
                  </Typography>
                ) : null}
                {isProtocolDisabled ? (
                  <Typography variant="body" sx={{ color: theme.palette.Text.Secondary }}>
                    Disabled
                  </Typography>
                ) : null}
                <Button variant="iconSubtle" onClick={onViewClick}>
                  <Icon name="OArrowCRight" />
                </Button>
              </>
            ) : (
              <Tooltip title={!isEntitledToEnablePrivacyProtocols ? SYSTEM_PROTOCOL_MAXIMUM : ''} placement={'bottom'}>
                <Box>
                  <Button
                    color="secondary"
                    size="small"
                    disabled={!isEntitledToEnablePrivacyProtocols}
                    onClick={onEnableClick}
                  >
                    Enable
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Box display="flex" gap={1.25} alignItems="center">
            {!hasNoProtocolConfig ? (
              <>
                <Tooltip title={INSUFFICIENT_PERMISSIONS_TOOLTIP} placement={'bottom'}>
                  <span>
                    <SwitchToggle
                      disabled={true}
                      checked={isProtocolEnabled}
                      onChange={e => onStatusToggle()}
                      onClick={e => e.stopPropagation()}
                      size="medium"
                    />
                  </span>
                </Tooltip>
                {isProtocolEnabled ? (
                  <Typography variant="label" sx={{ color: theme.palette.greenHaze.main }}>
                    Enabled
                  </Typography>
                ) : null}
                {isProtocolDisabled ? (
                  <Typography variant="body" sx={{ color: theme.palette.Text.Secondary }}>
                    Disabled
                  </Typography>
                ) : null}
                <Button variant={'iconSubtle'} onClick={onViewClick}>
                  <Icon name={'OArrowCRight'} />
                </Button>
              </>
            ) : (
              <Tooltip title={INSUFFICIENT_PERMISSIONS_TOOLTIP} placement={'bottom'}>
                <span>
                  <Button color="secondary" size={'small'} disabled={true} onClick={onEnableClick}>
                    Enable
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
