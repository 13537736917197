import { Button, Chip, Icon, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { useUploadFile } from 'api/files/mutations/useUploadFile'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useField } from 'formik'
import React from 'react'
import { useDropzone } from 'react-dropzone'

type SupportedFileTypes = {
  [key: string]: string[]
}

type Props = {
  name: string
  label?: string
  belowButtonLabel?: string
  accept?: SupportedFileTypes
  maxSize?: number
  uploadContext?: {
    version?: string
    folder?: string
    bucket?: string
    acl?: string
  }
}

const UploadNewBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const UploadReplaceBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const UploadedFileBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  gap: '12px',
})

function getFileTypesString(fileTypes: SupportedFileTypes | undefined): string {
  if (!fileTypes) return ''
  const flattened = Object.values(fileTypes)
    .flat()
    .map(fileType => fileType.replace('.', '').toUpperCase())
    .join(', ')
  return flattened
}

function replaceWhitespaceWithUnderscores(input: string): string {
  return input.replace(/\s+/g, '_')
}

export const FormFileUpload: React.FC<Props> = ({
  name,
  label,
  belowButtonLabel,
  accept,
  maxSize = 1000000,
  uploadContext,
}) => {
  // Mutation hooks for uploading, deleting, and downloading
  const { mutateAsync: handleUploadFile, isLoading: isUploading } = useUploadFile()
  const { mutateAsync: handleDownloadFile, isLoading: isDownloading } = useDownloadFile()

  // Form props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<string | undefined>(name)
  const { setValue } = helpers
  const { value } = field
  const fileName = value?.split('/').at(-1)

  // Dropzone setup
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles: 1,
    maxSize,
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        // NOTE: Currently there is an issue with uploading files with names containing spaces, so we
        // replace all spaces with underscores here.
        const fileWithNoSpaces = new File([file], replaceWhitespaceWithUnderscores(file.name), { type: file.type })

        handleUploadFile({
          params: { file: fileWithNoSpaces, ...uploadContext },
          onSuccess: ({ data }) => {
            // Update field with access url
            setValue(data.public_url)
          },
          onError: () => {
            showToast({
              content: 'Error uploading file',
              type: 'error',
            })
          },
        })
      })
    },
    onDropRejected: fileRejections => {
      const collectedErrorMessages = new Set<string>()
      fileRejections.forEach(fileRejection => {
        const { errors } = fileRejection
        errors.forEach(error => {
          collectedErrorMessages.add(error.message.replace(`${maxSize.toString()} bytes`, `${maxSize / 1000000}MB`))
        })
      })
      showToast({
        content: Array.from(collectedErrorMessages).join(', '),
        type: 'error',
      })
    },
  })

  const handleDownloadClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (value) {
      const { data } = await handleDownloadFile({
        params: {
          fileUrl: value,
        },
      })
      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = fileName || 'untitled'
      a.click()
      a = null
    }
  }

  // TODO:JB - Currently with the cargo method of storing files we cannot delete then in S3.
  // Should update this to call useDeleteFile() once that has changed.
  const handleDeleteClick = () => setValue(undefined)

  if (value) {
    return (
      <Box>
        <UploadReplaceBox>
          {label && (
            <Typography variant="label" mb="6px">
              {label}
            </Typography>
          )}
          <UploadedFileBox>
            <Chip
              label={<Typography variant="body">{fileName}</Typography>}
              onDelete={handleDeleteClick}
              deleteIcon={<Icon name={'OCross'} width={24} height={24} />}
            />
            <Button
              variant={'iconCustomRounded'}
              color={'tertiary'}
              onClick={handleDownloadClick}
              pending={isDownloading}
            >
              <Icon name={'ODownload'} iconColor={theme.palette.sphere.main} width={20} height={20} />
            </Button>
          </UploadedFileBox>
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Button fullWidth={false} color="secondary" pending={isUploading} sx={{ width: '82px', mb: '4px' }}>
              Change
            </Button>
          </Box>
        </UploadReplaceBox>
      </Box>
    )
  }

  return (
    <Box>
      <UploadNewBox {...getRootProps()}>
        <input {...getInputProps()} />
        {label && (
          <Typography variant="label" mb="6px">
            {label}
          </Typography>
        )}
        <Button fullWidth={false} color="secondary" pending={isUploading} sx={{ width: '82px', mb: '4px' }}>
          Browse...
        </Button>
        {belowButtonLabel && (
          <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
            {belowButtonLabel}
          </Typography>
        )}
        <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
          Up to {maxSize / 1000000}MB, supported file types: {getFileTypesString(accept)}
        </Typography>
      </UploadNewBox>
    </Box>
  )
}
