import { createContext } from 'react'
import { TrackerDiagnosticsUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackerDiagnosticsUtils } from 'pages/policyCenter/trackers/hooks/useTrackerDiagnosticsUtils'

export const TrackerDiagnosticsContext = createContext({} as TrackerDiagnosticsUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackerDiagnosticsContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackerDiagnosticsUtils()

  return (
    <TrackerDiagnosticsContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackerDiagnosticsContext.Provider>
  )
}

export const withTrackerDiagnosticsContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackerDiagnosticsContextProvider>
        <Component {...props} />
      </TrackerDiagnosticsContextProvider>
    )
  }
}
