import { useParams } from 'react-router-dom'
import { trackerURLParams } from 'utils/routing/routes/utils'
import { useTrackerRecentScanResultsInfinite } from 'api/trackers/queries/useTrackerRecentScanResultsInfinite'

export const useTrackerDiagnosticsRecentScanUtils = () => {
  const { encodedTrackerKey } = useParams<trackerURLParams>()

  const {
    scanResults: recentScanResults,
    isLoading: isLoadingRecentScanResults,
    isFetching: isFetchingRecentScanResults,
    isFetchingNextPage: isFetchingNextRecentScanResults,
    hasNextPage: hasNextRecentScanResults,
    fetchNextPage: fetchNextRecentScanResults,
  } = useTrackerRecentScanResultsInfinite({
    limit: 100,
    encodedTrackerKey,
  })

  const isFoundRecentScanReady = !isLoadingRecentScanResults

  return {
    isFoundRecentScanReady,
    recentScanResults,
    isLoadingRecentScanResults,
    isFetchingRecentScanResults,
    isFetchingNextRecentScanResults,
    hasNextRecentScanResults,
    fetchNextRecentScanResults,
  }
}
