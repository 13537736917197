import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchUserMe } from 'api/users/fetchers/fetchUserMe'
import { useAuth } from 'utils/hooks/useAuth'

/*
  * Our Auth0 access token expires in 24 hours
  * Caching for 8 hours is a performance gain

 */
const EIGHT_HOURS_IN_MS = 28800000
const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.userMe,
  queryFn: fetchUserMe,
  select: res => res?.data?.user || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery, 'organizationCode' | 'userId'>

export const useUserMe = (config?: Config) => {
  const { userData } = useAuth()

  return useCustomQuery({
    ...config,
    enabled: !!userData?.organizationCode && !!userData?.userId,
    retry: false,
    staleTime: EIGHT_HOURS_IN_MS,
    params: {
      ...config?.params,
      organizationCode: userData.organizationCode,
      userId: userData.userId,
    },
  })
}
