import { useAuth0 } from '@auth0/auth0-react'
import { theme } from '@ketch-com/deck'
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { generateSHA256 } from 'utils/helpers/getSha256Hash'

/**
 * Provider that initializes a Sendbird context with the required information.
 */
export const KetchSendbirdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sendbirdId, setSendbirdId] = useState<string>()

  // Get the ID for the currently logged in user
  const { user } = useAuth0()
  const userId = useMemo(() => {
    return user?.sub?.includes('support@ketchsupport.com') ? 'ketchsupport' : user?.sub || ''
  }, [user])

  // Get Sendbird application ID environment variable
  const sendbirdAppId = (window as any)?.figureheadConfig?.REACT_APP_SENDBIRD_APPLICATION_ID

  const ketchColorSet = {
    // See https://sendbird.com/docs/chat/uikit/v3/react/theme-and-style-resources/color-resources
    // for other variables and theme info
    '--sendbird-light-primary-300': theme.palette.heliotrope.main,
    '--sendbird-light-primary-100': theme.palette.Highlight.Disabled,
  }

  useEffect(() => {
    const hashSendbirdId = async () => {
      const hashedUserId = await generateSHA256(userId)
      setSendbirdId(hashedUserId)
    }
    hashSendbirdId()
  }, [userId])

  if (!sendbirdId) return null

  return (
    <SendbirdProvider appId={sendbirdAppId} userId={sendbirdId} colorSet={ketchColorSet}>
      {children}
    </SendbirdProvider>
  )
}
