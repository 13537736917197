import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useRegister } from 'api/auth/mutations/useRegister'
import { useAuthRedirect } from 'utils/hooks/useAuthRedirect'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useCookies } from 'react-cookie'
import { SignUpFormFields } from 'pages/onboarding/signUp/common/types/signUp'
import { OrgPlanIDs } from 'components/planAndBilling/common/constants/planAndBilling'
import { useValidationSchema } from 'pages/onboarding/signUp/common/hooks/useValidationSchema'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { formatCodeFromNameAndSanitize } from 'utils/formatters'
import { useIpLocation } from 'api/externalApis/ketchCdn/queries/useIpLocation'

export const useSignUp = () => {
  const navigate = useNavigate()
  useAuthRedirect()

  const { queries } = useQueryParams()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [cookies] = useCookies(['hubspotutk'])
  const validationSchema = useValidationSchema()
  const initialValues: SignUpFormFields = {
    name: '',
    email: '',
    company: '',
    website: '',
    privacyLink: '',
    marketingConsent: false,
  }

  const { mutateAsync: signUpNewCustomer } = useRegister()

  const trimWebsite = (website: string) => {
    if (website.startsWith('http://')) {
      website = website.substring(7)
    } else if (website.startsWith('https://')) {
      website = website.substring(8)
    }
    return website
  }

  const onSignUpFormSubmit = async (values: SignUpFormFields) => {
    /* make sure executeRecaptcha is initiated before calling */
    if (!executeRecaptcha) {
      return
    }
    let recaptchaToken = null
    try {
      recaptchaToken = await executeRecaptcha('signUp')
    } catch (error) {
      recaptchaToken = ''
    }

    await signUpNewCustomer({
      params: {
        formData: {
          email: values.email || '',
          orgCode: formatCodeFromNameAndSanitize(values.company) || '',
          orgName: values.company || '',
          plan: queries.planId || OrgPlanIDs.free,
          fullName: values.name || '',
          website: trimWebsite(values.website) || '',
          privacyPolicy: values.privacyLink || '',
          hubspotutk: cookies['hubspotutk'] || '',
          recaptchaToken,
          marketingConsent: values.marketingConsent || false,
        },
      },
      onSuccess: () => {
        navigate(RoutesManager.onboarding.signUp.completed.getURL())
      },
      onError: ({ response }) => {
        const message = response?.data?.error?.message
        let msg =
          'Oops, something went wrong. Please refresh and try again. If the error persists, please contact customer service at support@ketch.com'
        if (message && message.includes('user already exists'))
          msg = 'There is already an account associated with this email.  Please login or click "Forgot Password".'

        showToast({ content: msg, type: 'error' })
      },
    })
  }

  // Determine if the user is in the EU
  const { data: ipLocation, isLoading: isLoadingIpLocation } = useIpLocation({})

  return {
    initialValues,
    validationSchema,
    onSignUpFormSubmit,
    ipLocation,
    isLoadingIpLocation,
  }
}
