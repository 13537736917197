import React from 'react'

import { ReactComponent as EmptyStateIcon } from 'assets/icons/workflows.svg'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { isEmpty } from 'lodash'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { RelationshipsTable } from '.'
import { useV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlag } from 'api/assets/queries/useV2OrV3RelationshipsInfiniteBasedOnFeatureFlag'

export const RelationshipsDiscoveredTab: React.FC = () => {
  const {
    infiniteRelationshipsData: relationships,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingReferences,
    isFetchingNextPage,
  } = useV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlag({
    params: {
      type: 1,
    },
  })

  return (
    <>
      {isLoadingReferences && <Spinner padding={50} size={50} />}

      {!isLoadingReferences && isEmpty(relationships) && (
        <EmptyState variant="list" icon={<EmptyStateIcon />} title="No relations have been discovered yet" />
      )}

      <RelationshipsTable
        type="discovered"
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoadingReferences={isLoadingReferences}
        relationships={relationships}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  )
}
