import { Button, Icon, MegaFilter, MegaFilterOptionType } from '@ketch-com/deck'
import { SystemListFilter } from './SystemListFilter'
import { useSystemListFilterContext } from './SystemListFilterV2Context'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useMemo } from 'react'
import { FilterCategories } from 'api/dataSystems/queries/useInstalledDataSystemsInfiniteV2List'

export const SystemListMegaFilter: React.FC = () => {
  const { filterData, isLoading, setFilterData, sections, setCounts, filterButtonText, resetFilters } =
    useSystemListFilterContext()

  const selectedFilterValues = useMemo(() => {
    const filterValues: Record<string, MegaFilterOptionType[]> = {}
    for (const key in filterData) {
      filterValues[key] = filterData[key as FilterCategories]
        .filter(option => option.isSelected)
        .map(selectedFilter => ({
          value: selectedFilter.id!,
          label: selectedFilter.name,
          category: key,
        }))
    }
    return filterValues
  }, [filterData])

  const updateFilters = (categoryName: string, optionId: string, isSelected: boolean) => {
    const updatedFilterData = {
      ...filterData,
      [categoryName]: filterData[categoryName].map(filter => {
        if (filter.id === optionId) {
          return { ...filter, isSelected }
        }
        return filter
      }),
    }

    setFilterData(updatedFilterData)
    setCounts(prev => ({
      ...prev,
      [categoryName]: isSelected ? prev[categoryName] + 1 : prev[categoryName] - 1,
    }))
  }

  // inherent risk demo entitlement
  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)

  const filteredSections = useMemo(
    () =>
      sections.filter(section => {
        if (!isEntitledToDemo && section.value === 'inherentRisk') {
          return false
        }
        return true
      }),
    [isEntitledToDemo, sections],
  )

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      value={selectedFilterValues}
      sections={filteredSections}
      onDeselectChipHandler={option => updateFilters(option.category, option.value, false)}
      buttonTitle={filterButtonText}
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OArrowCDown" />,
        pending: isLoading,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      renderActionItems={
        <Button
          sx={{ marginBlock: 1 }}
          color="tertiary"
          onClick={() => {
            resetFilters()
          }}
        >
          Clear All
        </Button>
      }
    >
      {filteredSections.map(section => {
        return (
          <SystemListFilter
            key={section.value}
            name={section.value}
            label={section.label}
            options={filterData[section.value]}
            isLoading={isLoading}
            updateFilters={updateFilters}
          />
        )
      })}
    </MegaFilter>
  )
}
