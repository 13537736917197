export const onlyNumericInput: React.KeyboardEventHandler<HTMLDivElement> = event => {
  const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete']

  if (
    !/^\d$/.test(event.key) && // Allow numeric keys
    !allowedKeys.includes(event.key) // Allow control keys
  ) {
    event.preventDefault() // Block other keys
  }
}
