import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { DecisionStepDetailsHeaderActions } from './components/DecisionStepDetailsHeaderActions'
import { DecisionStepDetailsHeaderDetails } from './components/DecisionStepDetailsHeaderDetails'
import { DecisionStepDetails } from './components/DecisionStepDetails'
import { DecisionStepPaths } from './components/DecisionStepPaths'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from '../..'
import { DecisionGatewayContext, withDecisionGatewayContext } from './context/DecisionGatewayContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

export const DecisionGatewayWithContext: React.FC = () => {
  const { breadcrumbs, title, isReady, gatewayError, resolve, workflowStep, id } = useContext(DecisionGatewayContext)
  const navigate = useNavigate()

  if (!isReady || gatewayError) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }
  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: title,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id })),
          },
          imageBlockComponent: <WorkflowStepHeaderIcon />,
          infoBlockComponent: <DecisionStepDetailsHeaderDetails workflowStep={workflowStep} />,
          actionRightBlockComponent: <DecisionStepDetailsHeaderActions workflowStep={workflowStep} resolve={resolve} />,
        }}
        widgetContentComponent={<StepDetailsColumn />}
      >
        <Box display="flex" flexDirection="column" gap={6} mb={6}>
          <DecisionStepDetails />
          <DecisionStepPaths />
        </Box>
      </ViewLayout>
    </>
  )
}

export const DecisionGateway = withDecisionGatewayContext(DecisionGatewayWithContext)
