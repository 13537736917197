import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { Cell, Pie, PieChart } from 'recharts'
import { accessAndUsageRisksMockData } from 'pages/insights/dataRisks/__mocks__/dataRisks'

const PREFIX = 'AccessAndUsageRisksHighlight'

const classes = {
  accessAndUsageRisksHighlightContainer: `${PREFIX}-accessAndUsageRisksHighlightContainer`,
  accessAndUsageRisksHighlightDonutContainer: `${PREFIX}-accessAndUsageRisksHighlightDonutContainer`,
  accessAndUsageRisksHighlightData: `${PREFIX}-accessAndUsageRisksHighlightData`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.accessAndUsageRisksHighlightContainer}`]: {
    flex: '0 1 328px',
    backgroundColor: palette.lavender.main,
    borderRadius: spacing(1.5),
    padding: spacing(4),
  },

  [`& .${classes.accessAndUsageRisksHighlightDonutContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(2),
  },

  [`& .${classes.accessAndUsageRisksHighlightData}`]: {
    margin: spacing(6, 0),
  },
}))

//TODO: Real Data
export const AccessAndUsageRisksHighlight: React.FC = () => {
  const percentage = accessAndUsageRisksMockData?.percentage || 0 // TODO: replace with real data
  const data = [
    { name: 'Total', percent: percentage, value: percentage, color: '#7B3DE9' },
    { name: 'Other', percent: 100 - percentage, value: 100 - percentage, color: 'rgba(156, 100, 255, 0.25)' },
  ]
  return (
    <StyledBox className={classes.accessAndUsageRisksHighlightContainer}>
      <Text size={16} color="heliotrope" weight={700}>
        Highlights
      </Text>
      <Box className={classes.accessAndUsageRisksHighlightDonutContainer}>
        <PieChart
          width={116}
          height={116}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Pie
            data={data}
            cx={58}
            cy={58}
            innerRadius={35}
            outerRadius={55}
            fill="#8884d8"
            stroke="#f4d4ff"
            paddingAngle={0}
            dataKey="percent"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </Box>
      <Box className={classes.accessAndUsageRisksHighlightData}>
        <Text sx={{ fontSize: 48, color: 'pink.main', display: 'block', fontWeight: 500, lineHeight: 'normal' }}>
          {percentage}%
        </Text>

        <Text sx={{ fontSize: 16, color: 'pink.main', display: 'block', fontWeight: 500, lineHeight: 'normal' }}>
          of your data is unused.
        </Text>
      </Box>

      <Text sx={{ fontSize: 14, color: 'pink.main', display: 'block', fontWeight: 400, lineHeight: 'normal' }}>
        Some of your data assets may contain personal or sensitive information but they are barely used.
      </Text>
    </StyledBox>
  )
}
