import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Icon, IconDictionary, theme, DataGrid, TableCell } from '@ketch-com/deck'
import { TrackerProperty } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import moment from 'moment'
import pluralize from 'pluralize'
import { ApprovedTrackerPropertiesContext } from 'pages/policyCenter/trackers/context'
import { TrackerPropertyDisclosure } from 'pages/policyCenter/trackers/components'
import { TrackerKeys } from 'pages/policyCenter/trackers/interfaces'
import { MaybeNull } from 'interfaces'
import { TrackerPropertyBlocked } from './TrackerPropertyBlocked'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag, useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'

type Props = {
  trackerKeys: MaybeNull<TrackerKeys>
  properties: TrackerProperty[]
}

export const ApprovedTrackerProperties: React.FC<Props> = ({ trackerKeys, properties }: Props) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )
  const {
    isFetchingTrackerProperties,
    isFetchingNextTrackerProperties,
    hasNextTrackerProperties,
    fetchNextTrackerProperties,
  } = useContext(ApprovedTrackerPropertiesContext)

  return (
    <Box>
      {properties?.map((trackerProperty, trackerPropertyIndex) => {
        return (
          <Box
            key={trackerProperty?.id}
            sx={{
              borderBottom: ({ palette }) =>
                properties?.length - 1 !== trackerPropertyIndex ? `1px solid ${palette.iron.main}` : 'none',
              paddingBottom: ({ spacing }) => (properties?.length - 1 !== trackerPropertyIndex ? spacing(1.5) : 0),
              marginBottom: ({ spacing }) => (properties?.length - 1 !== trackerPropertyIndex ? spacing(1.5) : 0),
            }}
          >
            <Box display="flex" gap={1.5} alignItems="center" mb={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                <Icon name={IconDictionary.OGlobe} iconColor={theme.palette.Text.Secondary} width={36} height={36} />
              </Box>

              <Box display="flex" flexDirection="column" gap={0.25}>
                <Typography variant="label">{trackerProperty?.name}</Typography>
                <Typography variant="smallBody" color="darkDuskFaded.main">
                  {trackerProperty?.type}
                </Typography>
              </Box>
            </Box>

            <DataGrid
              rowHeight={60}
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
              }}
              rows={[trackerProperty]}
              columns={[
                {
                  width: isTrackerBlockingRelease ? 140 : 160,
                  field: 'provenance',
                  headerName: 'Provenance',
                  renderCell: (params: GridRenderCellParams<typeof trackerProperty>) => {
                    return (
                      <TableCell>
                        <ProvenanceVariant provenance={params?.row?.provenance} type={ProvenanceType.Chip} />
                      </TableCell>
                    )
                  },
                  sortable: false,
                },
                {
                  width: isTrackerBlockingRelease ? 200 : 220,
                  field: 'firstSeen',
                  headerName: 'First Seen',
                  renderCell: (params: GridRenderCellParams<typeof trackerProperty>) => {
                    return (
                      <TableCell>
                        <Typography variant="body">
                          {moment(params?.row?.firstSeen).format('MMM D YYYY, h:mm A')}
                        </Typography>
                      </TableCell>
                    )
                  },
                  sortable: false,
                },
                {
                  width: isTrackerBlockingRelease ? 200 : 220,
                  field: 'lastSeen',
                  headerName: 'Last Seen',
                  renderCell: (params: GridRenderCellParams<typeof trackerProperty>) => {
                    const days = moment(Date.now()).diff(params?.row?.lastSeen, 'days')
                    return (
                      <TableCell>
                        <Box display="flex" flexDirection="column" gap={0.5}>
                          <Typography variant="label" color="warning.main">
                            {moment(params?.row?.lastSeen).format('MMM D YYYY, h:mm A')}
                          </Typography>
                          <Typography variant="body" color="warning.main">
                            {days} {pluralize('day', days)}
                          </Typography>
                        </Box>
                      </TableCell>
                    )
                  },
                  sortable: false,
                },
                {
                  field: 'isDisclosed',
                  headerName: isTrackerBlockingRelease ? 'Disclosure & Blocking' : 'Disclosed',
                  width: isTrackerBlockingRelease ? 150 : 170,
                  renderCell: (params: GridRenderCellParams<typeof trackerProperty>) => {
                    return (
                      <TableCell>
                        <TrackerPropertyDisclosure trackerKeys={trackerKeys} trackerProperty={params?.row} />
                        {isTrackerBlockingRelease && (
                          <TrackerPropertyBlocked trackerKeys={trackerKeys} trackerProperty={params?.row} />
                        )}
                      </TableCell>
                    )
                  },
                  sortable: false,
                },
              ]}
              checkboxSelection={false}
              hideFooter={false}
              hideFooterPagination={false}
              hideFooterRowCount
              disableBorder
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              disableChildrenSorting
              disableRowSelectionOnClick
              loading={false}
              disableRowHoverStates
              columnMenuProps={
                {
                  slots: {
                    columnMenuFilterItem: null,
                    columnMenuAggregationItem: null,
                    columnMenuGroupingItem: null,
                  },
                } as any
              }
              onRowsScrollEnd={() => {
                if (hasNextTrackerProperties && !isFetchingTrackerProperties && !isFetchingNextTrackerProperties)
                  fetchNextTrackerProperties()
              }}
              paginationLoading={isFetchingNextTrackerProperties}
              isPaginationLoading={isFetchingNextTrackerProperties}
            />
          </Box>
        )
      })}
    </Box>
  )
}
