export enum Release {
  PolicyUI = 'policy-ui',
  DataSystems = 'data-systems',
  RightsQueueManualIntervention = 'rights-queue-manual-intervention',
  NewNavigation = 'Navigation 2.0',
  TeamNotifications = 'team-notifications',
  PropertyThemeDocuments = 'property-theme-documents',
  FormsWithinExperienceServer = 'forms-within-experience-server',
  PropertyTagsTrackerBlocking = 'property-tags-and-tracker-blocking',
  SendbirdChat = 'sendbird-chat-export',
  TrackerDiagnostics = 'tracker-diagnostics',
  RightsQueueAssigneeStatusFilters = 'rights-queue-assignee-status-filters',
}

export const RELEASES: Release[] = [
  Release.PolicyUI,
  Release.DataSystems,
  Release.RightsQueueManualIntervention,
  Release.NewNavigation,
  Release.TeamNotifications,
  Release.PropertyThemeDocuments,
  Release.FormsWithinExperienceServer,
  Release.PropertyTagsTrackerBlocking,
  Release.SendbirdChat,
  Release.TrackerDiagnostics,
  Release.RightsQueueAssigneeStatusFilters,
]
