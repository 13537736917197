import Box from '@mui/material/Box'
import { Banner, Button, Icon, Widget, theme } from '@ketch-com/deck'
import { ProcessingActivityContext } from '../../context/ProcessingActivityContext'
import React, { ChangeEvent, useContext, useRef, useState } from 'react'
import { ProcessingActivityAssessmentRequirementDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { AssessmentModal, AssessmentModalState } from '../AssessmentModal'
import { showToast } from 'components/modals/AlertComponent'
import { AssessmentDetails } from '../AssessmentDetails'
import { formatBytes } from 'utils/formatters'
import { acceptableAssessmentFileTypes, MAX_FILE_SIZE } from '../../../constants'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { AssessmentWidgetFileActionSheet } from './components/AssessmentWidgetFileActionSheet'
import { AssessmentWidgetActionSheet } from './components/AssessmentWidgetActionSheet'

export const AssessmentsWidget: React.FC = () => {
  const {
    processingActivity,
    uploadAssessmentFile,
    downloadAssessmentFile,
    handleAssessmentRequirementUpdate,
    removeAssessment,
    setShowEditApprovedDialog,
    isAssessmentUpdating,
    isProcessingActivityFetching,
    isProcessingActivityApproved,
  } = useContext(ProcessingActivityContext)

  const hasAssessment = (processingActivity.assessments && processingActivity?.assessments?.length > 0) || false
  const hasAssessmentFile =
    processingActivity.assessmentFile && Object.keys(processingActivity.assessmentFile).length !== 0

  const [assessmentModalState, setAssessmentModalState] = useState(AssessmentModalState.Closed)
  const uploadAssessment = useRef<HTMLInputElement | null>(null)

  const { isPermitted } = useIsPermitted()
  const canWrite = isPermitted(PERMISSIONS.ASSESSMENT_WRITE)

  const isWidgetLoading = isAssessmentUpdating || isProcessingActivityFetching

  const updateAssessmentRequirement = async (
    activityId: string,
    newRequirement: ProcessingActivityAssessmentRequirementDTO,
  ) => {
    await handleAssessmentRequirementUpdate({
      params: {
        activityId,
        formData: {
          assessmentRequirement: newRequirement,
        },
      },
    })
  }

  const handleFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files: assets } = event.target
    if (!assets?.length) {
      return
    }
    const newFiles = Array.from(assets).filter(file => {
      const { type, size } = file
      const isAcceptableType = Object.keys(acceptableAssessmentFileTypes).includes(type)
      const isAcceptableSize = size <= MAX_FILE_SIZE
      if (!isAcceptableType) {
        showToast({ content: 'File type is not supported', type: 'error' })
      }
      if (!isAcceptableSize) {
        showToast({ content: `File size is too big. Max size is ${formatBytes(MAX_FILE_SIZE)}`, type: 'error' })
      }
      return isAcceptableType && isAcceptableSize
    })

    // Upload the files
    await uploadAssessmentFile(processingActivity.id || '', newFiles[0])
  }

  const handleRemoveAssessmentClick = async () => {
    await removeAssessment(processingActivity.id || '')
  }

  const handleRemoveAssessmentFileClick = async () => {
    await removeAssessment(processingActivity.id || '')
    // Remove the old file from the input element
    if (uploadAssessment.current?.value) {
      uploadAssessment.current!.value = ''
    }
  }

  const NotRequiredBox = (
    <Banner
      title="Assessment Not Required"
      variant="standard"
      isInfoBox
      severity="info"
      actionTitle={canWrite ? 'Change to' : undefined}
      action={
        canWrite ? (
          <>
            <Button
              color="secondary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  updateAssessmentRequirement(
                    processingActivity.id || '',
                    ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement,
                  )
                }
              }}
            >
              Required
            </Button>
          </>
        ) : undefined
      }
    >
      This processing activity doesn’t require an Impact Assessment or any other type of assessment.
    </Banner>
  )

  const RecommendedBox = (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        <Banner
          title="Impact Assessment Recommended"
          variant="outlined"
          isInfoBox
          severity="info"
          actionTitle={canWrite ? 'Change to' : undefined}
          action={
            canWrite ? (
              <>
                <Button
                  color="secondary"
                  pending={isWidgetLoading}
                  onClick={() => {
                    if (isProcessingActivityApproved) {
                      setShowEditApprovedDialog(true)
                    } else {
                      updateAssessmentRequirement(
                        processingActivity.id || '',
                        ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement,
                      )
                    }
                  }}
                >
                  Required
                </Button>
                <Button
                  color="secondary"
                  pending={isWidgetLoading}
                  onClick={() => {
                    if (isProcessingActivityApproved) {
                      setShowEditApprovedDialog(true)
                    } else {
                      updateAssessmentRequirement(
                        processingActivity.id || '',
                        ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement,
                      )
                    }
                  }}
                >
                  Not Required
                </Button>
              </>
            ) : undefined
          }
        >
          <Typography>
            An Impact Assessment or another type of assessment is recommended for this processing activity.
          </Typography>
        </Banner>

        {canWrite && (
          <Box display="flex" flexDirection="column" gap={0.75} pl={1.25}>
            <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
              Select Assessment
            </Typography>
            <Box display="flex" gap={1}>
              <Button
                color="primary"
                pending={isWidgetLoading}
                onClick={() => {
                  if (isProcessingActivityApproved) {
                    setShowEditApprovedDialog(true)
                  } else {
                    setAssessmentModalState(AssessmentModalState.Select)
                  }
                }}
              >
                Select
              </Button>
              <Button
                color="tertiary"
                pending={isWidgetLoading}
                onClick={() => {
                  if (isProcessingActivityApproved) {
                    setShowEditApprovedDialog(true)
                  } else {
                    uploadAssessment.current?.click()
                  }
                }}
              >
                Upload File
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )

  const MaybeRequiredBox = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Banner
        title="Impact Assessment May Be Required"
        variant="outlined"
        isInfoBox
        severity="info"
        actionTitle={canWrite ? 'Change to' : undefined}
        action={
          canWrite ? (
            <>
              <Button
                color="secondary"
                pending={isWidgetLoading}
                onClick={() => {
                  if (isProcessingActivityApproved) {
                    setShowEditApprovedDialog(true)
                  } else {
                    updateAssessmentRequirement(
                      processingActivity.id || '',
                      ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement,
                    )
                  }
                }}
              >
                Required
              </Button>
              <Button
                color="secondary"
                pending={isWidgetLoading}
                onClick={() => {
                  if (isProcessingActivityApproved) {
                    setShowEditApprovedDialog(true)
                  } else {
                    updateAssessmentRequirement(
                      processingActivity.id || '',
                      ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement,
                    )
                  }
                }}
              >
                Not Required
              </Button>
            </>
          ) : undefined
        }
      >
        <Typography>
          This processing activity might necessitate an Impact Assessment or another type of assessment.
        </Typography>
      </Banner>

      {canWrite && (
        <Box display="flex" flexDirection="column" gap={0.75} pl={1.25}>
          <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
            Select Assessment
          </Typography>
          <Box display="flex" gap={1}>
            <Button
              color="primary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  setAssessmentModalState(AssessmentModalState.Select)
                }
              }}
            >
              Select
            </Button>
            <Button
              color="tertiary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  uploadAssessment.current?.click()
                }
              }}
            >
              Upload File
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )

  const NoneBox = (
    <Banner title="None" variant="standard" isInfoBox severity="info">
      No Impact Assessment or any other form of assessment was necessary for the approval of this processing activity.
    </Banner>
  )

  const RequiredBox = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Banner
        title="Impact Assessment Required"
        variant="outlined"
        isInfoBox
        severity="warning"
        actionTitle={canWrite ? 'Change to' : undefined}
        action={
          canWrite ? (
            <Button
              color="secondary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  updateAssessmentRequirement(
                    processingActivity.id || '',
                    ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement,
                  )
                }
              }}
            >
              Not Required
            </Button>
          ) : undefined
        }
      >
        <Typography>This processing activity requires an Impact Assessment or another type of assessment.</Typography>
      </Banner>

      {canWrite && (
        <Box display="flex" flexDirection="column" gap={0.75} pl={1.25}>
          <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
            Select Assessment
          </Typography>
          <Box display="flex" gap={1}>
            <Button
              color="primary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  setAssessmentModalState(AssessmentModalState.Select)
                }
              }}
            >
              Select
            </Button>
            <Button
              color="tertiary"
              pending={isWidgetLoading}
              onClick={() => {
                if (isProcessingActivityApproved) {
                  setShowEditApprovedDialog(true)
                } else {
                  uploadAssessment.current?.click()
                }
              }}
            >
              Upload File
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )

  const FileBox = (
    <Box display="flex" flexDirection="column" gap={0.25} width="100%">
      <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
        Uploaded File
      </Typography>
      <Box display="flex" alignItems="center" width="100%" gap={0.75}>
        <Icon name="OClip" iconColor={theme.palette.darkDusk.main} />
        <Typography variant="label">
          {processingActivity?.assessmentFile?.name || <Typography variant="smallLabel">'No File Name'</Typography>}
        </Typography>
        <Box ml="auto">
          <AssessmentWidgetFileActionSheet
            canWrite={canWrite}
            isProcessingActivityApproved={isProcessingActivityApproved}
            downloadAssessmentFile={downloadAssessmentFile}
            setShowEditApprovedDialog={() => setShowEditApprovedDialog(true)}
            handleRemoveAssessmentFileClick={handleRemoveAssessmentFileClick}
          />
        </Box>
      </Box>

      {processingActivity?.assessmentUpdatedAt && (
        <Typography mt={2} variant="footnote">
          Last updated {formatDateTimeFromUnix(processingActivity?.assessmentUpdatedAt)}
        </Typography>
      )}
    </Box>
  )

  return (
    <>
      <Widget
        expandable={false}
        title="Assessment"
        actionButton={
          hasAssessment && canWrite ? (
            <AssessmentWidgetActionSheet
              handleRemoveAssessmentClick={handleRemoveAssessmentClick}
              handleSetAssessmentModalState={() => setAssessmentModalState(AssessmentModalState.Select)}
              handleSetShowEditApprovedDialog={() => setShowEditApprovedDialog(true)}
              handleUploadFile={() => uploadAssessment.current?.click()}
              isProcessingActivityApproved={isProcessingActivityApproved}
              pending={isAssessmentUpdating}
            />
          ) : (
            <></>
          )
        }
        content={
          <Box display="flex" alignItems="center" width="100%">
            {hasAssessmentFile ? (
              FileBox
            ) : hasAssessment ? (
              <AssessmentDetails assessment={processingActivity.assessments![0]} />
            ) : (
              <>
                {/* Not required */}
                {processingActivity.assessmentRequirement ===
                  ProcessingActivityAssessmentRequirementDTO.NotRequiredProcessingActivityAssessmentRequirement &&
                  NotRequiredBox}

                {/* Recommended */}
                {processingActivity.assessmentRequirement ===
                  ProcessingActivityAssessmentRequirementDTO.RecommendedProcessingActivityAssessmentRequirement &&
                  RecommendedBox}

                {/* Maybe Required */}
                {processingActivity.assessmentRequirement ===
                  ProcessingActivityAssessmentRequirementDTO.MaybeRequiredProcessingActivityAssessmentRequirement &&
                  MaybeRequiredBox}

                {/* Required */}
                {processingActivity.assessmentRequirement ===
                  ProcessingActivityAssessmentRequirementDTO.RequiredProcessingActivityAssessmentRequirement &&
                  RequiredBox}

                {/* None */}
                {processingActivity.assessmentRequirement ===
                  ProcessingActivityAssessmentRequirementDTO.UnspecifiedProcessingActivityAssessmentRequirement &&
                  NoneBox}
              </>
            )}
          </Box>
        }
      />

      {assessmentModalState !== AssessmentModalState.Closed && (
        <AssessmentModal
          modalState={assessmentModalState}
          leftButtonVariant={assessmentModalState === AssessmentModalState.Select ? 'tertiary' : 'secondary'}
          rightButtonVariant={assessmentModalState === AssessmentModalState.Select ? 'secondary' : 'primary'}
          leftButtonLabel={assessmentModalState === AssessmentModalState.Select ? 'Create New Assessment' : 'Close'}
          rightButtonLabel={assessmentModalState === AssessmentModalState.Select ? 'Cancel' : 'Create'}
          onLeftButtonClick={
            assessmentModalState === AssessmentModalState.Select
              ? () => setAssessmentModalState(AssessmentModalState.Create)
              : () => setAssessmentModalState(AssessmentModalState.Closed)
          }
          onRightButtonClick={
            assessmentModalState === AssessmentModalState.Select
              ? () => setAssessmentModalState(AssessmentModalState.Closed)
              : () => {} // Uses submit form within the modal
          }
          onCloseButtonClick={() => setAssessmentModalState(AssessmentModalState.Closed)}
        />
      )}

      <input type="file" ref={uploadAssessment} onChange={handleFilesChange} hidden />
    </>
  )
}
