import React from 'react'
import { LinearProgress, CircularProgress } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'

export const ProgressExample: React.FC = () => {
  return (
    <>
      <Typography variant="h3" mb={2}>
        DECK Linear Progress component
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <LinearProgress value={50} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
        <LinearProgress value={80} label="Current value" />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
        <LinearProgress value={100} />
      </Box>
      <Typography variant="h3" my={2}>
        DECK Circular Progress component
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CircularProgress />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CircularProgress label="With Label" />
      </Box>
    </>
  )
}
