import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { Editor } from '@ketch-com/deck'

import 'react-quill/dist/quill.snow.css'

export const Description: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormTemplateDTO>()
  const { activeSectionIndex, languages } = useContext(FormTemplateUpsertContext)

  if (typeof activeSectionIndex !== 'number') return null

  const languageCodes = Object.keys(values?.sections?.[activeSectionIndex]?.translations || {})

  return (
    <Box display="flex" flexDirection="column">
      <Editor
        id={`section_description`}
        value={values?.sections?.[activeSectionIndex]?.description || ''}
        onChange={(value, deltaStatic, source) => {
          if (source === 'user') {
            setFieldValue(`sections.[${activeSectionIndex}].description`, value)
          }
        }}
      />

      <Typography variant="h4" mt={6} mb={3} component={Box}>
        Translations
      </Typography>

      {languageCodes.map((languageCode, index) => {
        const language = languages?.find(lang => lang.code === languageCode)
        const label = `Description (${language?.englishName})`
        const isLastElement =
          index === Object.keys(values?.sections?.[activeSectionIndex]?.translations || {}).length - 1
        return (
          <Box
            display="flex"
            flexDirection="column"
            key={`sections.[${activeSectionIndex}].translations.${languageCode}.description`}
            mb={isLastElement ? 0 : 3}
          >
            <Typography variant="label" mb={0.5}>
              {label}
            </Typography>
            <Editor
              id={`sections_translations_${languageCode}_description`}
              value={values?.sections?.[activeSectionIndex]?.translations?.[languageCode]?.description || ''}
              onChange={(value, deltaStatic, source) => {
                if (source === 'user') {
                  setFieldValue(`sections.[${activeSectionIndex}].translations.${languageCode}.description`, value)
                }
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}
