import React from 'react'
import { Grid, Typography } from '@mui/material'
import { withTrackerDiagnosticsContext } from 'pages/policyCenter/trackers/context'
import { FoundOn } from 'pages/policyCenter/trackers/details/components/diagnostics/components/FoundOn'
import { Lineages } from 'pages/policyCenter/trackers/details/components/diagnostics/components/Lineages'
import { TrackerType } from 'pages/policyCenter/trackers/details/components/diagnostics/components/TrackerType'
import { TrackerManagedBy } from 'pages/policyCenter/trackers/details/components/diagnostics/components/TrackerManagedBy'
import { theme } from '@ketch-com/deck'

export const DiagnosticsWithContext: React.FC = () => {
  return (
    <Grid>
      <Grid sx={{ borderBottom: `1px solid ${theme.palette.Black.o24}` }} pb={4} mb={2} flexDirection="column" gap={2}>
        <Grid container gap={0.5} flexDirection="column" mb={2}>
          <Typography variant="h3">Diagnostics</Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            Comprehensive analysis of a specific tracker, that uncovers its behavior, origins, connections to other
            trackers, and impact on data flow and user privacy.
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TrackerType />
          </Grid>
          <Grid item xs={6}>
            <TrackerManagedBy />
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ borderBottom: `1px solid ${theme.palette.Black.o24}` }} pb={4} mb={2}>
        <FoundOn />
      </Grid>
      <Grid>
        <Lineages />
      </Grid>
    </Grid>
  )
}

export const Diagnostics = withTrackerDiagnosticsContext(DiagnosticsWithContext)
