import React, { useContext } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from '../context'
import { Icon } from '@ketch-com/deck'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { getWorkflowStepIconNameFromActivityCode } from 'pages/orchestration/utils'

export const WorkflowStepHeaderIcon: React.FC = () => {
  const { workflowStep } = useContext(RightInvocationWorkflowStepDetailViewContext)
  return (
    <Icon
      name={getWorkflowStepIconNameFromActivityCode(workflowStep?.activityCode as WorkflowActivityCode)}
      height={64}
      width={64}
    />
  )
}
