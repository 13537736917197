import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem } from '@ketch-com/deck'

import { WorkflowDTO } from 'interfaces/workflows'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteWorkflowModal } from 'components/modals/workflows/DeleteWorkflowModal'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'

type Props = {
  isEntitledToCreateWorkflow: boolean
  workflow: WorkflowDTO
}

export const WorkflowsListDropdown: React.FC<Props> = ({ isEntitledToCreateWorkflow, workflow }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const { code } = workflow
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const isInSensitiveStatus = !!workflow?.isActive

  const handleEditClick = () => {
    if (isInSensitiveStatus) {
      setIsEditOpen(true)
      handleClose()
    } else {
      navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: workflow?.code }))
    }
  }

  const reFetchWorkflow = async () => {
    await queryClient.refetchQueries(ApiQueryKeys.workflowsPaginated)
    setIsDeleteOpen(false)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isPermitted(PERMISSIONS.WORKFLOW_WRITE)) return null

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
        {isPermitted(PERMISSIONS.WORKFLOW_WRITE) && <ActionSheetItem onClick={handleEditClick}>Edit</ActionSheetItem>}
        {isEntitledToCreateWorkflow && isPermitted(PERMISSIONS.WORKFLOW_WRITE) && <ActionSheetItem divider />}
        {isEntitledToCreateWorkflow && isPermitted(PERMISSIONS.WORKFLOW_WRITE) && (
          <ActionSheetItem
            onClick={() =>
              navigate(`${RoutesManager.orchestration.workflows.upsert.root.getURL({ code })}?duplicate=true`)
            }
          >
            Duplicate
          </ActionSheetItem>
        )}
        {!isInSensitiveStatus && isPermitted(PERMISSIONS.WORKFLOW_WRITE) && <ActionSheetItem divider />}
        {!isInSensitiveStatus && isPermitted(PERMISSIONS.WORKFLOW_WRITE) && (
          <ActionSheetItem
            onClick={() => {
              setIsDeleteOpen(true)
              handleClose()
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isEditOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.WORKFLOW}
          onSubmit={() => {
            navigate(RoutesManager.orchestration.workflows.upsert.root.getURL({ code: workflow?.code }))
          }}
          onCancel={() => setIsEditOpen(false)}
        />
      )}

      {isDeleteOpen && (
        <DeleteWorkflowModal workflowCode={code} onSubmit={reFetchWorkflow} onCancel={() => setIsDeleteOpen(false)} />
      )}
    </>
  )
}
