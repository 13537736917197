import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { RightType } from 'interfaces/events'
import { GetDSRConfigCountResponseBodyDTO, ScenarioDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { theme } from '@ketch-com/deck'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'
import Typography from '@mui/material/Typography'
import { Rights } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/Rights'

type Props = {
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  setRightType: Dispatch<SetStateAction<RightType>>
  assetSummary: AssetSummaryDTO
  rightType: RightType
}

const segmentsTabOptions = [
  {
    id: RightType.RIGHT_TYPE_UNSPECIFIED,
    name: 'All',
  },
  {
    id: RightType.RIGHT_TYPE_CANONICAL,
    name: 'Canonical',
  },
  {
    id: RightType.RIGHT_TYPE_CUSTOM,
    name: 'Custom',
  },
]

export const DatabaseScenario: React.FC<Props> = ({ dsrConfigCount, setRightType, assetSummary, rightType }) => {
  // TODO this is dataSystem.code
  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const databaseType = getAssetType(providerCode, 1)
  const datasetType = getAssetType(providerCode, 2)
  const { totalAssets = 0, personalAssets = 0, dsrScenario = {} } = dsrConfigCount
  const canonicalEvents: ScenarioDTO[] = Object.values(dsrScenario).filter(
    i => i.rightType === RightType.RIGHT_TYPE_CANONICAL,
  )
  const customEvents: ScenarioDTO[] = Object.values(dsrScenario).filter(
    i => i.rightType === RightType.RIGHT_TYPE_CUSTOM,
  )

  return (
    <>
      <Box mb={4} display="flex" gap={4}>
        <Box>
          <Typography color="grey">
            Total {pluralize(datasetType, totalAssets)} in {pluralize(databaseType, totalAssets)}
          </Typography>
          <Typography variant={'h3'}>{totalAssets}</Typography>
        </Box>
        <Box>
          <Typography color="grey">{pluralize(datasetType, personalAssets)} With Personal Data</Typography>
          <Typography variant={'h3'}>{personalAssets}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        gap={4}
        mb={4}
        pb={2}
        borderBottom={`1px solid ${theme.palette.iron.main}`}
      >
        <SegmentTabs
          tabs={segmentsTabOptions}
          value={rightType}
          onChange={rightType => {
            setRightType(rightType)
          }}
        />
      </Box>
      {canonicalEvents.length ? (
        <Box>
          <Typography variant="h3" color="grey">
            Canonical Events
          </Typography>
          <Rights
            eventBasedScenario={canonicalEvents}
            dsrConfigCount={dsrConfigCount}
            isCanonical={true}
            assetSummary={assetSummary}
          />
        </Box>
      ) : null}
      {customEvents.length ? (
        <Box>
          <Typography variant="h3" color="grey">
            Custom Events
          </Typography>
          <Rights eventBasedScenario={customEvents} dsrConfigCount={dsrConfigCount} assetSummary={assetSummary} />
        </Box>
      ) : null}
    </>
  )
}
