import React, { useState } from 'react'
import Popover from '@mui/material/Popover'
import { MaybeNull } from 'interfaces/common'
import { useAuth } from 'utils/hooks/useAuth'
import { useUserMe } from 'api/users/queries/useUserMe'
import { AvatarSize, Button, Icon, theme } from '@ketch-com/deck'
import Box from '@mui/material/Box'
import { HeaderOrganizationsList } from './components/HeaderOrganizationsList'
import { Tooltip, Typography, styled } from '@mui/material'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { API } from 'utils/api/api'

const PopoverBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  width: 250,
})

const LogoutBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  borderTop: `1px solid ${theme.palette.Common.Divider}`,
}))

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  justifyContent: 'center',
  height: '32px',
  lineHeight: '14.3px',
  width: 'fit-content',
})

export const HeaderUserInfo: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<MaybeNull<HTMLButtonElement>>(null)
  const { userData, logout } = useAuth()
  const { data: user, isLoading: isUserLoading } = useUserMe()

  if (isUserLoading) {
    return null
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'user-profile-popover' : undefined

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title={<Typography variant="label">Organizations</Typography>}>
        <span>
          <Button
            id="user-organization-button"
            variant={'contained'}
            size={'medium'}
            color={'tertiary'}
            startIcon={<UserAvatar user={user || undefined} size={AvatarSize.medium} sx={{ marginRight: '8px' }} />}
            endIcon={
              <Icon
                name={isOpen ? 'OArrowCUp' : 'OArrowCDown'}
                iconColor={theme.palette.Black.o48}
                sx={{ marginLeft: '4px' }}
              />
            }
            aria-describedby={id}
            onClick={handleOpen}
            sx={{
              height: '36px',
              textAlign: 'left',
              paddingLeft: '8px',
              background: isOpen ? '' : 'transparent',
            }}
          >
            <TextBox>
              <Typography variant={'label'} color={theme.palette.Black.o100}>
                {userData?.organizationName}
              </Typography>
              <Box height={'14.3px'} lineHeight={'14.3px'}>
                <Typography variant={'footnote'} sx={{ lineHeight: '14.3px' }}>
                  {user?.firstName} {userData?.lastName}
                </Typography>
              </Box>
            </TextBox>
          </Button>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px !important',
          },
        }}
      >
        <PopoverBox>
          <HeaderOrganizationsList
            onChange={() => {
              handleClose()
            }}
          />
          <LogoutBox>
            <Button
              color="secondary"
              onClick={async () => {
                // one time api call upon click on logout
                // via react-query is causing an issue with system delete
                await API.get('/api/logout')
                logout()
              }}
            >
              Log Out
            </Button>
          </LogoutBox>
        </PopoverBox>
      </Popover>
    </>
  )
}
