import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { Button, Icon, ListLayout, Spinner } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { RightsQueueMegaFilterButton } from './components'
import { RightsQueueListTable } from './components/RightsQueueListTable'
import { useRightsQueueContainerV2Utils } from './hooks'
import { RightsQueueSearchInput } from 'pages/orchestration/rightsQueue/listV2/components'
import { CSVLink } from 'react-csv'
import moment from 'moment/moment'
import { useAuth } from '../../../../utils/hooks'
import { showToast } from '../../../../components/modals/AlertComponent'
import { useFetchRightInvocationExport } from '../../../../api/rightInvocationsV2/mutations/useFetchRightInvocationExport'
import { ConfirmDownloadPIDataModal } from '../../../../components/modals/rightsQueue/ConfirmDownloadPIDataModal'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPaginationState } from 'store/paginationPreservationSlice/selectors'
import { RIGHTS_QUEUE_PAGINATION_REDUX_KEY, setPage } from 'store/paginationPreservationSlice'

export const RightsQueueV2: React.FC = () => {
  const navigate = useNavigate()
  const { isPermittedToCreateRights, isReady, rightsQueueListTableUtils } = useRightsQueueContainerV2Utils()
  const dispatch = useAppDispatch()
  const { pagination: reduxPaginationState } = useAppSelector(getPaginationState)
  const { paginationHelpers } = rightsQueueListTableUtils
  const { onPageChange } = paginationHelpers
  useEffect(() => {
    if (typeof reduxPaginationState[RIGHTS_QUEUE_PAGINATION_REDUX_KEY] !== 'undefined') {
      // since paginationHelpers.page is independent from the redux store, and is only updated when changing datagrid page
      // we need to update it in src/api/common/paginatedQuery.ts
      // when returning to the rights queue list and the redux store page value is not undefined
      onPageChange(reduxPaginationState[RIGHTS_QUEUE_PAGINATION_REDUX_KEY])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxPaginationState])

  const { userData } = useAuth()
  const [exportCSVData, setExportCSVData] = useState('')
  const csvLink = useRef<any | null>(null)
  const [isVerifyDownloadPIDataModalOpen, setIsVerifyDownloadPIDataModalOpen] = useState(false)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Rights', link: RoutesManager.orchestration.rightsQueue.root.getURL() },
  ]

  const handlePaginationReset = () => {
    dispatch(
      setPage({
        key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
        value: 0,
      }),
    )
  }

  const { mutateAsync: fetchRightInvocationExport, isLoading: isFetchRightInvocationExportLoading } =
    useFetchRightInvocationExport({
      onSuccess: r => {
        setExportCSVData(r.data?.rightInvocationExportData || '')
        showToast({
          content: 'Rights queue export downloaded successfully',
          type: 'success',
        })
      },
      onError: () => {
        showToast({
          content: 'Failed to download rights queue export',
          type: 'error',
        })
      },
    })

  const handleDownloadClick = () => {
    if (exportCSVData) {
      csvLink.current.link.click()
    } else {
      fetchRightInvocationExport()
    }
    setIsVerifyDownloadPIDataModalOpen(false)
  }

  useEffect(() => {
    if (exportCSVData && csvLink && csvLink.current && csvLink.current.link) {
      csvLink.current.link.click()
    }
  }, [exportCSVData])

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle={''}
        customHeaderContentComponent={
          <Box display="flex" justifyContent="space-between" mt={-4}>
            <Box display="flex" gap={3}>
              <Box width={300}>
                <RightsQueueSearchInput handlePaginationReset={handlePaginationReset} />
              </Box>
              <RightsQueueMegaFilterButton
                rightsQueueListTableUtils={rightsQueueListTableUtils}
                handlePaginationReset={handlePaginationReset}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" gap={1} minWidth={222}>
              <Box display="flex" gap={1}>
                <CSVLink
                  data={exportCSVData}
                  filename={`${userData.organizationName} RIGHTS_QUEUE_${moment().format('MM-DD-YYYY')}`}
                  style={{ textDecoration: 'none' }}
                  ref={csvLink}
                ></CSVLink>
                <Button
                  color="tertiary"
                  startIcon={<Icon name="OShare" />}
                  pending={isFetchRightInvocationExportLoading}
                  onClick={() => setIsVerifyDownloadPIDataModalOpen(true)}
                >
                  Export
                </Button>
              </Box>

              {isPermittedToCreateRights && (
                <Button
                  onClick={() => {
                    navigate(RoutesManager.orchestration.rightsQueue.create.root.getURL())
                  }}
                >
                  <Typography variant="label" color="white.main">
                    Create Request
                  </Typography>
                </Button>
              )}

              {isVerifyDownloadPIDataModalOpen && (
                <ConfirmDownloadPIDataModal
                  onCancel={() => setIsVerifyDownloadPIDataModalOpen(false)}
                  onSubmit={handleDownloadClick}
                />
              )}
            </Box>
          </Box>
        }
      >
        {!isReady ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Spinner size="32px" thickness={2.5} />
          </Box>
        ) : (
          <RightsQueueListTable rightsQueueListTableUtils={rightsQueueListTableUtils} />
        )}
      </ListLayout>
    </>
  )
}
