import React from 'react'

import { ReactComponent as EmptyStateIcon } from 'assets/icons/workflows.svg'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { isEmpty } from 'lodash'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useRelationshipsViewUtils } from '../hooks'
import { CreateRelationshipModal } from '.'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RelationshipsTable } from '.'
import { useV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlag } from 'api/assets/queries/useV2OrV3RelationshipsInfiniteBasedOnFeatureFlag'

export const RelationshipsCreatedTab: React.FC = () => {
  const { isCreateRelationshipModalOpen, setIsCreateRelationshipModalOpen, handleSubmit } = useRelationshipsViewUtils()
  const { isPermitted } = useIsPermitted()

  const {
    infiniteRelationshipsData: relationships,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingReferences,
    isFetchingNextPage,
  } = useV2OrV3RelatedRelationshipsInfiniteBasedOnFeatureFlag({
    params: {
      type: 2,
    },
  })

  return (
    <>
      {isLoadingReferences && <Spinner padding={50} size={50} />}

      {!isLoadingReferences && isEmpty(relationships) && (
        <EmptyState
          variant="list"
          icon={<EmptyStateIcon />}
          title="You have not created any relationships yet"
          description={true ? 'Create your first one to populate the table.' : null}
          actions={[
            {
              title: 'Create Relation',
              hidden: !isPermitted(PERMISSIONS.ASSET_RELATIONSHIP_WRITE),
              size: 'big',
              onClick: () => setIsCreateRelationshipModalOpen(true),
            },
          ]}
        />
      )}

      <RelationshipsTable
        type="created"
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoadingReferences={isLoadingReferences}
        relationships={relationships}
        isFetchingNextPage={isFetchingNextPage}
      />

      {/* Create Relation Modal */}

      {isCreateRelationshipModalOpen ? (
        <CreateRelationshipModal
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsCreateRelationshipModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}
