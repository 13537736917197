import React, { Fragment, useContext } from 'react'

import Typography from '@mui/material/Typography'
import { FormFieldDetailViewContext } from '../context/FormFieldDetailViewContext'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { ItemsLanguageCellRenderer } from '.'
import { DataGrid, InfoRow, TableCell } from '@ketch-com/deck'
import { CheckboxOptionTranslationDTO } from '../../upsert/interfaces'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { FormInlineEdit } from 'components/form/FormInlineEdit'

type DataGridTranslationType = CheckboxOptionTranslationDTO & {
  rowIndex: number
}

export const FormFieldViewItems: React.FC = () => {
  const { formikProps, organizationLanguages } = useContext(FormFieldDetailViewContext)
  const { values } = formikProps

  const isText = values?.inputType === FormFieldTypeDTO.TextFormFieldType
  const isCheckbox = values?.inputType === FormFieldTypeDTO.CheckboxFormFieldType
  const isDropdown = values?.inputType === FormFieldTypeDTO.DropdownFormFieldType

  const options = isCheckbox ? values?.checkboxOptions : isDropdown ? values?.dropdownOptions : []

  if (isText) return null

  return (
    <>
      {options?.map((option, optionIndex) => {
        const translationRows: DataGridTranslationType[] =
          option.translations.map((translation, index) => ({ ...translation, rowIndex: index })) || []

        const translationItems = Object.entries(translationRows || {})

        return (
          <Fragment key={`${option.label}_${option.value}`}>
            <InfoRow
              key={`${option.label}_${option.value}`}
              title={isCheckbox ? `Checkbox ${optionIndex + 1}` : `Item ${optionIndex + 1}`}
            >
              <Typography variant="body">{option?.label}</Typography>
            </InfoRow>

            {translationItems.length && (
              <DataGrid
                autosizeOnMount
                autosizeOptions={{
                  includeHeaders: true,
                  includeOutliers: false,
                  expand: true,
                }}
                getRowHeight={() => 'auto'}
                columns={[
                  {
                    align: 'left',
                    field: 'language',
                    flex: 1,
                    headerAlign: 'left',
                    headerName: 'Language',
                    sortable: false,
                    renderCell: ({
                      row: translationEntry,
                    }: GridRenderCellParams<[string, DataGridTranslationType]>) => {
                      return (
                        <TableCell>
                          <ItemsLanguageCellRenderer
                            translationEntry={translationEntry}
                            organizationLanguages={organizationLanguages}
                          />
                        </TableCell>
                      )
                    },
                  },
                  {
                    align: 'left',
                    field: 'fieldTitleTranslation',
                    flex: 1,
                    headerAlign: 'left',
                    headerName: 'Display Text Translation',
                    sortable: false,
                    renderCell: ({ row }: GridRenderCellParams<[string, DataGridTranslationType]>) => {
                      const accessKey = isCheckbox ? 'checkboxOptions' : 'dropdownOptions'
                      const rowIndex = row[1].rowIndex
                      const currentValue =
                        formikProps?.values?.[accessKey]?.[optionIndex].translations[rowIndex].fieldLabelTranslation
                      const initialValue =
                        formikProps?.initialValues?.[accessKey]?.[optionIndex].translations[rowIndex]
                          .fieldLabelTranslation
                      return (
                        <TableCell onBlur={e => e.stopPropagation()} onChange={e => e.stopPropagation()}>
                          <FormInlineEdit
                            onKeyDown={e => {
                              // if we don't do this, DataGrid will not let us type "space" inside the inline edit
                              if (e.code === 'Space') {
                                e.stopPropagation()
                              }
                            }}
                            shouldUpdateDebounced
                            formPropertyName={`${accessKey}.[${optionIndex}].translations.[${rowIndex}].fieldLabelTranslation`}
                            placeholder="Add Translation"
                            onBlur={() => {
                              // do not submit if current value equals to initial value
                              if (currentValue === initialValue) return
                              formikProps.submitForm()
                            }}
                          />
                        </TableCell>
                      )
                    },
                  },
                ]}
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnResize
                disableColumnReorder
                disableRowSelectionOnClick
                disableRowHoverStates
                disableMultipleRowSelection
                getRowId={row => row[0]}
                rows={translationItems}
                hideFooter
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}
