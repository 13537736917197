import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'
import { GatewayTransitionDTO } from '@ketch-com/figurehead'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { PathTile, PathTileVariantOptions } from './PathTile'
import { UnableToCompleteWarning } from './UnableToCompleteWarning'
import { DecisionGatewayContext } from '../context/DecisionGatewayContext'
import { getDoesTransitionHaveIssues } from '../utils'
import { ContentGroup } from '@ketch-com/deck'

const getVariant = (
  transition: GatewayTransitionDTO,
  isDecisionMulti: boolean,
  isAnyStepSelected: boolean,
): PathTileVariantOptions => {
  if (transition.isSelected) {
    return 'selected'
  }

  if (isAnyStepSelected) {
    return 'none'
  }

  if (isDecisionMulti) {
    return 'check'
  }

  return 'radio'
}

export const DecisionStepPaths: React.FC = () => {
  const theme = useTheme()
  const { gatewayContext, workflowStep, setSelectedPaths, selectedPaths, isTaskComplete } =
    useContext(DecisionGatewayContext)
  const [searchParams] = useSearchParams()

  const checkPath = (id: string, variant: PathTileVariantOptions) => {
    if (variant === 'radio') {
      if (selectedPaths.includes(id)) {
        setSelectedPaths([])
      } else {
        setSelectedPaths([id])
      }

      return
    }

    if (selectedPaths.includes(id)) {
      setSelectedPaths(prevSelectedPaths => prevSelectedPaths.filter(p => p !== id))
    } else {
      setSelectedPaths(prevSelectedPaths => [...prevSelectedPaths, id])
    }
  }

  const isManualStep = gatewayContext?.gateway?.isManualSelection || false
  const isAnyStepSelected =
    !!gatewayContext?.gateway?.transitions?.find(t => t.isSelected) ||
    workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const isFailedAutomaticDecision =
    !isManualStep && (gatewayContext?.gateway?.transitions?.filter(getDoesTransitionHaveIssues) || []).length > 0

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <ContentGroup title="Decision Paths" variant="inner-page-header" titleVariant="h3" />
        {searchParams.get('hasAttemptedToResolve') && !selectedPaths.length && (
          <Typography variant="smallBody" sx={{ color: 'chileanFire.main' }}>
            You must select a path before you can resolve Decision Gateway
          </Typography>
        )}
      </Box>

      {isFailedAutomaticDecision && !isTaskComplete ? <UnableToCompleteWarning /> : null}

      <Box
        pt={4}
        sx={{
          borderTop: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        {isAnyStepSelected ? (
          <Box>
            <Box>
              <Typography variant="h4" color={theme.palette.Text.Secondary} component="div" mb={1.5}>
                Selected
              </Typography>
              <Box>
                {gatewayContext?.gateway?.transitions
                  ?.map((transition, index) => {
                    const variant = getVariant(transition, gatewayContext?.gateway?.mode === 4, isAnyStepSelected)

                    if (variant !== 'selected') return null

                    return (
                      <PathTile
                        key={transition.pathId || String(index)}
                        variant={variant}
                        isChecked={selectedPaths.includes(transition.pathId || String(index))}
                        onCheckClick={() => checkPath(transition.pathId || String(index), variant)}
                        transition={transition}
                        isManualStep={isManualStep}
                      />
                    )
                  })
                  .filter(Boolean)}
              </Box>
            </Box>

            {!!gatewayContext?.gateway?.transitions?.filter(transition => {
              const variant = getVariant(transition, gatewayContext?.gateway?.mode === 4, isAnyStepSelected)
              return variant !== 'selected'
            }).length && (
              <Box mt={4}>
                <Typography variant="h4" color={theme.palette.Text.Secondary} component="div" mb={1.5}>
                  Other
                </Typography>
                <Box>
                  {gatewayContext?.gateway?.transitions
                    ?.map((transition, index) => {
                      const variant = getVariant(transition, gatewayContext?.gateway?.mode === 4, isAnyStepSelected)

                      if (variant === 'selected') return null

                      return (
                        <PathTile
                          key={transition.pathId || String(index)}
                          variant={variant}
                          isChecked={selectedPaths.includes(transition.pathId || String(index))}
                          onCheckClick={() => checkPath(transition.pathId || String(index), variant)}
                          transition={transition}
                          isManualStep={isManualStep}
                        />
                      )
                    })
                    .filter(Boolean)}
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          gatewayContext?.gateway?.transitions?.map((transition, index) => {
            const variant = getVariant(transition, gatewayContext?.gateway?.mode === 4, isAnyStepSelected)
            return (
              <PathTile
                key={transition.pathId || String(index)}
                variant={variant}
                isChecked={selectedPaths.includes(transition.pathId || String(index))}
                onCheckClick={() => checkPath(transition.pathId || String(index), variant)}
                transition={transition}
                isManualStep={isManualStep}
              />
            )
          })
        )}
      </Box>
    </Box>
  )
}
