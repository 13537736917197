import { useEffect, useState } from 'react'
import { InfoRow, InfoRowProps, TextInput } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export interface TextAreaInfoRowProps extends InfoRowProps {
  value?: string
  setValue: (text?: string) => void
  isCustomField?: boolean
}

export default function TextAreaInfoRow({
  value,
  setValue,
  isCustomField = false,
  isEditable = true,
  ...rest
}: TextAreaInfoRowProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [isInfoRowLoading, setIsInfoRowLoading] = useState(false)
  const [localValue, setLocalValue] = useState<typeof value>()

  useEffect(() => {
    setIsInfoRowLoading(Boolean(rest.isLoading))
  }, [rest.isLoading])

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <InfoRow
      {...rest}
      isEditable={isEditable}
      isEditing={isEditing}
      isLoading={isInfoRowLoading}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsInfoRowLoading(true)
        setValue(localValue)
        setTimeout(() => {
          setIsInfoRowLoading(false)
          setIsEditing(false)
        }, 1000)
      }}
      onCancelChange={() => {
        setIsEditing(false)
      }}
      onDeleteChange={() => {
        setIsEditing(false)
        setValue()
      }}
      isEmpty={rest.isEmpty || !localValue?.length}
      titleAddendum={isCustomField ? 'Custom' : undefined}
    >
      {isEditing ? (
        <TextInput
          autoFocus
          placeholder={rest.placeholder}
          multiline={true}
          rows={2}
          fullWidth={true}
          value={localValue}
          onChange={event => setLocalValue(event.target.value)}
        />
      ) : (
        <EmptyValueRenderer text={value || ''} />
      )}
    </InfoRow>
  )
}
