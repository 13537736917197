import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Chip, ContentGroup, Icon, Spinner, theme } from '@ketch-com/deck'

import { ReactComponent as UploadFileIcon } from 'assets/icons/file_upload_nocolor.svg'
import { acceptableDataFlowFileTypes } from '../../constants'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { useContext } from 'react'
import { convertFileInfoTypes, getDataFlowFileFormInitialValues } from '../utils'
import { Formik } from 'formik'
import { FileInfoDTO } from 'interfaces/files/fileInfo'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { MAX_FILE_UPLOAD_SIZE_4_MB } from 'utils/constants/maxFileUploadSize'
import { FormDropZoneWithUpload } from 'components/form/FormDropZone/FormDropZoneWithUpload'

export const DataFlowTab = () => {
  const {
    processingActivity,
    handleDataFlowUpdate,
    handleDeleteProcessingActivityDataFlowFile,
    downloadDataFlowFile,
    refetchProcessingActivityLatest,
    setShowEditApprovedDialog,
    isDataFlowFileDeleting,
    isPreviousVersion,
    isProcessingActivityApproved,
  } = useContext(ProcessingActivityContext)

  const { isPermitted } = useIsPermitted()

  const handleUploadComplete = async (files: FileInfoDTO[]) => {
    await handleDataFlowUpdate({
      params: {
        activityId: processingActivity.id || '',
        formData: {
          file: {
            name: files[0].name,
          },
        },
      },
    })
  }

  const handleDeleteFileClick = async () => {
    if (isProcessingActivityApproved) {
      setShowEditApprovedDialog(true)
    } else {
      await handleDeleteProcessingActivityDataFlowFile({
        params: {
          activityId: processingActivity.id || '',
        },
      })
      await refetchProcessingActivityLatest()
    }
  }

  return (
    <Formik
      initialValues={
        processingActivity.dataFlowFile && Object.keys(processingActivity.dataFlowFile).length
          ? {
              file: [convertFileInfoTypes(processingActivity.dataFlowFile)],
            }
          : getDataFlowFileFormInitialValues()
      }
      onSubmit={() => {}} // Empty just so we can use formik for the components (FormDropZone)
    >
      {({ setFieldValue }) => {
        return (
          <Box display="flex" flexDirection="column" gap={4}>
            {/* Heading text */}
            <ContentGroup
              title="Data Flow"
              subTitle="Upload a diagram illustrating the flow of data from collection to expiry for this processing activity."
              titleVariant="h3"
              variant="inner-page-header"
            />

            {/* Image display or dropzone */}
            {processingActivity.dataFlowFile?.publicUrl ? (
              isDataFlowFileDeleting ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Spinner thickness={2.5} size={32} />
                </Box>
              ) : (
                <Box>
                  {/* Uploaded image display */}
                  <Box maxWidth={840}>
                    <img
                      src={processingActivity.dataFlowFile?.publicUrl}
                      alt={`preview of ${processingActivity.dataFlowFile?.name}`}
                      style={{
                        maxWidth: '840px',
                        borderRadius: 13,
                        border: `1px solid ${theme.palette.iron.main}`,
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box maxWidth={300}>
                      <Chip
                        label={<Typography variant="body">{processingActivity.dataFlowFile?.name}</Typography>}
                        onDelete={async () => {
                          await handleDeleteFileClick()
                          setFieldValue('file', [])
                        }}
                        deleteIcon={<Icon name="OCross" />}
                        sx={{ pr: 0.75 }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center" ml="auto" gap={1}>
                      <Typography variant="body" color={theme.palette.Text.Secondary}>
                        {processingActivity.dataFlowFile?.size}
                      </Typography>
                      <Button variant="iconCustomRounded" color="tertiary" onClick={downloadDataFlowFile}>
                        <Icon name="ODownload" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            ) : (
              <FormDropZoneWithUpload
                value={
                  processingActivity.dataFlowFile ? [convertFileInfoTypes(processingActivity.dataFlowFile)] : undefined
                }
                maxFiles={1}
                accept={acceptableDataFlowFileTypes}
                name="file"
                subtitle="You can drop a file here for quick upload or use Browse button below to locate it in your file manager."
                maxSize={MAX_FILE_UPLOAD_SIZE_4_MB}
                uploadContext={{
                  bucket: '',
                  folder: processingActivity.id,
                  version: 'processing-activity-data-flow-file',
                  acl: `${AccessControlType.public}`,
                }}
                onUploadComplete={handleUploadComplete}
                onDelete={handleDeleteFileClick}
                disabled={isPreviousVersion || !isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE)} // No data flow upload for old processing activity
                disableDelete={!isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE)}
                disableDownload={!isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_READ)}
                icon={<UploadFileIcon fill={theme.palette.darkDusk.main} />}
                locked={processingActivity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus}
                onClickWhenLocked={() => setShowEditApprovedDialog(true)}
                hideUploadedFilesView
              />
            )}
          </Box>
        )
      }}
    </Formik>
  )
}
