import React, { useContext } from 'react'
import { Formik } from 'formik'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  ImportContextHeaderActions,
  ImportContextHeaderDetails,
  ImportContextDetails,
} from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { ImportContextData } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/context'
import { getImportContextValidationSchema } from 'pages/orchestration/rightsQueue/stepDetails/components/stepSpecificViews/ImportContext/utils'
import { withImportContextDataContext } from './context/ImportContextData'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

const ImportContextWithContext: React.FC = () => {
  const {
    breadcrumbs,
    isReady,
    rightInvocationId,
    initialValues,
    updateWorkflowExecutionStep,
    workflowStep,
    workflowExecutionId,
  } = useContext(ImportContextData)

  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getImportContextValidationSchema()}
      onSubmit={async values =>
        await updateWorkflowExecutionStep({
          params: {
            workflowExecutionId,
            stepId: workflowStep?.ID,
            formData: {
              manualInterventionData: {
                importContextValue: values?.importContextValue || '',
              },
            },
          },
        })
      }
    >
      {() => {
        return (
          <>
            <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
            <ViewLayout
              contentGroupProps={{
                title: 'Import Context',
                showBackButton: true,
                propsBackButton: {
                  onClick: () =>
                    navigate(
                      RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }),
                    ),
                },
                imageBlockComponent: <WorkflowStepHeaderIcon />,
                infoBlockComponent: <ImportContextHeaderDetails />,
                actionRightBlockComponent: <ImportContextHeaderActions />,
              }}
              widgetContentComponent={<StepDetailsColumn />}
            >
              <ImportContextDetails />
            </ViewLayout>
          </>
        )
      }}
    </Formik>
  )
}

export const ImportContext = withImportContextDataContext(ImportContextWithContext)
