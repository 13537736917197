import React from 'react'

import { PermitStatisticsOverall, PermitsSummaryTrend } from './components'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

export const PermitsSummary: React.FC = () => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  return (
    <>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h2">{t('Summary')}</Typography>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
        <PermitStatisticsOverall />
        <PermitsSummaryTrend />
      </Box>
    </>
  )
}
