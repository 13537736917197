export enum RightsQueueMegaFilterButtonPanelIds {
  assignees = 'assignees',
  jurisdictions = 'policyScopeCodes', // also referred to as "jurisdictions"
  rights = 'rightCodes',
  status = 'requestStatuses',
  subjectTypes = 'subjectTypeCodes',
  workflows = 'workflows',
  stepStatus = 'workflowExecutionStepStatuses',
}
// [on_track, due_soon, due_today, overdue, fulfilled, rejected, has_appeal]
export const statusFilters: { label: string; value: string }[] = [
  {
    label: 'On Track',
    value: 'on_track',
  },
  {
    label: 'Due Soon',
    value: 'due_soon',
  },
  {
    label: 'Due Today',
    value: 'due_today',
  },
  {
    label: 'Overdue',
    value: 'overdue',
  },
  {
    label: 'Completed',
    value: 'fulfilled',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    // Appealed will filter all right invocations that have an appeal (and is also one of the "statuses"
    // above). As in a right invocation can be both Appealed and Due Today
    label: 'Appealed',
    value: 'has_appeal',
  },
]

export const stepStatusFilters: { label: string; value: string }[] = [
  {
    label: 'In progress',
    value: '2',
  },
  {
    label: 'Failed',
    value: '4',
  },
]
