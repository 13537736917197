/**
 * Generate a SHA-256 hash of the input string
 * @param input - The input string to hash
 * @returns A promise that resolves to the hexadecimal string representation of the hash
 */
export async function generateSHA256(input: string): Promise<string> {
  // Encode the input string as a Uint8Array
  const encoder = new TextEncoder()
  const data = encoder.encode(input)

  // Generate the hash (returns a promise)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  // Convert the hash buffer to a hexadecimal string
  return Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')
}
