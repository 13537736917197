import React, { HTMLAttributes, useCallback, useMemo, useRef } from 'react'
import { useAuth } from 'utils/hooks'
import { useUsers } from 'api/users/queries/useUsers'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import { useTeams } from 'api/teams/queries/useTeams'
import { ActionSheetItem, AvatarSize, Button, DropListButton, ListItemText, TextInput } from '@ketch-com/deck'
import {
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  Popper,
  Typography,
  styled,
  autocompleteClasses,
  inputBaseClasses,
  textFieldClasses,
  InputProps,
} from '@mui/material'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { UserShortDTO } from 'interfaces/users/users'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { AssigneeFormatDelimter, fromAssignee } from 'utils/helpers/teamStringParser'
import { useTeam } from 'api/teams/queries/useTeam'
import { UserStatus } from 'interfaces/users/userStatus'

type Props = {
  label?: string
  inFormRow?: boolean
  hasAssignToMeButton?: boolean
  selectedAssigneeId?: string
  onChange?: (newAssigneeId?: string) => void
  currentUserIsNonAdminTeamMember?: boolean
  onlyShowTeams?: boolean
  disabled?: boolean
  size?: 'small' | 'medium'
  width?: string
  required?: boolean
}

const CustomPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.20), 0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: 5,
  backgroundColor: theme.palette.white.main,
  width: 'fit-content !important',
  maxWidth: 300,

  '& .MuiTypography-root': {
    overflowWrap: 'anywhere',
  },

  [`& .${autocompleteClasses.root}`]: {
    [`& .${textFieldClasses.root}`]: {
      padding: '8px 8px 0 8px',
      [`& .${inputBaseClasses.root}`]: {
        backgroundColor: theme.palette.white.main,
      },
    },
  },
}))

// Assignee option rows can be users, teams, or header dividers
// All three require ID+Name
type ListItem = {
  id: string
  name: string
  header?: string // Indicate that it is an unclickable header row
  isMe?: boolean // Indicate that it is the current user, indicate as such
} & Team

export const AssigneeOrTeamSelector: React.FC<Props> = ({
  label,
  selectedAssigneeId,
  width,
  onChange = () => {},
  hasAssignToMeButton = false,
  currentUserIsNonAdminTeamMember = false,
  onlyShowTeams = false,
  disabled = false,
  size = 'small',
  required = true,
}) => {
  const { userData } = useAuth()
  const { data: usersList } = useUsers({ params: { active: true } })
  const { data: teamsList } = useTeams({})
  const [team, teamAssignee] = fromAssignee(selectedAssigneeId || '')
  const ref = useRef()
  const { data: teamData } = useTeam({ params: { id: team } })

  const options = useMemo(() => {
    if (!currentUserIsNonAdminTeamMember) {
      const me = usersList.find(({ ID }) => userData.userId === ID)
      const meOption = me ? [{ id: me?.ID, name: getFullNameUserShortDTO(me), isMe: true }] : []

      const teamOptions = teamsList?.teams || []
      if (onlyShowTeams) return teamOptions as ListItem[]

      const usersOptions = (usersList || [])
        // Filter out inactive users
        .filter(f => f.status === UserStatus.ACTIVE)
        .map(user => ({
          id: user?.ID,
          name: getFullNameUserShortDTO(user),
        }))
        .filter(({ id }) => id !== me?.ID)

      const teamsHeader = teamOptions.length > 0 ? [{ id: 'teams-header', name: 'Teams', header: true }] : []
      const usersHeader = teamOptions.length > 0 ? [{ id: 'users-header', name: 'Users', header: true }] : []

      return [...teamsHeader, ...teamOptions, ...usersHeader, ...meOption, ...usersOptions] as ListItem[]
    } else {
      const header = { id: 'members-header', name: 'Team Members', header: true }
      return [
        header,
        ...(teamData?.members || []).map(user => ({
          id: user?.id || '',
          name: getFullNameUserShortDTO(user as UserShortDTO),
        })),
      ]
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList, teamsList, teamData, currentUserIsNonAdminTeamMember]) as Team[]

  const qualifiedAssigneeId = selectedAssigneeId?.includes(AssigneeFormatDelimter) ? teamAssignee : team || teamAssignee
  const selectedValue = options.find(option => option.id === qualifiedAssigneeId)

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const isTeam = !!selectedValue?.members
      const currentAssignee = isTeam
        ? ({ ID: selectedValue.id } as UserShortDTO)
        : selectedValue
        ? usersList.find(user => user?.ID === selectedValue.id)
        : undefined

      return (
        <TextInput
          {...params}
          value={selectedValue?.name}
          InputProps={
            {
              ...params.InputProps,
              value: selectedValue?.name,
              placeholder: `Select ${label || 'Assignee'}`,
              startAdornment: params.inputProps.value ? (
                <InputAdornment position="start">
                  <UserAvatar
                    user={currentAssignee}
                    alternativeText={isTeam ? selectedValue.label : undefined}
                    size={AvatarSize.small}
                  />
                </InputAdornment>
              ) : null,
              'data-test-id': `${label || 'Assignee'}OrTeamSelector`,
            } as Partial<InputProps>
          }
        />
      )
    },

    [label, selectedValue, usersList],
  )

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, opt: unknown, _: any, isSelected?: boolean) => {
      const option = opt as ListItem

      // Allow for non-clickable section headers in the list
      if (option.header) {
        return (
          <ActionSheetItem key={option.id} subSectionTitle>
            {option.name}
          </ActionSheetItem>
        )
      }

      const isTeam = !!option.members

      // Team rows should display their default assignee
      // Rows of the current user should indicate as such
      const rowSubtitle = option.isMe
        ? '(Assign to me)'
        : isTeam
        ? option.defaultAssigneeId
          ? 'Assignee is predefined'
          : 'Assignee is selected randomly'
        : undefined

      return (
        <ActionSheetItem
          selected={isSelected}
          {...props}
          key={option.id}
          data-test-id={`${label || 'Assignee'}Option-${option.id}`}
        >
          <Box display="flex" alignItems="center">
            <ListItemText selected={isSelected}>
              <Box display="flex" alignItems="center" gap={1}>
                <UserAvatar
                  // Pretend the team is also a user to generate an avatar that displays in the same list
                  user={isTeam ? ({ ID: option.id } as UserShortDTO) : usersList.find(user => option.id === user?.ID)}
                  alternativeText={isTeam ? option.label : undefined}
                  size={AvatarSize.small}
                />
                <Box>
                  {option.name}
                  {rowSubtitle ? (
                    <Typography variant="smallBody" color="Text.Secondary" component="div" sx={{ mt: -0.25 }}>
                      {rowSubtitle}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </ListItemText>
          </Box>
        </ActionSheetItem>
      )
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [label, usersList],
  )

  const labelText: React.ReactNode = !required ? (
    <>
      {label} <Typography color="Text.Secondary">(Optional)</Typography>
    </>
  ) : (
    <>{label}</>
  )

  return (
    <Box ref={ref}>
      <DropListButton
        disabled={disabled}
        disableClearable
        size={size}
        onChange={(_, value: Team) =>
          value && onChange(currentUserIsNonAdminTeamMember ? `${team}${AssigneeFormatDelimter}${value.id}` : value.id)
        }
        fullWidth
        label={labelText}
        options={options}
        renderInput={renderInput}
        getOptionLabel={(opt: Team) => opt.name || opt.id || ''}
        renderOption={renderOption}
        width={width}
        PopperComponent={CustomPopper}
        //TODO:JA DroplistButton is not typed correctly - to be a controlled input, an unset value needs to be `null`
        value={selectedValue || (null as any)}
        componentsProps={{ popper: { placement: 'bottom-start' } }}
      />
      {hasAssignToMeButton ? (
        <Button
          color="secondary"
          sx={{ mt: 1 }}
          onClick={() => {
            onChange(
              currentUserIsNonAdminTeamMember ? `${team}${AssigneeFormatDelimter}${userData.userId}` : userData.userId,
            )
          }}
        >
          Assign to me
        </Button>
      ) : null}
    </Box>
  )
}
