import { Button } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import React from 'react'

export const RecaptchaDisclosure: React.FC = () => {
  return (
    <Typography variant="footnote">
      This site is protected by reCAPTCHA and the Google{' '}
      <Button
        variant="link"
        color="secondary"
        href="https://policies.google.com/terms"
        sx={{
          fontSize: '11px',
        }}
      >
        Privacy Policy
      </Button>{' '}
      and{' '}
      <Button
        variant="link"
        color="secondary"
        href="https://policies.google.com/terms"
        sx={{
          fontSize: '11px',
        }}
      >
        Terms of Service
      </Button>{' '}
      apply.
    </Typography>
  )
}
