import React, { useContext, useEffect, useState } from 'react'

import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useSearchParams } from 'react-router-dom'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { InfoRowAssignUser } from 'pages/orchestration/rightsQueue/stepDetails/components/InfoRowAssignUser'
import { MemberShortDTO } from '@ketch-com/figurehead'

export const Assignee: React.FC = () => {
  const [searchParams] = useSearchParams()

  const { isPermitted } = useIsPermitted()

  const { stepDetails, workflowExecutionId, workflowStep } = useContext(RightInvocationWorkflowStepDetailViewContext)

  const [assigneeInfo, setAssigneeInfo] = useState<MemberShortDTO>({})
  useEffect(() => {
    if (stepDetails.assigneeInfo) {
      setAssigneeInfo(stepDetails.assigneeInfo)
    }
  }, [stepDetails?.assigneeInfo])

  const canReassign = isPermitted(PERMISSIONS.WORKFLOW_TASK_ASSIGN)
  const isComplete = workflowStep?.status === WorkflowExecutionStepStatus.SUCCESS
  const canEdit = canReassign && !isComplete

  const showRequired = !!searchParams.get('hasAttemptedToResolveWithoutAssignee')
  return (
    <InfoRowAssignUser
      infoRowTitle={`Assignee ${showRequired ? '(Required)' : ''}`}
      isError={!!searchParams.get('hasAttemptedToResolveWithoutAssignee')}
      assigneeInfo={assigneeInfo}
      assignee={stepDetails.assignee}
      canEdit={canEdit}
      isComplete={isComplete}
      onHandleSubmit={() => searchParams.delete('hasAttemptedToResolveWithoutAssignee')}
      workflowExecutionId={workflowExecutionId || ''}
      workflowStepId={workflowStep?.ID || ''}
    />
  )
}
