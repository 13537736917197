import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { FormTemplatePreviewHeader, FormTemplateSection, PreviewModeToggle } from './components'
import {
  FormTemplateUpsertContext,
  withFormTemplateUpsertContext,
} from '../upsert/context/FormTemplateUpsertContextProvider'
import { useQueryParams } from 'utils/hooks'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { FormTemplatePreviewQueryParams, PreviewFrom } from './interfaces'

type ContainerProps = {
  theme?: Theme
  previewMode?: 'desktop' | 'mobile'
}

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'previewMode',
})<ContainerProps>(({ theme, previewMode }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  borderRadius: '11px',
  margin: previewMode === 'desktop' ? undefined : '0 auto',
  padding: previewMode === 'desktop' ? theme?.spacing(3, 5) : theme?.spacing(3, 2.25),
  width: '100%',
  maxHeight: previewMode === 'desktop' ? undefined : 620,
  backgroundColor: theme?.palette.white.main,
  maxWidth: previewMode === 'desktop' ? undefined : 388,
  border: previewMode === 'desktop' ? undefined : `14px solid ${theme?.palette.ironLight.main}`,
  outline: previewMode === 'desktop' ? undefined : `14px solid ${theme?.palette.fadedGrey.main}`,
}))

const FormTemplatePreviewWithoutContext: React.FC = () => {
  const { breadcrumbs, formTemplate } = useContext(FormTemplateUpsertContext)
  const { queries, setQueryParam } = useQueryParams<FormTemplatePreviewQueryParams>()

  let formTemplateDTO = null

  if (queries.from === PreviewFrom.Detail && formTemplate) {
    formTemplateDTO = formTemplate
  }

  if (queries.from === PreviewFrom.Editing && formTemplate && queries.formDTO) {
    formTemplateDTO = JSON.parse(queries.formDTO) as FormTemplateDTO
  }

  if (queries.from === PreviewFrom.New && !formTemplate && queries.formDTO) {
    formTemplateDTO = JSON.parse(queries.formDTO) as FormTemplateDTO
  }

  // useEffect to set previewMode to 'desktop' if it's not set
  useEffect(() => {
    if (!queries.previewMode) {
      setQueryParam('previewMode', 'desktop')
    }
  }, [queries.previewMode, setQueryParam])

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs.filter(b => !!b?.title)} />
      <FormTemplatePreviewHeader formTemplateDTO={formTemplateDTO} />
      {/* Desktop/Mobil toggle */}
      <PreviewModeToggle />
      {/* Preview */}
      <Box minWidth={856} maxWidth={856} width={856}>
        <Container previewMode={queries.previewMode}>
          {/* Sections */}

          {formTemplateDTO?.sections?.map(section => {
            return <FormTemplateSection formTemplateSection={section} key={JSON.stringify(section)} />
          })}
        </Container>
      </Box>
    </>
  )
}

export const FormTemplatePreview = withFormTemplateUpsertContext(FormTemplatePreviewWithoutContext)
