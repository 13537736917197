import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { DataOwnerChip } from 'pages/assetManager/components'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { Error } from 'components/ui-kit/form/common/error/Error'
import { InlineEditLayout } from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/components'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { useUsers } from 'api/users/queries/useUsers'
import { UseInlineEditUtilsReturnType } from '../hooks'

type Props = {
  assetSummary?: AssetSummaryDTO
  inlineEditUtils: UseInlineEditUtilsReturnType
}

export const DataOwners: React.FC<Props> = ({ assetSummary, inlineEditUtils }) => {
  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
    },
  })
  const {
    errors,
    handleSubmit,
    isUpdatingDatabase,
    values,
    resetForm,
    setFieldValue,
    initialValues,
    editRow,
    setEditRow,
  } = inlineEditUtils

  const dropdownValueUser = users.find(({ ID }) => ID === values?.dataOwner)
  const dataOwnerName = `${dropdownValueUser?.firstName || ''} ${dropdownValueUser?.lastName || ''}`.trim()

  return (
    <InlineEditLayout
      editModeKey="DataOwners"
      setEditRow={setEditRow}
      key={assetSummary?.asset?.owner || 'unset-data-owner'}
      isLoadingRequiredFormData={isUsersLoading}
      pending={isUpdatingDatabase}
      onSubmit={handleSubmit}
      // TODO:BAC - refine onDelete handler once BE delivers uniform property nullification
      onDelete={() => setFieldValue('dataOwner', '')}
      error={errors?.dataCategory ? <Error>{errors?.description}</Error> : undefined}
      resetForm={resetForm}
      resetInputOnCancel={() => {
        setFieldValue('dataOwner', initialValues.dataOwner)
      }}
      isEditMode={editRow === 'DataOwners'}
      displayModeContent={
        assetSummary?.asset?.owner ? (
          <DataOwnerChip text={dataOwnerName} />
        ) : (
          <EmptyValueRenderer color="darkDuskFaded" />
        )
      }
      editModeContent={
        <Dropdown
          disabled={isUpdatingDatabase}
          placeholder="Select Data Owner"
          value={values?.dataOwner}
          items={users.map(u => ({
            active: values?.dataOwner === `${u.firstName} ${u.lastName}`.trim(),
            content: `${u.firstName} ${u.lastName}`,
            value: u.ID,
            onClick: () => setFieldValue('dataOwner', `${u.firstName || ''} ${u.lastName || ''}`.trim()),
          }))}
        >
          {dropdownValueUser?.firstName} {dropdownValueUser?.lastName}
        </Dropdown>
      }
    />
  )
}
