import React, { useContext } from 'react'
import { withTemplatesListContext } from '../utils/withContext'
import { TemplatesContext } from '../context/TemplatesContext'
import Box from '@mui/material/Box'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
import { FormMode } from 'interfaces/formModes/formMode'
import { useDebouncedSearchString } from 'pages/consentAndRights/forms/fields/list/hooks'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { DataGrid, EmptyState } from '@ketch-com/deck'
import { GridRowParams } from '@mui/x-data-grid'
import { FormTemplateSummaryDTO } from '@ketch-com/figurehead'
import { useTemplatesTabListColumns } from '../hooks/templatesDataGridUtils'
import { PreviewFrom } from '../../preview/interfaces'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const TemplatesTabWithoutContext: React.FC = () => {
  const navigate = useNavigate()
  const { setSearchString, queryParamSearch } = useDebouncedSearchString()
  const contextResp = useContext(TemplatesContext)
  const { infiniteCustomFormTemplatesResp, isUserExperienceServerAdmin } = contextResp
  const {
    data: infiniteFormTemplateSummariesData = [],
    isLoading: isLoadingInfiniteCustomFormTemplates,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = infiniteCustomFormTemplatesResp

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    { title: 'Forms', link: RoutesManager.deployment.forms.templates.root.getURL() },
  ]

  const columns = useTemplatesTabListColumns({ isUserExperienceServerAdmin })

  const isGridLoading = isFetchingNextPage || isLoadingInfiniteCustomFormTemplates
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />

      {!isGridLoading && !infiniteFormTemplateSummariesData.length && !queryParamSearch ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={6}
          borderRadius="11px"
          bgcolor="white.main"
        >
          <EmptyState
            customIcon={<EmptyStateIcon />}
            title="There are no custom forms for this organization"
            subTitle={isUserExperienceServerAdmin ? 'Create your first one to populate this section' : undefined}
            primaryButtonTitle={isUserExperienceServerAdmin ? 'Create Form' : undefined}
            primaryButtonProps={{
              size: 'large',
              onClick: () =>
                navigate(
                  RoutesManager.deployment.forms.templates.upsert.root.getURL({
                    id: NEW_ITEM_ID,
                    formMode: FormMode.CREATE,
                  }),
                ),
            }}
          />
        </Box>
      ) : (
        <>
          {/* Custom Templates Table */}

          <Box height={queryParamSearch ? 'auto' : '60vh'}>
            <DataGrid
              sx={{
                border: 'none',
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
                ...(isGridLoading &&
                  !infiniteFormTemplateSummariesData.length &&
                  queryParamSearch && {
                    '& .MuiDataGrid-virtualScroller': {
                      height: '300px',
                    },
                  }),
              }}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              getRowId={row => row.code}
              rows={infiniteFormTemplateSummariesData || []}
              hideFooter
              loading={isGridLoading}
              noRowsOverlayProps={{
                buttonProps: {
                  onClick: () => setSearchString(''),
                },
              }}
              onRowClick={({ row: template }: GridRowParams<FormTemplateSummaryDTO>) => {
                navigate({
                  pathname: RoutesManager.deployment.forms.templates.upsert.preview.root.getURL({
                    formMode: FormMode.EDIT,
                    id: template?.id || '',
                  }),
                  search: createSearchParams({
                    from: PreviewFrom.Detail,
                  }).toString(),
                })
              }}
              paginationLoading={isGridLoading}
              onRowsScrollEnd={() => {
                if (!isFetchingNextPage && hasNextPage) fetchNextPage()
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}

export const TemplatesTab = withTemplatesListContext(TemplatesTabWithoutContext)
