import { Box, Typography } from '@mui/material'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { useContext } from 'react'
import { IdentityVerificationContext } from '../context/IdentityVerificationContext'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'

export const IdvDetailsHeaderDetails: React.FC = () => {
  const { workflowStep } = useContext(IdentityVerificationContext)

  const statusBadge =
    workflowStep?.status === WorkflowExecutionStepStatus.FAIL ||
    workflowStep?.status === WorkflowExecutionStepStatus.INVALID_CREDENTIALS ? (
      <Status label="Failed" icon="Dot" status={StatusState.error} variant={StatusVariant.outlined} />
    ) : workflowStep?.status === WorkflowExecutionStepStatus.IN_PROGRESS ||
      workflowStep?.status === WorkflowExecutionStepStatus.PENDING ? (
      <Status label="In Progress" icon="Dot" status={StatusState.inProgress} variant={StatusVariant.outlined} />
    ) : (
      <Status label="Completed" icon="Dot" status={StatusState.success} variant={StatusVariant.outlined} />
    )

  return (
    <Box display="flex" gap={1}>
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{workflowStep?.code || 'none'}</Typography>
          </Typography>
        }
      />
      {statusBadge}
    </Box>
  )
}
