import { APIListRequestParams } from 'api/common/utils'
import { ListInstalledDataSystemV2AssetsResponseBodyDTO } from '@ketch-com/figurehead'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export type GetInstalledDataSystemAssetsParams = APIListRequestParams<{
  id?: string
  filters?: Record<string, string[] | string | undefined>
}>

const emptyStringifiedFilter = '[""]'
export const getInstalledDataSystemAssets = ({ id, filters }: GetInstalledDataSystemAssetsParams) => {
  /*
   * If filters are provided, and they are not empty, we will pass them as a query parameter
   * The "empty" check is because parent hooks are passing [""] as filter value, which is not a valid filter
   * This reduces unnecessary API calls when filters are empty
   */
  const params =
    filters &&
    Object.values(filters).some(
      filter =>
        (!Array.isArray(filter) && filter !== '') ||
        (Array.isArray(filter) && JSON.stringify(filter) !== emptyStringifiedFilter),
    )
      ? { filters }
      : undefined
  return API.get<ListInstalledDataSystemV2AssetsResponseBodyDTO>(
    formatRequestString({
      entityUrl: `/api/v2/installed-data-systems/${id}/assets`,
      params,
    }),
  )
}
