import React, { useState } from 'react'
import { DropListButton, TextInput } from '@ketch-com/deck'
import { Typography, Box, Autocomplete } from '@mui/material'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { MaybeNull } from 'interfaces'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'
import { useDebounce } from 'react-use'

interface FormData {
  identitySpaceCode: string
  name: string
  id?: string
}

const DropListButtonExample: React.FC = () => {
  const [identitySpaceQuery, setIdentitySpaceQuery] = useState('')
  const [selectedIdentitySpace, setSelectedIdentitySpace] = useState<MaybeNull<IdentitySpaceDTO>>(null)
  const [inputValue, setInputValue] = useState('')

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<FormData>()

  const {
    data: identitySpaces,
    isLoading: isLoadingIdentitySpaces,
    isFetching: isFetchingIdentitySpaces,
  } = useIdentitySpacesPaginated({
    params: {
      query: identitySpaceQuery,
      limit: 300,
    },
    onSuccess: ({ data }) => {
      const sel = data?.identitySpaces?.filter(identitySpace => identitySpace?.code === values?.identitySpaceCode)?.[0]
      if (!selectedIdentitySpace && sel) setSelectedIdentitySpace(sel)
    },
  })

  useDebounce(
    () => {
      if (inputValue) {
        setIdentitySpaceQuery(inputValue)
      } else {
        setIdentitySpaceQuery('')
      }
    },
    500,
    [inputValue],
  )
  return (
    <DropListButton
      sx={{ width: 320 }}
      loading={isLoadingIdentitySpaces || isFetchingIdentitySpaces}
      value={selectedIdentitySpace}
      filterOptions={x => x}
      clearOnBlur
      noOptionsText="No Identities"
      getOptionDisabled={option => option?.code === values?.identitySpaceCode}
      onChange={(e, identity, reason) => {
        if (values?.id) setFieldTouched('sqlQuery', true)
        if (reason === 'clear') {
          setFieldValue('identitySpaceCode', '')
          setSelectedIdentitySpace(null)
        } else {
          setFieldValue('identitySpaceCode', identity?.code)
          setSelectedIdentitySpace(identity)
        }
      }}
      onInputChange={(e, inputVal) => {
        setInputValue(inputVal)
      }}
      options={identitySpaces}
      renderInput={params => (
        <TextInput
          {...params}
          error={touched?.identitySpaceCode && Boolean(errors?.identitySpaceCode)}
          errorMessage={errors?.identitySpaceCode}
          onBlur={() => {
            setFieldTouched('identitySpaceCode', true)
          }}
          InputProps={{
            ...params.InputProps,
          }}
          placeholder="Select Identity"
          variant="outlined"
        />
      )}
      renderOption={(props, identity) => {
        return (
          <Box component="li" {...props} key={identity?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
            <Typography alignSelf="flex-start" variant="body">
              {identity?.name}
            </Typography>
            <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
              {identity?.code}
            </Typography>
          </Box>
        )
      }}
      getOptionLabel={option => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.code === value?.code}
    />
  )
}

const MuiAutocompleteExample: React.FC = () => {
  const [identitySpaceQuery, setIdentitySpaceQuery] = useState('')
  const [selectedIdentitySpace, setSelectedIdentitySpace] = useState<MaybeNull<IdentitySpaceDTO>>(null)
  const [inputValue, setInputValue] = useState('')

  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<FormData>()

  const {
    data: identitySpaces,
    isLoading: isLoadingIdentitySpaces,
    isFetching: isFetchingIdentitySpaces,
  } = useIdentitySpacesPaginated({
    params: {
      query: identitySpaceQuery,
      limit: 300,
    },
    onSuccess: ({ data }) => {
      const sel = data?.identitySpaces?.filter(identitySpace => identitySpace?.code === values?.identitySpaceCode)?.[0]
      if (!selectedIdentitySpace && sel) setSelectedIdentitySpace(sel)
    },
  })

  useDebounce(
    () => {
      if (inputValue) {
        setIdentitySpaceQuery(inputValue)
      } else {
        setIdentitySpaceQuery('')
      }
    },
    500,
    [inputValue],
  )
  return (
    <Autocomplete
      sx={{ width: 320 }}
      loading={isLoadingIdentitySpaces || isFetchingIdentitySpaces}
      value={selectedIdentitySpace}
      filterOptions={x => x}
      clearOnBlur
      noOptionsText="No Identities"
      getOptionDisabled={option => option?.code === values?.identitySpaceCode}
      onChange={(e, identity, reason) => {
        if (values?.id) setFieldTouched('sqlQuery', true)
        if (reason === 'clear') {
          setFieldValue('identitySpaceCode', '')
          setSelectedIdentitySpace(null)
        } else {
          setFieldValue('identitySpaceCode', identity?.code)
          setSelectedIdentitySpace(identity)
        }
      }}
      onInputChange={(e, inputVal) => {
        setInputValue(inputVal)
      }}
      options={identitySpaces}
      renderInput={params => (
        <TextInput
          {...params}
          error={touched?.identitySpaceCode && Boolean(errors?.identitySpaceCode)}
          errorMessage={errors?.identitySpaceCode}
          onBlur={() => {
            setFieldTouched('identitySpaceCode', true)
          }}
          InputProps={{
            ...params.InputProps,
          }}
          placeholder="Select Identity"
          variant="outlined"
        />
      )}
      renderOption={(props, identity) => {
        return (
          <Box component="li" {...props} key={identity?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
            <Typography alignSelf="flex-start" variant="body">
              {identity?.name}
            </Typography>
            <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
              {identity?.code}
            </Typography>
          </Box>
        )
      }}
      getOptionLabel={option => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.code === value?.code}
    />
  )
}

export const DropListButtonAsyncDataExample: React.FC = () => {
  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          identitySpaceCode: '',
          name: '',
          id: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          identitySpaceCode: Yup.string().required('Required'),
        })}
        onSubmit={() => {}}
      >
        {() => {
          return (
            <Box display="flex" flexDirection="column" gap={0.5} mb={6}>
              <Typography variant="label">DropListButton</Typography>
              <DropListButtonExample />
            </Box>
          )
        }}
      </Formik>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          identitySpaceCode: '',
          name: '',
          id: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          identitySpaceCode: Yup.string().required('Required'),
        })}
        onSubmit={() => {}}
      >
        {() => {
          return (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="label">MUI Autocomplete</Typography>
              <MuiAutocompleteExample />
            </Box>
          )
        }}
      </Formik>
    </>
  )
}
