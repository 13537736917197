import { useEffect, useState } from 'react'
import { useAuth } from 'utils/hooks/useAuth'

const posthogPublicKey = (window as any)?.figureheadConfig?.REACT_APP_POSTHOG_PUBLIC_KEY
const checksumai = (window as any)?.checksumai

export const Posthog: React.FC = () => {
  const { userData } = useAuth()
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!!posthogPublicKey) {
      console.info('initializing posthog')
      checksumai?.init(posthogPublicKey)
    }
  }, [])

  useEffect(() => {
    if (userData?.userId && !isMounted && !!posthogPublicKey) {
      checksumai?.identify(userData.userId)
      console.info('posthog identify, userId: ', userData.userId)
      setIsMounted(true)
    }
  }, [userData, isMounted, setIsMounted])

  return null
}
