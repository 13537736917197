import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { RoutesManager } from 'utils/routing/routesManager'
import { useFormTemplate } from 'api/formTemplates/queries/useFormTemplate'
import { FormTemplateActions, FormTemplateDetails } from './components'
import { ContentGroup, DataGrid, EmptyState, ViewLayout } from '@ketch-com/deck'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { getDataGridColumns } from './utils'

export const FormTemplateDetailView: React.FC = () => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isExpV1 = isFeatureFlagEnabled(
    FEATURE_FLAGS.EXPERIENCE_SERVER_VERSION,
    FEATURE_FLAGS_VALUES.EXPERIENCE_SERVER_VERSION_1,
  )

  const navigate = useNavigate()

  const { id: formTemplateId } = useParams<{ id?: string }>()

  const { data: formTemplate, isLoading: isLoadingFormTemplate } = useFormTemplate({
    params: {
      formTemplateId: formTemplateId || '',
      includeMetadata: true,
    },
  })

  const experiences = formTemplate?.experiences || []

  const isReady = !isLoadingFormTemplate

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Forms', link: RoutesManager.deployment.forms.templates.root.getURL() },
    { title: formTemplate?.name || formTemplate?.code },
  ]

  const columns = getDataGridColumns({
    isExpV1,
  })

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: formTemplate?.name || formTemplate?.code,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.deployment.forms.templates.root.getURL()),
          },
          actionRightBlockComponent: <FormTemplateActions formTemplate={formTemplate} />,
          infoBlockComponent: <FormTemplateDetails formTemplate={formTemplate} />,
        }}
      >
        <Box display="flex" flexDirection="column" gap={4} mb={6}>
          {/* Description */}
          <ContentGroup variant="inner-page-header" titleVariant="h3" title="Experience Details" />

          {experiences.length === 0 && isReady ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={6}
              borderRadius="11px"
              bgcolor="bone.main"
            >
              <EmptyState
                iconName="OIntegrity"
                title="There are no experiences associated with this form"
                subTitle="Assign this form to preference management experience to populate the table."
              />
            </Box>
          ) : (
            <DataGrid
              sx={{
                ...(!isReady && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
              }}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: false,
                expand: true,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              disableRowHoverStates
              loading={!isReady}
              getRowId={row => row.code}
              rows={experiences}
              hideFooter
            />
          )}
        </Box>
      </ViewLayout>
    </>
  )
}
