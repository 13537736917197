import { useContext } from 'react'
import { Typography } from '@mui/material'
import pluralize from 'pluralize'
import { EditWorkflowContainerContext } from '../context/EditWorkflowContainerContext'
import { Banner, Button, DataGrid, PopUp, TableCell } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { SubjectVariableWarningMessage } from '../../sidebarConfigs/forms/activity/TaskForm/utils/getIsContextVariableReferencedInGatewayButNotSetToRequired'

export const UnRequiredDataSubjectVariablesWarningModal: React.FC = () => {
  const {
    values,
    unRequiredDataSubjectVariablesReferencedInTasksWarning,
    setUnRequiredDataSubjectVariablesReferencedInTasksWarning,
    handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks,
  } = useContext(EditWorkflowContainerContext)

  const numWarnings = unRequiredDataSubjectVariablesReferencedInTasksWarning.length || 0

  const variable = pluralize('Variable', numWarnings)
  const task = pluralize('Task', numWarnings)
  const isOrAre = numWarnings === 1 ? 'is' : 'are'
  const itOrTheir = numWarnings === 1 ? 'its' : 'their'
  const thisOrThese = numWarnings === 1 ? 'this' : 'these'

  const message = `The ${variable} listed below ${isOrAre} currently marked as optional in ${itOrTheir} corresponding ${task}. However, in order to ensure automatic path selection in downstream decision gateways, values for ${thisOrThese} ${variable} must be provided. Please consider`

  return (
    <PopUp
      isOpen={!!unRequiredDataSubjectVariablesReferencedInTasksWarning.length}
      variant="modal"
      title="Save Workflow"
      popUpWidth="800px"
      onClose={() => setUnRequiredDataSubjectVariablesReferencedInTasksWarning([])}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={() => setUnRequiredDataSubjectVariablesReferencedInTasksWarning([])}
          >
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => handleConfirmSubmitWithUnRequiredDataSubjectVariablesReferencedInTasks(values)}
          >
            Continue
          </Button>
        </>
      }
    >
      <Banner
        severity="warning"
        sx={{
          // it is affected by the parent dialog, since they share some classnames
          '&.MuiPaper-root': {
            width: 'auto',
          },
        }}
      >
        This Workflow contains optional variables required for automatic path selection in downstream decisions.
      </Banner>

      <Typography variant="body" component="div">
        {message}
        <Typography variant="body" component="span" fontWeight="bold">
          {' '}
          changing their status to "required."
        </Typography>
      </Typography>

      <DataGrid
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            height: !!unRequiredDataSubjectVariablesReferencedInTasksWarning?.length ? 'auto' : '300px',
          },
        }}
        autosizeOnMount
        columns={dataGridColumns}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        getRowId={row => row.variableCode}
        disableRowHoverStates
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        hideFooter
        rows={unRequiredDataSubjectVariablesReferencedInTasksWarning}
        noRowsOverlayProps={{
          buttonTitle: '',
          subTitle: '',
        }}
      />
    </PopUp>
  )
}

const dataGridColumns: GridColDef<SubjectVariableWarningMessage>[] = [
  {
    align: 'left',
    field: 'variable',
    headerAlign: 'left',
    headerName: 'Variable',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { variableName, variableCode } }: GridRenderCellParams<SubjectVariableWarningMessage>) => {
      return <TableCell title={variableName} subTitle={variableCode} />
    },
  },
  {
    align: 'left',
    field: 'task',
    headerAlign: 'left',
    headerName: 'Task',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { taskName } }: GridRenderCellParams<SubjectVariableWarningMessage>) => {
      return <TableCell title={taskName} />
    },
  },
  {
    align: 'left',
    field: 'gateway',
    headerAlign: 'left',
    headerName: 'Gateway',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { gatewayName } }: GridRenderCellParams<SubjectVariableWarningMessage>) => {
      return <TableCell title={gatewayName} />
    },
  },
]
