import { JSXElementConstructor } from 'react'
import { TreeItemContentProps } from '@mui/x-tree-view'
import { without } from 'lodash'
import Box from '@mui/material/Box'
import { Icon } from '@ketch-com/deck'
import { ObjectLiteral } from 'interfaces'
import { Tree } from 'components/transferList/tree/Tree'
import { getAllCodes } from './utils'

type Props<T> = {
  excludedTitle: string
  /** Excluded items section title */
  excludedItems: T[]
  /** Excluded items section search string */
  excludedItemsSearchString?: string
  includedTitle: string
  /** Included items section items */
  includedItems: T[]
  /** Included items section search string */
  includedItemsSearchString?: string
  /** Component onChange method - returns updated list of included items */
  onChange: (nextIncludedItems: T[], nextIncludedItem: T, key: string) => void
  /** Component onSearch method - returns search string */
  onSearchIncludedItems?: (nextSearchString: string) => void
  /** Component onSearch method - returns search string */
  onSearchExcludedItems?: (nextSearchString: string) => void
  /** Component custom className */
  className?: string
  /** Defines what key is an expandable key */
  expandableKey?: string
  /** Component used to render the TreeItem content */
  ContentComponent: JSXElementConstructor<TreeItemContentProps>
  /** Whether to disable the transfer list */
  disabled?: boolean
}

export function TransferList<T extends ObjectLiteral>(props: Props<T>) {
  const {
    excludedTitle,
    excludedItems,
    includedTitle,
    includedItems,
    className,
    onChange,
    onSearchExcludedItems,
    onSearchIncludedItems,
    excludedItemsSearchString,
    includedItemsSearchString,
    expandableKey,
    ContentComponent,
    disabled = false,
  } = props

  const handleIncludeItem = (nextItem: T) => {
    onChange([...includedItems, nextItem], nextItem, 'INCLUDE')
  }

  const handleExcludeItem = (nextItem: T) => {
    onChange(without(includedItems, nextItem), nextItem, 'EXCLUDE')
  }

  return (
    <Box display="flex" className={className} sx={disabled ? { opacity: 0.3, pointerEvents: 'none' } : {}}>
      <Tree
        expandableKey={expandableKey}
        type="EXCLUDE"
        title={excludedTitle}
        items={excludedItems}
        searchString={excludedItemsSearchString}
        onSearch={onSearchExcludedItems}
        onItemClick={handleIncludeItem}
        ContentComponent={ContentComponent}
      />

      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height={430} width={130}>
        <Icon name="OArrowCRight" sx={{ opacity: '50%' }} />
        <Icon name="OArrowCLeft" sx={{ marginTop: '30px', opacity: '50%' }} />
      </Box>

      <Tree
        expandableKey={expandableKey}
        type="INCLUDE"
        title={includedTitle}
        items={includedItems}
        searchString={includedItemsSearchString}
        onSearch={onSearchIncludedItems}
        onItemClick={handleExcludeItem}
        defaultExpanded={getAllCodes(includedItems)}
        ContentComponent={ContentComponent}
      />
    </Box>
  )
}
