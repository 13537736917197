import React, { useContext } from 'react'
import { Typography, Box } from '@mui/material'
import { Spinner, theme } from '@ketch-com/deck'
import { TrackerDiagnosticsContext } from 'pages/policyCenter/trackers/context'
import { GetTrackerTypeResponse } from '@ketch-com/atlas/dist/apiTypes/lineage'

export const TrackerType: React.FC = () => {
  const { isTrackerLineageTypeReady, trackerLineageType } = useContext(TrackerDiagnosticsContext)

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      px={2.5}
      py={2}
      sx={{ backgroundColor: theme.palette.Black.o8, borderRadius: '11px' }}
    >
      {!isTrackerLineageTypeReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={80}>
          <Spinner size="32px" thickness={3} />
        </Box>
      ) : (
        <>
          <Typography variant="body" color={theme.palette.Text.Secondary}>
            Type
          </Typography>
          <Typography variant="h4">
            {trackerLineageType === GetTrackerTypeResponse?.authorized ? 'Authorized' : 'Unauthorized'}
          </Typography>
          <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
            {trackerLineageType === GetTrackerTypeResponse?.authorized
              ? 'This tracker has the same system as the system of preceding initiator.'
              : 'This Tracker does not have the same system as the system of the preceding initiator.'}
          </Typography>
        </>
      )}
    </Box>
  )
}
