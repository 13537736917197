import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

import { Text } from 'components/ui-kit/typography/Text'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start ',
        background: palette.fadedGrey.main,
        gap: spacing(1.25),
        borderRadius: spacing(0.75),
        padding: spacing(1),
      },
    }),
  { name: 'LoadingIndicator' },
)

export const LoadingIndicator: React.FC = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <CircularProgress size={18} />
      <Text size={11} weight={500} color="grey">
        Loading...
      </Text>
    </Box>
  )
}
