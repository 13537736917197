import { ActionSheetItem, Button, ChipsAutocomplete, ChipsAutocompleteOptionType, ListItemText } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useRef } from 'react'
import * as Yup from 'yup'

export const MOCK_OPTIONS: Array<ChipsAutocompleteOptionType> = [
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Apple',
    value: 'apple',
  },
  {
    label: 'Pineapple',
    value: 'pineapple',
  },
  {
    label: 'Grape Grape Grape',
    value: 'grape',
  },
]

export const getValidationSchema = () =>
  Yup.object().shape({
    multipleDropListButton: Yup.array().of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    ),
  })

export const ChipsAutocompleteExample: React.FC = () => {
  const initialValues: {
    multipleDropListButton: ChipsAutocompleteOptionType[]
  } = { multipleDropListButton: [] }

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ChipsAutocompleteOptionType) => (
    <ActionSheetItem {...props} key={option.value}>
      <ListItemText>{option.label}</ListItemText>
    </ActionSheetItem>
  )

  const ref = useRef()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => console.info('Form submitted with values:', values)}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { values, setFieldValue } = formik
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant="h3" mb={2}>
              Chips Multiple DropList Button
            </Typography>

            <ChipsAutocomplete
              ref={ref.current}
              fullWidth
              renderOption={renderOption}
              isOptionEqualToValue={(elem, value) => elem.value === value.value}
              disableClearable
              placeholder="Select a value"
              chipSize="medium"
              size="medium"
              multiple
              options={MOCK_OPTIONS}
              onChange={(_, newValue) => {
                setFieldValue('multipleDropListButton', newValue)
              }}
              handleChipClick={v => {
                const result = values.multipleDropListButton.filter(elem => elem.value !== v.value)
                setFieldValue('multipleDropListButton', result)
              }}
              value={values.multipleDropListButton}
            />

            <Button sx={{ width: 100 }} type="submit">
              Submit
            </Button>

            <Button color="secondary" sx={{ width: 100 }} onClick={() => formik.resetForm()}>
              Clear
            </Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}
