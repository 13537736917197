import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { TrackersSettingsContext } from 'pages/policyCenter/trackers/context'
import { Spinner, EmptyState, ContentGroup } from '@ketch-com/deck'
import { ReactComponent as EmptyTrackersIcon } from 'assets/icons/emptyState/EmptyStateTrackers.svg'
import { CategoriesToPurposes } from 'pages/policyCenter/trackers/trackersSettings/components'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

export const CategoriesMapping: React.FC = () => {
  const { isReady, trackerCategories } = useContext(TrackersSettingsContext)

  if (!isReady)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  let Component: JSX.Element | JSX.Element[] = (
    <EmptyState
      size="medium"
      customIcon={<EmptyTrackersIcon />}
      title="Looking For Trackers..."
      subTitle="Please wait while we search for trackers on your properties. This process may take some time, and we will notify you once the scan is complete."
    />
  )

  if (trackerCategories?.length) {
    Component = trackerCategories.map(trackersCategory => {
      return <CategoriesToPurposes key={trackersCategory?.code} trackersCategory={trackersCategory} />
    })
  }

  return (
    <Box>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: 'Settings' },
          { title: 'Purposes & Categories Mapping' },
        ]}
      />
      <ContentGroup
        title="Purposes & Categories Mapping"
        subTitle="When new trackers are discovered they can be automatically assigned to a purpose. Create your preferred mappings below to enable automation on discovery."
        titleVariant="h4"
        variant="inner-page-header"
      />
      {Component}
    </Box>
  )
}
