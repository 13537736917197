import { CircularProgress, DropListButton, TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import React, { useContext, useState } from 'react'
import { PreviewDisplayMode } from 'pages/consentAndRights/experiences-v2/view/utils/enums'
import { previewDisplayModeLabels } from 'pages/consentAndRights/experiences-v2/view/utils/labels'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'
import { extractUniqueColors } from 'pages/consentAndRights/themes-v3/upsert/utils/utils'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { ThemeTypeLabels } from 'pages/consentAndRights/themes-v3/upsert/utils/labels'
import { ColorPicker } from 'pages/consentAndRights/themes-v3/upsert/components/ColorPicker'
import { useParams } from 'react-router-dom'
import { IDParams } from 'utils/routing/routes/utils'
import { useTheme } from 'api/themes-v3/queries/useTheme'

const PreviewHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '12px 16px',
  boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10);',
  zIndex: 3,
})

const PreviewHeaderLeftBox = styled(Box)({
  display: 'flex',
  gap: '32px',
  alignItems: 'flex-end',
})

const PreviewHeaderRightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

interface Props {
  isLoading: boolean
}

export const ThemePreviewHeader: React.FC<Props> = ({ isLoading = false }) => {
  const {
    themeType,
    setThemeType,
    displayMode,
    setDisplayMode,
    setIsPreviewReady,
    experienceOptions,
    isExperiencesLoading,
    experience,
    setExperience,
  } = useContext(ExperiencePreviewContext)

  const { id } = useParams<IDParams>()
  const { data: theme } = useTheme({
    params: {
      id: id || '',
      includeMetadata: true,
    },
  })

  const [uniqueColors, setUniqueColors] = useState(extractUniqueColors(theme))

  const handleThemeTypeChange = (event: React.MouseEvent<HTMLElement>, newThemeType: ThemeType) => {
    // Determine which object to calculate colors from
    let sectionConfig
    if (newThemeType === ThemeType.Banner) {
      sectionConfig = theme?.content?.banner
    } else if (newThemeType === ThemeType.Modal) {
      sectionConfig = theme?.content?.modal
    } else {
      sectionConfig = theme?.content?.preference
    }
    const newUniqueColors = extractUniqueColors(sectionConfig)

    // Set new colors and theme type
    setUniqueColors(newUniqueColors)
    setThemeType(newThemeType)
  }

  return (
    <PreviewHeaderBox>
      <PreviewHeaderLeftBox>
        {/* Theme type picker */}
        <TabSegmentedGroup
          value={themeType}
          onChange={handleThemeTypeChange}
          exclusive
          size={'small'}
          sx={{ height: 'fit-content' }}
        >
          {Object.values(ThemeType).map(expType => (
            <TabSegmented value={expType} key={expType}>
              <Typography
                // Different text variant for selected
                variant={themeType === expType ? 'smallLabel' : 'smallBody'}
              >
                {ThemeTypeLabels[expType]}
              </Typography>
            </TabSegmented>
          ))}
        </TabSegmentedGroup>

        {/* Display mode picker */}
        <TabSegmentedGroup
          value={displayMode}
          onChange={(event: React.MouseEvent<HTMLElement>, newDisplayMode: PreviewDisplayMode) => {
            setIsPreviewReady(false)
            setDisplayMode(newDisplayMode)
          }}
          exclusive
          size={'small'}
          sx={{ height: 'fit-content' }}
        >
          {Object.values(PreviewDisplayMode).map(dp => (
            <TabSegmented value={dp} key={dp} sx={{ px: '20px' }}>
              <Typography
                // Different text variant for selected
                variant={dp === displayMode ? 'smallLabel' : 'smallBody'}
              >
                {previewDisplayModeLabels[dp]}
              </Typography>
            </TabSegmented>
          ))}
        </TabSegmentedGroup>

        {/* Experience picker */}
        <DropListButton
          label="Experience"
          size="small"
          width="200px"
          loading={isExperiencesLoading}
          options={experienceOptions ?? []}
          onChange={(_, newValue) => {
            setIsPreviewReady(false)
            setExperience(newValue)
          }}
          value={experience}
          getOptionLabel={t => t.name || 'Unamed Experience'}
        />
      </PreviewHeaderLeftBox>

      <PreviewHeaderRightBox>
        {isLoading && <CircularProgress label={<Typography variant={'label'}>Preview Loading</Typography>} />}
        {/* Theme colors preview */}
        <ColorPicker
          colors={uniqueColors}
          listBreakpoint={7}
          backgroundColors={['#FFFFFF']}
          disableHover
          noDropdown
          noMargin
        />
      </PreviewHeaderRightBox>
    </PreviewHeaderBox>
  )
}
