import React, { SyntheticEvent, useContext } from 'react'
import { SidebarFormGroup } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { FormCheckbox } from 'pages/consentAndRights/experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { FormTextInput } from '../FormTextInput'
import { Chip, theme } from '@ketch-com/deck'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'

export const PreferenceExperienceSubscriptionsTabActionButtonForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showActionTextForm = !getIn(values, experienceUpsertFormKeys.preferenceSubscriptionTabActionButtonUseDefault)

  const handleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.preferenceSubscriptionsTabActionButtonText
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom={showActionTextForm && hasLanguages}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" mb="8px">
            Action
          </Typography>
          <Chip
            label={<Typography variant="label">Save Current State</Typography>}
            sx={{ mb: '16px', width: '150px' }}
          />
          <Box>
            <FormControlLabel
              control={
                <FormCheckbox
                  name={experienceUpsertFormKeys.preferenceSubscriptionTabActionButtonUseDefault}
                  afterChange={handleDefaultChange}
                />
              }
              label={<Typography variant={'label'}>Use Default Text</Typography>}
            />
            <Typography component={'p'} variant="body" color={theme.palette.Text.Secondary}>
              The default text "Save" has translations for all available languages.
            </Typography>
          </Box>
        </Box>
        {showActionTextForm && (
          <FormTextInput
            label={'Action Button Text'}
            name={experienceUpsertFormKeys.preferenceSubscriptionsTabActionButtonText}
            fullWidth
            charLimit={30}
          />
        )}
      </SidebarFormGroup>
      {showActionTextForm && (
        <SidebarFormTranslations
          title={'Action Button Text Translations'}
          name={experienceUpsertFormKeys.preferenceSubscriptionsTabActionButtonText}
          variant={'text'}
          charLimit={30}
        />
      )}
    </>
  )
}
