import { GetTrackerLineagesParams, GetTrackerLineagesResponse } from '@ketch-com/atlas/dist/apiTypes/lineage'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerLineages = ({
  after,
  limit,
  property_code,
  encodedTrackerKey,
}: GetTrackerLineagesParams & {
  encodedTrackerKey?: string
}) => {
  return API.get<GetTrackerLineagesResponse>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/lineage/${encodedTrackerKey}`,
      params: {
        filters: {
          ...(after && {
            after,
          }),
          ...(limit && {
            limit,
          }),
          ...(property_code && {
            property_code,
          }),
        },
      },
    }),
  )
}
