import React, { useContext } from 'react'
import { Formik } from 'formik'
import { RoutesManager } from 'utils/routing/routesManager'
import { TaskDetails, TaskHeaderDetails, TaskDetailsHeaderActions } from './components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'
import { TaskContext } from './context'
import { withTaskContext } from './context/TaskContext'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const TaskWithContext: React.FC = () => {
  const { breadcrumbs, initialValues, isReady, rightInvocationId, validationSchema, workflowStep } =
    useContext(TaskContext)
  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <>
            <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
            <ViewLayout
              contentGroupProps={{
                title: 'Task',
                showBackButton: true,
                propsBackButton: {
                  onClick: () =>
                    navigate(
                      RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }),
                    ),
                },
                imageBlockComponent: <WorkflowStepHeaderIcon />,
                infoBlockComponent: <TaskHeaderDetails workflowStep={workflowStep} />,
                actionRightBlockComponent: <TaskDetailsHeaderActions />,
              }}
              widgetContentComponent={<StepDetailsColumn />}
            >
              <TaskDetails />
            </ViewLayout>
          </>
        )
      }}
    </Formik>
  )
}

export const Task = withTaskContext(TaskWithContext)
