import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import Box from '@mui/material/Box'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useInvokeRight } from 'api/rightInvocations/mutations/useInvokeRight'
import { RoutesManager } from 'utils/routing/routesManager'
import { showToast } from 'components/modals/AlertComponent'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { RightInvocationFormValues } from 'interfaces/rightInvocations'
import { Button, FormRow } from '@ketch-com/deck'

type OptionType = {
  label: string
  value: any
}

const options: OptionType[] = [
  {
    label: 'workflow-test',
    value: {
      applicationCode: 'figurehead',
      applicationEnvironmentCode: 'production',
      controllerCode: '',
      identities: ['srn:::::vara_labs:id/email/jeffrey.andolora@ketch.com'],
      policyScopeCode: 'gdpr_eea',
      rightCodes: ['test'],
      user: {
        email: 'jeffrey.andolora@ketch.com',
        first: 'Workflow',
        last: 'Test',
        country: '',
        stateRegion: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        phone: '',
        description: '',
        dataSubjectTypeCode: 'PetersTestSubject',
        dataSubjectTypeRelationshipDetails: '',
        formData: [],
      },
      dataRole: 2,
    },
  },
  // ... potentially other options ...
]

/**
 * Developer tool to quickly invoke a right from the UI, only rendered in local development via check
 * for localhost in the URL
 */
export const DevQuickCreate: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutateAsync: handleInvokeRight } = useInvokeRight({
    onError: error => {
      console.error('\n', `error = `, error, '\n')
    },
  })
  const [rightInvocationValues, setRightInvocationValues] = React.useState<any>(null)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRightInvocationValues(JSON.parse(event.target.value))
  }

  const onSubmit = async (values: RightInvocationFormValues) => {
    if (!executeRecaptcha) {
      return
    }

    let recaptchaToken = null
    try {
      recaptchaToken = await executeRecaptcha('invokeRight')
    } catch (error) {
      recaptchaToken = ''
    }

    try {
      await handleInvokeRight({
        params: {
          formData: {
            ...rightInvocationValues,
            recaptchaToken,
          },
        },
      })
      await queryClient.refetchQueries(ApiQueryKeys.rightInvocationsPaginated)
      showToast({ content: 'Right was invoked', type: 'success' })
      navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
    } catch (error) {
      showToast({ content: 'Failed to invoke right', type: 'error' })
    }
  }

  const isLocalDevelopment = window?.location?.href?.includes?.('localhost')

  if (!isLocalDevelopment) return null

  return (
    <FormRow>
      <Box display="flex" alignItems="center" gap={4} pl={3}>
        <select
          id="rightOptions"
          style={{
            width: 300,
            height: 40,
          }}
          name="rightOptions"
          onChange={handleSelectChange}
        >
          <option value="">-select-</option>
          {options.map(option => (
            <option key={option.label} value={JSON.stringify(option.value)}>
              {option.label}
            </option>
          ))}
        </select>

        <Button type="button" onClick={() => onSubmit(rightInvocationValues)}>
          Submit
        </Button>
      </Box>
    </FormRow>
  )
}
