import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Cell, Label, Pie, PieChart } from 'recharts'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import { MaybeNull } from 'interfaces'
import { RightStatsDTO } from 'interfaces/rights/rightStats'
import { CircularProgress, CircularProgressSize } from '@ketch-com/deck'
import { COMMON, RIGHTS_STATISTICS } from 'i18n/types'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import pluralize from 'pluralize'

const ContainerBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  background: theme.palette.white.main,
  borderRadius: 11,
  padding: 32,
  height: '100%',
  flex: 1,
}))

const PendingContainerBox = styled(ContainerBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ChartDataBox = styled(Box)(({ theme }) => ({
  marginTop: 40,
  display: 'flex',
  justifyContent: 'space-between',
}))

const ChartDescriptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: 160,
}))

const ChartWrapperBox = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginTop: -10,
}))

type Props = {
  isReady: boolean
  rightsReport: MaybeNull<RightStatsDTO>
}

export const RightsSummaryTotalFulfillment: React.FC<Props> = ({ isReady, rightsReport }) => {
  const theme = useTheme()
  const { t } = useTranslation([RIGHTS_STATISTICS, COMMON])
  const percentage = round(((rightsReport?.onTime || 0) / (rightsReport?.totalInvocations || 1)) * 100)
  const data = [
    { name: 'Total', percent: percentage, value: percentage, color: '#28988B' },
    { name: 'Other', percent: 100 - percentage, value: 100 - percentage, color: '#FFFFFF' },
  ]

  if (!isReady) {
    return (
      <PendingContainerBox>
        <CircularProgress size={CircularProgressSize.xLarge} />
      </PendingContainerBox>
    )
  }

  return (
    <ContainerBox>
      {/* Title */}

      <Typography variant="h3" lineHeight={0.9} mb={0.25}>
        {t('Total Fulfillment')}
      </Typography>

      {/* Subtitle */}

      <Typography variant="body" color={theme.palette.Text.Secondary}>
        {t('How quickly are requests being fulfilled?')}
      </Typography>

      {/* Chart Data */}

      <ChartDataBox>
        {/* Left */}

        <ChartDescriptionBox flexDirection="column">
          {/* Requests Fulfilled On Time */}

          <Typography variant="h3">{percentage}%</Typography>
          <Typography variant="smallLabel" mb={2}>
            {t('Requests Fulfilled On Time')}
          </Typography>

          {/* Average Fulfillment Time */}

          <Typography variant="h3">
            {`${rightsReport?.avgFulfilTime || 0} ${t((rightsReport?.avgFulfilTime || 0) === 1 ? 'day' : 'days', {
              ns: 'common',
            })}`}
          </Typography>
          <Typography variant="smallLabel" mb={2}>
            {t('Average Fulfillment Time')}
          </Typography>

          {/* Median Fulfillment Time */}

          <Typography variant="h3">
            {`${rightsReport?.medialFulfillTime || 0} ${pluralize('day', rightsReport?.medialFulfillTime || 0)}`}
          </Typography>

          <Typography variant="smallLabel">Median Fulfillment Time</Typography>
        </ChartDescriptionBox>

        {/* Right */}

        <ChartWrapperBox>
          <PieChart
            width={116}
            height={116}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Pie
              data={data}
              cx={58}
              cy={58}
              innerRadius={35}
              outerRadius={55}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="percent"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              <Label position="center" fontWeight={700} fontSize={20}>
                {`${percentage}%`}
              </Label>
            </Pie>
          </PieChart>
        </ChartWrapperBox>
      </ChartDataBox>
    </ContainerBox>
  )
}
