import { Formik } from 'formik'

// components
import { RoutesManager } from 'utils/routing/routesManager'
import { AppDetails, AppDetailsHeaderDetails, AppDetailsHeaderActions } from './components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'

// types
import { useAppViewUtils } from './hooks'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

export const AppView: React.FC = () => {
  const {
    isReady,
    resolve,
    breadcrumbs,
    isAssignedToMe,
    validationSchema,
    initialValues,
    isCompleted,
    workflowStep,
    rightInvocationId,
    stepDetails,
    resolutionAttachments,
    setIsResolving,
    isResolving,
    showRequiredFields,
    setShowRequiredFields,
  } = useAppViewUtils()

  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {}}
      validateOnMount
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, errors }) => (
        <>
          <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
          <ViewLayout
            contentGroupProps={{
              title: workflowStep?.name,
              showBackButton: true,
              propsBackButton: {
                onClick: () =>
                  navigate(
                    RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId }),
                  ),
              },
              imageBlockComponent: <WorkflowStepHeaderIcon />,
              infoBlockComponent: <AppDetailsHeaderDetails workflowStep={workflowStep} />,
              actionRightBlockComponent: (
                <AppDetailsHeaderActions
                  canSubmit={!isSubmitting && isValid && isAssignedToMe && !isCompleted}
                  setShowRequiredFields={setShowRequiredFields}
                  isAssignedToMe={isAssignedToMe}
                  errors={errors}
                  resolve={resolve}
                  workflowStep={workflowStep}
                  stepDetails={stepDetails}
                  setIsResolving={setIsResolving}
                  isResolving={isResolving}
                />
              ),
            }}
            widgetContentComponent={<StepDetailsColumn />}
          >
            <AppDetails
              resolutionAttachments={resolutionAttachments}
              isAssignedToMe={isAssignedToMe}
              stepDetails={stepDetails}
              showRequiredFields={showRequiredFields}
            />
          </ViewLayout>
        </>
      )}
    </Formik>
  )
}
