import { Banner, Button, PopUp, theme } from '@ketch-com/deck'
import { memo, useEffect, useState } from 'react'
import { ConfigItemDisplay } from '../../components'
import { useCreateApplianceToken } from 'api/dataSystems/mutations/useCreateApplianceToken'
import { showToast } from 'components/modals/AlertComponent'
import { useVisitTransponderDocumentation } from '../../hooks/useTransponderDocumentation'
import { Box, Typography } from '@mui/material'

export interface TransponderKeyModalProps {
  handleClose: () => void
  applianceId: string
  enableQuery?: boolean
}

export const TransponderKeyModal: React.FC<TransponderKeyModalProps> = memo(({ handleClose, applianceId }) => {
  const handleVisitTransponderDocumentation = useVisitTransponderDocumentation()

  const [transponderToken, setTransponderToken] = useState('')
  const [enableQuery, setEnableQuery] = useState(false)

  const {
    mutateAsync: handleCreateApplianceToken,
    isError,
    isLoading,
  } = useCreateApplianceToken({
    onSuccess: ({ data }) => {
      setTransponderToken(data.token)
      showToast({ content: 'New transponder token generated!', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to generate transponder token', type: 'error' })
    },
  })

  useEffect(() => {
    if (enableQuery && applianceId) {
      handleCreateApplianceToken({ params: { applianceId } })
    }
  }, [applianceId, enableQuery, handleCreateApplianceToken])

  const onHandleClose = () => {
    handleClose()
    setEnableQuery(false)
  }

  return (
    <PopUp
      variant="dialog"
      title="Transponder Key"
      subTitle="Within your transponder, click Add Organization and paste this key as the Transponder Key."
      onClose={onHandleClose}
      popUpActionChildren={
        <>
          <Button
            color="secondary"
            size="large"
            onClick={() => {
              handleVisitTransponderDocumentation()
            }}
          >
            Read Documentation
          </Button>
          <Button color="primary" size="large" onClick={onHandleClose}>
            Close
          </Button>
        </>
      }
    >
      {enableQuery ? (
        <>
          <ConfigItemDisplay
            alwaysVisible
            onRegenerate={() => {
              handleCreateApplianceToken({ params: { applianceId } })
            }}
            isLoading={isLoading}
            type="token"
            value={transponderToken}
            error={isError}
            name="Transponder Key"
          />
          <ConfigItemDisplay alwaysVisible type="token" value={applianceId} name="Transponder Appliance Id" />
        </>
      ) : (
        <Box
          borderBottom={`1px solid ${theme.palette.Common.Divider}`}
          borderTop={`1px solid ${theme.palette.Common.Divider}`}
          padding={2}
        >
          <Banner
            severity="warning"
            action={
              <Button variant="link" sx={{ whiteSpace: 'nowrap' }} onClick={() => setEnableQuery(true)}>
                Confirm
              </Button>
            }
            title="Are you sure you want to generate a new key?"
          >
            <Typography variant="body">
              Generating a new transponder key will invalidate the current key if one exists and any connection
              associated with it.
            </Typography>
          </Banner>
        </Box>
      )}
    </PopUp>
  )
})
