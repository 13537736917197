import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useFiltersV3 } from 'utils/hooks'
import { Icon, TextInput } from '@ketch-com/deck'
import { debounce } from 'lodash'

export const useRightsQueueSearchInputUtils = () => {
  const { removeSingleSearchParam, replaceParams, reduxSearchParams } = useFiltersV3()
  const [searchString, setSearchString] = useState<string>(() => {
    return reduxSearchParams?.query || ''
  })

  /* debounce search input updating search params */
  useDebounce(
    () => {
      if (searchString) {
        replaceParams({ query: searchString })
      }
      if (!searchString) {
        removeSingleSearchParam('query')
      }
    },
    500,
    [searchString],
  )

  useEffect(() => {
    if (!reduxSearchParams?.query) {
      setSearchString(reduxSearchParams?.query || '')
    }
  }, [reduxSearchParams?.query])

  return {
    setSearchString,
    searchString,
  }
}

type Props = {
  handlePaginationReset: () => void
}

export const RightsQueueSearchInput: React.FC<Props> = ({ handlePaginationReset }) => {
  // we reset pagination if we search using search bar
  const debounceFn = useMemo(
    () =>
      debounce(() => {
        handlePaginationReset()
      }, 500),
    [handlePaginationReset],
  )

  const { setSearchString, searchString } = useRightsQueueSearchInputUtils()
  return (
    <TextInput
      sx={{
        width: 300,
      }}
      inputWidth="300px"
      value={searchString}
      startIcon={<Icon name="OMag" />}
      endIcon={
        searchString && (
          <Icon
            sx={{ cursor: 'pointer' }}
            name="OCross"
            onClick={() => {
              setSearchString('')
              handlePaginationReset()
            }}
          />
        )
      }
      size="small"
      placeholder="Request ID, Subject Name, or Email"
      onChange={event => {
        setSearchString(event.target.value)
        debounceFn()
      }}
    />
  )
}
