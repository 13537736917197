import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { ThirdPartyRisksHighlight, ThirdPartyRisksItems } from './components'

const PREFIX = 'ThirdPartyRisks'

const classes = {
  thirdPartyRisksContainer: `${PREFIX}-thirdPartyRisksContainer`,
  thirdPartyRisksLabel: `${PREFIX}-thirdPartyRisksLabel`,
  thirdPartyRisksItemsContainer: `${PREFIX}-thirdPartyRisksItemsContainer`,
  thirdPartyRisksHighlightContainer: `${PREFIX}-thirdPartyRisksHighlightContainer`,
  thirdPartyRisksItemContainer: `${PREFIX}-thirdPartyRisksItemContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.thirdPartyRisksContainer}`]: {
    margin: spacing(5, 0),
  },

  [`& .${classes.thirdPartyRisksLabel}`]: {
    fontSize: 28,
    fontWeight: 800,
    color: 'rgba(7, 26, 36, 0.5)',
  },

  [`& .${classes.thirdPartyRisksItemsContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 510,
    marginTop: spacing(2),
  },

  [`& .${classes.thirdPartyRisksHighlightContainer}`]: {
    flexBasis: 416,
    flexGrow: 0,
    backgroundColor: `${palette.sphere.main}`,
    borderRadius: spacing(1.5),
  },

  [`& .${classes.thirdPartyRisksItemContainer}`]: {
    flexBasis: 848,
    flexGrow: 0,
    backgroundColor: `${palette.white.main}`,
    borderRadius: spacing(1.5),
  },
}))

//TODO: Complete UI
export const ThirdPartyRisks: React.FC = () => {
  return (
    <StyledBox className={classes.thirdPartyRisksContainer}>
      <Text className={classes.thirdPartyRisksLabel}>3rd Party Risk</Text>
      <Box className={classes.thirdPartyRisksItemsContainer}>
        <ThirdPartyRisksHighlight />
        <ThirdPartyRisksItems />
      </Box>
    </StyledBox>
  )
}
