import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { Text } from 'components/ui-kit/typography/Text'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import { Button } from '@ketch-com/deck'
import { Link } from 'components/ui-kit/link/Link'
import { useAuditLogInteractions } from '../hooks'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/interactions_empty_state_icon.svg'

const PREFIX = 'InteractionsLog'

const classes = {
  container: `${PREFIX}-container`,
  pendingContainer: `${PREFIX}-pendingContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerSubTitle: `${PREFIX}-headerSubTitle`,
  emptyState: `${PREFIX}-emptyState`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    boxSizing: 'border-box',
    background: theme.palette.white.main,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(4),
    height: 464,
    flex: 1,
    overflow: 'hidden',
  },

  [`&.${classes.pendingContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.headerTitle}`]: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.headerSubTitle}`]: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.emptyState}`]: {
    height: '80%',
  },
}))

export const AuditLogsInteractionsLog: React.FC = () => {
  const interaction = useAuditLogInteractions()

  if (interaction.isLoading) {
    return (
      <Root className={clsx(classes.container, classes.pendingContainer)}>
        <Spinner />
      </Root>
    )
  }

  return (
    <Root className={classes.container}>
      <Text size={20} weight={700} className={classes.headerTitle}>
        Interactions Log
      </Text>

      <Text size={14} color="grey" className={classes.headerSubTitle}>
        What interactions lead to the current state of permits and how were the rights invoked?
      </Text>

      {interaction.data.length === 0 ? (
        <EmptyState
          className={classes.emptyState}
          variant="page"
          bgVariant="white"
          icon={<EmptyStateIcon />}
          title="No interactions yet"
          description="Interactions will be displayed here when they have been created"
        />
      ) : null}

      {interaction.data.length > 0 ? (
        <TablePaginated
          pending={!!interaction.isLoading}
          pagination={interaction.pagination}
          items={interaction.data}
          isSmallHeaderText
          shouldRemoveTableRowExpandContentPadding
          cellSettings={{
            interaction: {
              width: 180,
              label: 'Interaction',
              cellRenderer: ({ type }) => <TextCellRenderer value={type} />,
            },
            date: {
              width: 180,
              label: 'Date',
              cellRenderer: ({ date }) => <FormattedDateRenderer date={date} />,
            },
            jurisdiction: {
              width: 250,
              label: 'Jurisdiction',
              cellRenderer: ({ jurisdiction }) => (
                <Link
                  outer
                  variant="blue"
                  size="regular"
                  // TODO:BAC verify link goes where it should when real data flows
                  //https://app.ketch.com/policy-center/policy-scopes/view/{jurisdiction.code}/rights
                  to={RoutesManager.policyCenter.policyScopes.view.rights.root.getURL({ code: jurisdiction?.code })}
                >
                  {jurisdiction?.name}
                </Link>
              ),
            },
            deploymentPlan: {
              width: 250,
              label: 'Deployment Plan',
              cellRenderer: ({ deployment }) =>
                deployment?.isDeleted ? (
                  <Tooltip content={`${deployment.name} has been deleted`}>
                    <TextCellRenderer value={`${deployment.name}`} />
                  </Tooltip>
                ) : (
                  <Link
                    outer
                    variant="blue"
                    size="regular"
                    // TODO:BAC verify link goes where it should when real data flows
                    // https://app.ketch.com/deployment/deployment-plans/view/{deployment.code}/overview
                    to={RoutesManager.deployment.deploymentPlans.view.overview.root.getURL({ code: deployment?.code })}
                  >
                    {deployment?.name}
                  </Link>
                ),
            },
            experience: {
              width: 250,
              label: 'Experience',
              cellRenderer: ({ experience }) => (
                <Link
                  outer
                  variant="blue"
                  size="regular"
                  // TODO:BAC verify link goes where it should when real data flows
                  // https://app.ketch.com/deployment/experiences/view/{experience.code}/overview
                  to={RoutesManager.deployment.experiences.view.root.getURL({ code: experience?.code })}
                >
                  {experience?.name}
                </Link>
              ),
            },
            actions: {
              label: '',
              cellRenderer: ({ experience }) => (
                <Link
                  variant="buttonWrap"
                  to={RoutesManager.admin.skus.skus.view.root.getURL({ id: experience?.code })}
                >
                  <Button color="tertiary" onClick={() => {}}>
                    Preview
                  </Button>
                </Link>
              ),
            },
          }}
        />
      ) : null}
    </Root>
  )
}
