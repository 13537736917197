import React, { SyntheticEvent, useContext, useEffect } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { TabGroup, Tab, TabPanel, theme, Icon, Button, DropListButton } from '@ketch-com/deck'
import { FormTextInput } from '../FormTextInput'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { getIn, useFormikContext } from 'formik'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { FormCheckbox } from '../FormCheckbox'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { RightsFormMode } from '@ketch-sdk/ketch-types'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { RightsFormModeLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { RightsMappingDragList } from '../RightsMappingDragList'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { OptionType } from 'interfaces/optionType'
import { PreferenceExperienceBuilderSection } from '../../../builder/utils/enums'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

export const PreferenceExperienceRequestsTabHomeRightsListForm: React.FC = () => {
  // Form info
  const { values, setFieldValue, errors } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading, formTemplateOptions, isFormTemplatesLoading, experienceBuilderSection } =
    useContext(ExperienceUpsertContext)
  const isFormsWithinExperienceServerRelease = isRelease(Release.FormsWithinExperienceServer)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showTitleForm = !getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitleUseDefaultText)
  const isSingleMode =
    getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode) === RightsFormMode.Single
  const singleFormID = getIn(values, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID)
  const singleFormValue = formTemplateOptions.find(form => form.value === singleFormID)
  const singleFormError = getIn(errors, experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID)

  // Current tab of the form
  const [tab, setTab] = React.useState(
    experienceBuilderSection === PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes ? 1 : 0,
  )

  const handleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset to english default
      const englishFieldToReset = experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitle
      const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
      setFieldValue(englishFieldToReset, defaultEnglishValue)

      // Reset to default for each locale
      languages.forEach(({ language: { code } }) => {
        const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
        const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
        setFieldValue(localeFieldToReset, defaultLocaleValue)
      })
    }
  }

  const handleFormCreateClick = () => {
    window.open(
      RoutesManager.deployment.forms.templates.upsert.root.getURL({
        id: NEW_ITEM_ID,
        formMode: FormMode.CREATE,
      }),
      '_blank',
    )
  }

  const handleSingleFormChange = (newValue: OptionType | null) => {
    setFieldValue(experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsSingleFormID, newValue?.value)
  }

  // Changes tab on error
  useEffect(() => {
    setTab(experienceBuilderSection === PreferenceExperienceBuilderSection.RequestsHomeRightsListRequestTypes ? 1 : 0)
  }, [experienceBuilderSection])

  return (
    <SidebarFormGroup sx={{ pt: 0 }}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabGroup value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
            <Tab size={'medium'} label="Title" />
            <Tab size={'medium'} label="Request Types" />
          </TabGroup>
        </Box>

        {/* Title */}
        <TabPanel value={tab} index={0} sx={{ px: 0, pt: '12px' }}>
          <SidebarFormGroup sx={{ pt: 0 }} hasBorderBottom={showTitleForm && hasLanguages}>
            <Box display="flex" flexDirection="column">
              <Box>
                <FormControlLabel
                  control={
                    <FormCheckbox
                      name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitleUseDefaultText}
                      afterChange={handleDefaultChange}
                    />
                  }
                  label={<Typography variant={'label'}>Use Default Text</Typography>}
                />
                <Typography component="p" variant="body" color={theme.palette.Text.Secondary}>
                  The default text "Select a Request Type" has translations for all available languages.
                </Typography>
              </Box>
            </Box>
            {showTitleForm && (
              <FormTextInput
                label={'Title Text'}
                name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitle}
                fullWidth
                charLimit={200}
              />
            )}
          </SidebarFormGroup>
          {showTitleForm && (
            <SidebarFormTranslations
              title={'Title Text Translations'}
              name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsTitle}
              variant={'text'}
              charLimit={200}
            />
          )}
        </TabPanel>

        {/* Request Types */}
        <TabPanel value={tab} index={1} sx={{ px: 0, pt: '12px' }}>
          {!isFormsWithinExperienceServerRelease && (
            <SidebarFormGroup hasBorderBottom sx={{ paddingTop: '16px', gap: '12px' }}>
              {/* Form mode radios */}
              <SidebarFormRadioGroup
                name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsMode}
                options={Object.values(RightsFormMode).map(mode => ({
                  label: RightsFormModeLabels[mode],
                  value: mode,
                }))}
                row
              />
              {isSingleMode ? (
                <DropListButton
                  size={'medium'}
                  label="Form"
                  placeholder="Select Form"
                  options={formTemplateOptions}
                  value={singleFormValue}
                  loading={isFormTemplatesLoading}
                  onChange={(e, newValue) => handleSingleFormChange(newValue)}
                  error={!!singleFormError}
                  errorMessage={singleFormError}
                />
              ) : (
                // Custom form mapping
                <RightsMappingDragList variant={RightsFormMode.Custom} />
              )}
            </SidebarFormGroup>
          )}
          {isFormsWithinExperienceServerRelease ? (
            <SidebarFormGroup sx={{ paddingTop: 1.5 }}>
              <Box display="flex" gap={0.75}>
                <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
                <Typography color={theme.palette.Text.Secondary}>
                  Drag the tiles below to customize the order in which each right grouping will appear in the Rights
                  Form.
                </Typography>
              </Box>
              <RightsMappingDragList variant={RightsFormMode.Single} shouldOpened={false} />
            </SidebarFormGroup>
          ) : (
            <SidebarFormGroup>
              {isSingleMode ? (
                // Single form ordering
                <>
                  <Box display="flex" alignItems="flex-start" gap={'6px'}>
                    <Icon name={'FInfo'} iconColor={theme.palette.Text.Secondary} width={20} height={20} />
                    <Typography variant={'body'} color={theme.palette.Text.Secondary}>
                      Drag the tiles below to customize the order in which each right grouping will appear in the Rights
                      Form.
                    </Typography>
                  </Box>
                  <RightsMappingDragList variant={RightsFormMode.Single} />
                </>
              ) : (
                // Create form link
                <Box>
                  <Typography component={'p'} variant={'body'} mb={1}>
                    Need to create a form? Click here:
                  </Typography>
                  <Button
                    onClick={handleFormCreateClick}
                    color={'tertiary'}
                    endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
                  >
                    Create Form
                  </Button>
                </Box>
              )}
            </SidebarFormGroup>
          )}
        </TabPanel>
      </Box>
    </SidebarFormGroup>
  )
}
