import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { Tab, TabGroup, CircularProgress, CircularProgressSize, EmptyState } from '@ketch-com/deck'
import { Bar, ComposedChart, Tooltip, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts'
// TODO:BAC - de-scoped, coming soon...
// import { CSVLink } from 'react-csv'

import { ChartTrendPeriod } from 'interfaces/rights/rightStats'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { Legend, PermitsTrendChartTooltip } from './components'
import { updateTrendPeriod } from 'store/permitStatisticsFilterStateSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { usePermitsTrendChartUtils } from './hooks'
import { formatCount, formatCountWithoutDecimals } from '../PermitStatisticsOverall/utils'
import { differenceInDays } from 'date-fns'
import { getXAxisDataKey, getXAxisInterval } from './utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

const PREFIX = 'PermitsTrendChart'

const classes = {
  chartWrapper: `${PREFIX}-chartWrapper`,
  legend: `${PREFIX}-legend`,
  legendIcon: `${PREFIX}-legendIcon`,
  legendIconSquare: `${PREFIX}-legendIconSquare`,
  legendIconCircle: `${PREFIX}-legendIconCircle`,
  legendIconLine: `${PREFIX}-legendIconLine`,
  legendIconDashedLine: `${PREFIX}-legendIconDashedLine`,
  legendText: `${PREFIX}-legendText`,
  legendItem: `${PREFIX}-legendItem`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.chartWrapper}`]: {
    display: 'flex',
    flex: 1,
    height: '100%',
    marginRight: 35,
  },

  [`& .${classes.legend}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 560,
    height: 22,
  },

  [`& .${classes.legendIcon}`]: {
    marginRight: 10,
  },

  [`& .${classes.legendIconSquare}`]: {
    width: 16,
    height: 16,
    borderRadius: 2,
  },

  [`& .${classes.legendIconCircle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },

  [`& .${classes.legendIconLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px solid',
  },

  [`& .${classes.legendIconDashedLine}`]: {
    width: 16,
    height: 1,
    borderBottom: '2px dashed',
  },

  [`& .${classes.legendText}`]: {},

  [`& .${classes.legendItem}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.tabs}`]: {
    width: '100%',
    borderBottom: `1px solid ${palette.iron.main}`,
  },

  [`& .${classes.tab}`]: {
    paddingLeft: '10px',
  },
}))

export const PermitsSummaryTrend: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const { trendPeriod, startDate, endDate } = useAppSelector(getPermitStatisticsFilterState)
  const startEndDifferenceInDays = Math.abs(differenceInDays(new Date(startDate), new Date(endDate)))
  const handleTabChange = (event: React.SyntheticEvent, newTab: ChartTrendPeriod) => {
    dispatch(updateTrendPeriod(newTab))
  }

  const {
    monthlyData,
    aggregateAllowanceRate,
    reshapedDailyTrendData,
    dailyAggregateData,
    aggregateTotal,
    aggregateAllowed,
    isLoadingTrendData,
    shouldShowEmptyState,
    // TODO:BAC - de-scoped, coming soon
    // csvData,
  } = usePermitsTrendChartUtils()

  const shouldHideTrendLines =
    (trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE && monthlyData.length === 1) ||
    (trendPeriod === ChartTrendPeriod.DAILY && reshapedDailyTrendData.length === 1)

  return (
    <StyledBox
      sx={{
        background: theme => theme.palette.white.main,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 549,
        padding: 4,
        width: 416,
        flex: 1,
      }}
    >
      <Box display="flex" flexDirection="column" mb={2}>
        {/* Row 1: Header */}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h3">{t('Trend')}</Typography>
            <Typography variant="body" color="darkDuskFaded.main">
              {t('How are stats changing over time?')}
            </Typography>
          </Box>
          {/* // TODO:BAC - de-scoped, coming soon... */}
          {/* <CSVLink data={csvData} filename={'todo-custom-file-name-based-on-filters-and-date.csv'} style={{ textDecoration: 'none' }}>
            <Button>Export</Button>
          </CSVLink> */}
        </Box>
      </Box>

      {/* Row 2: Tabs and Date Range Input */}

      <Box>
        <Box display="flex" alignItems="flex-end">
          <Box flex={1}>
            <TabGroup value={trendPeriod} onChange={handleTabChange}>
              <Tab
                className={classes.tab}
                value={ChartTrendPeriod.DAILY}
                label={t('Daily', { ns: 'common' })}
                key="daily"
              />
              <Tab
                className={classes.tab}
                value={ChartTrendPeriod.MONTHLY_PAST_TWELVE}
                label={t('Monthly', { ns: 'common' })}
                key="monthly"
              />
            </TabGroup>
          </Box>
          <Box
            sx={{
              pl: 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          ></Box>
        </Box>
      </Box>

      {isLoadingTrendData ? (
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 2,
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={CircularProgressSize.xLarge} />
        </Box>
      ) : shouldShowEmptyState || (reshapedDailyTrendData || [])?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <EmptyState colorTheme="darkDuskFaded" iconName="OFilter" title="No data for selected filter criteria" />
        </Box>
      ) : (
        <>
          {/* Row 3: Legend */}

          <Legend reshapedDailyTrendData={reshapedDailyTrendData} />

          {/* Row 3: Chart and Summary */}

          <Box
            sx={{
              flex: 1,
              display: 'flex',
            }}
          >
            {/* Column 1: Chart */}

            <Box className={classes.chartWrapper}>
              <ResponsiveContainer>
                <ComposedChart
                  data={trendPeriod === ChartTrendPeriod.MONTHLY_PAST_TWELVE ? monthlyData : reshapedDailyTrendData}
                  margin={{
                    top: 10,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                  barSize={40}
                >
                  <XAxis
                    tickLine={false}
                    padding={{ left: 30, right: 30 }}
                    dataKey={getXAxisDataKey({ trendPeriod, startEndDifferenceInDays })}
                    stroke="black"
                    interval={getXAxisInterval({ startEndDifferenceInDays, trendPeriod })}
                  />
                  <YAxis
                    tickLine={false}
                    width={65}
                    domain={[
                      0,
                      (dataMax: any) => {
                        const roundedDataMax = Math.round(dataMax / 10) * 10
                        const roundedYAxisBuffer = Math.round(Math.floor(dataMax * 0.3) / 10) * 10
                        return roundedDataMax + roundedYAxisBuffer
                      },
                    ]}
                    stroke="black"
                    tickFormatter={value => {
                      return formatCountWithoutDecimals(value).toString()
                    }}
                  />

                  <Bar dataKey="consent" stackId="bars" fill="#15005A" />
                  <Bar dataKey="noConsentRequired" stackId="bars" fill="#3EB3CD" />
                  <Bar dataKey="noConsent" stackId="bars" fill="#D1E0ED" />

                  {!shouldHideTrendLines && (
                    <Line type="linear" dataKey="consentLine" stroke="#1463BB" strokeWidth={2} dot={false} />
                  )}

                  {/* Total, dashed line */}

                  {!shouldHideTrendLines && (
                    <Line type="linear" dataKey="overallLine" stroke="#9C64FF" strokeWidth={2} dot={false} />
                  )}

                  <Tooltip
                    content={props => {
                      return (
                        <PermitsTrendChartTooltip
                          active={props.active}
                          label={props.label}
                          payload={props.payload}
                          trendPeriod={trendPeriod}
                        />
                      )
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>

            {/* Column 2: Summary */}

            <Box
              sx={{
                width: 180,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                pb: 4,
              }}
            >
              {/* Allowance Rate */}

              <Box display="flex" flexDirection="column" mb={4}>
                <Typography variant="h3" sx={{ mb: 0.5 }}>
                  {aggregateAllowanceRate.toFixed(1)}%
                </Typography>
                <Typography variant="smallLabel">{t('Allowance Rate')}</Typography>
              </Box>

              {/* Total */}

              <Box display="flex" flexDirection="column" mb={4}>
                <Typography variant="h3" sx={{ mb: 0.5 }}>
                  {formatCount(aggregateTotal)}
                </Typography>
                <Typography variant="smallLabel">{t('Total')}</Typography>
              </Box>

              {/* Allowed */}

              <Box display="flex" flexDirection="column">
                <Typography variant="h3" sx={{ mb: 1 }} color="black">
                  {formatCount(aggregateAllowed)}
                </Typography>
                <Typography variant="smallLabel" sx={{ mb: 0.5 }}>
                  {t('Allowed')}
                </Typography>
                <Typography variant="smallLabel" color="graph13.main" sx={{ mb: 0.5 }}>
                  {t('Consent Not Required')}: {formatCount(dailyAggregateData?.consentNotRequired || 0)}
                </Typography>
                <Typography variant="smallLabel" color="graph10.main">
                  {t('Consent')}: {formatCount(dailyAggregateData?.consent || 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </StyledBox>
  )
}
