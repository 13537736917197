import React, { HTMLAttributes, ReactNode, useCallback } from 'react'
import { DropListButton, ListItemText, ActionSheetItem, Icon, theme } from '@ketch-com/deck'
import { FieldValidator, useField } from 'formik'
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  InputLabel,
  PopperProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  AutocompleteProps,
} from '@mui/material'
import { ObjectLiteral } from 'interfaces'

interface FormDropdownProps<T, K extends ObjectLiteral = ObjectLiteral> {
  disabled?: boolean
  formPropertyName: keyof T
  fullWidth?: boolean
  items: K[]
  label?: string
  onItemClick?: (v: K, e?: React.SyntheticEvent<Element, Event>, reason?: AutocompleteChangeReason) => void
  validate?: FieldValidator
  sx?: SxProps<Theme>
  valueKey: string
  labelValueKey?: string
  placeholder?: string
  required?: boolean
  size?: 'small' | 'medium'
  isOptionEqualToValue?: (option: K, value: K) => boolean
  hideOptionalLabel?: boolean
  disableClearable?: boolean
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: K,
    state: AutocompleteRenderOptionState,
    isSelected?: boolean,
  ) => ReactNode
  getOptionDisabled?: (option: K) => boolean
  getOptionLabel?: (option: K) => string
  renderInput?: (params: AutocompleteRenderInputParams, currentValue: K | null, error?: string) => React.ReactNode
  labelTooltipText?: string | ReactNode
  width?: string
  PopperComponent?: React.JSXElementConstructor<PopperProps>
  componentsProps?: AutocompleteProps<any, any, any, any>['componentsProps']
  checkSelectedValueAsString?: boolean
}
export const FormDroplistButton = <T, K extends ObjectLiteral = ObjectLiteral>({
  disabled,
  formPropertyName,
  fullWidth,
  items,
  placeholder,
  onItemClick,
  validate,
  valueKey,
  labelValueKey = 'name',
  sx,
  required,
  size = 'medium',
  label,
  isOptionEqualToValue,
  hideOptionalLabel,
  disableClearable,
  renderOption,
  getOptionDisabled,
  getOptionLabel,
  renderInput,
  labelTooltipText,
  width,
  PopperComponent,
  componentsProps,
  checkSelectedValueAsString,
}: FormDropdownProps<T, K>) => {
  const [field, { error, touched }, helpers] = useField({ name: formPropertyName as string, validate: validate })
  let selectedValue: K | null
  if (checkSelectedValueAsString) {
    selectedValue = items.find(item => item[valueKey] === field.value || item[valueKey] === String(field.value)) || null
  } else {
    selectedValue = items.find(item => item[valueKey] === field.value) || null
  }

  const showError = error && touched

  const onChangeHandler = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      newValue: K | NonNullable<K> | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (newValue) {
        const currentItem = items.find(item => item[valueKey] === newValue[valueKey])
        if (currentItem) {
          helpers.setValue(currentItem[valueKey])
          if (onItemClick) {
            helpers.setValue(currentItem[valueKey])
            onItemClick(currentItem, e, reason)
          }
        }
      }
    },
    [helpers, items, onItemClick, valueKey],
  )

  const internalRenderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: K, state: AutocompleteRenderOptionState) => {
      if (renderOption) {
        return renderOption(props, option, state, !!selectedValue && option[valueKey] === selectedValue[valueKey])
      }

      return (
        <ActionSheetItem
          {...props}
          key={option[valueKey]}
          selected={!!selectedValue && option[valueKey] === selectedValue[valueKey]}
        >
          <ListItemText selected={!!selectedValue && option[valueKey] === selectedValue[valueKey]}>
            {option[labelValueKey]}
          </ListItemText>
        </ActionSheetItem>
      )
    },
    [labelValueKey, renderOption, selectedValue, valueKey],
  )
  return (
    <>
      {!!label && (
        <InputLabel
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 0.5,
          }}
        >
          {required ? (
            <Typography variant="label" color="darkDusk.main">
              {label}
            </Typography>
          ) : (
            <Box display="inline-flex" alignItems="center" gap={0.5}>
              <Typography variant="label" color="darkDusk.main">
                {label}
              </Typography>
              {!hideOptionalLabel && (
                <Typography variant="label" color="darkGrey.main">
                  {'(Optional)'}
                </Typography>
              )}
            </Box>
          )}
          {labelTooltipText && (
            <Tooltip title={labelTooltipText}>
              <Box display="inline-flex" alignItems="center" ml={0.5}>
                <Icon name="FImportant" iconColor={theme.palette.Text.Secondary} width={16} height={16} />
              </Box>
            </Tooltip>
          )}
        </InputLabel>
      )}

      <DropListButton
        renderInput={
          renderInput
            ? params => renderInput(params, selectedValue, required && showError ? error : undefined)
            : undefined
        }
        size={size}
        fullWidth={fullWidth}
        disabled={disabled}
        // this is hacky, but if we want a controlled component, which can also accept null as a value, and to also have the disableClearable prop
        // we need to do smth like this, see https://github.com/mui/material-ui/issues/33661 for more details
        disableClearable={disableClearable ? selectedValue !== null : false}
        options={items}
        sx={sx}
        error={!!required && !!showError}
        errorMessage={error}
        placeholder={placeholder}
        getOptionLabel={option => (getOptionLabel ? getOptionLabel(option) : option[labelValueKey])}
        onBlur={field.onBlur}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={onChangeHandler}
        value={selectedValue}
        renderOption={internalRenderOption}
        getOptionDisabled={getOptionDisabled}
        width={width}
        PopperComponent={PopperComponent}
        componentsProps={componentsProps}
      />
    </>
  )
}
