import React from 'react'
import { AccessAndUsageRisks, DataRisksIntelligence, SecurityRisks, ThirdPartyRisks } from './components'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { Box } from '@mui/material'

export const DataRisks: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white.main',
          mt: -3,
        }}
      >
        <ContentBoundaries>
          <DataRisksIntelligence />
        </ContentBoundaries>
      </Box>
      <ContentBoundaries>
        <AccessAndUsageRisks />
        <ThirdPartyRisks />
        <SecurityRisks />
      </ContentBoundaries>
    </>
  )
}
