import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchPlan, FetchPlanParams } from '../fetchers/fetchPlan'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.orgPlan,
  queryFn: fetchPlan,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery> & FetchPlanParams

export const usePlan = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
    enabled: config?.enabled,
  })
}
