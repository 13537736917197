import * as Yup from 'yup'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'

export const getScheduleValidationSchema = () =>
  Yup.object().shape({
    schedule: Yup.object().shape({
      time: Yup.string().test('validate-time', 'Must be 5 characters or less and match the format H:MM', value => {
        if (!value || value.trim() === '') {
          return true // Allow undefined or empty string
        }
        const maxLengthValid = value.length <= 5
        const matchesPattern = validationRegExp.TIME.test(value)
        return maxLengthValid && matchesPattern
      }),
      period: Yup.string(),
      frequency: Yup.string(),
      day: Yup.string().when('frequency', {
        is: '2',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      sampleSize: Yup.number()
        .optional()
        .min(100, 'Sample size must be at least 100')
        .max(100000, 'Sample size must not exceed 100000'),
      executionDuration: Yup.string()
        .optional()
        .matches(
          /^(1[0-9]|2[0-4]|[1-9])h$/,
          'Execution duration must be a valid hour string inclusively between 1h and 24h',
        ),
    }),
  })
