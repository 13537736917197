import { Button, InlineEdit } from '@ketch-com/deck'
import { Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

export const getValidationSchema = () =>
  Yup.object().shape({
    inlineEditField: Yup.string().required(),
    inlineEditFieldGhost: Yup.string().required(),
    inlineEditActionsField: Yup.string().required(),
  })

export const InlineEditExample: React.FC = () => {
  return (
    <Formik
      initialValues={{ inlineEditField: '', inlineEditFieldGhost: '', inlineEditActionsField: '' }}
      onSubmit={values => console.info('Form submitted with values:', values)}
      validationSchema={getValidationSchema()}
    >
      {formik => {
        const { values, setFieldValue, errors } = formik
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography variant="h3" mb={2}>
              Inline Edit
            </Typography>

            <Typography variant="body" mt={1}>
              Outlined Inline Edit
            </Typography>
            <InlineEdit
              placeholder="InlineEdit"
              onChange={e => setFieldValue('inlineEditField', e.target.value)}
              value={values.inlineEditField}
              validationText={errors.inlineEditField}
            />

            <Typography variant="body" mt={1}>
              Ghost Inline Edit
            </Typography>
            <InlineEdit
              textInputVariant="ghost"
              placeholder="InlineEdit"
              onChange={e => setFieldValue('inlineEditFieldGhost', e.target.value)}
              value={values.inlineEditFieldGhost}
              validationText={errors.inlineEditFieldGhost}
            />

            <Typography variant="body" mt={1}>
              Actions Inline Edit
            </Typography>
            <InlineEdit
              showActions
              placeholder="Actions InlineEdit"
              onChange={e => {}}
              value={values.inlineEditActionsField}
              validationText={errors.inlineEditActionsField}
              onActionBarAccept={v => {
                setFieldValue('inlineEditActionsField', v)
              }}
              onActionBarClose={v => {
                setFieldValue('inlineEditActionsField', v)
              }}
            />
            <Button sx={{ width: 100 }} type="submit">
              Submit
            </Button>

            <Button color="secondary" sx={{ width: 100 }} onClick={() => formik.resetForm()}>
              Clear
            </Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}
