import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Formik } from 'formik'
import { TemplateSectionConfigSidePanel, TemplateSections, UpsertFormTemplateHeader } from '.'

import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'

export const CreateFormTemplate: React.FC = () => {
  const { initialValues, activeSectionIndex, handleSubmit, validationSchema } = useContext(FormTemplateUpsertContext)
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, errors }) => {
        return (
          <>
            <UpsertFormTemplateHeader />

            <Box
              display="flex"
              justifyContent={activeSectionIndex === undefined ? 'center' : 'space-between'}
              maxWidth={1280}
              gap={3}
            >
              {/* Left */}

              <TemplateSections />

              {/* Right */}

              <TemplateSectionConfigSidePanel />
            </Box>
          </>
        )
      }}
    </Formik>
  )
}
