import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Chip, ContentGroup, Icon, InfoRow } from '@ketch-com/deck'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'

import { AppealWorkflowInfoRow } from './AppealWorkflowInfoRow'
import pluralize from 'pluralize'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

export const RightsQueueOverviewTabAppealDetails: React.FC = () => {
  const { rightInvocation, original, appeal, isAppealed, rightAllowsAppeal } = useContext(RightsQueueViewContext)

  /*
    Show Appeal Details section when:

    • After original workflow is Rejected via End Tile and the right allows appeal and the End Tile allowed appeal
    • After original workflow is Rejected via Turbo Finalize and the right allows appeal
  */

  // only if an appeal workflow definition has been pre-configured (e.g., via End Tile), should the Appeal Details
  // section be available
  const isAppealWorkflowConfigured = !!rightInvocation?.appeal?.settings?.appealWorkflowDefinitionCode

  if (!original.isRejected || !rightAllowsAppeal || !isAppealWorkflowConfigured) return null

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Box display="flex" alignItems="center">
          <ContentGroup title="Appeal Details" titleVariant="h3" variant="inner-page-header" />
        </Box>

        {/* Appeal Not Started  */}

        {!isAppealed && (
          <>
            {/* Workflow Used */}
            <AppealWorkflowInfoRow />

            <InfoRow title="Allowed Until">
              <Typography variant="body">
                <FormattedDateRenderer date={appeal.invokeAllowedUntil} showTimezone />
              </Typography>
            </InfoRow>
          </>
        )}

        {/* Appeal Started */}

        {!!isAppealed && appeal.isPending && (
          <>
            {/* Workflow Used */}
            <AppealWorkflowInfoRow />

            <InfoRow title="Reason To Appeal">
              {appeal.reason ? <Typography variant="body">{appeal.reason}</Typography> : <EmptyValueRenderer />}
            </InfoRow>

            <InfoRow title="Appealed After">
              <Typography variant="body">
                Appealed after {appeal.daysTakenToInvokeAppeal || 1}{' '}
                {pluralize('day', appeal.daysTakenToInvokeAppeal || 1)}
              </Typography>
            </InfoRow>

            <InfoRow title="Invoke Date">
              <Typography variant="body">
                <FormattedDateRenderer date={appeal.invokedAt} showTimezone />
              </Typography>
            </InfoRow>
          </>
        )}

        {/* Appeal Completed */}

        {!!(appeal.isFulfilled || appeal.isRejected) && !!isAppealed && (
          <>
            {/* Workflow Used */}
            <AppealWorkflowInfoRow />

            <InfoRow title="Reason To Appeal">
              {appeal.reason ? <Typography variant="body">{appeal.reason}</Typography> : <EmptyValueRenderer />}
            </InfoRow>

            <InfoRow title="Appealed After">
              <Typography variant="body">
                {appeal.daysTakenToInvokeAppeal || 1} {pluralize('day', appeal.daysTakenToInvokeAppeal || 1)}
              </Typography>
            </InfoRow>

            <InfoRow title="Invoke Date">
              <Typography variant="body">
                <FormattedDateRenderer date={appeal.invokedAt} showTimezone />
              </Typography>
            </InfoRow>

            <InfoRow title="Completed">
              <Typography variant="body">
                <FormattedDateRenderer date={appeal.finalizedAt} showTimezone />
              </Typography>
            </InfoRow>

            <InfoRow title="Appeal Fulfilled In">
              <Typography variant="body">
                {appeal.daysTakenToFulfillAppeal || 1} of {appeal.daysAllowedToFulfillAppeal}{' '}
                {pluralize('day', appeal.daysAllowedToFulfillAppeal)}
              </Typography>
            </InfoRow>

            <InfoRow title="Request Finalized As">
              <Box display="flex" alignItems="center">
                {appeal.isFulfilled ? (
                  <Chip label="Completed" icon={<Icon name="OCheck" />} />
                ) : (
                  <Chip label="Rejected" icon={<Icon name="ODecline" />} />
                )}
              </Box>
            </InfoRow>

            <InfoRow title="Forced Finalization By" hidden={!appeal.forcefullyCompleted}>
              <Typography variant="body">{appeal.finalizedByName}</Typography>
            </InfoRow>

            <InfoRow hidden={!appeal.forcefullyCompletedReason} title="Reason For Finalization">
              <Typography variant="body">{appeal.forcefullyCompletedReason}</Typography>
            </InfoRow>
          </>
        )}
      </Box>
    </>
  )
}
