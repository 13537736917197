import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'

const PREFIX = 'ThirdPartyRisksHighlight'

const classes = {
  thirdPartyRisksHighlightContainer: `${PREFIX}-thirdPartyRisksHighlightContainer`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.thirdPartyRisksHighlightContainer}`]: {
    flex: '0 1 416px',
    backgroundColor: `${palette.sphere.main}`,
    borderRadius: spacing(1.5),
    padding: spacing(4),
  },
}))

//TODO: Real Data
export const ThirdPartyRisksHighlight: React.FC = () => {
  return (
    <StyledBox className={classes.thirdPartyRisksHighlightContainer}>
      <Text size={16} color="whiteFaded" weight={700}>
        Highlights
      </Text>

      <Text component="div" size={26} color="white" lineHeight="140%" marginTop="48px">
        <strong>11 systems</strong> generate a high volume of unknown data transfers.
      </Text>

      <Text component="div" size={18} color="white" lineHeight="140%" marginTop="32px">
        DSR requests can't be enforced across <strong>5 systems</strong> you use, check Privacy Controls tab.
      </Text>

      <Text component="div" size={18} color="white" lineHeight="140%" marginTop="32px">
        <strong>Intercom and 12 other systems</strong> create risks across several categories. Review all risk
        categories to resolve.
      </Text>
    </StyledBox>
  )
}
