import React from 'react'
import Box from '@mui/material/Box'

export const DisabledInputOverlay: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'fadedGrey.main',
        zIndex: 100,
        borderRadius: 0.625,
      }}
    />
  )
}
