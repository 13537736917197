import React from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

import { Link } from 'components/ui-kit/link/Link'
import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as AccessUtilizationWidgetIcon } from 'assets/icons/access-utilization-widget-icon.svg'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { DateRangePicker } from '.'
import { usePermitsStatsPerIdentitySpace } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpace'
import { TablePaginated } from 'components/ui-layouts/table/compositions/TablePaginated'
import numeral from 'numeral'
import { useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { usePermitsStatsPerIdentitySpaceMaxNumberOfRequests } from 'api/permitStatistics/queries/usePermitsStatsPerIdentitySpaceMaxNumberOfRequests'
import { getTransformedPerIdentitySpaceData } from 'pages/insights/permitsStatistics/components/Distribution/utils/getTransformedPerIdentitySpaceData'
import { RoutesManager } from 'utils/routing/routesManager'
import { teamMembersAlt } from 'pages/insights/activityAndAccess/__mocks__/generateDummyTeamMembers'

const PREFIX = 'AccessUtilizationWidget'

const classes = {
  container: `${PREFIX}-container`,
  widgetIcon: `${PREFIX}-widgetIcon`,
  nameCell: `${PREFIX}-nameCell`,
  queriesCountCell: `${PREFIX}-queriesCountCell`,
  personAvatarIcon: `${PREFIX}-personAvatarIcon`,
  rowClassName: `${PREFIX}-rowClassName`,
  tablePaginated: `${PREFIX}-tablePaginated`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    background: palette.white.main,
    borderRadius: spacing(1),
    padding: spacing(3),
  },

  [`& .${classes.widgetIcon}`]: {
    height: 48,
    width: 48,
  },

  [`& .${classes.nameCell}`]: {
    alignItems: 'center',
  },

  [`& .${classes.queriesCountCell}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  [`& .${classes.personAvatarIcon}`]: {
    height: 12,
    width: 12,
    marginRight: spacing(1.5),
  },

  [`& .${classes.rowClassName}`]: {
    minHeight: 52,
  },

  [`& .${classes.tablePaginated}`]: {
    marginBottom: spacing(2),
  },
}))

export const AccessUtilizationWidget: React.FC = () => {
  const navigate = useNavigate()

  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose } =
    useAppSelector(getPermitStatisticsFilterState)

  const {
    data: identitySpaces,
    isLoading: isLoadingIdentitySpaces,
    pagination: identitySpacesPagination,
  } = usePermitsStatsPerIdentitySpace({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const { data: identitySpacesMaxNumberOfRequests } = usePermitsStatsPerIdentitySpaceMaxNumberOfRequests({
    enabled: !!startDate && !!endDate,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const transformedIdentitySpaceData = getTransformedPerIdentitySpaceData(
    identitySpaces,
    identitySpacesMaxNumberOfRequests,
  )

  return (
    <StyledBox className={classes.container}>
      {/* Title Row */}

      <Link variant="buttonWrap" to={RoutesManager.insights.activityAndAccess.accessUtilization.root.getURL()}>
        <Box display="flex" alignItems="center" gap={1.5} mb={3}>
          <AccessUtilizationWidgetIcon className={classes.widgetIcon} />
          <Box display="flex" flexDirection="column" flex={1}>
            <Text size={20} weight={700} mb={1.25}>
              Access Utilization
            </Text>
            <Text size={14} color="darkDuskFaded">
              Understand the relationship between the access data users have, and the amount it’s used.
            </Text>
          </Box>
        </Box>
      </Link>

      <Box display="flex" alignItems="center" mb={3.375}>
        <DateRangePicker />
      </Box>

      <TablePaginated
        variant="page"
        isSmallHeaderText
        className={classes.tablePaginated}
        rowClassName={classes.rowClassName}
        pagination={identitySpacesPagination}
        pending={isLoadingIdentitySpaces}
        items={transformedIdentitySpaceData}
        onRowClick={(row, index) => {
          // TODO:BAC - make dynamic to the real row data
          const member = teamMembersAlt[index]
          const targetPath = RoutesManager.insights.activityAndAccess.accessUtilization.view.root.getURL({
            code: member.id,
          })
          navigate(targetPath)
        }}
        cellSettings={{
          name: {
            label: 'Name',
            width: 420,
            className: classes.nameCell,
            cellRenderer: (identitySpace, index) => {
              const displayName = teamMembersAlt?.[index]?.name

              return (
                <Box display="flex" alignItems="center">
                  <PersonAvatarSmall className={classes.personAvatarIcon} />
                  <Text size={14} lineHeight={2.5}>
                    {displayName}
                  </Text>
                </Box>
              )
            },
          },
          queriesCount: {
            width: 240,
            label: 'Access Utilization',
            className: classes.queriesCountCell,
            cellRenderer: ({ percent, totalRequests }) => {
              return (
                <Box display="flex" alignItems="center">
                  <Text size={14} lineHeight={2.5}>
                    <Text size={14} weight={600}>
                      {Math.floor(percent)}%
                    </Text>
                    <Text size={14} lineHeight={2.5}>
                      ({numeral(totalRequests * 100).format('0,0')} data assets)
                    </Text>
                  </Text>
                </Box>
              )
            },
          },
          queriesProgress: {
            cellRenderer: ({ percent, totalRequests }) => {
              const shouldPercentBeOrange = percent < 40
              // TODO:BAC - percent should be calculated using totalRequests
              return (
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  sx={{
                    width: 102,
                    backgroundColor: 'iron.main',
                    borderRadius: 5,
                    height: 10,
                    maxWidth: 115,
                  }}
                >
                  <Box
                    sx={{
                      width: `${(percent / 100) * 102}px`,
                      height: 8,
                      borderRadius: 5,
                      backgroundColor: shouldPercentBeOrange ? 'chileanFire.main' : 'darkDusk.main',
                    }}
                  />
                </Box>
              )
            },
          },
        }}
      />
    </StyledBox>
  )
}
