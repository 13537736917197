import { DataGrid, Pagination, theme } from '@ketch-com/deck'
import { GridColDef } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { PaginationModel } from './hooks/useAssetQueryPayload'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { FilterContextProps } from './context/AssetsTabFilterContext'
import { Box } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'

interface DataSystemAssetsTableProps {
  rowCount?: number
  columns: GridColDef<ListAssetItemDTO>[]
  rows?: ListAssetItemDTO[]
  isLoading?: boolean
  setPaginationModel?: FilterContextProps['setPaginationModel']
  paginationModel?: PaginationModel
  width?: string
  resourceType?: string
  elementRef?: React.RefObject<HTMLDivElement>
}

export const DataSystemAssetsTable: React.FC<DataSystemAssetsTableProps> = ({
  columns,
  isLoading,
  setPaginationModel,
  rowCount,
  rows,
  width,
  resourceType,
  elementRef,
}) => {
  const pageCount = useMemo(() => (rowCount ? Math.ceil(rowCount / 10) : 0), [rowCount])
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      if (setPaginationModel) {
        setPaginationModel(prevValue => ({ ...prevValue, page: value - 1 }))
      }
    },
    [setPaginationModel],
  )
  const apiRef = useGridApiRef()

  useEffect(() => {
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    })
  }, [columns, apiRef])

  const contentCountainerWidth = useMemo(() => {
    if (resourceType === 'column') {
      return '1550px'
    }
    if (resourceType === 'database') {
      return '1900px'
    }
    if (resourceType !== 'table') {
      return '1650px'
    }
    return '1750px'
  }, [resourceType])

  return (
    <Box display="flex" flexDirection="column" gap={2} paddingBottom={2}>
      <Box
        maxWidth={width ? width : '870px'}
        sx={{ overflowX: 'scroll' }}
        borderRadius="16px"
        border={`1px solid ${theme.palette.Common.Divider}`}
      >
        <Box display="flex" width={contentCountainerWidth}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
                width: 'auto',
              },
              ...(isLoading && {
                '& .MuiDataGrid-virtualScroller': {
                  height: '300px',
                },
              }),
            }}
            apiRef={apiRef}
            ref={elementRef}
            getRowHeight={() => 'auto'}
            columns={columns}
            getRowId={row => row.resource?.id!}
            rows={rows || []}
            disableRowSelectionOnClick
            disableColumnMenu
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: true,
            }}
            loading={isLoading}
            rowCount={rowCount || 0}
            hideFooterPagination
            hideFooterRowCount
            pageSizeOptions={[10]}
          />
        </Box>
      </Box>
      {pageCount > 1 && (
        <Pagination
          onChange={handleChange}
          count={pageCount}
          shape={'rounded'}
          hideNextButton={false}
          hidePrevButton={false}
          showFirstButton={false}
          showLastButton={false}
          boundaryCount={1}
          size={'medium'}
        />
      )}
    </Box>
  )
}
