import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchUsage, FetchUsageParams } from '../fetchers/fetchUsage'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.orgUsage,
  queryFn: fetchUsage,
  select: res => res?.data || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery> & FetchUsageParams

export const useUsage = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
    enabled: config?.enabled,
  })
}
