import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Tooltip } from '@mui/material'
import {
  withApprovedTrackersContext,
  ApprovedTrackersContext,
  ApprovedTrackerPropertiesContextProvider,
  withTrackersFilterContext,
  TrackersFilterContext,
} from 'pages/policyCenter/trackers/context'
import { Spinner, EmptyState, DataGrid, TableCell, Icon, IconDictionary, ListLayout, Button } from '@ketch-com/deck'
import { ReactComponent as EmptyTrackersIcon } from 'assets/icons/emptyState/EmptyStateTrackers.svg'
import { ReactComponent as EmptyTrackersFoundIcon } from 'assets/icons/emptyState/EmptyStateTrackersFound.svg'
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium'
import { Tracker, GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import {
  Purposes,
  Disclosure,
  Categories,
  Properties,
  HowDoesItWork,
  TrackerStatistics,
} from 'pages/policyCenter/trackers/list/approvedTrackers/components'
import { UnapprovedTrackers, NeedsReviewTrackers } from 'pages/policyCenter/trackers/list'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { Drawer } from 'components/ui-kit/drawer/Drawer'
import { TrackersSearchAndFilter, ProvenanceVariant } from 'pages/policyCenter/trackers/components'
import { DrawerType, ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import pluralize from 'pluralize'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { showToast } from 'components/modals/AlertComponent'
import { BlockedTracker } from './components/BlockedTracker'
import { useFeatureFlag } from 'utils/hooks'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

const AcceptedTrackersWithContext: React.FC = () => {
  const [showHowDoesItWork, setShowHowDoesItWork] = useState(false)
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const isTrackerBlockingRelease =
    getIsPermitted([PERMISSIONS.DEMO]) ||
    isFeatureFlagEnabled(
      FEATURE_FLAGS.PROPERTY_TAGS_TRACKER_BLOCKING,
      FEATURE_FLAGS_VALUES.PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED,
    )
  const {
    isReady: isApprovedTrackersReady,
    approvedTrackers = [],
    isFetchingApprovedTrackers,
    isFetchingNextApprovedTrackers,
    hasNextApprovedTrackers,
    fetchNextApprovedTrackers,
    showDrawer,
    setShowDrawer,
    getApprovedTrackersParams,
    setGetApprovedTrackersParams,
    trackerStatistics = {} as GetTrackerStatisticsResponseBody,
  } = useContext(ApprovedTrackersContext)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
  ]

  const { isReady: isTrackersFilterReady } = useContext(TrackersFilterContext)
  const isReady = isTrackersFilterReady && isApprovedTrackersReady
  const rows = approvedTrackers?.map(tracker => tracker) || []
  const cols = [
    {
      width: isTrackerBlockingRelease ? 265 : 400,
      field: 'key',
      headerName: 'Tracker',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance
        return (
          <TableCell>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                <ProvenanceVariant provenance={provenance} type={ProvenanceType.Icon} />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">{params?.row?.key}</Typography>

                {params?.row?.trackerInstances?.length ? (
                  <Box>
                    <Tooltip
                      title={
                        <Box>
                          {params?.row?.trackerInstances?.map((instances, index) => {
                            return (
                              <Box key={index} my={1}>
                                <Typography variant="label" color="white.main">
                                  {instances}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                      }
                      placement="bottom"
                    >
                      <Typography variant="smallBody" color="darkDuskFaded.main">
                        {params?.row?.trackerInstances?.length || 0}{' '}
                        {pluralize('instance', params?.row?.trackerInstances?.length)}
                      </Typography>
                    </Tooltip>
                  </Box>
                ) : null}

                {params?.row?.dataSystem?.name || params?.row?.dataSystem?.logoURL ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {params?.row?.dataSystem?.logoURL ? (
                      <img
                        src={params?.row?.dataSystem?.logoURL}
                        alt={params?.row?.dataSystem?.name}
                        width={12}
                        height={12}
                      />
                    ) : null}
                    {params?.row?.dataSystem?.name ? (
                      <Typography variant="footnote" color="darkDusk.main">
                        {params?.row?.dataSystem?.name}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Unknown System
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: isTrackerBlockingRelease ? 135 : 160,
      field: 'categories',
      headerName: 'Categories',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.categories?.length ? (
              <Categories tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                Unknown
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: isTrackerBlockingRelease ? 135 : 160,
      field: 'provenance',
      headerName: 'Provenance',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance || ''
        return (
          <TableCell>
            <Typography variant="body">
              <ProvenanceVariant provenance={provenance} type={ProvenanceType.Text} />
            </Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: isTrackerBlockingRelease ? 150 : 160,
      field: 'purposes',
      headerName: 'Purposes',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.purposes?.length ? (
              <Purposes tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: isTrackerBlockingRelease ? 150 : 160,
      field: 'id',
      headerName: 'Found on',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <ApprovedTrackerPropertiesContextProvider>
              <Properties tracker={params?.row} />
            </ApprovedTrackerPropertiesContextProvider>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 200,
      field: 'properties',
      headerName: 'Disclosure',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Disclosure tracker={params?.row} />
          </TableCell>
        )
      },
      sortable: false,
    },
    ...(isTrackerBlockingRelease
      ? [
          {
            width: 205,
            field: 'blocked',
            headerName: 'Blocking',
            renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
              return (
                <TableCell>
                  <BlockedTracker tracker={params?.row} />
                </TableCell>
              )
            },
            sortable: false,
          },
        ]
      : []),
  ]

  const hasFilters =
    getApprovedTrackersParams?.categoryCodes ||
    getApprovedTrackersParams?.dataSystemCodes ||
    getApprovedTrackersParams?.disclosureStatus ||
    getApprovedTrackersParams?.propertyIDs ||
    getApprovedTrackersParams?.purposeIDs ||
    getApprovedTrackersParams?.searchString

  const { mutateAsync: exportApprovedTrackers, isLoading: isExportingApprovedTrackers } = useDownloadFile()
  const initiateApprovedTrackersExport = async () => {
    try {
      const { data } = await exportApprovedTrackers({
        params: {
          fileUrl: '/api/privacy-configuration/trackers/download',
        },
      })

      let a: HTMLAnchorElement | null = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = 'trackers.csv'
      a.click()
      a = null
    } catch (error) {
      showToast({ content: 'Unable to export trackers', type: 'success' })
    }
  }

  if (!isReady && !hasFilters)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  const approvedTrackersComponent = (
    <Box>
      <TrackersSearchAndFilter />
      {approvedTrackers.length ? (
        <Box height={980}>
          <DataGrid
            getRowHeight={() => 'auto'}
            getRowId={(row: Tracker) => row?.key}
            sx={{
              cursor: 'pointer',
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
            }}
            rows={rows}
            columns={cols}
            checkboxSelection={false}
            hideFooter={false}
            hideFooterPagination={false}
            hideFooterRowCount
            disableBorder
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            loading={false}
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
            onRowClick={(params: GridRowParams<Tracker>) => {
              const { row } = params
              if (row?.encodedKey) {
                navigate(
                  RoutesManager.policyCenter.trackers.details.root.getURL({ encodedTrackerKey: row?.encodedKey }),
                )
              }
            }}
            onRowsScrollEnd={() => {
              if (hasNextApprovedTrackers && !isFetchingApprovedTrackers && !isFetchingNextApprovedTrackers)
                fetchNextApprovedTrackers()
            }}
            paginationLoading={isFetchingNextApprovedTrackers}
            isPaginationLoading={isFetchingNextApprovedTrackers}
          />
        </Box>
      ) : null}

      {!approvedTrackers?.length && hasFilters && isApprovedTrackersReady ? (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            iconName={IconDictionary.OFilter}
            title="No data matches the filter criteria"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
            primaryButtonTitle="Reset to Defaults"
            primaryButtonProps={{
              onClick: () => {
                setGetApprovedTrackersParams({ searchString: '' })
              },
              color: 'tertiary',
            }}
          />
        </Box>
      ) : null}

      {!approvedTrackers?.length && hasFilters && !isApprovedTrackersReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <Spinner size={35} thickness={3} />
        </Box>
      ) : null}
    </Box>
  )

  let Component = (
    <Box>
      <Box mb={3}>
        <TrackerStatistics trackerStatistics={trackerStatistics} setShowDrawer={setShowDrawer} />
      </Box>

      {trackerStatistics?.count ? approvedTrackersComponent : null}

      {!trackerStatistics?.count && trackerStatistics.unReviewed ? (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            size="medium"
            customIcon={<EmptyTrackersFoundIcon width={400} height={260} />}
            title="New Trackers Found!"
            subTitle="Please click on the button above to review what we've found and add it to your disclosures."
          />
        </Box>
      ) : null}
    </Box>
  )

  if (!trackerStatistics?.count && !trackerStatistics.unReviewed)
    Component = (
      <Box
        sx={{
          padding: '50px 0',
          borderRadius: '11px',
          backgroundColor: 'white.main',
        }}
      >
        <EmptyState
          size="medium"
          customIcon={<EmptyTrackersIcon width={400} height={260} />}
          title="Looking For Trackers..."
          subTitle="Please wait while we search for trackers on your properties. This process may take some time, and we will notify you once the scan is complete."
        />
      </Box>
    )

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle="Trackers"
        customHeaderRightActionComponent={
          <Box display="flex" gap={1.5}>
            <Button
              startIcon={<Icon name={IconDictionary.OInfo} />}
              color="tertiary"
              onClick={() => {
                setShowHowDoesItWork(true)
              }}
            >
              How Does it Work
            </Button>
            {trackerStatistics?.count || trackerStatistics?.unReviewed ? (
              <Button
                pending={isExportingApprovedTrackers}
                color="tertiary"
                startIcon={<Icon name="OShare" />}
                onClick={initiateApprovedTrackersExport}
              >
                Export
              </Button>
            ) : null}
            <Button
              startIcon={<Icon name={IconDictionary.OCog} />}
              color="tertiary"
              disabled={!isPermittedToWriteCookie}
              onClick={() => {
                navigate(RoutesManager.policyCenter.trackers.settings.root.getURL())
              }}
            >
              Settings
            </Button>
          </Box>
        }
        headerProps={{}}
      >
        <Box>{Component}</Box>
      </ListLayout>

      <Drawer
        open={Boolean(showDrawer)}
        onClose={() => setShowDrawer(null)}
        PaperProps={{
          sx: {
            height: '90%',
            width: '100%',
            maxWidth: '1300px',
            margin: 'auto', // Centered
            padding: '32px',
            borderRadius: '37px 37px 0px 0px',
            overflow: 'hidden',
          },
        }}
      >
        {showDrawer === DrawerType.unapproved ? <UnapprovedTrackers trackerStatistics={trackerStatistics} /> : null}
        {showDrawer === DrawerType.review ? <NeedsReviewTrackers trackerStatistics={trackerStatistics} /> : null}
      </Drawer>
      {showHowDoesItWork ? <HowDoesItWork setShowHowDoesItWork={setShowHowDoesItWork} /> : null}
    </>
  )
}

export const ApprovedTrackers = withTrackersFilterContext(withApprovedTrackersContext(AcceptedTrackersWithContext))
