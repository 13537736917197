import { GetTrackerScanResultsParams, GetTrackerScanResults } from '@ketch-com/atlas/dist/apiTypes/scanResults'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerRecentScanResults = ({
  encodedTrackerKey,
  after,
  limit = 10,
}: GetTrackerScanResultsParams & {
  encodedTrackerKey?: string
}) => {
  return API.get<GetTrackerScanResults>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/scan-results/${encodedTrackerKey}`,
      params: {
        filters: {
          ...(after && {
            after,
          }),
          ...(limit && {
            limit,
          }),
        },
      },
    }),
  )
}
