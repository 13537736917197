import React from 'react'
import { useNavigate } from 'react-router-dom'

import { showToast } from 'components/modals/AlertComponent'
import { useUserMe } from 'api/users/queries/useUserMe'
import { UserProfileView } from 'pages/settings/userProfile/view/UserProfileView'

export const UserProfileContainer: React.FC = () => {
  const navigate = useNavigate()
  const {
    data: userMe,
    isLoading,
    isFetching,
  } = useUserMe({
    params: {
      includeMetadata: true,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch user', type: 'error' })
      navigate('/')
    },
  })

  return <UserProfileView isReady={!isLoading && !isFetching} user={userMe} />
}
