import React from 'react'
import { SxProps, Theme, Typography } from '@mui/material'
import Box from '@mui/material/Box'

export type OverlayOverride = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

type Props = {
  children?: React.ReactNode
  active?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
  hideClickToEdit?: boolean
  removeMinWidth?: boolean
  containerSx?: SxProps<Theme>
  overlayOverride?: OverlayOverride
}

export const EditableOverlay: React.FC<Props> = ({
  active = false,
  className,
  children,
  onClick,
  component: Comp = Box,
  hideClickToEdit = false,
  removeMinWidth = false,
  containerSx = {},
  overlayOverride,
}) => {
  return (
    <Comp
      className={className}
      onClick={onClick}
      sx={{
        position: 'relative',
        minWidth: 120,
        cursor: 'pointer',

        '&:hover': {
          '& .Overlay': {
            opacity: 1,
          },
          '& .OverlayLabel': {
            opacity: !active ? 1 : 0,
          },
        },
        ...(removeMinWidth && {
          minWidth: 'unset',
        }),
        ...containerSx,
      }}
    >
      <Box
        className="Overlay"
        sx={{
          borderRadius: '3px',
          transition: 'opacity .2s linear',
          opacity: 0,
          position: 'absolute',
          top: overlayOverride?.top ?? '-5px',
          right: overlayOverride?.right ?? '-5px',
          bottom: overlayOverride?.bottom ?? '-5px',
          left: overlayOverride?.left ?? '-5px',
          background: ({ palette }) => palette.fadedHeliotrope.main,
          border: ({ palette }) => `1px solid ${palette.minsk.main}`,
          ...(active && { opacity: 1 }),
        }}
      />
      {!hideClickToEdit && (
        <>
          <Typography
            className="OverlayLabel"
            sx={{
              display: 'block',
              transition: 'opacity .2s linear',
              opacity: 0,
              position: 'absolute',
              right: overlayOverride?.right ?? '-5px',
              top: overlayOverride?.top ?? '-5px',
              backgroundColor: theme => theme.palette.heliotrope.main,
              letterSpacing: theme => theme.typography.pxToRem(1.3),
              padding: '2px 10px',
              borderRadius: '0 2px 0 2px',
            }}
            variant="smallLabel"
            color="white.main"
          >
            CLICK TO EDIT
          </Typography>
        </>
      )}
      {children}
    </Comp>
  )
}
