import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormTemplateUpsertContext } from 'pages/consentAndRights/forms/templates/upsert/context/FormTemplateUpsertContextProvider'
import { FormTemplateDTO } from '@ketch-com/figurehead'
import { useFormikContext } from 'formik'
import { FormInput } from 'components/form/FormInput'

export const Title: React.FC = () => {
  const { values } = useFormikContext<FormTemplateDTO>()
  const { activeSectionIndex, languages } = useContext(FormTemplateUpsertContext)

  if (typeof activeSectionIndex !== 'number') return null

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          mb: 6,
        }}
      >
        <FormInput
          id="title"
          fullWidth
          formPropertyName={`sections.[${activeSectionIndex}].title`}
          label="Title"
          placeholder="Add section title"
          shouldUpdateDebounced
        />
      </Box>

      <Typography variant="h4" mb={3} component={Box}>
        Translations
      </Typography>

      {Object.keys(values?.sections?.[activeSectionIndex]?.translations || {}).map((languageCode, index) => {
        const language = languages?.find(lang => lang.code === languageCode)
        const label = `Title (${language?.englishName})`
        return (
          <Box
            sx={{
              mb: 3,
            }}
          >
            <FormInput
              key={index}
              fullWidth
              formPropertyName={`sections.[${activeSectionIndex}].translations.${languageCode}.title`}
              label={label}
              placeholder="Add translation"
              shouldUpdateDebounced
            />
          </Box>
        )
      })}
    </Box>
  )
}
