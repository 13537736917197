import React, { useState } from 'react'
import { useScenarios } from 'api/assets/queries/useScenarios'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { RightType } from 'interfaces/events'
import { DatasetScenario } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/DatasetScenario/DatasetScenario'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { SelectedAsset } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'
import { Logger } from 'utils/Logger'
import { Alert } from '@ketch-com/deck'

export const DatasetScenarioContainer = () => {
  const [rightType, setRightType] = useState(RightType.RIGHT_TYPE_UNSPECIFIED)
  const selectedAsset = React.useContext(SelectedAsset)
  const assetId = selectedAsset.resource?.id as string
  const resourceTypeCode = selectedAsset.resource?.resourceType!
  /*
    No pagination. Max limit is 100 scenarios for now.
    https://ketch-com.atlassian.net/browse/KD-5597
  */
  const {
    data: scenariosResponse = {},
    isLoading: isScenariosLoading,
    error: scenarioError,
  } = useScenarios({
    params: {
      start: 0,
      limit: 100,
      rightType,
      canonicalRightCode: 'get,delete',
    },
  })

  const {
    data: assetSummary,
    isLoading: isAssetSummaryLoading,
    error: assetError,
  } = useAssetV2({
    params: {
      assetCode: assetId,
      resourceTypeCode,
    },
  })

  const isReady = !isScenariosLoading && !isAssetSummaryLoading
  const { scenario = [] } = scenariosResponse
  if (!isReady) {
    return <Spinner size={16} />
  } else if (assetError) {
    Logger.error('Failed to fetch asset', assetError)
    return (
      <Alert
        severity="warning"
        title="Failed to fetch asset. Trying again..."
        message="Contact support if the problem persists"
      />
    )
  } else if (scenarioError) {
    Logger.error('Failed to fetch scenarios', scenarioError)
    return (
      <Alert
        severity="warning"
        title="Failed to fetch scenarios. Trying again..."
        message="Contact support if the problem persists"
      />
    )
  } else if (!assetSummary.asset || !assetSummary.asset.resource) {
    Logger.error(`Malformed Asset detected. --> ${assetId}`)
    return null
  }
  return (
    <DatasetScenario
      scenarios={scenario}
      setRightType={setRightType}
      rightType={rightType}
      assetSummary={assetSummary}
    />
  )
}
