import React, { useContext, useState } from 'react'
import { Chip, FormRow, theme } from '@ketch-com/deck'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useFormikContext } from 'formik'
import { JURISDICTION_CHOICE_OPTIONS } from 'interfaces/privacyProtocols'
import { PrivacyProtocolUpsertContext } from '../context/PrivacyProtocolUpsertContext'
import { mutallyExclusiveProtocols } from '../../constants'
import { isArray } from 'lodash'
import { PrivacyProtocolConfigurationPutRequestBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { Jurisdiction } from '@ketch-com/supercargo/dist/jurisdiction_gen.schemas'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { Tooltip } from '@mui/material'
import { FormError } from 'components/form/FormError'

type ChipProps = {
  onClick: any
  code: string
  name: string
  value: boolean
  inOtherProtocol: string
}

/**
 * Specialized chip that can display a tooltip when the chip is disabled and
 * a user hovers over it
 */
const JurisdictionChip: React.FC<ChipProps> = ({ onClick, code, name, value = false, inOtherProtocol }) => {
  const [selected, setSelected] = useState(value)

  const chip = (
    <Chip
      disabled={Boolean(inOtherProtocol)}
      onClick={() => {
        onClick()
        setSelected(!selected)
      }}
      isSelected={selected}
      label={name}
      selectable
      clickable
    />
  )

  if (inOtherProtocol) {
    return (
      <Tooltip
        title={
          <Typography component="div" variant="smallBody" maxWidth={200}>
            Another privacy protocol uses this jurisdiction. Update the{' '}
            <Typography variant="smallLabel" fontWeight={600}>
              {inOtherProtocol}
            </Typography>{' '}
            protocol to make this jurisdiction available.
          </Typography>
        }
      >
        <Box>{chip}</Box>
      </Tooltip>
    )
  }

  return chip
}

/**
 * Check if a jurisdiction is already selected in another mutually exclusive
 * protocol, and return that protocol if so
 * @param protocolId ID of the protocol we are currently configuring
 * @param jurisdiction Jurisdiction to check
 */
const isInOtherProtocol = (protocolId: string, jurisdiction: Jurisdiction) => {
  const isMutuallyExclusiveProtocol = mutallyExclusiveProtocols.includes(protocolId)
  const otherProtocol = isArray(jurisdiction.protocols)
    ? jurisdiction.protocols.find(p => mutallyExclusiveProtocols.includes(p.id) && p.id !== protocolId)
    : undefined

  return isMutuallyExclusiveProtocol && otherProtocol ? otherProtocol.name : ''
}

const errorStateStyles = {
  border: `1px solid ${theme.palette.chileanFire.main}`,
  m: -1,
  p: 1,
}

/**
 * Form section for selecting from a list of jurisdictions, with the optional
 * choice of selecting all
 */
export const JurisdictionsFormSection: React.FC = () => {
  const {
    setFieldValue,
    errors,
    values: { selectAllJurisdictions, jurisdictions },
  } = useFormikContext<PrivacyProtocolConfigurationPutRequestBody>()

  const {
    jurisdictions: allJurisdictions,
    allowSelectAllJurisdictions,
    protocol,
  } = useContext(PrivacyProtocolUpsertContext)

  // Show the jurisdiction picker if select all isn't allowed for this protocol
  // or if the user doesn't want to select all in a protocol that supports it
  const showJurisdictionPicker = !allowSelectAllJurisdictions || !selectAllJurisdictions

  const handleChipClick = (code: string) => () => {
    setFieldValue(
      'jurisdictions',
      jurisdictions?.some(jurisdictionId => jurisdictionId === code)
        ? jurisdictions?.filter(jurisdictionId => jurisdictionId !== code) // Remove from list
        : [...(jurisdictions ?? []), code],
    ) // Add to list
  }

  return (
    <FormRow
      title="Jurisdictions"
      subTitle="Please specify the jurisdictions where this privacy protocol will be supported."
    >
      {allowSelectAllJurisdictions && (
        <Box>
          <FormRadioGroup
            formPropertyName="selectAllJurisdictions"
            row
            hideOptionalLabel
            valueKey="stringId"
            renderLabel={({ id, name, description }) => (
              <Box paddingRight="50px">
                {/* Make selected element bold */}
                <Typography
                  variant={
                    (selectAllJurisdictions && id === true) || (!selectAllJurisdictions && id === false)
                      ? 'label'
                      : 'body'
                  }
                >
                  {name}
                </Typography>
                <Typography display="block" variant="footnote" color={theme.palette.Text.Secondary}>
                  {description}
                </Typography>
              </Box>
            )}
            options={JURISDICTION_CHOICE_OPTIONS.map((elem: { id: boolean; name: string; description: string }) => ({
              ...elem,
              stringId: String(elem.id),
            }))}
            onChange={(event, value) => {
              setFieldValue('selectAllJurisdictions', value === '1' || value === 'true')
            }}
          />
        </Box>
      )}

      {showJurisdictionPicker && (
        <Box>
          <Typography variant="h4" mb={2}>
            Select Jurisdictions
          </Typography>
          <Box
            display="inline-flex"
            gap="8px"
            flexWrap="wrap"
            borderRadius={2}
            sx={Boolean(errors.jurisdictions) ? errorStateStyles : {}}
          >
            {allJurisdictions &&
              allJurisdictions.map(j => (
                <JurisdictionChip
                  key={j.code}
                  onClick={handleChipClick(j.code || '')}
                  name={j.name || ''}
                  code={j.code || ''}
                  value={jurisdictions?.includes(j.code) || false}
                  inOtherProtocol={isInOtherProtocol(protocol.id, j) || ''}
                />
              ))}
          </Box>
          {Boolean(errors.jurisdictions) && (
            <Box pt={1}>
              <FormError msg="Required" />
            </Box>
          )}
        </Box>
      )}
    </FormRow>
  )
}
