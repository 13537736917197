import React, { useMemo } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { UpsertWebhookModal } from './components/UpsertWebhookModal'
import { DeleteWebhookModal, WebhookActionsCellRenderer } from './components'
import { useWebhooksListUtils } from './hooks'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { ContentGroup, TooltipButton, TextInput, Icon, EmptyState, TableCell, Chip, DataGrid } from '@ketch-com/deck'
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { WebhookDTO } from '@ketch-com/figurehead'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

export const WebhooksList: React.FC = () => {
  const {
    handleCreateHookOnClick,
    handleSubmit,
    handleSubmitDeleteHook,
    webhooks,
    hookToDeleteId,
    hookToEditId,
    isCreateHookModalOpen,
    isLoadingWebhooks,
    isPermittedToCreateHooks,
    isEntitledToCreateHooks,
    pagination,
    searchString,
    setHookToDeleteId,
    setHookToEditId,
    setIsCreateHookModalOpen,
    setSearchString,
  } = useWebhooksListUtils()

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 10),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const columns: GridColDef<WebhookDTO>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams) => <TableCell title={params.row.name} />,
        sortable: false,
        flex: 1,
      },
      {
        field: 'events',
        headerName: 'Event Subscriptions',
        width: 300,
        renderCell: (params: GridRenderCellParams) => {
          switch (params.row?.events?.length) {
            case 0:
            case undefined:
              return (
                <TableCell>
                  <EmptyValueRenderer />
                </TableCell>
              )

            case 1:
              return (
                <TableCell>
                  <Chip label={params.row?.events?.[0]} />
                </TableCell>
              )

            default:
              return (
                <TableCell>
                  <Tooltip
                    title={
                      <Box display="flex" flexDirection="column">
                        {params.row?.events.map((item: string) => <Typography variant="body">{item}</Typography>)}
                      </Box>
                    }
                  >
                    <Box>
                      <Chip label={params.row?.events?.length} clickable />
                    </Box>
                  </Tooltip>
                </TableCell>
              )
          }
        },
        sortable: false,
      },
      {
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.isSystemClassification) {
            return (
              <WebhookActionsCellRenderer
                webhook={params.row}
                setHookToDeleteId={setHookToDeleteId}
                setHookToEditId={setHookToEditId}
              />
            )
          } else {
            return <div />
          }
        },
        align: 'right',
        maxWidth: 60,
        flex: 1,
        sortable: false,
        field: 'action',
        renderHeader: () => null,
      },
    ],
    [setHookToDeleteId, setHookToEditId],
  )

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'Webhooks', link: RoutesManager.developers.webhooks.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box flexDirection="column" display="flex" gap={3}>
        <ContentGroup
          isDivider
          title="Webhooks"
          actionRightBlockComponent={
            <TooltipButton
              disabled={!isPermittedToCreateHooks}
              title={
                !isPermittedToCreateHooks || !isEntitledToCreateHooks
                  ? 'You do not have sufficient permissions to perform this action, please contact your admin.'
                  : ''
              }
              onClick={handleCreateHookOnClick}
            >
              Create Webhook
            </TooltipButton>
          }
          actionBottomBlockComponent={
            <Box display="flex" alignItems="center" gap={1.5}>
              <TextInput
                placeholder="Search"
                startIcon={<Icon name="OMag" />}
                size="small"
                inputWidth="250px"
                fullWidth={false}
                onChange={event => setSearchString(event.currentTarget.value)}
                value={searchString}
                endIcon={
                  searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
                }
              />
            </Box>
          }
        />

        {!isLoadingWebhooks && !webhooks.length ? (
          <EmptyState
            customIcon={<Icon name="OFilter" width="64px" height="64px" />}
            title="There are no Webhooks for this organization"
            subTitle={isPermittedToCreateHooks ? 'Create your first one to populate the table.' : ''}
            primaryButtonTitle="Create Webhook"
            primaryButtonProps={{
              onClick: () => handleCreateHookOnClick(),
              disabled: !isPermittedToCreateHooks || !isEntitledToCreateHooks,
            }}
            primaryButtonTooltipTitle={
              isPermittedToCreateHooks && isEntitledToCreateHooks
                ? undefined
                : 'You do not have sufficient permissions to perform this action, please contact your admin.'
            }
          />
        ) : (
          <DataGrid
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: false,
            }}
            rows={webhooks}
            columns={columns}
            loading={isLoadingWebhooks}
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableChildrenSorting
            disableRowSelectionOnClick
            disableRowHoverStates
            disableColumnResize
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[rowsPerPage]}
            rowCount={pagination.totalResults}
            sx={{
              ...(isLoadingWebhooks && {
                '& .MuiDataGrid-virtualScroller': {
                  height: '300px',
                },
              }),
            }}
          />
        )}
      </Box>

      {/* Create Hook Modal */}

      {isCreateHookModalOpen || !!hookToEditId ? (
        <UpsertWebhookModal
          hookToEditId={hookToEditId}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsCreateHookModalOpen(false)
            setHookToEditId('')
          }}
        />
      ) : null}

      {/* Delete Hook Modal */}

      {hookToDeleteId ? (
        <DeleteWebhookModal
          hookToDeleteId={hookToDeleteId}
          onSubmit={handleSubmitDeleteHook}
          onCancel={() => {
            setHookToDeleteId('')
          }}
        />
      ) : null}
    </>
  )
}
