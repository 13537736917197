import React, { useContext } from 'react'
import pluralize from 'pluralize'
import { Typography, Box } from '@mui/material'
import { Icon, IconDictionary, theme, Widget, DataGrid, TableCell, CustomIconName } from '@ketch-com/deck'
import { withTrackerDiagnosticsRecentScanContext } from 'pages/policyCenter/trackers/context'
import { TrackerDiagnosticsRecentScanContext, TrackerDetailsContext } from 'pages/policyCenter/trackers/context'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { ScanResult } from '@ketch-com/atlas/dist/apiTypes/scanResults'
import dayjs from 'dayjs'

export const RecentScanResultsWithContext: React.FC = () => {
  const { properties } = useContext(TrackerDetailsContext)
  const {
    recentScanResults,
    isLoadingRecentScanResults,
    isFetchingNextRecentScanResults,
    hasNextRecentScanResults,
    fetchNextRecentScanResults,
  } = useContext(TrackerDiagnosticsRecentScanContext)

  const rows = recentScanResults?.map(scanResult => scanResult) || []
  const cols = [
    {
      width: 180,
      field: 'property_name',
      headerName: 'Property',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const date = dayjs(params?.row?.last_scan)
        const formattedDate = date.format('MMM DD, YYYY, HH:mm')
        let iconName: CustomIconName = IconDictionary.OComputer

        switch (params?.row?.platform) {
          case 2:
            iconName = IconDictionary.OMobile
            break
          case 5:
            iconName = IconDictionary.OComputer //TODO: update tv logo
            break
        }
        return (
          <TableCell>
            <Box display="flex" gap={0.5} alignItems="center">
              {iconName ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={25}
                  height={25}
                  sx={{ backgroundColor: theme.palette.Black.o8, borderRadius: '4px' }}
                >
                  <Icon name={iconName} width={20} height={20} />
                </Box>
              ) : null}

              <Box component="div" display="flex" gap={0.25} flexDirection="column">
                <Typography variant="smallLabel">{params?.row?.property_name}</Typography>
                <Typography variant="footnote" color={theme.palette.Text.Secondary}>
                  {formattedDate}
                </Typography>
              </Box>
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 120,
      field: 'wasFound',
      headerName: 'Tracker',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Box component="div" display="flex" gap={0.25} alignItems="center">
              {params?.row?.wasFound ? (
                <>
                  <Icon
                    width={20}
                    height={20}
                    name={IconDictionary.FCheckRound}
                    iconColor={theme.palette.success.main}
                  />
                  <Typography variant="smallLabel" color={theme.palette.success.main}>
                    Found
                  </Typography>
                </>
              ) : (
                <>
                  <Icon
                    width={20}
                    height={20}
                    name={IconDictionary.FMinusRound}
                    iconColor={theme.palette.Text.Secondary}
                  />
                  <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
                    Not Found
                  </Typography>
                </>
              )}
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
  ]

  return (
    <Widget
      expandable
      title="Recent Scan Results"
      subTitle={`This tracker was found on ${
        recentScanResults?.filter(r => r?.wasFound)?.length || 0
      }/${properties?.length} ${pluralize('property', properties?.length)}.`}
      emptyStateProps={{
        title: 'No Data Yet',
        subTitle: "You'll see the most recent scanning results.",
        customIcon: (
          <Icon name={IconDictionary.OChart} iconColor={theme.palette.Text.Secondary} width={64} height={64} />
        ),
      }}
      content={
        <Box width="100%" height={300} overflow="hidden">
          <DataGrid
            getRowHeight={() => 'auto'}
            getRowId={(row: ScanResult) => row?.property_code}
            sx={{
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
            }}
            rows={rows}
            columns={cols}
            hideFooter={!isFetchingNextRecentScanResults}
            hideFooterPagination={!isFetchingNextRecentScanResults}
            hideFooterRowCount
            disableBorder
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            disableRowHoverStates
            loading={isLoadingRecentScanResults}
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
            onRowsScrollEnd={() => {
              if (hasNextRecentScanResults && !isFetchingNextRecentScanResults && !isFetchingNextRecentScanResults)
                fetchNextRecentScanResults()
            }}
            paginationLoading={isFetchingNextRecentScanResults}
            isPaginationLoading={isFetchingNextRecentScanResults}
            noRowsOverlayProps={{
              title: 'No Data Yet',
              subTitle: "You'll see the most recent scanning results.",
              iconName: IconDictionary.OChart,
              buttonTitle: '',
            }}
          />
        </Box>
      }
    />
  )
}

export const RecentScanResults = withTrackerDiagnosticsRecentScanContext(RecentScanResultsWithContext)
