import {
  ActionSheetItem,
  Chip,
  ChipsAutocomplete,
  ChipsAutocompleteOptionType,
  ContentGroup,
  FormRow,
  Icon,
  ListItemText,
  UpsertLayout,
  UpsertLayoutMode,
} from '@ketch-com/deck'
import { Box, Grid, Typography } from '@mui/material'
import { useCreateTeam } from 'api/teams/queries/useCreateTeam'
import { useUpdateTeam } from 'api/teams/queries/useUpdateTeam'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormInput } from 'components/form/FormInput'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { showToast } from 'components/modals/AlertComponent'
import { FormikProvider, useFormik } from 'formik'
import { ObjectLiteral } from 'interfaces'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { UserShortDTO } from 'interfaces/users/users'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NEW_ITEM_CODE } from 'utils/constants/misc'
import { getURLParams } from 'utils/routing/routes/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { TeamFormType, getInitialValues, getValidationSchema, mapFormValuesToPayload } from './utils'
import { FormSwitch } from 'components/form/FormSwitch'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type Props = {
  team?: Team
  users?: UserShortDTO[]
}

type AutoCompleteOptionType = UserShortDTO & ChipsAutocompleteOptionType

const getFullNameUserShortDTO = (user: AutoCompleteOptionType) =>
  user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : user?.email

export const TeamsUpsert: React.FC<Props> = ({ team, users }) => {
  const navigate = useNavigate()
  const { id } = useParams<getURLParams>()
  const isCreate = !id || id === NEW_ITEM_CODE

  const { mutateAsync: createTeam, isLoading: isCreateLoading } = useCreateTeam({
    onSuccess: () => {
      showToast({ type: 'success', content: 'Team saved successfully' })
      navigate(RoutesManager.settings.teams.root.getURL())
    },
    onError: () => showToast({ type: 'error', content: 'Error occured while saving team' }),
  })
  const { mutateAsync: updateTeam, isLoading: isEditLoading } = useUpdateTeam({
    onSuccess: () => {
      showToast({ type: 'success', content: 'Team saved successfully' })
      navigate(RoutesManager.settings.teams.root.getURL())
    },
    onError: () => showToast({ type: 'error', content: 'Error occured while saving team' }),
  })

  const isSubmitting = isCreateLoading || isEditLoading

  const onSubmit = (values: TeamFormType) => {
    const formData = mapFormValuesToPayload(values)

    if (isCreate) {
      createTeam({ params: { formData } })
    } else {
      updateTeam({ params: { formData } })
    }
  }

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: getInitialValues(team, users),
    validationSchema: getValidationSchema(),
    onSubmit: onSubmit,
  })

  const { submitForm, validateForm, setFieldTouched, values, setFieldValue } = formikProps

  const enrichedUsers: AutoCompleteOptionType[] =
    users?.map((user: UserShortDTO) => ({
      ...user,
      value: user.ID,
      name: `${user.firstName || ''} ${user.lastName || ''}`,
    })) || []

  const assigneeOptions = enrichedUsers.filter(user => !!values?.members?.find(member => member.ID === user.ID))

  const autoCompleteSelectedValues: AutoCompleteOptionType[] =
    values.members?.map(elem => ({
      ...elem,
      value: elem.ID,
    })) || []

  const renderMultiDroplistOption = (
    props: { className?: string; id?: string },
    option: AutoCompleteOptionType,
    { selected }: { selected: boolean },
  ) => (
    <ActionSheetItem selected={selected} {...props}>
      <ListItemText selected={selected}>{getFullNameUserShortDTO(option)}</ListItemText>
    </ActionSheetItem>
  )

  const onAccept = async () => {
    const errors = await validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(errorKey => {
        setFieldTouched(errorKey, true)
      })
      return
    }

    submitForm()
  }

  return (
    <>
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={isCreate ? UpsertLayoutMode.create : UpsertLayoutMode.edit}
          renderFormTitle={() => (
            <ContentGroup title={isCreate ? 'Create New Team' : 'Edit Team'} titleVariant="h2" isDivider />
          )}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.settings.teams.root.getURL())
          }}
          acceptButtonProps={{
            pending: isSubmitting,
          }}
          cancelButtonProps={{
            pending: isSubmitting,
          }}
        >
          <FormRow title="Basic Details">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  required
                  fullWidth
                  label="Name"
                  id="name"
                  formPropertyName="name"
                  placeholder="Example: Marketing"
                />
              </Grid>

              <Grid item xs={6}>
                <FormInput
                  required
                  fullWidth
                  label="Label"
                  id="label"
                  formPropertyName="label"
                  placeholder="Example: XX"
                  inputWidth="120px"
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="Description"
                  id="description"
                  formPropertyName="description"
                  placeholder="Example: Our marketing team and advertising unit."
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow
            title="Team Members"
            subTitle="Use the dropdown below to select people to include in this team. Please ensure that all members have proper permissions for anticipated assignments."
          >
            <ChipsAutocomplete
              inRowChips
              chipSize="medium"
              disableClearable
              getOptionLabel={option => getFullNameUserShortDTO(option)}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              multiple
              onChange={(_, value) => setFieldValue('members', value)}
              options={enrichedUsers}
              placeholder="Select Team Members"
              renderInput={undefined}
              renderOption={renderMultiDroplistOption}
              renderTags={() => null}
              size="medium"
              value={autoCompleteSelectedValues}
              width="600px"
              renderChipItem={(data, index): React.ReactNode => {
                return (
                  <Box key={`${index}-${data.ID}`}>
                    <Chip
                      size="medium"
                      label={getFullNameUserShortDTO(data)}
                      deleteIcon={<Icon name="OCross" />}
                      onDelete={() => {
                        setFieldValue('members', values.members?.filter(member => member.ID !== data.ID))
                      }}
                    />
                  </Box>
                )
              }}
            />
          </FormRow>
          <FormRow
            title="Manual Activity Assignments"
            subTitle="Specify default behavior for how work will be assigned to members of the team."
          >
            <Box
              sx={{
                '& .MuiFormControlLabel-root': {
                  mb: 2,
                },
              }}
            >
              <FormRadioGroup
                required
                formPropertyName="selectionMethod"
                options={[
                  {
                    name: 'Round Robin Selection',
                    subtitle: 'Assign tasks evenly across the team',
                    value: 'random',
                  },
                  {
                    name: 'Predefined Assignee',
                    subtitle: 'Assign tasks to specific team member',
                    value: 'predefined',
                  },
                ]}
                renderLabel={(item: ObjectLiteral, isSelected?: boolean | undefined) => (
                  <Box display="flex" flexDirection="column">
                    <Typography variant={isSelected ? 'label' : 'body'}>{item.name}</Typography>
                    <Typography variant="smallBody" color="Text.Secondary">
                      {item.subtitle}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
            {values.selectionMethod === 'predefined' ? (
              <Box width={300}>
                <FormDroplistButton
                  required
                  formPropertyName="defaultAssigneeId"
                  items={assigneeOptions}
                  labelValueKey="name"
                  valueKey="ID"
                  label="Assignee"
                  placeholder="Select Assignee"
                />
              </Box>
            ) : null}
          </FormRow>
          {isRelease(Release.TeamNotifications) ? (
            <FormRow title="Notifications">
              <Grid item xs={12}>
                <FormSwitch
                  formPropertyName="notificationsEnabled"
                  label="Team members receive notifications about their fellow teammates' assignments."
                  inlineLabel
                  boldLabel={false}
                />
              </Grid>
            </FormRow>
          ) : null}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
