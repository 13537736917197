import React from 'react'
import { Provenance } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { Icon, IconDictionary, theme, Chip } from '@ketch-com/deck'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'

type Props = {
  provenance?: Provenance
  iconWidth?: number
  iconHeight?: number
  type: ProvenanceType
}

export const ProvenanceVariant: React.FC<Props> = ({ provenance, type, iconWidth, iconHeight }) => {
  if (type === ProvenanceType.Icon) {
    switch (provenance) {
      case Provenance.first:
        return (
          <Icon
            name={IconDictionary.FCookie}
            iconColor={theme.palette.Text.Secondary}
            width={iconWidth}
            height={iconHeight}
          />
        )
      case Provenance.third:
        return (
          <Icon
            name={IconDictionary.OCookie}
            iconColor={theme.palette.Text.Secondary}
            width={iconWidth}
            height={iconHeight}
          />
        )
      case Provenance.mixed:
        return (
          <Icon
            name={IconDictionary.FMixedCookie}
            iconColor={theme.palette.Text.Secondary}
            width={iconWidth}
            height={iconHeight}
          />
        )
      default:
        return null
    }
  }

  if (type === ProvenanceType.Chip) {
    switch (provenance) {
      case Provenance.first:
        return <Chip label="1st party" />
      case Provenance.third:
        return <Chip label="3rd party" />
      case Provenance.mixed:
        return <Chip label="Mixed" />
      default:
        return null
    }
  }

  if (type === ProvenanceType.Text) {
    switch (provenance) {
      case Provenance.first:
        return <>1st party</>
      case Provenance.third:
        return <>3rd party</>
      case Provenance.mixed:
        return <>Mixed</>
      default:
        return <>Unknown</>
    }
  }

  return null
}
