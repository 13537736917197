import { Box, styled } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import desktopBackground from 'assets/images/previewBackground/bg-desktop-v2.png'
import mobileBackground from 'assets/images/previewBackground/bg-mobile-v2.png'
import { ReactComponent as PhoneDots } from 'assets/images/phone-preview-dots.svg'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import {
  ExperiencePreviewVariant,
  SHOW_BANNER_PREVIEW_MESSAGE,
  SHOW_MODAL_PREVIEW_MESSAGE,
  SHOW_PREFERENCE_PREVIEW_MESSAGE,
} from './constants'
import { ExperiencePreviewContext, withExperiencePreviewContext } from './context/ExperiencePreviewContext'
import { PreviewDisplayMode } from '../../utils/enums'
import { ExperiencePreviewHeader } from './components/ExperiencePreviewHeader'
import { PreviewMessage, PreviewMessageType } from '@ketch-sdk/ketch-types'
import { ThemePreviewHeader } from './components/ThemePreviewHeader'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { DeploymentPreviewHeader } from './components/DeploymentPreviewHeader'
import { PreviewBackground } from './components/PreviewBackground'
import { ExperiencePreviewRenderer } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/components/ExperiencePreviewRenderer'
import { DeploymentPlanDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'

type Props = {
  /* Type of preview */
  variant: ExperiencePreviewVariant

  /* Experience ID */
  experienceID?: string

  /* Theme ID */
  themeID?: string

  /* Deployment Plan */
  deploymentPlan?: DeploymentPlanDTO
}

const PreviewBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '11px',
  border: `1px solid ${theme.palette.iron.main}`,
}))

const DesktopPreviewBox = styled(Box)({
  zIndex: 2,
})

const MobilePreviewBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '30px 0px 35px 0px',
  background: theme.palette.dataMapFillDefault.main,
  borderBottomLeftRadius: '11px',
  borderBottomRightRadius: '11px',
}))

interface DesktopPreviewBackgroundBoxProps {
  hasGreaterBackgroundHeight: boolean
}

const DesktopPreviewBackgroundBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'experiencePreviewVariant',
})<DesktopPreviewBackgroundBoxProps>(({ hasGreaterBackgroundHeight }) => ({
  backgroundImage: `url('${desktopBackground}')`,
  position: 'relative',
  width: '100%',
  height: '100%',
  minHeight: !!hasGreaterBackgroundHeight ? '769px' : '570px',
  zIndex: 1,
  borderBottomLeftRadius: '11px',
  borderBottomRightRadius: '11px',
}))

const MobilePreviewBackgroundBox = styled(Box)({
  backgroundImage: `url('${mobileBackground}')`,
  position: 'relative',
  height: '100%',
  width: '100%',
})

const PhoneOuterBox = styled(Box)({
  display: 'flex',
  background: 'white',
  width: '386px',
  height: '685px',
  padding: '8.711px 13px 13px 13px',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '31px',
  boxShadow:
    '30px 2px 40px 0px rgba(255, 255, 255, 0.20) inset, -30px -2px 30px 0px rgba(229, 229, 229, 0.15) inset, 25px 35px 50px 0px rgba(7, 26, 36, 0.15), 0px -4px 8px 0px rgba(7, 26, 36, 0.19) inset;',
})

const PhoneHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '32.5px',
  width: '100%',
  marginTop: '8.289px',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px',
  background: theme.palette.Black.o4,
}))

const PhoneFooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '48px',
  width: '100%',
  marginTop: '8.289px',
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  background: theme.palette.Black.o4,
}))

const ExperiencePreviewPhone: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <PhoneOuterBox>
      <PhoneDots />
      <PhoneHeaderBox />
      <MobilePreviewBackgroundBox>{children}</MobilePreviewBackgroundBox>
      <PhoneFooterBox />
    </PhoneOuterBox>
  )
}

export const themeTypeMessageMap: { [themeTypeKey: string]: PreviewMessage } = {
  [ThemeType.Banner]: SHOW_BANNER_PREVIEW_MESSAGE,
  [ThemeType.Modal]: SHOW_MODAL_PREVIEW_MESSAGE,
  [ThemeType.Preference]: SHOW_PREFERENCE_PREVIEW_MESSAGE,
}

export const themeTypeMessageTypeMap: { [themeTypeKey: string]: PreviewMessageType } = {
  [ThemeType.Banner]: PreviewMessageType.ShowBanner,
  [ThemeType.Modal]: PreviewMessageType.ShowModal,
  [ThemeType.Preference]: PreviewMessageType.ShowPreference,
}

export const ExperiencePreviewWithoutContext: React.FC<Props> = ({
  variant,
  experienceID,
  themeID,
  deploymentPlan,
}) => {
  const { displayMode, consentExperienceType, isPreviewReady, theme, themeType, experience, jurisdiction, language } =
    useContext(ExperiencePreviewContext)

  const previewVariantHeaders = {
    [ExperiencePreviewVariant.ConsentExperience]: (
      <ExperiencePreviewHeader variant={ExperienceType.Consent} isLoading={!isPreviewReady} />
    ),
    [ExperiencePreviewVariant.PreferenceExperience]: (
      <ExperiencePreviewHeader variant={ExperienceType.Preference} isLoading={!isPreviewReady} />
    ),
    [ExperiencePreviewVariant.Theme]: <ThemePreviewHeader isLoading={!isPreviewReady} />,
    [ExperiencePreviewVariant.DeploymentPlan]: (
      <DeploymentPreviewHeader isLoading={!isPreviewReady} deploymentPlan={deploymentPlan} />
    ),
  }

  //
  // Display components
  //

  const isDesktop = displayMode === PreviewDisplayMode.Desktop
  const OuterBox = isDesktop ? DesktopPreviewBox : MobilePreviewBox
  const InnerBox = isDesktop ? DesktopPreviewBackgroundBox : ExperiencePreviewPhone

  const hasGreaterBackgroundHeight =
    themeType === ThemeType.Preference ||
    themeType === ThemeType.Modal ||
    variant === ExperiencePreviewVariant.PreferenceExperience ||
    (variant === ExperiencePreviewVariant.ConsentExperience && consentExperienceType === ConsentExperienceType.Modal)

  // Pass props to preview renderer based on preview variant
  let component: React.ReactNode | null = useMemo(() => {
    if (
      (variant === ExperiencePreviewVariant.ConsentExperience ||
        variant === ExperiencePreviewVariant.PreferenceExperience) &&
      jurisdiction?.code &&
      language?.language.code &&
      experienceID &&
      theme?.id
    ) {
      return (
        <ExperiencePreviewRenderer
          variant={variant}
          experienceID={experienceID}
          themeID={theme.id}
          jurisdictionCode={jurisdiction?.code}
          languageCode={language?.language.code}
        />
      )
    } else if (variant === ExperiencePreviewVariant.Theme && themeID && experience?.id) {
      const jurisdictionCode = 'default'
      const languageCode = 'en'
      return (
        <ExperiencePreviewRenderer
          variant={variant}
          themeID={themeID}
          experienceID={experience.id}
          jurisdictionCode={jurisdictionCode}
          languageCode={languageCode}
        />
      )
    } else if (variant === ExperiencePreviewVariant.DeploymentPlan && deploymentPlan?.ID) {
      return (
        <ExperiencePreviewRenderer
          variant={variant}
          deploymentID={deploymentPlan?.ID}
          jurisdictionCode={jurisdiction?.code || 'default'}
          languageCode={language?.language.code || 'en'}
        />
      )
    }
  }, [
    variant,
    experienceID,
    theme?.id,
    experience?.id,
    themeID,
    deploymentPlan?.ID,
    jurisdiction?.code,
    language?.language.code,
  ])

  return (
    <PreviewBox>
      {previewVariantHeaders[variant]}
      <OuterBox>
        <InnerBox hasGreaterBackgroundHeight={hasGreaterBackgroundHeight}>
          <PreviewBackground
            variant={displayMode}
            isLoading={!isPreviewReady}
            hasGreaterBackgroundHeight={hasGreaterBackgroundHeight}
          >
            {component}
          </PreviewBackground>
        </InnerBox>
      </OuterBox>
    </PreviewBox>
  )
}

export const ExperiencePreview = withExperiencePreviewContext(ExperiencePreviewWithoutContext)
