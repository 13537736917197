import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { MaybeNull } from 'interfaces'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { getCanDeletePolicyScope } from 'utils/constants/policyScopes'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeletePolicyScopeModal } from 'components/modals/policyScopes/DeletePolicyScopeModal'
import { Button, Icon, ActionSheetItem, ActionSheetWrapper, ListItemText } from '@ketch-com/deck'
import { Box } from '@mui/material'

type Props = {
  policyScope: PolicyScopeDTO
}

export const PolicyScopesListDropdown: React.FC<Props> = ({ policyScope }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [policyScopeToRemove, setPolicyScopeToRemove] = useState<MaybeNull<PolicyScopeDTO>>(null)
  const isPolicyScopeWriter = isPermitted(PERMISSIONS.JURISDICTION_WRITE)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  const items = [
    {
      content: 'Edit',
      onClick: () => {
        navigate(
          RoutesManager.policyCenter.policyScopes.upsert.root.getURL({
            code: policyScope.code,
          }),
        )
        handleClose()
      },
    },
    {
      content: 'divider',
    },
    {
      content: 'Delete',
      hidden: !getCanDeletePolicyScope(policyScope.code),
      onClick: () => {
        setPolicyScopeToRemove(policyScope)
        handleClose()
      },
    },
  ]

  if (!isPolicyScopeWriter) return null

  return (
    <>
      <Button size="medium" variant="iconSubtle" onClick={handleButtonClick}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(option => {
          if (option.content === 'divider') {
            return <ActionSheetItem key={option.content} divider />
          }

          return option.hidden ? null : (
            <ActionSheetItem key={option.content} onClick={option.onClick}>
              <ListItemText>
                <Box display="flex" alignItems="center" gap="15px">
                  {option.content}
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        })}
      </ActionSheetWrapper>

      {policyScopeToRemove?.code && (
        <DeletePolicyScopeModal
          onSubmit={async () => {
            setPolicyScopeToRemove(null)

            await queryClient.refetchQueries(ApiQueryKeys.policyScopesPaginated)
          }}
          code={policyScopeToRemove.code}
          onCancel={() => setPolicyScopeToRemove(null)}
        />
      )}
    </>
  )
}
