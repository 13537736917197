import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import moment from 'moment'
import { TaskDTO } from '@ketch-com/figurehead'

import { Typography } from '@mui/material'
import { getDisplayIdentifierHeaderByTaskType } from '../utils/getDisplayDetails'
import { Avatar, AvatarSize, theme } from '@ketch-com/deck'
import { UserAvatar } from 'components/avatar/UserAvatar'

type Props = {
  isLast?: boolean
  isFirst?: boolean
  task: TaskDTO
}

export const TaskCardCompleted: React.FC<Props> = ({ task, isLast }) => {
  const isAutomated = task?.isAutomatedAssignedBy
  const imageURL = task?.assignedByMember?.imageURL
  const imageURLIncludesGravatar = imageURL?.includes?.('gravatar') // if true, then UI will not render because it doesn't match our design system

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        paddingBottom: 3,
        borderBottom: !isLast ? `1px solid` : 'none',
        borderColor: 'iron.main',
      }}
    >
      {/* Row 1 */}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {/* Left */}

        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {isAutomated ? (
              <Avatar
                icon="FAutomation"
                size={AvatarSize.large}
                variant="circular"
                backgroundColor={theme.palette.iron.main}
                textColor={theme.palette.Text.Secondary}
              />
            ) : imageURL && !imageURLIncludesGravatar ? (
              <Avatar
                variant="circular"
                alt={[task?.assignedByMember?.firstName, task?.assignedByMember?.lastName].filter(Boolean).join(' ')}
                src={task?.assignedByMember?.imageURL}
                size={AvatarSize.large}
              />
            ) : (
              <UserAvatar user={task?.assignedByMember} size={AvatarSize.large} />
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">{task.title}</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body" color="darkDuskFaded.main" mr={0.5}>
                {task.requestId ? getDisplayIdentifierHeaderByTaskType(task?.taskType) : ''}
              </Typography>
              <Link
                to={task?.viewUrl || '#'}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant="body"
                  color="sphere.main"
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {task.requestId}
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>

        {/* Right */}

        <Box display="flex" flexDirection="column">
          <Typography variant="smallBody" mr={2}>
            Created: {moment((task?.metadata?.createdAt || 0) * 1000).fromNow()}
          </Typography>

          <Typography variant="smallBody" mr={2}>
            Resolved: {moment((task?.metadata?.resolvedAt || 0) * 1000).fromNow()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
