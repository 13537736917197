import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Tooltip } from '@mui/material'

import { DropListButton, Button } from '@ketch-com/deck'

import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import {
  updateJurisdictionFilter,
  updateIdentitySpaceFilter,
  updatePurposeFilter,
  updatePropertyFilter,
  updateEnvironmentFilter,
  clearDropdownFilters,
} from 'store/permitStatisticsFilterStateSlice'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { useProperties } from 'api/properties/queries/useProperties'
import { TrendDateRangePicker } from './components/TrendDateRangePicker'
import { MenuItem } from './components/MenuItem'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

const PREFIX = 'PermitsFilters'

const classes = {
  container: `${PREFIX}-container`,
  filtersList: `${PREFIX}-filtersList`,
  filterGroup: `${PREFIX}-filterGroup`,
  dateRangePicker: `${PREFIX}-dateRangePicker`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginBottom: 24,
    paddingBottom: 22,
    borderBottom: `1px solid ${theme.palette.iron.main}`,
  },

  [`& .${classes.filtersList}`]: {
    marginTop: 26,
  },

  [`& .${classes.filterGroup}`]: {
    width: '100%',
  },

  [`& .${classes.dateRangePicker}`]: {
    marginLeft: 'auto',
  },
}))

export const PermitsFilters: React.FC = () => {
  // clear material-ui autocomplete value using react key
  const [key, setKey] = React.useState(1)
  const dispatch = useAppDispatch()
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const { property } = useAppSelector(getPermitStatisticsFilterState)

  const { data: identitySpaces } = useIdentitySpaces()
  const { data: jurisdictions } = usePolicyScopes()
  const { data: purposes } = usePurposes()
  const { data: properties } = useProperties()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Consent', link: RoutesManager.insights.permits.root.getURL() },
  ]

  const jurisdictionsItems = jurisdictions.map(el => {
    return {
      value: el.code,
      label: el.name,
    }
  })

  const purposesItems = purposes.map(el => {
    return {
      value: el.code,
      label: el.name,
    }
  })

  const identitySpacesItems = identitySpaces.map(el => {
    return {
      value: el.code,
      label: el.name,
    }
  })

  const propertiesItems = properties.map(el => {
    return {
      value: el?.code || '',
      label: el?.name || '',
      environments: el?.environments || [],
    }
  })

  const environmentItems = property
    ? (propertiesItems || [])
        .find(el => el.value === property)
        ?.environments.map(el => {
          return {
            value: el?.code || '',
            label: el?.code || '',
            pattern: el?.pattern || '',
          }
        }) || []
    : []

  return (
    <Root className={classes.container}>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Typography variant="h1"> {t('Permit Statistics')}</Typography>
      <div className={classes.filtersList}>
        <Box display="inline-flex" alignItems="center" gap={1} className={classes.filterGroup}>
          <DropListButton
            // force re-render material-ui autocomplete to clear when clicked on "Clear All" button
            key={`jurisdiction${key}`}
            renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
            onChange={(_, code) => {
              dispatch(updateJurisdictionFilter(code ? code.value : ''))
            }}
            options={jurisdictionsItems}
            placeholder={t('All Jurisdictions')!}
            size="small"
          />
          <DropListButton
            key={`purposes${key}`}
            renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
            onChange={(_, code) => {
              dispatch(updatePurposeFilter(code ? code.value : ''))
            }}
            options={purposesItems}
            placeholder={t('All Purposes')!}
            size="small"
          />
          <DropListButton
            key={`identity${key}`}
            renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
            onChange={(_, code) => {
              dispatch(updateIdentitySpaceFilter(code ? code.value : ''))
            }}
            options={identitySpacesItems}
            placeholder={t('All Identifiers')!}
            size="small"
          />
          <DropListButton
            key={`properties${key}`}
            renderOption={(props, option, { selected }) => <MenuItem {...props} option={option} selected={selected} />}
            onChange={(_, code) => {
              if (code) {
                dispatch(updatePropertyFilter(code.value))
              } else {
                dispatch(updateEnvironmentFilter(''))
                dispatch(updatePropertyFilter(''))
              }
            }}
            options={propertiesItems}
            placeholder={t('All Properties')!}
            size="small"
          />

          <Tooltip
            title={
              !property
                ? 'Please select a property'
                : environmentItems.length === 0
                ? `Property "${property}" has no environments.`
                : ''
            }
          >
            <DropListButton
              key={`environments${key}`}
              disabled={!property || environmentItems.length === 0}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} option={option} selected={selected} />
              )}
              onChange={(_, code) => {
                if (code) {
                  dispatch(updateEnvironmentFilter(code.value))
                }
              }}
              options={environmentItems}
              placeholder={t('All Environments')!}
              size="small"
            />
          </Tooltip>

          {/* Clear All Filters Button */}
          <Button
            variant="link"
            color="secondary"
            onClick={() => {
              dispatch(clearDropdownFilters())
              setKey(key => -key)
            }}
          >
            <Typography variant="body" color="sphere.main" sx={{ cursor: 'pointer' }}>
              {t('Clear All')}
            </Typography>
          </Button>

          <div className={classes.dateRangePicker}>
            <TrendDateRangePicker />
          </div>
        </Box>
      </div>
    </Root>
  )
}
