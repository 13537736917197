import { useContext } from 'react'
import { DocumentViewer } from './components/DocumentViewer'
import { RawData } from './components/RawData'
import { Sidebar } from './components/Sidebar'
import { RedactionEmptyState } from './components/RedactionEmptyState'
import Box from '@mui/material/Box'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RedactionContext, withRedactionContextContext } from './context/RedactionContext'
import { RedactionHeader, UploadDocumentsModal } from './components'
import { Spinner } from '@ketch-com/deck'

const RedactionWithContext: React.FC = () => {
  const {
    activeFile,
    annotationManager,
    breadcrumbs,
    documentViewer,
    files,
    handleDocumentLoad,
    handleExclude,
    handleFileChange,
    handleFileRedaction,
    handleInvalidFormatFiles,
    rightInvocationId,
    isComplete,
    isDocumentLoaded,
    isFileFetching,
    isLoading,
    isRawData,
    isReady,
    isSaving,
    rightInvocation,
    scrollView,
    setActiveFile,
    setAnnotationManager,
    setDocumentViewer,
    setShowModal,
    showModal,
    stepId,
    steps,
    viewer,
    workflowExecutionStep,
  } = useContext(RedactionContext)

  if (!isReady || !rightInvocation)
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs.filter(b => !!b?.title)} />
      <Box minWidth="1280px" width="100%">
        <RedactionHeader />
        <Box display="flex" flexDirection="row" flex={1} width="100%" px={1.5}>
          <Sidebar
            activeFile={activeFile}
            files={files}
            isComplete={isComplete}
            isSaving={isSaving}
            onFileChange={handleFileChange}
            setShowModal={setShowModal}
            steps={steps}
            isFileFetching={isFileFetching}
            workflowExecutionStep={workflowExecutionStep!}
          />

          <Box width="calc(100% - 328px)" height="100%" alignSelf={isLoading ? 'center' : 'auto'}>
            {/* loading state */}
            {isLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <>
                {/* Empty State */}
                {!activeFile && <RedactionEmptyState />}

                {/* raw data */}
                {!!activeFile && isRawData(activeFile?.name) && (
                  <RawData
                    activeFile={activeFile}
                    isComplete={isComplete}
                    isSaving={isSaving}
                    onExclude={handleExclude}
                    setInvalidFormatFiles={handleInvalidFormatFiles}
                    setActiveFile={setActiveFile}
                  />
                )}
              </>
            )}

            {/* only init document viewer if core deps exist */}
            {(window as any).Core && (
              <DocumentViewer
                annotationManager={annotationManager}
                documentViewer={documentViewer}
                onExclude={handleExclude}
                activeFile={activeFile}
                isDocumentLoaded={isDocumentLoaded}
                isComplete={isComplete}
                isSaving={isSaving}
                onFileRedact={handleFileRedaction}
                onDocumentLoaded={handleDocumentLoad}
                scrollView={scrollView}
                setAnnotationManager={setAnnotationManager}
                setDocumentViewer={setDocumentViewer}
                viewer={viewer}
              />
            )}
          </Box>
        </Box>
      </Box>
      {showModal && rightInvocation && workflowExecutionStep && (
        <UploadDocumentsModal version={rightInvocationId!} folder={stepId!} onClose={() => setShowModal(false)} />
      )}
    </>
  )
}

export const Redaction = withRedactionContextContext(RedactionWithContext)
