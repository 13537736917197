import React, { useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useDebounce } from 'react-use'
import { Box, Typography, InputAdornment } from '@mui/material'
import { Chip, Icon, IconDictionary, DropListButton, TextInput, theme } from '@ketch-com/deck'
import { Tracker, UnapprovedTracker, TrackerWithNewPurposes } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpsertTrackerPurposes } from 'api/trackers/mutations/useUpsertTrackerPurposes'

type Props = {
  tracker: Tracker | UnapprovedTracker | TrackerWithNewPurposes
  boltStatusEnabled: boolean
}

function isTrackerType(tracker: Tracker | UnapprovedTracker | TrackerWithNewPurposes): tracker is Tracker {
  return 'newPurposes' in tracker
}

export const AddRemovePurposes: React.FC<Props> = ({
  tracker = {} as Tracker | UnapprovedTracker | TrackerWithNewPurposes,
  boltStatusEnabled,
}) => {
  const queryClient = useQueryClient()
  const [purposeListQuery, setPurposeListQuery] = useState('')
  const { purposes: trackerPurposes = [] } = tracker
  const [inputValue, setInputValue] = useState('')

  const { mutateAsync: upsertNewTrackerPurposes, isLoading: isUpdatingNewTrackerPurposes } = useUpsertTrackerPurposes({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.trackerDetails])
      await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.trackersWithNewPurposesInfinite])
      await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
      showToast({ content: 'Updated purpose', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update purpose', type: 'error' })
    },
  })

  const trackerPurposesLength = trackerPurposes?.length || 0
  const trackerPurposeOptions = useMemo(() => {
    if (tracker?.purposes?.length && isTrackerType(tracker)) {
      return [...tracker?.purposes, ...(tracker?.newPurposes || [])]
    }
    return tracker?.purposes
  }, [tracker])

  useDebounce(
    () => {
      if (inputValue) {
        setPurposeListQuery(inputValue)
      } else {
        setPurposeListQuery('')
      }
    },
    500,
    [inputValue],
  )

  const { data: purposeList, isLoading: isLoadingPurposeList } = usePurposes({
    params: {
      query: purposeListQuery,
    },
  })

  return (
    <Box>
      <DropListButton
        width="100%"
        value={null}
        disabled={isUpdatingNewTrackerPurposes}
        loading={isLoadingPurposeList}
        filterOptions={x => x}
        clearOnBlur
        noOptionsText="No Purposes"
        getOptionDisabled={option => trackerPurposeOptions?.some(item => item?.id === option?.ID)}
        onChange={(e, purpose) => {
          const purposeIds = trackerPurposeOptions?.map(purpose => purpose?.id || '') || []
          purposeIds?.push(purpose?.ID || '')
          const filteredPurposeIds = purposeIds?.filter(id => id) || []
          upsertNewTrackerPurposes({
            params: {
              encodedTrackerKey: tracker?.encodedKey || '',
              data: {
                purposeIDs: [...filteredPurposeIds],
              },
            },
          })
        }}
        onInputChange={(e, inputVal) => {
          setInputValue(inputVal)
        }}
        options={purposeList}
        renderInput={params => {
          return (
            <TextInput
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon name="OMag" iconColor={theme.palette.Text.Secondary} />
                  </InputAdornment>
                ),
              }}
              placeholder="Search purposes to assign"
              variant="outlined"
            />
          )
        }}
        renderOption={(props, purpose) => {
          return (
            <Box component="li" {...props} key={purpose?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {purpose?.name}
              </Typography>
              <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                {purpose?.code}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
      />

      <Box
        mt={2}
        sx={{
          borderTop: ({ palette }) => `1px solid ${palette.iron.main}`,
        }}
      >
        <Box my={2}>
          <Typography variant="label">Tracker Purposes </Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            {trackerPurposesLength}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" flexWrap="wrap" gap={1.5}>
          {trackerPurposesLength ? (
            trackerPurposes?.map((currentTrackerPurposesItem, i) => {
              return (
                <Box key={currentTrackerPurposesItem?.id}>
                  <Chip
                    label={currentTrackerPurposesItem?.name}
                    onDelete={() => {
                      const selectedPurposes = trackerPurposeOptions.filter(
                        trackerPurposesItem => trackerPurposesItem?.id !== currentTrackerPurposesItem?.id,
                      )
                      const purposeIds = selectedPurposes?.map(purpose => purpose?.id || '') || []
                      const filteredPurposeIds = purposeIds?.filter(id => id) || []

                      upsertNewTrackerPurposes({
                        params: {
                          encodedTrackerKey: tracker?.encodedKey || '',
                          data: {
                            purposeIDs: [...filteredPurposeIds],
                          },
                        },
                      })
                    }}
                    deleteIcon={
                      <Icon id={`${currentTrackerPurposesItem?.id}-delete-button`} name={IconDictionary.OCross} />
                    }
                    disabled={isUpdatingNewTrackerPurposes}
                    icon={
                      boltStatusEnabled && trackerPurposesLength && currentTrackerPurposesItem?.isAutoAssigned ? (
                        <Icon
                          name={IconDictionary.FAutomation}
                          iconColor={theme.palette.greenHaze.main}
                          width={16}
                          height={16}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                  {!boltStatusEnabled && currentTrackerPurposesItem?.isAutoAssigned ? (
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <Icon
                        name={IconDictionary.FAutomation}
                        iconColor={theme.palette.Text.Secondary}
                        width={12}
                        height={12}
                      />
                      <Typography variant="label" color="darkDuskFaded.main">
                        Auto-Assigned
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              )
            })
          ) : (
            <Box>
              <Typography variant="body" color="darkDuskFaded.main">
                No purpose assigned yet.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
