import React, { useContext } from 'react'
import { Typography, Box } from '@mui/material'
import { Spinner, theme } from '@ketch-com/deck'
import { TrackerDiagnosticsContext } from 'pages/policyCenter/trackers/context'

export const TrackerManagedBy: React.FC = () => {
  const { isTrackerLineageTypeReady, trackerLineageManagedBy } = useContext(TrackerDiagnosticsContext)

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      px={2.5}
      py={2}
      sx={{ backgroundColor: theme.palette.Black.o8, borderRadius: '11px' }}
    >
      {!isTrackerLineageTypeReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={80}>
          <Spinner size="32px" thickness={3} />
        </Box>
      ) : (
        <>
          <Typography variant="body" color={theme.palette.Text.Secondary}>
            Managed by
          </Typography>
          <Typography variant="h4">{trackerLineageManagedBy?.[0]?.name}</Typography>
          <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
            A tag management system that allows you to set up and manage tags on your site without changing your
            website's code.
          </Typography>
        </>
      )}
    </Box>
  )
}
