import { useMemo } from 'react'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { useContext } from 'react'
import { Formik } from 'formik'
import { getDataSubjectVariablesInitialValues, getDataSubjectVariablesValidationSchema } from './utils'
import { ContentGroup } from '@ketch-com/deck'
import { InfoRowCtxVariable } from '../InfoRowCtxVariable/InfoRowCtxVariable'
import { TaskContext } from '../../../../context'
import { Box } from '@mui/material'

export const DataSubjectVariables: React.FC = () => {
  const { stepDetails, hasAttemptedToResolve } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { canEdit } = useContext(TaskContext)

  const initialValues = useMemo(() => getDataSubjectVariablesInitialValues(stepDetails), [stepDetails])
  const validationSchema = useMemo(() => getDataSubjectVariablesValidationSchema(), [])

  if (!stepDetails?.dataSubjectContext?.length) return null

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={() => {}}
    >
      {({ errors }) => {
        const appealOverriddenVariables = new Set(
          stepDetails?.dataSubjectContext?.filter(variable => variable.isAppealValue).map(variable => variable.code),
        )

        return (
          <Box display="flex" flexDirection="column" gap={4}>
            <ContentGroup variant="inner-page-header" titleVariant="h3" title="Data Subject Variables" />
            {stepDetails?.dataSubjectContext?.map((variable, variableIndex) => {
              if (!variable.isConfiguredForStep) return null

              // For this Task Details (left-hand) main view, if a variable has one original value and
              // one appeal-overridden value, only show the appeal-overridden value
              if (!variable.isAppealValue && appealOverriddenVariables.has(variable.code)) return null

              return (
                <InfoRowCtxVariable
                  key={`${variable.code}_${variableIndex}`}
                  variable={variable}
                  canEdit={canEdit}
                  name={`dataSubjectContext[${variableIndex}].value`}
                  variableIndex={variableIndex}
                  errors={errors}
                  hasAttemptedToResolve={hasAttemptedToResolve}
                />
              )
            })}
          </Box>
        )
      }}
    </Formik>
  )
}
