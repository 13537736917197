import { AccessibilityButtonConfiguration, ExperienceDisplayType } from '@ketch-sdk/ketch-types'
import { MetaData } from 'interfaces/common'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { ApplicationStatus } from 'interfaces/applications/applicationStatus'
import { ApplicationIdentitySpaceType } from 'interfaces/applications/applicationIdentitySpaceType'
import { PolicyDocumentType, PolicyDocumentStatus } from 'interfaces/policyDocuments/policyDocument'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { ApplicationIdentitySpaceEncoding } from './ApplicationIdentitySpaceEncoding'
import { ApplicationIdentitySpaceFormat } from './ApplicationIdentitySpaceFormat'
import { ApplicationIdentitySpace } from './ApplicationFormValues'
import { Property } from '@ketch-com/supercargo/dist/experience_gen.schemas'

export const ACCESSIBILITY_BUTTON_POSITION_NAMES: Record<AccessibilityButtonConfiguration['position'], string> = {
  'bottom-left': 'Bottom Left',
  'bottom-right': 'Bottom Right',
}

export type ApplicationPrivacyPolicyDTO = {
  ID: string
  URL: string
  code: string
  latest: boolean
  name: string
  description?: string
  organizationID: string
  policyDocumentType: PolicyDocumentType.PRIVACY_POLICY
  status: PolicyDocumentStatus
  version: number
}

export type ApplicationTermsOfServiceDTO = {
  ID: string
  URL: string
  code: string
  latest: boolean
  name: string
  organizationID: string
  policyDocumentType: PolicyDocumentType.TERMS_OF_SERVICE
  status: PolicyDocumentStatus
  version: number
}

export type ApplicationIdentitySpacesDTO = {
  ID: string
  applicationID: string
  identitySpaceID: string
  variable: string
  type?: ApplicationIdentitySpaceType
  key?: string
  format: ApplicationIdentitySpaceFormat
  encoding: ApplicationIdentitySpaceEncoding
}

export type ApplicationEnvironmentDTO = {
  ID: string
  applicationID: string
  code: string
  deployment?: {
    ID: string
    code: string
    latest?: boolean
    name: string
    organizationCode: string
    status: string
    themeID: string
    version: number
  }
  deploymentID?: {
    value: string
  }
  applicationPlatform: ApplicationPlatform
  applicationName: string
  pattern: string
  domain: string
  metadata?: MetaData
  property?: Property
  themeID: string
}

export type AccessibilityButtonDTO = {
  color: string
  icon: string
  position: 'bottom-left' | 'bottom-right'
  experienceType?: ExperienceDisplayType
}

export type ApplicationDTO = {
  ID: string
  code: string
  name: string
  platform: ApplicationPlatform
  status: ApplicationStatus
  organizationCode: string
  active?: boolean
  defaultPolicyScopeVariable?: string
  proxy?: string
  hasIssues?: boolean
  metadata?: MetaData
  environments: ApplicationEnvironmentDTO[]
  managedIdentity?: boolean
  dataSubjectTypes?: DataSubjectTypeDTO[]
  identitySpaces?: ApplicationIdentitySpacesDTO[]
  propertyCookies?: {
    cookieID: string
    propertyCode: string
    lastScanned?: number
    disclose?: boolean
  }[]
  progressiveConsentRules?: { list: string }
  version: number
  latest?: boolean
  accessibilityButton?: AccessibilityButtonDTO
  privacyPolicy?: ApplicationPrivacyPolicyDTO
  termsOfService?: ApplicationTermsOfServiceDTO
}

export type UpsertApplicationDTO = {
  ID?: string
  code: string
  name: string
  managedIdentity: boolean
  dataSubjectTypes?: DataSubjectTypeDTO[]
  defaultPolicyScopeVariable?: string
  proxy?: string
  identitySpaces?: ApplicationIdentitySpace[]
  propertyCookies?: {
    cookieID: string
    propertyCode: string
    lastScanned?: number
    disclose?: boolean
  }[]
  platform: ApplicationPlatform
  environments: {
    code: string
    pattern: string
    themeID: string
  }[]
  accessibilityButton?: AccessibilityButtonDTO
  privacyPolicyCode?: string
  termsOfServiceCode?: string
}
