import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetUsage200, GetUsageParams } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

export type FetchUsageParams = GetUsageParams & {
  organizationCode?: string
}

export const fetchUsage = ({ organizationCode, granularity }: FetchUsageParams) => {
  let entityUrl = `/api/organizations/${organizationCode}/usage`
  if (granularity) entityUrl = entityUrl + `?granularity=${granularity}`
  return API.get<GetUsage200>(
    formatRequestString({
      entityUrl,
    }),
  )
}
