import React from 'react'
import Box from '@mui/material/Box'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const Home: React.FC = () => {
  return (
    <>
      <NavigationBreadCrumbs type="light" items={[{ title: 'Home' }]} />
      <Box display="flex" alignItems="center" maxWidth={1280}>
        <Box display="flex" flexDirection="column">
          <div id="youcanduit" />
        </Box>
      </Box>
    </>
  )
}
