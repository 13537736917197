import { InitiatorTypeMap, PlatformTypeMap } from 'pages/policyCenter/trackers/interfaces'

export const InitiatorTypeTextMap: InitiatorTypeMap = {
  page: 'Page',
  tagManager: 'Tag Manager',
  authorized: 'Authorized',
  primary: 'Primary Initiator',
  initiator: 'Initiator',
}

export const PlatformTypeTextMap: PlatformTypeMap = {
  1: 'Web',
  2: 'Mobile',
  5: 'CTV',
}

export enum PlatformType {
  Web = 'Web',
  Mobile = 'Mobile',
  CTV = 'CTV',
}
