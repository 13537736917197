import { FC } from 'react'
import { useFormikContext } from 'formik'
import { Box, Grid, InputLabel, Typography } from '@mui/material'
import { FormRow, Icon, theme } from '@ketch-com/deck'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormInputColor } from 'components/form/FormInputColor'
import { FormFileUpload } from 'components/ui-kit/form/fileUpload/FormFileUpload'
import { AccessControlType } from 'components/ui-kit/form/imageUpload/hooks'
import { ExperienceDisplayType } from '@ketch-sdk/ketch-types'
import { experienceDisplayTypeLabels } from 'pages/consentAndRights/applications/upsert/utils/labels'

const radioOptions = [
  {
    value: true,
    label: 'Enabled',
  },
  {
    value: false,
    label: 'Disabled',
  },
]

const experienceTypeOptions = [
  {
    value: ExperienceDisplayType.Modal,
    label: experienceDisplayTypeLabels[ExperienceDisplayType.Modal],
  },
  {
    value: ExperienceDisplayType.Preference,
    label: experienceDisplayTypeLabels[ExperienceDisplayType.Preference],
  },
]

const acceptFileTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
}

const BottomLeftIcon: FC<{ isActive?: boolean }> = ({ isActive }) => (
  <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="48" rx="5" fill="#071A24" fillOpacity="0.08" />
    {!!isActive && <rect x="1" y="1" width="54" height="46" rx="4" stroke="#5774FF" strokeWidth="2" />}
    <rect x="10" y="11" width="36" height="26" rx="2" fill="#071A24" fillOpacity="0.25" />
    <rect x="14.75" y="23.75" width="8.5" height="8.5" rx="1.25" fill="white" stroke="#071A24" strokeWidth="1.5" />
  </svg>
)

const BottomRightIcon: FC<{ isActive?: boolean }> = ({ isActive }) => (
  <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="54" height="46" rx="4" fill="#071A24" fillOpacity="0.08" />
    {!!isActive && <rect x="1" y="1" width="54" height="46" rx="4" stroke="#5774FF" strokeWidth="2" />}
    <rect x="10" y="11" width="36" height="26" rx="2" fill="#071A24" fillOpacity="0.25" />
    <rect x="32.75" y="23.75" width="8.5" height="8.5" rx="1.25" fill="white" stroke="#071A24" strokeWidth="1.5" />
  </svg>
)

export const ApplicationAccessibility = () => {
  const { values, setFieldValue } = useFormikContext<ApplicationFormValues>()
  const {
    accessibilityButton: { enabled, color, icon, position },
  } = values

  return (
    <FormRow hasTopBorder title="Experience Trigger Button">
      <Box mt={-3} mb={2}>
        <Typography color={theme.palette.Text.Secondary}>
          Configure the <strong>Experience Trigger Button</strong> that overlays on your property and opens the modal or
          preference experience when clicked. You can customize the button’s position, color, and icon. Alternatively
          you can trigger preference management experience via a link in a footer.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormRadioGroup
            row
            hideOptionalLabel
            size="medium"
            formPropertyName="accessibilityButton.enabled"
            options={radioOptions}
            renderLabel={({ label }) => label}
            onChange={(_, nextValue) => {
              setFieldValue('accessibilityButton.enabled', nextValue === 'true')
            }}
          />
        </Grid>
        {!!enabled && (
          <Grid container item spacing={4}>
            <Grid container item spacing={4} xs={5}>
              <Grid item xs={12}>
                <FormInputColor
                  fullWidth
                  required
                  shouldUpdateDebounced
                  id="accessibilityButton.color"
                  label="Button Background Color"
                  formPropertyName="accessibilityButton.color"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  '& button + span': {
                    display: 'none',
                  },
                }}
              >
                <FormFileUpload
                  name="accessibilityButton.icon"
                  label="Icon"
                  accept={acceptFileTypes}
                  uploadContext={{
                    bucket: '',
                    folder: 'application-accessibility-button-icon',
                    version: `${new Date().getTime()}`,
                    acl: `${AccessControlType.public}`,
                  }}
                />
                <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                  Up to 1MB, supported file types: jpeg, jpg, png, SVG. <br /> Container size is 32x32px.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  <Typography variant="label" color="darkDusk.main">
                    Position
                  </Typography>
                </InputLabel>
                <Box display="flex" gap={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setFieldValue('accessibilityButton.position', 'bottom-left')}
                  >
                    <BottomLeftIcon isActive={position === 'bottom-left'} />
                    <Typography color={theme.palette.Text.Secondary}>Bottom Left</Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setFieldValue('accessibilityButton.position', 'bottom-right')}
                  >
                    <BottomRightIcon isActive={position === 'bottom-right'} />
                    <Typography color={theme.palette.Text.Secondary}>Bottom Right</Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Experience Type */}
              <Grid item xs={12}>
                <InputLabel>
                  <Typography variant="label" color="darkDusk.main">
                    Experience Type
                  </Typography>
                </InputLabel>
                <Box display="flex" gap={1}>
                  <FormRadioGroup
                    row
                    hideOptionalLabel
                    size="medium"
                    formPropertyName="accessibilityButton.experienceType"
                    options={experienceTypeOptions}
                    renderLabel={({ label }) => label}
                    onChange={(_, nextValue) => {
                      setFieldValue('accessibilityButton.experienceType', nextValue)
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <InputLabel>
                <Typography variant="label" color="darkDusk.main">
                  Preview&nbsp;
                </Typography>
                <Typography variant="label" color={theme.palette.Text.Secondary}>
                  (48x48px Button)
                </Typography>
              </InputLabel>
              <Box
                p={1}
                width={48}
                height={48}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={color}
                borderRadius="50%"
                sx={{
                  flex: '0 0 48px',
                  overflow: 'hidden',
                  '& > svg, & > img': {
                    width: 32,
                    height: 32,
                  },
                }}
              >
                {!!icon ? (
                  <img src={icon} alt="preview icon" />
                ) : (
                  <Icon name="OFingerprint" iconColor={theme.palette.white.main} fontSize="large" />
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormRow>
  )
}
