import React from 'react'

import { useExperiencesPaginated } from 'api/experiences-v2/queries/useExperiencesPaginated'
import { ExperiencesList } from 'pages/consentAndRights/experiences-v2/list/ExperiencesList'

export const ExperiencesV2ListContainer: React.FC = () => {
  const {
    data: experiences,
    isLoading,
    isFetching,
    pagination,
  } = useExperiencesPaginated({
    params: {
      includeIssues: true,
      includeMetadata: true,
    },
  })

  return (
    <ExperiencesList experiences={experiences} isLoading={isLoading} isFetching={isFetching} pagination={pagination} />
  )
}
