import React from 'react'
import { Typography, Box } from '@mui/material'
import { theme, Button } from '@ketch-com/deck'
import DiagnosticsViz from 'assets/icons/diagnostics/visualization.svg'

export const UnlockDiagnostics: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.White.o100,
        borderRadius: '11px',
      }}
    >
      <Box
        height={200}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={2}
        sx={{
          backgroundColor: theme.palette.Info.Disabled,
          border: `1px solid ${theme.palette.Info.PrimaryDark}`,
          borderRadius: '11px',
          backgroundSize: '260px 160px',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${DiagnosticsViz})`,
          backgroundPosition: 'bottom right',
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h3" color={theme.palette.Info.PrimaryDark}>
            Upgrade your tracking game!
          </Typography>
          <Typography variant="body">
            <Typography variant="label">Unlock Diagnostics</Typography> for detailed reports and smarter decisions.
          </Typography>
        </Box>
        <Box>
          <Button
            color="success"
            onClick={() => {
              window.open('https://www.ketch.com/request-pricing', '_blank', 'noopener,noreferrer')
            }}
            sx={{ backgroundColor: theme.palette.Success.Primary }}
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
