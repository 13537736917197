import { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'
import {
  getInitialValues,
  getValidationSchema,
  mapValuesToPayload,
} from 'pages/assetManager/assets/view/hooks/useAssetDetailContentRows/utils'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'

type UseInlineEditUtilsArgs = {
  assetSummary?: AssetSummaryDTO
  resourceTypeCode?: string
  onSubmitSuccess?: () => Promise<any>
}

export const useInlineEditUtils = ({ assetSummary, resourceTypeCode, onSubmitSuccess }: UseInlineEditUtilsArgs) => {
  const queryClient = useQueryClient()
  const [editRow, setEditRow] = useState<string>('')
  const { data: identitySpaces } = useIdentitySpaces()

  const initialValues = useMemo(() => getInitialValues({ assetSummary: assetSummary || undefined }), [assetSummary])
  const validationSchema = useMemo(getValidationSchema, [])

  const { mutate: handleUpdateAsset, isLoading: isUpdatingDatabase } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to update asset', type: 'error' })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([
        ApiQueryKeys.resource,
        {
          assetCode: assetSummary?.asset?.resource?.id,
        },
      ])
      if (onSubmitSuccess) {
        await onSubmitSuccess()
      }
      showToast({ content: 'Asset updated', type: 'success' })
      setEditRow('')
    },
  })

  const onSubmit = async (values: EditDatabaseFormValues) => {
    const formData = mapValuesToPayload({
      values,
      identitySpaces: identitySpaces || [],
    })

    handleUpdateAsset({
      params: { formData, assetCode: assetSummary?.asset?.resource?.id || '', resourceTypeCode },
    })
  }

  const { values, handleChange, errors, handleSubmit, setFieldValue, touched, setTouched, resetForm } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  return {
    errors,
    handleChange,
    handleSubmit,
    isUpdatingDatabase,
    resetForm,
    setFieldValue,
    setTouched,
    touched,
    values,
    initialValues,
    editRow,
    setEditRow,
    onSubmit,
  }
}

export type UseInlineEditUtilsReturnType = ReturnType<typeof useInlineEditUtils>
