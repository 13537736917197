import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import pluralize from 'pluralize'
import { useFormikContext, FieldArray, ErrorMessage } from 'formik'
import { Box, Typography, Autocomplete, InputAdornment } from '@mui/material'
import {
  SubscriptionTopicFormData,
  OrchestrationType,
  OrchestrationOptionsData,
} from 'pages/policyCenter/subscriptions/interfaces'
import { WebhookDTO, SubscriptionContactMethodDTO, AppDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  SystemOrchestration,
  WebhookOrchestration,
} from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration'
import { Button, Icon, ListItemText, ActionSheetItem, TextInput, theme } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'
import { StyledOrchestrationAutocompletePaper } from './utils'
import { ConfirmWebhookNavigationDialog } from 'components/modals/purposes/ConfirmWebhookNavigationDialog'

const PREFIX = 'Orchestration'

const classes = {
  orchestrationItem: `${PREFIX}-orchestrationItem`,
}

const Root = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.orchestrationItem}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },
}))

type Props = {
  webhooksData: WebhookDTO[]
  systemsData: AppDTO[]
  channel: SubscriptionContactMethodDTO
}

export const Orchestration: React.FC<Props> = ({ webhooksData, systemsData, channel }) => {
  const [searchString, setSearchString] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const navigate = useNavigate()
  const { setFieldValue, values, isSubmitting, setFieldTouched } = useFormikContext<SubscriptionTopicFormData>()
  const { contactMethods } = values
  const contactMethodOrchestrations = contactMethods[channel.code].orchestrations
  const contactMethodOrchestrationCodes = contactMethodOrchestrations.map(
    orchestration => orchestration?.appCode || orchestration?.webhookId,
  )

  const filteredWebhooksData = webhooksData.filter(option => !contactMethodOrchestrationCodes.includes(option?.id))

  const optionsData: OrchestrationOptionsData[] = [...systemsData, ...filteredWebhooksData]

  const options: OrchestrationOptionsData[] = optionsData.map(option => {
    if (option.url || option.testMode) {
      option as WebhookDTO
      const webhook = pluralize('Webhook', webhooksData?.length)
      return {
        webhook,
        ...option,
      }
    } else {
      option as AppDTO
      const system = pluralize('System', systemsData?.length)
      return {
        system,
        ...option,
      }
    }
  })
  return (
    <Root p={2}>
      <Box mb={1}>
        <Typography variant="label">Orchestrated via</Typography>
      </Box>

      <FieldArray
        name={`contactMethods.${channel.code}.orchestrations`}
        render={fieldArrayHelpers => {
          return (
            <>
              <Box mb={3}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  disabled={isSubmitting}
                  sx={{
                    width: 326,
                    backgroundColor: ({ palette }) => palette.white.main,
                    '& .MuiInputBase-root': {
                      '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                        padding: '0px 14px 0 6px',
                        '& .MuiInputAdornment-root': {
                          marginTop: 0,
                        },
                        '& .MuiAutocomplete-input': {
                          padding: 0,
                          marginTop: 0,
                        },
                      },
                    },
                  }}
                  options={options.sort((a, b) => {
                    if (a.system) return -1
                    if (b.system) return 1
                    return 0
                  })}
                  groupBy={option => option?.system || option?.webhook || ''}
                  renderTags={() => null}
                  getOptionLabel={option => {
                    if (typeof option !== 'string') return option?.name || ''
                    return ''
                  }}
                  PaperComponent={StyledOrchestrationAutocompletePaper}
                  renderInput={params => (
                    <TextInput
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon name="OMag" />
                          </InputAdornment>
                        ),
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                      }}
                      size="small"
                      placeholder="Select Orchestration"
                      variant="outlined"
                      onChange={e => setSearchString(e.currentTarget.value)}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ActionSheetItem key={option?.code} selected={selected} {...props}>
                      <ListItemText selected={selected}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {option.webhook ? (
                            <Icon name="WApi" width={32} height={32} />
                          ) : (
                            <img src={option?.logoUrl} alt={option?.name} width={32} height={32} />
                          )}

                          {option.name}
                        </Box>
                      </ListItemText>
                    </ActionSheetItem>
                  )}
                  onBlur={() => {
                    setSearchString('')
                    if (!contactMethods?.[channel?.code]?.orchestrations?.length)
                      setFieldTouched(`contactMethods.${channel.code}.orchestrations`, true)
                  }}
                  onChange={(e, selectedOrchestration) => {
                    setSearchString('')
                    if (!selectedOrchestration)
                      return setFieldValue(`contactMethods.${channel.code}.orchestrations`, [])
                    if (selectedOrchestration && typeof selectedOrchestration !== 'string') {
                      if (selectedOrchestration?.webhook)
                        fieldArrayHelpers.push({
                          type: OrchestrationType.WEBHOOK,
                          webhookId: selectedOrchestration?.id,
                          name: selectedOrchestration?.name,
                        })

                      if (selectedOrchestration?.system) {
                        fieldArrayHelpers.push({
                          type: OrchestrationType.SYSTEM,
                          appCode: selectedOrchestration?.code,
                          name: selectedOrchestration?.name,
                          appParams: { fieldName: '', groupID: '' },
                          appInstanceId: '',
                          appLogo: { url: selectedOrchestration?.logoUrl, title: selectedOrchestration?.name },
                        })
                      }
                    }
                  }}
                  inputValue={searchString}
                />
                <ErrorMessage name={`contactMethods.${channel.code}.orchestrations`}>
                  {msg => {
                    if (typeof msg === 'string')
                      return (
                        <Typography fontWeight={400} fontSize={12} color="chileanFire.main">
                          {msg}
                        </Typography>
                      )
                    return null
                  }}
                </ErrorMessage>
              </Box>
              {contactMethodOrchestrations.map((orchestration, fieldArrayIndex) => {
                const { appCode = '', webhookId = '' } = orchestration
                const isSystem = orchestration.type === OrchestrationType.SYSTEM
                return (
                  <Box
                    key={appCode + fieldArrayIndex || webhookId}
                    className={clsx({
                      [classes.orchestrationItem]: contactMethodOrchestrations.length - 1 !== fieldArrayIndex,
                    })}
                  >
                    {isSystem ? (
                      <SystemOrchestration
                        orchestration={orchestration}
                        fieldArrayIndex={fieldArrayIndex}
                        fieldArrayHelpers={fieldArrayHelpers}
                        channelCode={channel.code}
                      />
                    ) : (
                      <WebhookOrchestration
                        orchestration={orchestration}
                        fieldArrayIndex={fieldArrayIndex}
                        fieldArrayHelpers={fieldArrayHelpers}
                      />
                    )}
                  </Box>
                )
              })}
            </>
          )
        }}
      />

      <Box mt={3} display="flex" alignItems="flex-start" gap={1}>
        <Icon name="FUnknown" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
        <Box>
          <Typography component="div" variant="smallBody" color="darkDuskFaded.main" mt={0.25}>
            Looking for a webhook not in the list?
          </Typography>
          <Button
            variant="link"
            color="secondary"
            onClick={_ => {
              setIsConfirmationModalOpen(true)
            }}
          >
            Create New Webhook
          </Button>
        </Box>
      </Box>
      {isConfirmationModalOpen && (
        <ConfirmWebhookNavigationDialog
          onCancel={() => {
            setIsConfirmationModalOpen(false)
          }}
          onSubmit={() => {
            navigate(RoutesManager.developers.webhooks.list.pattern)
            setIsConfirmationModalOpen(false)
          }}
        />
      )}
    </Root>
  )
}
