import { FormMode } from 'interfaces/formModes/formMode'
import { getURLParams, CodeParams, IDParams, trackerURLParams } from './utils'

export interface UpsertRouteProps {
  formMode: FormMode
  code?: string
  id?: string
}

// TODO:JB - Rename to privacyProgram after production release
export const policyCenter = {
  root: {
    pattern: '/privacy-program',
    getURL: () => '/privacy-program',
  },

  businessFunctions: {
    root: {
      pattern: '/business-functions',
      getURL: () => '/privacy-program/business-functions',
    },

    list: {
      root: {
        pattern: '',
        getURL: () => '/privacy-program/business-functions',
      },
    },

    view: {
      root: {
        pattern: '/business-functions/view/:code',
        getURL: ({ code }: getURLParams) => `/privacy-program/business-functions/view/${code}`,
      },
    },

    upsert: {
      root: {
        pattern: `/business-functions/:formMode/:code`,
        getURL: ({ code, formMode }: UpsertRouteProps) => `/privacy-program/business-functions/${formMode}/${code}`,
      },
    },
  },

  policyScopes: {
    root: {
      pattern: '/jurisdictions',
      getURL: () => '/privacy-program/jurisdictions',
    },

    list: {
      root: {
        pattern: '',
        getURL: () => '/privacy-program/jurisdictions',
      },

      table: {
        root: {
          pattern: '/list',
          getURL: () => '/privacy-program/jurisdictions/list',
        },
      },

      map: {
        root: {
          pattern: '/map',
          getURL: () => '/privacy-program/jurisdictions/map',
        },
      },
    },

    view: {
      root: {
        pattern: '/jurisdictions/view/:code',
        getURL: ({ code }: getURLParams) => `/privacy-program/jurisdictions/view/${code}/rights`,
      },

      rights: {
        root: {
          pattern: '/rights',
          getURL: ({ code }: getURLParams) => `/privacy-program/jurisdictions/view/${code}/rights`,
        },
      },

      purposes: {
        root: {
          pattern: '/purposes',
          getURL: ({ code }: getURLParams) => `/privacy-program/jurisdictions/view/${code}/purposes`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: getURLParams) => `/privacy-program/jurisdictions/view/${code}/versions`,
        },
      },
    },

    library: {
      root: {
        pattern: '/jurisdictions-library',
        getURL: () => '/privacy-program/jurisdictions-library',
      },
    },

    upsert: {
      root: {
        path: `/jurisdictions/:formMode/:code`,
        pattern: `/jurisdictions/:formMode/:code`,
        getURL: ({ code, templateCode }: getURLParams & { templateCode?: string }) =>
          templateCode
            ? `/privacy-program/jurisdictions/edit/${code}?templateCode=${templateCode}`
            : `/privacy-program/jurisdictions/edit/${code}`,
      },
    },
  },

  purposes: {
    root: {
      pattern: '/purposes',
      getURL: () => '/privacy-program/purposes',
    },

    view: {
      root: {
        pattern: '/purposes/view/:code/*',
        getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/overview`,
        },
      },

      legalBases: {
        root: {
          pattern: '/legal-bases',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/legal-bases`,
        },
      },

      display: {
        root: {
          pattern: '/display',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/display`,
        },
      },

      cookies: {
        root: {
          pattern: '/cookies',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/cookies`,
        },
      },

      trackers: {
        root: {
          pattern: '/trackers',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/trackers`,
        },
      },

      // NOTE :: this is a demo page (to be removed in future)
      data: {
        root: {
          pattern: '/data',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/data`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/versions`,
        },
      },

      businessFunctions: {
        root: {
          pattern: '/business-functions',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/business-functions`,
        },
      },

      dataSystems: {
        root: {
          pattern: '/data-systems',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/data-systems`,
        },
      },

      processingActivities: {
        root: {
          pattern: '/processing-activities',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/processing-activities`,
        },
      },

      // NOTE: this is a demo page (to be removed in future)
      documents: {
        root: {
          pattern: '/documents',
          getURL: ({ code }: CodeParams) => `/privacy-program/purposes/view/${code}/documents`,
        },
      },
    },

    library: {
      root: {
        pattern: '/purposes/library',
        getURL: () => '/privacy-program/purposes/library',
      },
    },

    upsert: {
      root: {
        path: `/purposes/:formMode/:code`,
        pattern: `/purposes/:formMode/:code`,
        getURL: ({
          code,
          templateCode,
          collectionCode,
        }: getURLParams & { templateCode?: string; collectionCode?: string }) =>
          templateCode && collectionCode
            ? `/privacy-program/purposes/edit/${code}?templateCode=${templateCode}&collectionCode=${collectionCode}`
            : `/privacy-program/purposes/edit/${code}`,
      },
    },
  },

  policyDocuments: {
    root: {
      pattern: '/documents',
      getURL: () => '/privacy-program/documents',
    },

    view: {
      root: {
        pattern: '/documents/view/:code',
        getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}/overview`,
        },
      },

      deployments: {
        root: {
          pattern: '/deployments',
          getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}/deployments`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}/versions`,
        },
      },

      purposes: {
        root: {
          pattern: '/purposes',
          getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}/purposes`,
        },
      },

      content: {
        root: {
          pattern: '/content',
          getURL: ({ code }: CodeParams) => `/privacy-program/documents/view/${code}/content`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/documents/edit/:code',
        getURL: ({ code }: CodeParams) => `/privacy-program/documents/edit/${code}`,
      },
    },
  },

  cookies: {
    root: {
      pattern: '/cookies',
      getURL: () => '/privacy-program/cookies',
    },

    view: {
      root: {
        pattern: '/cookies/view/:code',
        getURL: ({ code }: getURLParams) => `/privacy-program/cookies/view/${code}`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ code }: getURLParams) => `/privacy-program/cookies/view/${code}/overview`,
        },
      },

      applications: {
        root: {
          pattern: '/applications',
          getURL: ({ code }: getURLParams) => `/privacy-program/cookies/view/${code}/applications`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: getURLParams) => `/privacy-program/cookies/view/${code}/versions`,
        },
      },
    },

    library: {
      root: {
        pattern: '/cookies/library',
        getURL: () => '/privacy-program/cookies/library',
      },
    },

    upsert: {
      root: {
        pattern: '/cookies/edit/:code',
        getURL: ({ code, ketchCode }: getURLParams & { ketchCode?: string }) =>
          ketchCode
            ? `/privacy-program/cookies/edit/${code}?ketchCode=${ketchCode}`
            : `/privacy-program/cookies/edit/${code}`,
      },
    },
  },

  controllers: {
    root: {
      pattern: '/controllers',
      getURL: () => '/privacy-program/controllers',
    },

    upsert: {
      root: {
        pattern: '/controllers/edit/:code',
        getURL: ({ code }: CodeParams) => `/privacy-program/controllers/edit/${code}`,
      },
    },
  },

  policies: {
    list: {
      pattern: '/policies',
      getURL: () => '/privacy-program/policies',
    },
    detail: {
      pattern: `/policies/view/:id`,
      getURL: ({ id }: IDParams) => `/privacy-program/policies/view/${id}`,

      overview: {
        pattern: `/policies/view/:id/overview`,
        getURL: ({ id }: IDParams) => `/privacy-program/policies/view/${id}/overview`,
      },

      logic: {
        pattern: `/policies/view/:id/logic`,
        getURL: ({ id }: IDParams) => `/privacy-program/policies/view/${id}/logic`,
      },

      versions: {
        pattern: `/policies/view/:id/versions`,
        getURL: ({ id }: IDParams) => `/privacy-program/policies/view/${id}/versions`,
      },
    },
    upsert: {
      pattern: `/policies/edit/:id`,
      getURL: ({ id }: IDParams) => `/privacy-program/policies/edit/${id}`,
    },
  },

  subscriptions: {
    root: {
      pattern: '/subscriptions',
      getURL: () => '/consent-and-rights/experiences-home/subscriptions',
    },
    list: {
      subscriptionTopics: {
        root: {
          pattern: '/topics',
          getURL: () => '/consent-and-rights/experiences-home/subscriptions/topics',
        },
      },
      subscriptionControls: {
        root: {
          pattern: '/controls',
          getURL: () => '/consent-and-rights/experiences-home/subscriptions/controls',
        },
      },
    },

    view: {
      subscriptionTopic: {
        root: {
          pattern: '/consent-and-rights/experiences-home/subscriptions/topic/view/:code',
          getURL: ({ code }: getURLParams) => `/consent-and-rights/experiences-home/subscriptions/topic/view/${code}`,
        },
      },
      subscriptionControl: {
        root: {
          pattern: '/consent-and-rights/experiences-home/subscriptions/control/view/:code',
          getURL: ({ code }: getURLParams) => `/consent-and-rights/experiences-home/subscriptions/control/view/${code}`,
        },
      },
    },

    upsert: {
      subscriptionTopic: {
        root: {
          pattern: `/consent-and-rights/experiences-home/subscriptions/topic/:formMode/:code`,
          getURL: ({ code, formMode }: UpsertRouteProps) =>
            `/consent-and-rights/experiences-home/subscriptions/topic/${formMode}/${code}`,
        },
      },
      subscriptionControl: {
        root: {
          pattern: `/consent-and-rights/experiences-home/subscriptions/control/:formMode/:code`,
          getURL: ({ code, formMode }: UpsertRouteProps) =>
            `/consent-and-rights/experiences-home/subscriptions/control/${formMode}/${code}`,
        },
      },
    },
  },

  assessments: {
    root: {
      pattern: '/privacy-program/assessments',
      getURL: () => '/privacy-program/assessments',
    },

    list: {
      pattern: '/list',
      getURL: () => '/privacy-program/assessments/list',
    },

    create: {
      pattern: '/privacy-program/assessments/create',
      getURL: () => '/privacy-program/assessments/create',
    },

    edit: {
      pattern: `/privacy-program/assessments/edit/:code`,
      getURL: ({ code }: { code: string }) => `/privacy-program/assessments/edit/${code}`,

      approved: {
        pattern: `/privacy-program/assessments/edit/approved/:code`,
        getURL: ({ code }: { code: string }) => `/privacy-program/assessments/edit/approved/${code}`,
      },
    },

    view: {
      pattern: `/privacy-program/assessments/view/:code`,
      getURL: ({ code }: { code: string }) => `/privacy-program/assessments/view/${code}`,
    },

    show: {
      pattern: `/privacy-program/assessments/show/:code`,
      getURL: ({ code }: { code: string }) => `/privacy-program/assessments/show/${code}`,
    },

    templates: {
      root: {
        pattern: '/templates',
        getURL: () => '/privacy-program/assessments/templates',
      },

      list: {
        pattern: '/templates',
        getURL: () => '/privacy-program/assessments/templates',
      },

      upsert: {
        pattern: `/privacy-program/assessments/templates/:formMode/:code`,
        getURL: ({ code = 'new', formMode }: UpsertRouteProps) =>
          `/privacy-program/assessments/templates/${formMode}/${code}`,
      },

      view: {
        pattern: `/privacy-program/assessments/templates/view/:code`,
        getURL: ({ code }: { code: string }) => `/privacy-program/assessments/templates/view/${code}`,
      },

      published: {
        pattern: `/privacy-program/assessments/templates/published/:code`,
        getURL: ({ code }: { code?: string }) => `/privacy-program/assessments/templates/published/${code}`,
      },

      show: {
        pattern: `/privacy-program/assessments/templates/show/:code`,
        getURL: ({ code, isPublished }: { code: string; isPublished?: boolean }) =>
          `/privacy-program/assessments/templates/show/${code}${isPublished === true ? '?published=true' : ''}`,
      },
    },

    questions: {
      root: {
        pattern: '/questions',
        getURL: () => '/privacy-program/assessments/questions',
      },

      upsert: {
        pattern: `/privacy-program/assessments/questions/:formMode/:code`,
        getURL: ({ code = 'new', formMode }: UpsertRouteProps) =>
          `/privacy-program/assessments/questions/${formMode}/${code}`,
      },

      published: {
        pattern: `/privacy-program/assessments/questions/published/:code`,
        getURL: ({ code }: { code?: string }) => `/privacy-program/assessments/questions/published/${code}`,
      },

      view: {
        pattern: `/privacy-program/assessments/questions/view/:code`,
        getURL: ({ code }: { code: string }) => `/privacy-program/assessments/questions/view/${code}`,
      },
    },
  },

  processingActivities: {
    root: {
      pattern: '/processing-activities',
      getURL: () => '/privacy-program/processing-activities',
    },

    view: {
      root: {
        pattern: '/processing-activities/:id/view/*',
        getURL: ({ id }: IDParams) => `/privacy-program/processing-activities/${id}/view`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/privacy-program/processing-activities/${id}/view/overview`,
        },
      },

      dataSources: {
        root: {
          pattern: '/data-sources',
          getURL: ({ id }: IDParams) => `/privacy-program/processing-activities/${id}/view/data-sources`,
        },
      },

      dataFlow: {
        root: {
          pattern: '/data-flow',
          getURL: ({ id }: IDParams) => `/privacy-program/processing-activities/${id}/view/data-flow`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ id }: IDParams) => `/privacy-program/processing-activities/${id}/view/versions`,
        },
      },
    },

    upsert: {
      pattern: '/privacy-program/processing-activities/:formMode/:id',
      getURL: ({ id, formMode }: UpsertRouteProps) => `/privacy-program/processing-activities/${formMode}/${id}`,
    },
  },

  trackers: {
    root: {
      pattern: '/consent-and-rights/trackers',
      getURL: () => '/consent-and-rights/trackers',
    },
    list: {
      approvedTrackers: {
        root: {
          pattern: '/consent-and-rights/trackers/approved',
          getURL: () => '/consent-and-rights/trackers/approved',
        },
      },
    },
    details: {
      root: {
        pattern: '/consent-and-rights/trackers/view/:encodedTrackerKey',
        getURL: ({ encodedTrackerKey }: trackerURLParams) => `/consent-and-rights/trackers/view/${encodedTrackerKey}`,
      },
      overview: {
        root: {
          pattern: '/consent-and-rights/trackers/view/:encodedTrackerKey/overview',
          getURL: ({ encodedTrackerKey }: trackerURLParams) =>
            `/consent-and-rights/trackers/view/${encodedTrackerKey}/overview`,
        },
      },
      propertiesAndVisibility: {
        root: {
          pattern: '/consent-and-rights/trackers/view/:encodedTrackerKey/properties-visibility',
          getURL: ({ encodedTrackerKey }: trackerURLParams) =>
            `/consent-and-rights/trackers/view/${encodedTrackerKey}/properties-visibility`,
        },
      },
      trackerDiagnostics: {
        root: {
          pattern: '/consent-and-rights/trackers/view/:encodedTrackerKey/tracker-diagnostics',
          getURL: ({ encodedTrackerKey }: trackerURLParams) =>
            `/consent-and-rights/trackers/view/${encodedTrackerKey}/tracker-diagnostics`,
        },
      },
    },
    settings: {
      root: {
        pattern: '/consent-and-rights/trackers/view/settings',
        getURL: () => '/consent-and-rights/trackers/view/settings',
      },
    },
  },
}
