import React, { useState } from 'react'
import { useFormik } from 'formik'
import { InfoRow, InfoRowSize, TextInput } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import * as Yup from 'yup'

type FormValues = {
  smallInfoRowValue: string
  smallInfoRowPrevValue: string
  regularInfoRowValue: string
  regularInfoRowPrevValue: string
}

export const getValidationSchema = () =>
  Yup.object().shape({
    smallInfoRowValue: Yup.string().required(),
    regularInfoRowValue: Yup.string().required(),
  })

export const InfoRowExample: React.FC = () => {
  const [isSmallInfoRowEditing, setIsSmallInfoRowEditing] = useState(false)
  const [isRegularInfoRowEditing, setIsRegularInfoRowEditing] = useState(false)
  const [isInfoRowLoading, setIsInfoRowLoading] = useState(false)

  const formik = useFormik<FormValues>({
    initialValues: {
      smallInfoRowValue: '',
      smallInfoRowPrevValue: '',
      regularInfoRowValue: '',
      regularInfoRowPrevValue: '',
    },
    onSubmit: () => {},
    validationSchema: getValidationSchema(),
  })

  return (
    <>
      <Typography variant="h3" mb={2}>
        Info Row component
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '28px',
        }}
      >
        <InfoRow
          size={InfoRowSize.small}
          title="Small read-only InfoRow"
          titleAddendum="label"
          titleAddendumIcon="OLabel"
        >
          Some content here Some content here Some content here
        </InfoRow>
        <InfoRow title="Regular read-only InfoRow" titleAddendum="label" titleAddendumIcon="OLabel">
          Some content here Some content here Some content here
        </InfoRow>
        <InfoRow
          title="Small editable InfoRow"
          titleAddendum="label"
          titleAddendumIcon="OLabel"
          isEditable
          size={InfoRowSize.small}
          isEditing={isSmallInfoRowEditing}
          isLoading={isInfoRowLoading}
          onEditChange={() => {
            setIsSmallInfoRowEditing(true)
            formik.setFieldValue('smallInfoRowPrevValue', formik.values.smallInfoRowValue)
          }}
          onAcceptChange={() => {
            setIsInfoRowLoading(true)
            setTimeout(() => {
              setIsInfoRowLoading(false)
              setIsSmallInfoRowEditing(false)
            }, 1000)
          }}
          onCancelChange={() => {
            setIsSmallInfoRowEditing(false)
            formik.setFieldValue('smallInfoRowValue', formik.values.smallInfoRowPrevValue)
          }}
          onDeleteChange={() => {
            setIsSmallInfoRowEditing(false)
            formik.setFieldValue('smallInfoRowValue', '')
            formik.setFieldValue('smallInfoRowPrevValue', '')
          }}
          isEmpty={!!!formik.values.smallInfoRowValue}
          isValidation={!!formik.errors.smallInfoRowValue}
        >
          {isSmallInfoRowEditing ? (
            <TextInput
              autoFocus
              placeholder="Placeholder"
              multiline={true}
              rows={2}
              fullWidth={true}
              value={formik.values.smallInfoRowValue}
              onChange={event => {
                formik.setFieldValue('smallInfoRowValue', event.target.value)
              }}
            />
          ) : (
            formik.values.smallInfoRowValue || 'None'
          )}
        </InfoRow>
        <InfoRow
          title="Regular editable InfoRow"
          titleAddendum="label"
          titleAddendumIcon="OLabel"
          isEditable
          size={InfoRowSize.medium}
          isValidation={!!formik.errors.regularInfoRowValue}
          isEditing={isRegularInfoRowEditing}
          isLoading={isInfoRowLoading}
          onEditChange={() => {
            setIsRegularInfoRowEditing(true)
            formik.setFieldValue('regularInfoRowPrevValue', formik.values.regularInfoRowValue)
          }}
          onAcceptChange={() => {
            setIsInfoRowLoading(true)
            setTimeout(() => {
              setIsInfoRowLoading(false)
              setIsRegularInfoRowEditing(false)
            }, 1000)
          }}
          onCancelChange={() => {
            setIsRegularInfoRowEditing(false)
            formik.setFieldValue('regularInfoRowValue', formik.values.regularInfoRowPrevValue)
          }}
          onDeleteChange={() => {
            setIsRegularInfoRowEditing(false)
            formik.setFieldValue('regularInfoRowValue', '')
            formik.setFieldValue('regularInfoRowPrevValue', '')
          }}
          isEmpty={!!!formik.values.regularInfoRowValue}
        >
          {isRegularInfoRowEditing ? (
            <TextInput
              autoFocus
              placeholder="Placeholder"
              multiline={true}
              rows={2}
              fullWidth={true}
              value={formik.values.regularInfoRowValue}
              onChange={event => {
                formik.setFieldValue('regularInfoRowValue', event.target.value)
              }}
            />
          ) : (
            formik.values.regularInfoRowValue || 'None'
          )}
        </InfoRow>
      </Box>
      <pre>{JSON.stringify(formik.values, null, 2) || 'nothing to preview'}</pre>
    </>
  )
}
