import React, { useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { IdvDetails, IdvDetailsHeaderDetails, IdvDetailsHeaderActions } from './components'
import { StepDetailsColumn, WorkflowStepHeaderIcon } from 'pages/orchestration/rightsQueue/stepDetails/components'

import { IdentityVerificationContext, withIdentityVerificationContext } from './context/IdentityVerificationContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Box } from '@mui/material'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { useNavigate } from 'react-router-dom'

const IdentityVerificationWithContext: React.FC = () => {
  const { isReady, breadcrumbs, workflowStep, rightInvocationId } = useContext(IdentityVerificationContext)

  const navigate = useNavigate()

  if (!isReady) {
    return (
      <>
        <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      </>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs.filter(b => !!b?.title)} />
      <ViewLayout
        contentGroupProps={{
          title: workflowStep?.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () =>
              navigate(RoutesManager.orchestration.rightsQueue.view.workflow.root.getURL({ id: rightInvocationId })),
          },
          imageBlockComponent: <WorkflowStepHeaderIcon />,
          infoBlockComponent: <IdvDetailsHeaderDetails />,
          actionRightBlockComponent: <IdvDetailsHeaderActions />,
        }}
        widgetContentComponent={<StepDetailsColumn />}
      >
        <IdvDetails />
      </ViewLayout>
    </>
  )
}

export const IdentityVerification = withIdentityVerificationContext(IdentityVerificationWithContext)
