import React from 'react'
import Box from '@mui/material/Box'

import { TaskCardCompleted, TaskCardTodo, TasksListFilters } from './components'
import { useFilters } from 'utils/hooks/useFiltersV2'
import { useInfiniteTasks } from 'api/tasks/queries/useInfiniteTasks'

import { Waypoint } from 'react-waypoint'

import { ContentGroup, EmptyState, Spinner } from '@ketch-com/deck'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const TasksList: React.FC = () => {
  const { searchParams } = useFilters()

  const breadcrumbs = [
    {
      title: 'My Updates',
      link: RoutesManager.updates.root.getURL(),
    },
    { title: 'Tasks' },
  ]

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteTasks({
    query: searchParams.get('search') || '',
    status: searchParams.get('status') || '1',
  })

  const { isLoading: isLoadingTasksTodoTotalResults } = useInfiniteTasks({
    status: '1',
  })

  const { isLoading: isLoadingTasksCompletedTotalResults } = useInfiniteTasks({
    status: '2',
  })

  const isLoadingAggregate = isLoadingTasks || isLoadingTasksTodoTotalResults || isLoadingTasksCompletedTotalResults

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3}>
        <ContentGroup isDivider title="Tasks" actionBottomBlockComponent={<TasksListFilters count={tasks.length} />} />

        <Box
          sx={{
            borderRadius: 3,
            backgroundColor: 'white.main',
            padding: '14px',
          }}
        >
          {isLoadingAggregate ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : null}
          {tasks?.length > 0 && !isLoadingAggregate ? (
            <Box flexDirection="column" display="flex">
              {tasks?.map((task, index: number) => {
                const isLast = index === tasks?.length - 1

                if (task?.isCompleted) {
                  return <TaskCardCompleted isFirst={index === 0} isLast={isLast} task={task} key={task.id} />
                } else {
                  return <TaskCardTodo isLast={isLast} task={task} key={task.id} />
                }
              })}

              <Waypoint
                onEnter={() => {
                  if (hasNextPage) fetchNextPage()
                }}
              />

              <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
            </Box>
          ) : null}
          {!(tasks?.length > 0) && !isLoadingAggregate && !searchParams.get('search') ? (
            <Box
              mt={2.5}
              bgcolor="bone.main"
              borderRadius="11px"
              py={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <EmptyState
                iconName="OChatAlert"
                title="All Caught Up!"
                subTitle="There are no active tasks that require your attention"
              />
            </Box>
          ) : null}

          {!(tasks?.length > 0) && !isLoadingAggregate && searchParams.get('search') ? (
            <Box
              mt={2.5}
              bgcolor="bone.main"
              borderRadius="11px"
              py={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <EmptyState
                iconName={'OFilter'}
                title="No data matches the filter criteria"
                subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  )
}
