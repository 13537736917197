import Box from '@mui/material/Box'

import { Icon, SwitchToggle, theme } from '@ketch-com/deck'
import { SxProps, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { getIn, useFormikContext } from 'formik'
import { ExperienceConfig, ThemeConfig } from '@ketch-sdk/ketch-types'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'

type Props = {
  title: string
  name: string
  size?: 'small' | 'medium'
  tooltip?: string
  sx?: SxProps
  onHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitchBox = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 14px',
  gap: '18px',
  borderRadius: '11px',
}))

export const FormSectionSwitch: React.FC<Props> = ({
  title,
  name,
  size = 'medium',
  tooltip = '',
  sx,
  onHandleChange,
}) => {
  const { values, setFieldValue } = useFormikContext<ExperienceConfig | ThemeConfig>()
  const checked = Boolean(getIn(values, name || ''))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (name) setFieldValue(name, e.target.checked)
    onHandleChange?.(e)
  }

  return (
    <SwitchBox sx={{ background: checked ? theme.palette.tara.main : theme.palette.fadedGrey.main, ...sx }}>
      <SwitchToggle size={size} checked={checked} onChange={handleChange} />
      <Box display={'flex'} gap={1}>
        <Typography
          variant={size === 'small' ? 'label' : 'h4'}
          color={checked ? theme.palette.greenHaze.main : theme.palette.darkDusk.main}
        >
          {title}
        </Typography>
        {tooltip && (
          <TooltipV2 title={tooltip} arrow={true} placement={'bottom'} sx={{ width: '229px' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name={'FUnknown'} width={16} height={16} iconColor={theme.palette.Text.Secondary} />
            </span>
          </TooltipV2>
        )}
      </Box>
    </SwitchBox>
  )
}
