import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { showToast } from 'components/modals/AlertComponent'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { StepIcon } from 'pages/orchestration/workflows/edit/components/stepIcon/StepIcon'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem, theme } from '@ketch-com/deck'
import { WorkflowActivityCode, WorkflowActivityGatewayMode } from 'interfaces/workflowActivities/workflowActivity'

import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import { EditWorkflowContainerContext } from '../../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { getStepType } from '../../../utils/steps/getStepType'
import { CanvasStepType } from '../../../utils/constants'
import { isUndefined } from 'lodash'
import { getNextJoinTile } from '../../../utils/steps/getNextJoinTile'
import { getStepsInPath } from '../../../utils/steps/getStepsInPath'
import { WorkflowCanvasContext } from '../../WorkflowCanvas/context/WorkflowCanvasContext'
import { getStepsInGateway } from '../../../utils/steps/getStepsInGateway'

/**
 * Helper function that checks if the step the user is attempting to remove immediately
 * precedes a Decision Boolean tile, and, if so, returns false to prevent removal */
export const getShouldPreventRemoval = ({ step, steps }: { step: ICanvasStep; steps: ICanvasStep[] }) => {
  const transitionStep = steps.find(({ ID }) => ID === step?.activity?.transition)
  if (transitionStep?.activityCode === WorkflowActivityCode.DECISION_BOOLEAN) {
    return true
  } else {
    return false
  }
}

interface ISidebarConfigsHeaderProps {
  isValid: boolean
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleSave?: () => void
  handleRemove?: () => void
  handleClose: () => void
  hoverContent?: string
  containerSx?: SxProps<Theme>
  removable?: boolean
  copyable?: boolean
}

export const SidebarConfigsHeader: React.FC<ISidebarConfigsHeaderProps> = ({
  step,
  handleSave,
  handleRemove,
  handleClose,
  steps = [],
  hoverContent,
  containerSx,
  removable = true,
  copyable = false,
}) => {
  const { name } = step

  const shouldPreventRemoval = getShouldPreventRemoval({ steps, step })
  const { handleCopyActivity, handleCopyGateway } = useContext(EditWorkflowContainerContext)
  const { removeActiveSteps, setHoveredCopySteps } = useContext(WorkflowCanvasContext)
  const stepType = getStepType(step)
  const handleCopy = useCallback(() => {
    if (stepType === CanvasStepType.Gateway) {
      handleCopyGateway(step, steps)
    } else {
      handleCopyActivity(step)
    }
    setHoveredCopySteps([])
    removeActiveSteps()
  }, [handleCopyActivity, handleCopyGateway, removeActiveSteps, setHoveredCopySteps, step, stepType, steps])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const disabledCopyMessage = useRef('Copy not allowed for this workflow step')
  const disableCopy = useMemo(() => {
    // Disable copying of an invalid step
    if (isUndefined(step.valid) || !step.valid) {
      disabledCopyMessage.current = `Please complete step configuration before copying`
      return true
    }

    if (stepType === CanvasStepType.Gateway) {
      // Initially get the gateway and join tile (if it exists)
      const nextJoin = getNextJoinTile({ step, steps })!
      const gatewaySteps: ICanvasStep[] = [step, ...(nextJoin ? [nextJoin] : [])]
      const isDecisionBoolean = step.gateway?.mode === WorkflowActivityGatewayMode.DECISION_BOOLEAN

      // Get all steps in all paths
      step.gateway?.transitions.forEach(transition => {
        gatewaySteps.push(
          ...getStepsInPath({
            pathStartStepId: transition.ID?.toString() || '',
            steps,
            untilEnd: isDecisionBoolean,
          }),
        )
      })

      if (
        gatewaySteps.some(
          gatewayStep =>
            getStepType(gatewayStep) !== CanvasStepType.Placeholder &&
            (isUndefined(gatewayStep.valid) || !gatewayStep.valid),
        )
      ) {
        disabledCopyMessage.current = 'A gateway containing an invalid step cannot be copied'
        return true
      }
    }

    return false
  }, [step, stepType, steps])

  const otherOptionsMenuItems = []
  if (removable && handleRemove) {
    otherOptionsMenuItems.push(
      <ActionSheetItem
        key="remove"
        onClick={() => {
          shouldPreventRemoval
            ? showToast({ content: 'Cannot remove tile preceding a Decision Boolean', type: 'error' })
            : handleRemove()
          handleCloseMenu()
        }}
      >
        Remove
      </ActionSheetItem>,
    )
  }
  if (removable && handleRemove && copyable) {
    otherOptionsMenuItems.push(<ActionSheetItem divider key="divider" />)
  }
  if (copyable) {
    otherOptionsMenuItems.push(
      <Tooltip key="copy" title={disableCopy ? disabledCopyMessage.current : undefined}>
        <Box key="copy">
          <ActionSheetItem
            disabled={disableCopy}
            onMouseEnter={() => {
              // Hover all steps in gateway
              if (stepType === CanvasStepType.Gateway) setHoveredCopySteps(getStepsInGateway({ gateway: step, steps }))
            }}
            onMouseLeave={() => {
              setHoveredCopySteps([])
            }}
            onClick={() => {
              handleCopy()
              handleCloseMenu()
            }}
          >
            Copy
          </ActionSheetItem>
        </Box>
      </Tooltip>,
    )
  }

  return (
    <Box
      sx={{
        padding: '18px 24px',
        width: '100%',
        ...containerSx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
          paddingBottom: '18px',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',

          // Fix Safari issue
          minHeight: 65,
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            variant="iconCustomRounded"
            sx={{
              mr: 2,
            }}
          >
            <Icon name="OCross" onClick={() => handleClose()} />
          </Button>
          <StepIcon
            step={step}
            sx={{
              marginRight: 1,
            }}
          />
          <Typography variant="h3" maxWidth={hoverContent ? 195 : undefined}>
            {name}
          </Typography>
          {hoverContent ? (
            <Tooltip title={hoverContent}>
              <Box display="flex" alignItems="center" ml={2}>
                <Icon name="FUnknown" iconColor={theme.palette.Text.Secondary} height={20} width={20} />
              </Box>
            </Tooltip>
          ) : null}
        </Box>

        <Box display="inline-flex" gap={1.5} alignItems="center">
          {!!otherOptionsMenuItems.length && (
            <>
              <Button size="large" variant="icon" onClick={handleClickListItem} color="tertiary">
                <Icon name="OMore" />
              </Button>
              <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleCloseMenu} transitionDuration={0}>
                {otherOptionsMenuItems}
              </ActionSheetWrapper>
            </>
          )}

          {handleSave ? (
            <Button size="large" onClick={handleSave}>
              Save
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
