import React from 'react'
import Box from '@mui/material/Box'
import { RightsQueueOverviewTabAppealDetails, MetadataCardList, SubjectDetails, WorkflowDetails } from './components'

export const RightsQueueOverviewTab: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      {/* Metadata Chip Row */}
      <MetadataCardList />
      {/* Subject Details */}
      <SubjectDetails />
      {/* Workflow Details */}
      <WorkflowDetails />
      {/* Appeal Details */}
      <RightsQueueOverviewTabAppealDetails />
    </Box>
  )
}
