import { useUserMe } from 'api/users/queries/useUserMe'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useMemo } from 'react'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'

export const useDataMapFeatureFlags = () => {
  const { data: user } = useUserMe()
  const ketchSupportId = 'google-apps|support@ketchsupport.com'

  const isKetchSupport = useMemo(() => user?.ID === ketchSupportId, [user?.ID])
  const { isFeatureFlagEnabled } = useFeatureFlag()

  const showAssetsTab = useMemo(
    () =>
      isFeatureFlagEnabled(FEATURE_FLAGS.SYSTEMS_ASSET_TAB, FEATURE_FLAGS_VALUES.SYSTEMS_ASSET_TAB_ENABLED) ||
      isKetchSupport,
    [isFeatureFlagEnabled, isKetchSupport],
  )

  const showRightsOrchestrationTab = useMemo(
    () =>
      isFeatureFlagEnabled(
        FEATURE_FLAGS.SYSTEMS_RIGHTS_ORCHESTRATION_TAB,
        FEATURE_FLAGS_VALUES.SYSTEMS_RIGHTS_ORCHESTRATION_TAB,
      ) || isKetchSupport,
    [isFeatureFlagEnabled, isKetchSupport],
  )

  const value = {
    showAssetsTab,
    showRightsOrchestrationTab,
  }

  return value
}
