import { useMemo } from 'react'
import * as Yup from 'yup'
import { mapValues } from 'lodash'
import { legacyProtocols, lspaProtocols, mspaProtocols } from '../../constants'
import { MSPAChoice } from 'interfaces/privacyProtocols'
import {
  PrivacyProtocol,
  PrivacyProtocolConfigurationPutRequestBody,
  PrivacyProtocolConfigurationPutRequestBodyStatus,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { Jurisdiction } from '@ketch-com/supercargo/dist/jurisdiction_gen.schemas'

// Schema for validating a non-empty object with arbitrary string keys and string array values
const stringToArrayDTOSchema = Yup.lazy(obj =>
  Yup.object(mapValues(obj, () => Yup.array().of(Yup.string()).min(1).required())).test({
    name: 'non-empty',
    test: obj => Object.keys(obj).length !== 0,
    message: 'must not be empty',
  }),
)

// Schema for validating an object with arbitrary string keys and boolean values
const stringToBooleanOrStringDTOSchema = Yup.lazy(obj =>
  Yup.object(
    mapValues(obj, () =>
      Yup.lazy(value => (typeof value === 'boolean' ? Yup.boolean().required() : Yup.string().required())),
    ),
  ),
)

export const usePrivacyProtocolsValidationSchema = (
  supportsJurisdictionSelection: boolean,
  supportsPurposeMapping: boolean,
  supportsVendorSelection: boolean,
  supportsOtherOptions: boolean,
) => {
  return useMemo(
    () =>
      Yup.object().shape({
        enabled: Yup.boolean(),
        protocolId: Yup.string(),
        selectAllJurisdictions: Yup.boolean(),
        selectAllVendors: Yup.boolean(),

        ...(supportsJurisdictionSelection && {
          jurisdictions: Yup.array()
            .of(Yup.string())
            .when('selectAllJurisdictions', {
              is: true,
              then: schema => schema.notRequired(),
              otherwise: schema => schema.min(1).required(),
            }),
        }),

        ...(supportsPurposeMapping && {
          purposeMapping: stringToArrayDTOSchema,
        }),

        ...(supportsVendorSelection && {
          vendors: Yup.array()
            .of(Yup.string())
            .when('selectAllVendors', {
              is: true,
              then: schema => schema.notRequired(),
              otherwise: schema => schema.min(1).notRequired(),
            }),
        }),

        ...(supportsOtherOptions && {
          options: stringToBooleanOrStringDTOSchema,
        }),
      }),
    [supportsJurisdictionSelection, supportsPurposeMapping, supportsVendorSelection, supportsOtherOptions],
  )
}

export const getInitialValues = (protocol: PrivacyProtocol): PrivacyProtocolConfigurationPutRequestBody => ({
  status: PrivacyProtocolConfigurationPutRequestBodyStatus.enabled,
  jurisdictions: [],
  // MSPA and LSPA are not configured by default
  options: {
    ...(mspaProtocols.includes(protocol.id) && {
      mspaCovered: false,
      mspaMode: MSPAChoice.optOut,
    }),
    ...(lspaProtocols.includes(protocol.id) && {
      lspaCovered: false,
    }),
  },
  // For single purpose protocols, default that purpose to enabled with no mapped purpose
  purposeMapping: protocol.purposes.length === 1 ? { [protocol.purposes[0].code]: [] } : {},
  // Select all jurisdictions only supported in legacy protocols, so ensure the
  // select all field is false in that case and when jurisdiction selection isn't supported at all
  selectAllJurisdictions: protocol.supportsJurisdictionSelection && legacyProtocols.includes(protocol.id),
  selectAllVendors: protocol.supportsVendorSelection,
  vendors: [],
})

/**
 * Remove jurisdictions that no longer exist in the jurisdictions list
 * @param formData - The form data to remove jurisdictions from
 * @param jurisdictions - The jurisdictions to remove from the form data
 * @returns The form data with the deleted jurisdictions removed
 */
export const removeDeletedJurisdictions = (
  formData: PrivacyProtocolConfigurationPutRequestBody,
  jurisdictions: Jurisdiction[],
) => ({
  ...formData,
  jurisdictions: formData.jurisdictions.filter(code => !!jurisdictions.find(j => j.code === code)),
})
