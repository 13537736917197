import { GetTrackerFoundOnParams, GetTrackerFoundOnPages } from '@ketch-com/atlas/dist/apiTypes/foundOn'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerFoundOnPages = ({
  after,
  limit,
  startDate,
  endDate,
  property_code,
  encodedTrackerKey,
}: GetTrackerFoundOnParams & {
  encodedTrackerKey?: string
}) => {
  return API.get<GetTrackerFoundOnPages>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/found-on/${encodedTrackerKey}`,
      params: {
        filters: {
          ...(after && {
            after,
          }),
          ...(limit && {
            limit,
          }),
          ...(startDate && {
            startDate,
          }),
          ...(endDate && {
            endDate,
          }),
          ...(property_code && {
            property_code,
          }),
        },
      },
    }),
  )
}
