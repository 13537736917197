import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { DatasetScenarioContainer, DatabaseScenarioContainer } from 'pages/assetManager/scenario/list/components'
import { Logger } from 'utils/Logger'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

const isDatabaseResource = (resourceTypeCode: string) => resourceTypeCode.includes('.database')

/**
 * @deprecated
 */
export const ScenarioList: React.FC<Props> = ({ assetSummary }) => {
  const resourceTypeCode = assetSummary?.asset?.resource?.resourceType?.code
  if (!assetSummary || !assetSummary.asset || !assetSummary.asset.resource) {
    Logger.error(`Malformed Asset detected. --> ${JSON.stringify(assetSummary)}`)
    return null
  }
  if (!resourceTypeCode) {
    Logger.error(`Resource type code is not available for ${assetSummary.asset.resource.id}`)
    return null
  }

  if (isDatabaseResource(resourceTypeCode)) {
    return <DatabaseScenarioContainer />
  } else {
    return <DatasetScenarioContainer assetSummary={assetSummary} />
  }
}
