import { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Status, StatusVariant, StatusState, Chip } from '@ketch-com/deck'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { EndFlowContext } from '../context/EndFlowContext'

export const EndDetailsHeaderDetails: React.FC = () => {
  const { workflowStep } = useContext(EndFlowContext)
  let status: StatusState
  let label: string

  switch (workflowStep?.status) {
    case WorkflowExecutionStepStatus.FAIL:
    case WorkflowExecutionStepStatus.INVALID_CREDENTIALS:
      status = StatusState.error
      label = 'Failed'
      break
    case WorkflowExecutionStepStatus.IN_PROGRESS:
    case WorkflowExecutionStepStatus.PENDING:
      status = StatusState.inProgress
      label = 'In Progress'
      break
    default:
      status = StatusState.success
      label = 'Completed'
  }

  return (
    <Box display="flex" gap={1}>
      <Chip
        size="small"
        label={
          <Typography variant="smallLabel">
            Code: <Typography variant="smallBody">{workflowStep?.code ?? 'none'}</Typography>
          </Typography>
        }
      />
      <Status icon="Dot" status={status} variant={StatusVariant.outlined} label={label} />
    </Box>
  )
}
