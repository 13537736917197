import React from 'react'
import { EditWorkflowContextProvider } from './contexts/EditWorkflowContext'
import { EditWorkflow } from './EditWorkflow'

export const EditWorkflowContainer: React.FC = () => {
  return (
    <EditWorkflowContextProvider>
      <EditWorkflow />
    </EditWorkflowContextProvider>
  )
}
