import React, { useState } from 'react'
import { useDebounce } from 'react-use'

import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueMegaFilterButtonPanelIds } from '../constants'
import { Checkbox, Icon, Spinner, TextInput, ActionSheetItem } from '@ketch-com/deck'
import { Typography, Box } from '@mui/material'
import { UserShortDTO } from 'interfaces/users/users'
import { Team } from 'interfaces/services/harbormaster/teams_gen.schemas'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'

const panelId = RightsQueueMegaFilterButtonPanelIds.assignees

type Props = {
  userList: UserShortDTO[]
  teamList: Team[]
  isLoading: boolean
  handlePaginationReset: () => void
}

export const AssigneeFilter: React.FC<Props> = ({ userList, teamList, isLoading, handlePaginationReset }) => {
  const { removeQueryParamFromList, replaceParams, getPanelParamValuesArrayByPanelId } = useFiltersV3()
  const [searchString, setSearchString] = useState<string>('')
  const [searchLabelValue, setSearchLabelValue] = useState<string>('')

  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  useDebounce(
    () => {
      if (searchString) {
        setSearchLabelValue(searchString)
      } else {
        setSearchLabelValue('')
      }
    },
    500,
    [searchString],
  )

  const filteredUserList = userList.filter(user => {
    const isChecked = !!panelParamValuesArray.find(param => param === user.ID)
    return (
      getFullNameUserShortDTO(user).toLowerCase().includes(searchLabelValue.toLowerCase()) || isChecked // always show checked users
    )
  })

  const filteredTeamList = teamList.filter(team => {
    const isChecked = !!panelParamValuesArray.find(param => param === team.id)
    return (
      (team.name || '').toLowerCase().includes(searchLabelValue.toLowerCase()) || isChecked // always show checked teams
    )
  })

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      {/* Search */}
      <Box paddingX={1.5} paddingTop={1} paddingBottom={1.5} width="100%">
        <TextInput
          sx={{ mb: 1 }}
          value={searchString}
          startIcon={<Icon name="OMag" />}
          endIcon={
            searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
          }
          size="small"
          fullWidth
          placeholder="Search User"
          onChange={event => setSearchString(event.target.value)}
        />
      </Box>

      {isLoading ? (
        // Loading Spinner
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="16px" thickness={2} />
        </Box>
      ) : (!userList.length || !filteredUserList.length) && (!teamList.length || !filteredTeamList.length) ? (
        <Box height={34} width="100%" pl={1.5}>
          <Typography color="darkDuskFaded.main">No results found</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto', width: '100%' }}
        >
          {/* Teams Checkboxes */}

          {filteredTeamList.length > 0 ? (
            <ActionSheetItem key="teams-header" subSectionTitle>
              Teams
            </ActionSheetItem>
          ) : null}

          {filteredTeamList.map(team => {
            const isChecked = !!panelParamValuesArray.find(param => param === team.id)
            return (
              <Box key={team.id} display="flex" alignItems="center" padding="1px 3px">
                <Checkbox
                  id={team.id}
                  name={team.id}
                  title={team.name}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      removeQueryParamFromList({ key: panelId, value: team.id || '' })
                    } else {
                      const paramValues = getPanelParamValuesArrayByPanelId(panelId)
                      replaceParams({ [panelId]: [...paramValues, team.id].join(','), page: '0' })
                    }
                    handlePaginationReset()
                  }}
                />
                <Typography
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {team.name}
                </Typography>
              </Box>
            )
          })}

          {/* Users Checkboxes */}
          {filteredUserList.length > 0 ? (
            <ActionSheetItem key="user-header" subSectionTitle>
              Users
            </ActionSheetItem>
          ) : null}

          {filteredUserList.map(user => {
            const isChecked = !!panelParamValuesArray.find(param => param === user.ID)
            return (
              <Box key={user.ID} display="flex" alignItems="center" padding="1px 3px">
                <Checkbox
                  id={user.ID}
                  name={user.ID}
                  title={getFullNameUserShortDTO(user)}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      removeQueryParamFromList({ key: panelId, value: user.ID })
                    } else {
                      const paramValues = getPanelParamValuesArrayByPanelId(panelId)
                      replaceParams({ [panelId]: [...paramValues, user.ID].join(','), page: '0' })
                    }
                    handlePaginationReset()
                  }}
                />
                <Typography
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {getFullNameUserShortDTO(user)}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
