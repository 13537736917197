export function convertUTCToLocalTime(utcTime: string): string {
  // Parse the UTC time into a Date object
  const utcDate = new Date(`1970-01-01T${utcTime}Z`) // Using a dummy date since only time matters

  // Convert to local time components
  const localHours = utcDate.getHours()
  const localMinutes = utcDate.getMinutes()

  // Format local time as hh:mm am/pm
  const localAmPm = localHours >= 12 ? 'pm' : 'am'
  const formattedHours = localHours % 12 || 12 // Convert 0 to 12 for 12-hour format
  const formattedMinutes = localMinutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes} ${localAmPm}`
}
