import { useState } from 'react'
import { DatasetScenario } from 'pages/assetManager/scenario/list/components'
import { useScenarios } from 'api/assets/queries/useScenarios'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { RightType } from 'interfaces/events'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

/**
 * @deprecated
 */
export const DatasetScenarioContainer: React.FC<Props> = ({ assetSummary }) => {
  const [rightType, setRightType] = useState(RightType.RIGHT_TYPE_UNSPECIFIED)

  /*
    No pagination. Max limit is 100 scenarios for now.
    https://ketch-com.atlassian.net/browse/KD-5597
  */
  const { data: scenariosResponse = {}, isLoading: isScenariosLoading } = useScenarios({
    params: {
      start: 0,
      limit: 100,
      rightType,
      canonicalRightCode: 'get,delete',
    },
  })

  const isReady = !isScenariosLoading
  const { scenario = [] } = scenariosResponse
  if (isReady)
    return (
      <DatasetScenario
        scenarios={scenario}
        setRightType={setRightType}
        rightType={rightType}
        assetSummary={assetSummary}
      />
    )
  return <Spinner />
}
