import React, { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useContext } from 'react'
import { Button, EmptyState, theme } from '@ketch-com/deck'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ReactComponent as EmptyStateWorkflowsIcon } from 'assets/icons/emptyState/EmptyStateWorkflows.svg'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/styles'
import { RightsQueueViewContext } from '../../../context/RightsQueueViewContext'
import { useIsPermitted } from 'utils/hooks'

const MissingWorkFlowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mx: 'auto',
  gap: '24px',
  background: theme.palette.bone.main,
  borderRadius: '11px',
  padding: '50px',
})

type tooltipTitle =
  | string
  | (ReactElement<any, string | JSXElementConstructor<any>> & string)
  | (Iterable<ReactNode> & string)
  | (ReactPortal & string)
  | undefined

export const NoWorkflowAssigned: React.FC = () => {
  const { isKetchFree, setAssignOpen } = useContext(RightsQueueViewContext)
  const { isPermitted } = useIsPermitted()

  const tooltipTitle = (
    <Typography variant="body">
      Please fulfill this request manually. In order to use workflows to automate requests, a license upgrade is
      required.
      <br />
      <br /> If you are interested, please contact our{' '}
      <Button variant="link" color="secondary" href="https://www.ketch.com/request-a-demo">
        <Typography>sales team</Typography>
      </Button>
      .
    </Typography>
  )

  return (
    <MissingWorkFlowBox>
      <EmptyState
        title="Request missing workflow assignment"
        subTitle="Assign a workflow to start fulfilling the request."
        customIcon={<EmptyStateWorkflowsIcon />}
        primaryButtonTooltipTitle={isKetchFree ? (tooltipTitle as tooltipTitle) : undefined}
        primaryButtonTitle={isPermitted(PERMISSIONS.WORKFLOW_ASSIGN) ? 'Assign Workflow' : undefined}
        primaryButtonProps={{
          disabled: isKetchFree,
          color: 'primary',
          onClick: () => setAssignOpen(true),
        }}
      />
    </MissingWorkFlowBox>
  )
}
