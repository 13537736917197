import React from 'react'
import Grid from '@mui/material/Grid'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { DataGrid, theme, InlineEdit, TableCell, InfoRow } from '@ketch-com/deck'
import { useOrganizationLanguages } from 'api/languages/queries/useOrganizationLanguages'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LanguageCellRenderer } from '../LanguageCellRenderer'
import { useContextCustomRightNameUtils } from '../../hooks'
import { extractValueInParentheses } from '../../utils'
import { CustomRightNameSaveAlertModal } from '../modals/CustomRightNameSaveAlertModal'

type Props = {
  values: any
  tab: any
}

export const Details: React.FC<Props> = ({ values }) => {
  const { data: orgLangs, isLoading: isOrgLangLoading } = useOrganizationLanguages()
  const { onSubmitUpdate, isLoading, saveWarningModal, setSaveWarningModal } = useContextCustomRightNameUtils()
  const formik = useFormik({
    initialValues: {
      ...values,
    },
    onSubmit: values => {
      onSubmitUpdate(values)
    },
  })

  const validationSchema = Yup.object({
    translation: Yup.string(),
    name: Yup.string().email('Invalid email address'),
  })

  const onSubmit = (values: any) => {
    onSubmitUpdate(values)
  }

  const mappedTranslations = orgLangs.map(langObj => {
    const translation =
      values.translations &&
      values.translations.find((t: { languageCode: string }) => t.languageCode === langObj.language.code)
    return {
      language: langObj.language.englishName,
      enabled: langObj.enabled,
      name: translation ? translation.name : '',
    }
  })

  const columns = [
    {
      field: 'language',
      headerName: 'Language',
      width: 270,
      sortable: false,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string; obj: any } }) => (
        <TableCell>
          <LanguageCellRenderer languageObj={params.row.obj} englishName={params.row.language} />
        </TableCell>
      ),
    },
    {
      field: 'translation',
      headerName: 'Custom Right Name Translation',
      width: 650,
      sortable: false,
      renderCell: (params: { row: { translation: string | undefined; id: number; language: string } }) => (
        <TableCell columnGap={1.5}>
          <InlineEdit
            textInputVariant="ghost"
            placeholder="Add Translation"
            value={params.row.translation}
            onBlur={e => {
              onSubmit(formik.values)
            }}
            onChange={e => handleTranslation(e, params.row.language)}
            onKeyDown={handleKeyDown}
          />
        </TableCell>
      ),
    },
  ]

  const generateRows = () => {
    if (values.translations) {
      return mappedTranslations.map((mappedTranslation, index) => ({
        id: index,
        language: mappedTranslation.language,
        translation: mappedTranslation.name,
        enabled: mappedTranslation.enabled,
        obj: mappedTranslation,
      }))
    }
    return orgLangs.map((orgLang, index) => ({
      id: index,
      language: orgLang.language.englishName,
      translation: 'Add Translation',
      enabled: orgLang.enabled,
      obj: orgLang,
    }))
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.code === 'Space') {
      e.stopPropagation()
    } else if (e.key === 'Tab' || e.key === 'Enter') {
      onSubmit(formik.values)
    }
  }

  const handleTranslation = (e: React.ChangeEvent<HTMLInputElement>, language: string) => {
    const languageCode = extractValueInParentheses(language)
    const name = e.target.value

    if (values.translations === undefined) {
      formik.setFieldValue('translations', [{ languageCode, name }])
    } else {
      const updatedTranslation =
        values.translations?.length > 0
          ? values.translations?.find(
              (translation: { languageCode: string | null }) => translation.languageCode === languageCode,
            )
          : null
      if (updatedTranslation) {
        updatedTranslation.name = name
        formik.setFieldValue('translations', [...values.translations])
      } else {
        formik.setFieldValue('translations', [...values.translations, { languageCode, name }])
      }
    }
  }

  const rows = generateRows()

  return (
    <>
      <Grid container spacing={3} sx={{ position: 'relative' }}>
        <Grid item xs={6}>
          <Typography variant="h4" color={theme.palette.Black}>
            Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <InfoRow title={'Display Name'}>{values.name}</InfoRow>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="h4" color={theme.palette.Text.Secondary} mt={3} mb={3}>
            Translations
          </Typography>

          <Formik initialValues={formik.initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            <Form>
              <DataGrid
                sx={{
                  ...((isLoading || isOrgLangLoading) && {
                    '& .MuiDataGrid-virtualScroller': {
                      height: '300px',
                    },
                  }),
                }}
                columns={columns}
                rows={rows.length ? rows : []}
                disableColumnResize
                disableColumnFilter
                disableColumnReorder
                disableColumnPinning
                disableChildrenSorting
                disableMultipleRowSelection
                disableColumnMenu
                disableAggregation
                hideFooter
                disableRowHoverStates
                disableRowSelectionOnClick
                loading={isLoading || isOrgLangLoading}
                noRowsOverlayProps={{
                  subTitle: '',
                  buttonTitle: '',
                }}
              />
              {saveWarningModal && (
                <CustomRightNameSaveAlertModal
                  onClose={() => {
                    setSaveWarningModal(false)
                  }}
                  data={values}
                  action={() => {
                    onSubmit(formik.values)
                    setSaveWarningModal(false)
                  }}
                />
              )}
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </>
  )
}
