import React, { useState } from 'react'

import { ReactComponent as MoveAroundIcon } from 'assets/icons/workflow/tutorial/canvas_nav_move_around.svg'
import { ReactComponent as ZoomIcon } from 'assets/icons/workflow/tutorial/canvas_nav_zoom.svg'
import { ReactComponent as MouseSlideVertically } from 'assets/icons/workflow/tutorial/canvas_nav_slide_vertically.svg'
import { ReactComponent as KeyboardZoom } from 'assets/icons/workflow/tutorial/canvas_nav_keyboard_zoom.svg'
import { ReactComponent as MouseMoveAround } from 'assets/icons/workflow/tutorial/canvas_nav_mouse_move_around.svg'
import { ReactComponent as MouseSlideHorizontally } from 'assets/icons/workflow/tutorial/canvas_nav_mouse_slide_horizontally.svg'

import { ReactComponent as GatewaysActivitiesSystemsIcon } from 'assets/icons/workflow/tutorial/faq_canvas_components.svg'
import { ReactComponent as CopyPasteIcon } from 'assets/icons/workflow/tutorial/faq_canvas_copy_paste.svg'
import { ReactComponent as DragNDropIcon } from 'assets/icons/workflow/tutorial/faq_canvas_drag.svg'
import { ReactComponent as NameComponentsIcon } from 'assets/icons/workflow/tutorial/faq_canvas_name_it.svg'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, PopUp, Tab, TabGroup, theme } from '@ketch-com/deck'

type Props = {
  onCancel: () => void
}

const CanvasNavControlsContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  rowGap: '32px',
  paddingTop: '16px',
})

const CanvasNavControlBox = styled(Box)({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  width: '270px',
})

const CanvasNavControlTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '150px',
})

const WorkflowTipBox = styled(Box)({
  display: 'flex',
  gap: '24px',
  alignItems: 'flex-start',
  justifyContent: 'center',
})

const WorkflowTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '480px',
})

enum CanvasTutorialTab {
  WorkflowTips,
  CanvasNavigation,
}

export const CanvasTutorialModal: React.FC<Props> = ({ onCancel }) => {
  const [activeTab, setActiveTab] = useState(CanvasTutorialTab.CanvasNavigation)

  const tabDetails: {
    key: number
    title: string
  }[] = [
    { key: CanvasTutorialTab.WorkflowTips, title: 'Workflow Tips' },
    { key: CanvasTutorialTab.CanvasNavigation, title: 'Canvas Navigation' },
  ]

  return (
    <PopUp
      title="How Does it Work"
      variant="modal"
      popUpWidth="750px"
      showHeaderDivide={false}
      showFooterDivider={true}
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button
            size={'large'}
            color={'tertiary'}
            onClick={() => window.open('https://docs.ketch.com/ketch/docs/managing-workflows', '_blank')}
          >
            Read Documentation
          </Button>
          <Button size={'large'} color={'secondary'} onClick={onCancel}>
            Close
          </Button>
        </>
      }
    >
      <Box
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
          width: '100%',
          marginBottom: 2,
          marginTop: -2,
        }}
      >
        <TabGroup value={activeTab} onChange={(_: React.SyntheticEvent, newValue: number) => setActiveTab(newValue)}>
          {tabDetails.map(detail => {
            return <Tab key={detail.key} label={detail.title} value={detail.key} />
          })}
        </TabGroup>
      </Box>

      {activeTab === CanvasTutorialTab.WorkflowTips ? (
        <Box mx="auto">
          <WorkflowTipBox mb={5} mt={1}>
            <DragNDropIcon />
            <WorkflowTextBox>
              <Typography variant="label">Drag-n-Drop</Typography>
              <Typography variant="body">
                Begin your workflow by choosing a tile from the left-hand tray, dragging it to the canvas, and dropping
                it into an empty tile. To make a new empty tile, hover over a connecting line and click the "+" icon.
              </Typography>
            </WorkflowTextBox>
          </WorkflowTipBox>

          <WorkflowTipBox my={5}>
            <GatewaysActivitiesSystemsIcon />
            <WorkflowTextBox>
              <Typography variant="label">Gateways, Activities and Systems</Typography>
              <Typography variant="body">
                Gateways control how your workflow runs. Activities perform discrete, configurable tasks. Systems link
                workflow to external parties to support DSRs beyond your company's internal systems.
              </Typography>
            </WorkflowTextBox>
          </WorkflowTipBox>

          <WorkflowTipBox my={5}>
            <NameComponentsIcon />
            <WorkflowTextBox>
              <Typography variant="label">Name Your Components and Paths</Typography>
              <Typography variant="body">
                All tiles contain a description label to provide a high-level overview of the purpose of each tile.
                Additionally, all Gateway contain paths. Naming each path with intention will help distinguish the
                various routes within a given workflow.
              </Typography>
            </WorkflowTextBox>
          </WorkflowTipBox>

          <WorkflowTipBox mt={5} mb={3}>
            <CopyPasteIcon />
            <WorkflowTextBox>
              <Typography variant="label">Copy-and-Paste</Typography>
              <Typography variant="body">
                You can copy any tile on the canvas, as well entire paths and Gateways. Copied items can be pasted into
                empty nodes on the canvas.
              </Typography>
            </WorkflowTextBox>
          </WorkflowTipBox>
        </Box>
      ) : (
        <Box mx={'auto'} mt={1} mb={2.25}>
          {/* Trackpad */}
          <Typography variant="h4" color={theme.palette.Text.Secondary}>
            Trackpad
          </Typography>
          <CanvasNavControlsContainer mb="56px">
            <CanvasNavControlBox>
              <MoveAroundIcon />
              <CanvasNavControlTextBox>
                <Typography variant="label">Move Around</Typography>
                <Typography variant="body">Slide with two fingers.</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>

            <CanvasNavControlBox>
              <Box display="flex" width="100px" justifyContent="flex-end">
                <ZoomIcon />
              </Box>
              <CanvasNavControlTextBox>
                <Typography variant="label" display="block">
                  Zoom
                </Typography>
                <Typography variant="body">Pinch in and out.</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>
          </CanvasNavControlsContainer>

          {/* Mouse and Keyboard*/}
          <Typography variant="h4" color={theme.palette.Text.Secondary}>
            Mouse and Keyboard
          </Typography>
          <CanvasNavControlsContainer>
            <CanvasNavControlBox>
              <MouseSlideVertically />
              <CanvasNavControlTextBox>
                <Typography variant="label">Slide Vertically</Typography>
                <Typography variant="body">Scroll</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>

            <CanvasNavControlBox>
              <KeyboardZoom />
              <CanvasNavControlTextBox>
                <Typography variant="label">Zoom</Typography>
                <Typography variant="body">Hold Cmd/Win + Scroll</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>

            <CanvasNavControlBox>
              <MouseMoveAround />
              <CanvasNavControlTextBox>
                <Typography variant="label">Move Around</Typography>
                <Typography variant="body">Click + Drag</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>

            <CanvasNavControlBox>
              <MouseSlideHorizontally />
              <CanvasNavControlTextBox>
                <Typography variant="label">Slide Horizontally</Typography>
                <Typography variant="body">Hold Shift + Scroll</Typography>
              </CanvasNavControlTextBox>
            </CanvasNavControlBox>
          </CanvasNavControlsContainer>
        </Box>
      )}
    </PopUp>
  )
}
