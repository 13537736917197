import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as MagnifyingGlassIcon } from 'assets/icons/magnifyingGlass.svg'
import { TextColorOptions } from 'components/ui-kit/typography/Text'

const PREFIX = 'SecurityRisksHighlight'

const classes = {
  securityRisksHighlightContainer: `${PREFIX}-securityRisksHighlightContainer`,
  securityRisksHighlightMagnifyingGlass: `${PREFIX}-securityRisksHighlightMagnifyingGlass`,
  securityRisksHighlightData: `${PREFIX}-securityRisksHighlightData`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`&.${classes.securityRisksHighlightContainer}`]: {
    flex: '0 1 416px',
    backgroundColor: palette.iron.main,
    borderRadius: spacing(1.5),
    padding: spacing(4),
  },

  [`& .${classes.securityRisksHighlightMagnifyingGlass}`]: {
    textAlign: 'right',
    margin: spacing(6, 3),
  },

  [`& .${classes.securityRisksHighlightData}`]: {
    marginBottom: spacing(7),
  },
}))

//TODO: Real Data
export const SecurityRisksHighlight: React.FC = () => {
  const trout = 'trout' as TextColorOptions
  return (
    <StyledBox className={classes.securityRisksHighlightContainer}>
      <Text size={16} color={trout} weight={700}>
        Highlights
      </Text>
      <Box className={classes.securityRisksHighlightMagnifyingGlass}>
        <MagnifyingGlassIcon />
      </Box>
      <Box className={classes.securityRisksHighlightData}>
        <Text component="div" size={48} color={trout} width={260} lineHeight="normal" fontWeight={500}>
          361,890
        </Text>

        <Text component="div" size={16} color={trout} width={260} lineHeight="normal" fontWeight={500}>
          PII data records found in your dataverse.
        </Text>
      </Box>

      <Text component="div" size={14} color={trout} width={260} lineHeight="normal" fontWeight={400}>
        With such a vast amount of PII data, you should ensure that your data assets have proper security controls
        assigned.
      </Text>
    </StyledBox>
  )
}
