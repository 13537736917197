import Box from '@mui/material/Box'
import { Routes, Route, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ViewLayoutHeaderLeftMenu, ViewLayoutLeftMenu } from 'components/ui-layouts/viewLayoutLeftMenu'
import { ReactComponent as OverAccessIcon } from 'assets/icons/overAccess.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { OverAccessMenu } from './components/OverAccessMenu'
import { OverAccessDetails } from './components/OverAccessDetails'
import { Button } from '@ketch-com/deck'
import { overAccessMockData } from '../__mocks__/overAccess' //TODO:JA use real data
import { DetailsEmptyState } from '../components/layout/DetailsEmptyState'
import { useStyles } from '../components/layout/styles'
import { DataRiskHeaderNav } from '../components/layout/DataRiskHeaderNav'
import { useScrollToTopOnLoad } from 'utils/hooks/useScrollToTopOnLoad'
import { showClipboardToast } from 'pages/insights/utils'
import { relativePath } from 'utils/routing/relativePath'

export const OverAccessContainer: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()

  useScrollToTopOnLoad()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Risks', link: RoutesManager.insights.dataRisks.root.getURL() },
    { title: 'Over-Access', link: RoutesManager.insights.dataRisks.overAccess.root.getURL() },
  ]

  return (
    <ViewLayoutLeftMenu breadcrumbs={breadcrumbs}>
      <ViewLayoutHeaderLeftMenu
        title="Over-Access"
        titleComponent={<DataRiskHeaderNav />}
        details="Insights into which team members that have access to data they do not need."
        detailsClassName={classes.subtitle}
        backTo={RoutesManager.insights.dataRisks.root.getURL()}
        icon={<OverAccessIcon />}
        actions={
          <Button color="tertiary" size="large" clipboardText={window.location.href} onClick={showClipboardToast}>
            Share
          </Button>
        }
      />
      <Box display="flex" gap={1.5}>
        <OverAccessMenu rows={overAccessMockData.overAccess} />

        <Routes location={location}>
          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.overAccess.view.root.path,
              RoutesManager.insights.dataRisks.overAccess.root.path,
            )}
            element={<OverAccessDetails overAccessRecords={overAccessMockData.overAccess} />}
          />

          <Route
            path={relativePath(
              RoutesManager.insights.dataRisks.overAccess.root.path,
              RoutesManager.insights.dataRisks.overAccess.root.path,
            )}
            element={
              <DetailsEmptyState
                title="Select a team member"
                description="Check the panel on the left to look up and select the user to see the insights."
                icon={<SearchIcon />}
              />
            }
          />
        </Routes>
      </Box>
    </ViewLayoutLeftMenu>
  )
}
