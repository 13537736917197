import React, { useContext, useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { withTrackersOnNewPropertiesContext, TrackersOnNewPropertiesContext } from 'pages/policyCenter/trackers/context'
import { Spinner, Icon, IconDictionary, theme, Button, PopUp, TextInput, EmptyState } from '@ketch-com/deck'
import { TrackerOnNewPropertiesDetails } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersOnNewProperties/components'
import { Waypoint } from 'react-waypoint'
import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useDebounce } from 'react-use'
import { useConfirmAllTrackersOnNewProperties } from 'api/trackers/mutations/useConfirmAllTrackersOnNewProperties'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import pluralize from 'pluralize'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
}

const TrackersOnNewPropertiesWithContext: React.FC<Props> = ({ trackerStatistics }) => {
  const queryClient = useQueryClient()
  const [shouldConfirm, setShouldConfirm] = useState(false)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const {
    isReady = false,
    trackersOnNewProperties = [],
    isFetchingTrackersOnNewProperties,
    isFetchingNextTrackersOnNewProperties,
    hasNextTrackersOnNewProperties,
    fetchNextTrackersOnNewProperties,
    trackersOnNewPropertiesParams,
    setTrackersOnNewPropertiesParams,
    totalResultsForTrackersOnNewProperties,
  } = useContext(TrackersOnNewPropertiesContext)
  const [searchInputValue, setSearchInputValue] = useState(trackersOnNewPropertiesParams?.searchString || '')

  useDebounce(
    () => {
      setTrackersOnNewPropertiesParams(
        Object.assign({}, trackersOnNewPropertiesParams, { searchString: searchInputValue }),
      )
    },
    500,
    [searchInputValue],
  )

  useEffect(() => {
    if (!trackersOnNewPropertiesParams?.searchString) setSearchInputValue('')
  }, [trackersOnNewPropertiesParams])

  const { mutateAsync: confirmAllTrackersOnNewProperties, isLoading: isConfirmingAllTrackersOnNewProperties } =
    useConfirmAllTrackersOnNewProperties({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackersOnNewPropertiesInfinite])
        await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
        showToast({ content: 'Updated trackers', type: 'success' })
        setShouldConfirm(false)
      },
      onError: () => {
        showToast({ content: 'Failed to update trackers', type: 'error' })
      },
    })

  if (!isReady && trackersOnNewPropertiesParams.searchString === '')
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={0.5} mb={2}>
        <Icon name={IconDictionary.OInfo} iconColor={theme.palette.fadedDarkGrey.main} width={16} height={16} />
        <Typography variant="smallBody" color="darkDuskFaded.main">
          These trackers, which already exist in your library, were discovered on a new property where they hadn't been
          found before. Review the list below, confirm and update the disclosure status.
        </Typography>
      </Box>

      <Box>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <TextInput
            inputWidth="180px"
            size="small"
            placeholder="Search"
            startIcon={<Icon name={IconDictionary.OMag} iconColor={theme.palette.Text.Secondary} />}
            rows={2}
            value={searchInputValue}
            onChange={e => {
              setSearchInputValue(e?.currentTarget?.value)
            }}
          />
          {trackersOnNewProperties.length > 1 && isPermittedToWriteCookie ? (
            <Button
              disabled={isConfirmingAllTrackersOnNewProperties}
              color="primary"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setShouldConfirm(true)
              }}
            >
              Confirm {totalResultsForTrackersOnNewProperties}
            </Button>
          ) : null}
        </Box>

        {!isReady && trackersOnNewPropertiesParams?.searchString ? (
          <Box display="flex" justifyContent="center" alignItems="center" my={4}>
            <Spinner size={35} thickness={3} />
          </Box>
        ) : null}

        {!trackersOnNewProperties?.length && trackersOnNewPropertiesParams?.searchString && isReady ? (
          <Box
            sx={{
              padding: '50px 0',
              borderRadius: '11px',
              backgroundColor: 'white.main',
            }}
          >
            <EmptyState
              iconName={IconDictionary.OFilter}
              title="No data matches the search criteria"
              subTitle="Sorry, we couldn't find any results that match your search criteria. Please adjust your search and try again."
              primaryButtonTitle="Reset to Defaults"
              primaryButtonProps={{
                onClick: () => {
                  setTrackersOnNewPropertiesParams({ searchString: '' })
                },
                color: 'tertiary',
              }}
            />
          </Box>
        ) : null}

        {trackersOnNewProperties?.length ? (
          <Box maxHeight="50vh" overflow="auto">
            {trackersOnNewProperties.map(tracker => {
              return <TrackerOnNewPropertiesDetails key={tracker?.key} tracker={tracker} />
            })}

            {hasNextTrackersOnNewProperties ? (
              <Box display="flex" justifyContent="center" alignItems="center" my={4}>
                <Waypoint
                  onEnter={() => {
                    if (
                      !isFetchingTrackersOnNewProperties &&
                      !isFetchingNextTrackersOnNewProperties &&
                      hasNextTrackersOnNewProperties
                    )
                      fetchNextTrackersOnNewProperties()
                  }}
                />
                {isFetchingNextTrackersOnNewProperties ? <Spinner size={35} thickness={3} /> : null}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>

      {shouldConfirm ? (
        <PopUp
          title="Confirm All Trackers"
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setShouldConfirm(false)}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={() => setShouldConfirm(false)}>
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={() =>
                  confirmAllTrackersOnNewProperties({
                    params: { searchString: trackersOnNewPropertiesParams?.searchString },
                  })
                }
              >
                Confirm {totalResultsForTrackersOnNewProperties}
              </Button>
            </>
          }
        >
          <Box>
            <Typography variant="body">
              Are you sure you want to confirm {totalResultsForTrackersOnNewProperties}{' '}
              {pluralize('tracker', totalResultsForTrackersOnNewProperties)}?
            </Typography>
          </Box>
        </PopUp>
      ) : null}
    </Box>
  )
}

export const TrackersOnNewProperties = withTrackersOnNewPropertiesContext(TrackersOnNewPropertiesWithContext)
