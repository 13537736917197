import { ListAssetItemDTO, ListInstalledDataSystemV2AssetsResponseBodyDTO } from '@ketch-com/figurehead'
import {
  InstalledDataSystemAssetsQueryConfig,
  useInstalledDataSystemAssets,
} from 'api/dataSystems/queries/useInstalledDataSystemsAssets'
import { useMemo } from 'react'
import { dedupeWithinItemsByKeys } from '../utils/dedupeDataAssetsById'

function useFilteredAndDedupedDataSystemAssets(
  assetQueryPayload: InstalledDataSystemAssetsQueryConfig,
  enabled: boolean = true,
) {
  const {
    data: unfilteredInstalledDatasystemAssets,
    isLoading: isLoadingInstalledDatasystemAssets,
    refetch: refetchInstalledDatasystemAssets,
  } = useInstalledDataSystemAssets({ ...assetQueryPayload, enabled: !!assetQueryPayload.params.id && enabled })

  // Filter out "unknown" values, remove when API handles this
  const installedDatasystemAssets: ListInstalledDataSystemV2AssetsResponseBodyDTO = useMemo(
    () => ({
      ...unfilteredInstalledDatasystemAssets,
      assets:
        unfilteredInstalledDatasystemAssets?.assets?.map((asset: ListAssetItemDTO) => ({
          ...asset,
          dataCategories: asset.dataCategories?.filter(category => category.code !== 'unknown'),
          dataTypes: asset.dataTypes?.filter(type => type.code !== 'unknown'),
          dataSensitivities: asset.dataSensitivities?.filter(sensitivity => sensitivity.code !== 'unknown'),
        })) || [],
    }),
    [unfilteredInstalledDatasystemAssets],
  )

  // Deduplication properties
  const dedupeProperties = useMemo(() => ['dataCategories', 'dataTypes', 'dataSensitivities'], [])

  const dedupedDataSystemAssets = useMemo(() => {
    if (installedDatasystemAssets?.assets) {
      return dedupeWithinItemsByKeys(installedDatasystemAssets.assets, dedupeProperties)
    }
  }, [dedupeProperties, installedDatasystemAssets?.assets])

  const { pagination: installedDatasystemPagination } = installedDatasystemAssets

  return {
    dedupedDataSystemAssets,
    installedDatasystemPagination,
    isLoadingInstalledDatasystemAssets,
    refetchInstalledDatasystemAssets,
  }
}

export default useFilteredAndDedupedDataSystemAssets
