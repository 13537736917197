import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ErrorMessage, useFormikContext } from 'formik'
import { FormFieldFormDTO } from '../../interfaces'

import { TextInputConfig, DropdownInputConfig, CheckboxInputConfig } from './components'
import { FormFieldContext } from '../../context/FormFieldUpsertContextProvider'
import { FormFieldTypeDTO } from '@ketch-com/figurehead'
import { getFormFieldInitialValues } from '../../utils'
import { useEnabledOrganizationLanguages } from '../../hooks'
import { FormRow, Radio } from '@ketch-com/deck'
import { FormError } from 'components/form/FormError'

export const FieldTypeFormSection: React.FC = () => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FormFieldFormDTO>()
  const { getDummyDropdownItem } = useContext(FormFieldContext)
  const { enabledLanguages } = useEnabledOrganizationLanguages()

  const isErrorState = errors.inputType && touched.inputType

  const handleTextRadioOnChange = () => {
    setFieldValue('inputType', 'text')
    setFieldValue('variant', 'input')

    setFieldValue('title', '')
    setFieldTouched('title', false)

    setFieldTouched('dropdownOptions', false)
    setFieldValue('dropdownOptions', [getDummyDropdownItem(), getDummyDropdownItem()])

    setFieldTouched('checkboxOptions', false)
    setFieldValue('checkboxOptions', [getDummyDropdownItem()])

    setFieldValue(
      'translations',
      getFormFieldInitialValues({
        formField: undefined,
        languages: enabledLanguages,
      }).translations,
    )
  }

  const handleDropdownRadioOnChange = () => {
    setFieldValue('inputType', 'dropdown')

    setFieldValue('identitySpaceId', '')
    setFieldTouched('identitySpaceId', false)

    setFieldValue('title', '')
    setFieldTouched('title', false)

    setFieldValue('variant', '')
    setFieldTouched('variant', false)

    setFieldValue('dropdownOptions', [getDummyDropdownItem(), getDummyDropdownItem()])

    setFieldTouched('checkboxOptions', false)
    setFieldValue('checkboxOptions', [getDummyDropdownItem()])

    setFieldValue(
      'translations',
      getFormFieldInitialValues({
        formField: undefined,
        languages: enabledLanguages,
      }).translations,
    )
  }

  const handleCheckboxRadioOnChange = () => {
    setFieldValue('inputType', 'checkbox')

    setFieldValue('title', '')
    setFieldTouched('title', false)

    setFieldValue('identitySpaceId', '')
    setFieldTouched('identitySpaceId', false)

    setFieldValue('variant', '')
    setFieldTouched('variant', false)

    setFieldValue('dropdownOptions', [getDummyDropdownItem(), getDummyDropdownItem()])
    setFieldValue('checkboxOptions', [getDummyDropdownItem()])
    setFieldTouched('checkboxOptions', false)

    setFieldValue(
      'translations',
      getFormFieldInitialValues({
        formField: undefined,
        languages: enabledLanguages,
      }).translations,
    )
  }

  const isCheckboxInputType = values.inputType === FormFieldTypeDTO.CheckboxFormFieldType

  return (
    <FormRow title="Type">
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            border: '1px solid white',
            borderColor: isErrorState ? 'chileanFire.main' : 'transparent',
            borderRadius: 2.75,
            mb: isErrorState ? 1 : 0,
          }}
        >
          <Box display="flex" alignItems="center" gap={3} ml="-9px" mb={3}>
            <Box display="flex" alignItems="center" gap="1px">
              <Radio
                title="Text Field"
                value={values.inputType === FormFieldTypeDTO.TextFormFieldType}
                checked={values.inputType === FormFieldTypeDTO.TextFormFieldType}
                onChange={() => handleTextRadioOnChange()}
              />
              <Typography variant={values.inputType === FormFieldTypeDTO.TextFormFieldType ? 'label' : 'body'}>
                Text Field
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="1px">
              <Radio
                title="Dropdown"
                value={values.inputType === FormFieldTypeDTO.DropdownFormFieldType}
                checked={values.inputType === FormFieldTypeDTO.DropdownFormFieldType}
                onChange={() => handleDropdownRadioOnChange()}
              />
              <Typography variant={values.inputType === FormFieldTypeDTO.DropdownFormFieldType ? 'label' : 'body'}>
                Dropdown
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="1px">
              <Radio
                title="Checkbox"
                value={isCheckboxInputType}
                checked={isCheckboxInputType}
                onChange={() => handleCheckboxRadioOnChange()}
              />
              <Typography variant={isCheckboxInputType ? 'label' : 'body'}>Checkbox</Typography>
            </Box>
          </Box>

          {/* Text Input Section */}
          {values.inputType === 'text' && (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                backgroundColor:
                  values.inputType === FormFieldTypeDTO.TextFormFieldType ? 'superLightGrey.main' : undefined,
                borderRadius: 2.75,
              }}
            >
              <TextInputConfig />
            </Box>
          )}

          {/* Dropdown Input Section */}

          {values.inputType === 'dropdown' && (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                backgroundColor:
                  values.inputType === FormFieldTypeDTO.DropdownFormFieldType ? 'superLightGrey.main' : undefined,
                borderRadius: 2.75,
              }}
            >
              <DropdownInputConfig />
            </Box>
          )}

          {/* Checkbox Input Section */}

          {values.inputType === 'checkbox' && (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                backgroundColor: isCheckboxInputType ? 'superLightGrey.main' : undefined,
                borderRadius: 2.75,
              }}
            >
              <CheckboxInputConfig />
            </Box>
          )}
        </Box>

        {/* Error Message */}

        {errors.inputType && (
          <Box display="flex" alignItems="center" pl={3} mt={3}>
            <ErrorMessage name="inputType">{msg => <FormError msg={msg} />}</ErrorMessage>
          </Box>
        )}
      </Box>
    </FormRow>
  )
}
