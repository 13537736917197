import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/styles'
import { Icon, theme } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  section: string
  /** Compact only shows an icon and the string passed in section */
  compact?: boolean
  /** Hide display */
  hidden?: boolean
} & BoxProps

const EmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  background: theme.palette.fadedGrey.main,
  borderRadius: 100,
  height: 35,
}))

export const SectionEmptyState: React.FC<Props> = ({ section, compact = false, hidden = false, ...rest }) => {
  return hidden ? null : (
    <EmptyStateBox p={compact ? '0px 12px' : '0px 16px'} {...rest}>
      <Icon name={'FEyeHide'} iconColor={theme.palette.Text.Secondary} />
      <Typography variant={'label'} color={theme.palette.Text.Secondary}>
        {section}
        {!compact && ' is hidden. Click here to configure.'}
      </Typography>
    </EmptyStateBox>
  )
}
