import { ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { useMemo } from 'react'
import { ClassificationList } from 'pages/dataSystems/DataSystem/types'

function useSplitClassificationsByStatus(classificationList: ClassificationList) {
  const { assigned, unassigned } = useMemo(() => {
    const assigned: ClassificationList = []
    const unassigned: ClassificationList = []

    classificationList.forEach(classification => {
      if (classification.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3) {
        unassigned.push(classification)
      } else {
        assigned.push(classification)
      }
    })

    return { assigned, unassigned }
  }, [classificationList])

  return { assigned, unassigned }
}

export default useSplitClassificationsByStatus
