import { Box, Typography, Autocomplete, InputAdornment } from '@mui/material'
import { useFormikContext, FieldArray } from 'formik'
import {
  ProcessingActivityDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityAssetDTO,
} from '@ketch-com/figurehead'
import { showToast } from 'components/modals/AlertComponent'
import { useProcessingActivitiesUpsertSearch } from 'pages/policyCenter/processingActivities/hooks'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'
import { useResourceTypeDetails } from 'api/assets/queries/useResourceTypeDetails'
import { ActionSheetItem, Icon, ListItemText, TextInput, theme } from '@ketch-com/deck'
import { StyledOrchestrationAutocompletePaper } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/utils'

export const DataSystemGridAutocomplete = ({ system }: { system: ProcessingActivityDataSystemDTO }) => {
  const { isSubmitting, values } = useFormikContext<ProcessingActivityDTO>()

  const assetsSummarySearchState = useProcessingActivitiesUpsertSearch<ProcessingActivityAssetDTO>()
  const appCode = system?.dataSystem?.appCode || ''

  const systemIndex = values?.dataSystems?.findIndex(elem => elem.dataSystemId === system.dataSystemId)

  const { data: resourceTypeDetails, isLoading: isLoadingResourceTypeDetails } = useResourceTypeDetails({
    params: {
      providerCode: appCode,
      capability: 'processingActivity',
    },
  })
  const resourceTypeCode = resourceTypeDetails.details?.[0]?.resourceTypeCode || ''
  const datasetType = resourceTypeDetails.details?.[0]?.groupName || ''
  const { isLoading: isLoadingAssetsSummary } = useAssetsPaginatedV2({
    enabled: Boolean(resourceTypeCode),
    itemsPerPage: 300,
    params: {
      resourceTypeCode,
      query: assetsSummarySearchState.searchQueryValue,
    },
    onSuccess: ({ data = {} }) => {
      const { assets: assetsSummary = [] } = data
      const ProcessingActivityAssetData = assetsSummary?.map(asset => {
        return {
          dataSystemId: system?.id,
          personalDataType: system?.personalDataType,
          dataCategories: system?.dataCategories,
          processingStages: [],
          asset: asset,
          resourceTypeCode,
        } as ProcessingActivityAssetDTO
      })

      assetsSummarySearchState.setOptions(ProcessingActivityAssetData)
    },
    onError: () => {
      showToast({ content: 'Failed to fetch assets summary', type: 'error' })
    },
  })
  const isReady = !isLoadingResourceTypeDetails && !isLoadingAssetsSummary

  return (
    <Box>
      {typeof systemIndex === 'number' && systemIndex >= 0 && (
        <FieldArray name={`dataSystems[${systemIndex}].assets`}>
          {({ push, remove }) => {
            return (
              <Box display="flex" gap={1} flexDirection="column">
                <Autocomplete
                  key={system?.assets?.length}
                  loading={!isReady}
                  disabled={isSubmitting}
                  sx={{
                    width: 420,
                    '& .MuiTextField-root': {
                      '& .MuiInputBase-root': {
                        paddingRight: 6,
                        '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                          padding: '2px 9px 4px 14px',
                          '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
                            padding: '0 4px',
                          },
                        },
                      },
                    },
                  }}
                  filterOptions={x => x}
                  clearOnBlur
                  autoComplete
                  size="small"
                  includeInputInList
                  filterSelectedOptions
                  noOptionsText="No Assets"
                  getOptionDisabled={option =>
                    Boolean(
                      values?.dataSystems?.[systemIndex]?.assets?.some(
                        processingActivityAsset =>
                          processingActivityAsset?.asset?.asset?.resource?.id === option?.asset?.asset?.resource?.id,
                      ),
                    )
                  }
                  onChange={(e, asset) => {
                    push(asset)
                  }}
                  onInputChange={(e, inputVal) => assetsSummarySearchState.setInputValue(inputVal)}
                  onClose={() => {
                    assetsSummarySearchState.setInputValue('')
                    assetsSummarySearchState.setSearchQueryValue('')
                  }}
                  options={assetsSummarySearchState?.options || []}
                  renderInput={params => (
                    <TextInput
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon name="OMag" />
                          </InputAdornment>
                        ),
                        endAdornment: <></>,
                      }}
                      placeholder={`Start typing a ${datasetType.toLowerCase()} name, click to add`}
                      value={assetsSummarySearchState.inputValue}
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, processingActivityAsset, { selected }) => (
                    <ActionSheetItem
                      {...props}
                      key={processingActivityAsset.asset?.asset?.resource?.id}
                      selected={selected}
                    >
                      <ListItemText selected={selected}>
                        <Box alignSelf="flex-start">
                          <Typography variant="label">
                            {processingActivityAsset?.asset?.asset?.resource?.name || ''}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={0.5}>
                          <img
                            width={16}
                            height={16}
                            src={system.dataSystem?.logoUrl}
                            alt={system.dataSystem?.logoUrl || 'asset image'}
                          />
                          <Typography variant="footnote">{datasetType}</Typography>
                        </Box>
                      </ListItemText>
                    </ActionSheetItem>
                  )}
                  getOptionLabel={processingActivityAsset => processingActivityAsset?.asset?.asset?.resource?.id || ''}
                  PaperComponent={StyledOrchestrationAutocompletePaper}
                />
                {!values?.dataSystems?.[systemIndex]?.assets?.length && (
                  <Box display="flex" alignItems="flex-start" gap={1}>
                    <Icon name="FInfo" iconColor={theme.palette.Text.Secondary} width={18} height={18} />
                    <Typography variant="body" color="darkDuskFaded.main">
                      {`You can designate the specific ${datasetType.toLowerCase()}s involved in the processing by selecting via the search bar
                  above.`}
                    </Typography>
                  </Box>
                )}
              </Box>
            )
          }}
        </FieldArray>
      )}
    </Box>
  )
}
