import React, { useContext, useMemo, useState } from 'react'
import { Button, Icon, LabelChip, LabelChipColor, LabelChipVariant, Widget, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { ConfidenceLevelV3DTO, ConfirmationStatusV3DTO, LabelMethodV3DTO } from '@ketch-com/figurehead'
import { showToast } from 'components/modals/AlertComponent'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useUpdateAssignedCategories } from 'api/dataSystemsCategories/mutations/useUpdateAssignedCategories'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsCategories/queries/useAssignedDataCategoriesInfinite'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import pluralize from 'pluralize'
import { useDebounce } from 'react-use'
import { DataSystemWidgetsContext } from '../../context/DataSystemWidgetsContext'
import { ScrollContainer, SubTitle } from 'pages/dataSystems/DataSystem/components/Widgets/components/StyledComponents'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { useDataCategoriesRolloverContext } from 'pages/dataSystems/DataSystem/context/DataCategoriesRolloverContext'
import { AssignedCategoriesEditModal } from 'pages/dataSystems/DataSystem/components/Widgets/components/DataCategoriesWidget/Modals/AssignedCategoriesEditModal'
import { AssignedCategoriesRestoreModal } from 'pages/dataSystems/DataSystem/components/Widgets/components/DataCategoriesWidget/Modals/AssignedCategoriesRestoreModal'

export const DataCategoriesWidget: React.FC = () => {
  const { id } = useContext(DataSystemWidgetsContext)
  const { setdataClassificationsRolloverProps, showAssetsTab } = useDataSystemsContext()
  const { setDataClassificationStaticFilter } = useDataCategoriesRolloverContext()

  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteDataSystems = getIsPermitted([PERMISSIONS.DATA_SYSTEMS_WRITE])
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  const { data } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: id,
    limit: 9000,
  })

  const dataAssignedCategories = useMemo(
    () => ({
      ...data,
      assignedCategories: data.assignedCategories.filter(
        assignedCategory => assignedCategory.category?.code !== 'unknown',
      ),
    }),
    [data],
  )

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false)

  const handleEditModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsEditModalOpen(!isEditModalOpen)
  }

  const handleRestoreModal = () => {
    setIsRestoreModalOpen(!isRestoreModalOpen)
  }

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.assignedDataCategories, { installedDataSystemId: id }])

      showToast({ content: 'Assigned data categories updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned data categories', type: 'error' })
    },
  })

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedCategories?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: id,
        formData: { assignedCategories: assignedCategoriesRemap },
      },
    })
  }

  const assignedCategories = dataAssignedCategories?.assignedCategories?.filter(
    assignedCategory => assignedCategory?.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  const assignedCategoriesDismissed = dataAssignedCategories?.assignedCategories?.filter(
    assignedCategory => assignedCategory?.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  const displayCategories = useMemo(
    () => assignedCategories?.filter(category => category?.category?.name?.toLowerCase().includes(debouncedSearch)),
    [assignedCategories, debouncedSearch],
  )

  const [closeTooltip, setCloseTooltip] = useState(false)

  // 250ms debounce for search filter
  useDebounce(
    () => {
      if (search) {
        setDebouncedSearch(search)
      } else {
        setDebouncedSearch('')
      }
    },
    250,
    [search],
  )

  return (
    <>
      {!assignedCategories?.length ? (
        <Widget
          expandable
          title="Data Categories"
          subTitle={`${displayCategories.length} ${pluralize('category', displayCategories.length)} assigned.`}
          actionButton={
            isPermittedToWriteDataSystems && (
              <Button onClick={handleEditModal} color="tertiary" variant="icon" fullWidth={false}>
                <Icon name="FEdit" />
              </Button>
            )
          }
          emptyStateProps={{
            title: 'No Data Categories',
            subTitle: 'This system has no associated data categories.',
            iconName: 'OLabel',
            colorTheme: theme.palette.Text.Secondary,
          }}
        />
      ) : (
        <Widget
          expandable
          title="Data Categories"
          subTitle={`${displayCategories.length} ${pluralize('category', displayCategories.length)} assigned.`}
          content={
            <Box>
              <Box mb={2}>
                <SearchTextInput
                  variant="ghost"
                  size="small"
                  placeholder="Search"
                  onChange={newValue => setSearch(newValue)}
                  value={search}
                  fullWidth
                />
              </Box>

              <ScrollContainer>
                {displayCategories?.map(assignedCategory => {
                  const category = assignedCategory?.category
                  return (
                    <Box key={assignedCategory.id}>
                      {assignedCategory?.method !== LabelMethodV3DTO.ManualLabelMethodV3 ? (
                        <LabelChip
                          PopperProps={{
                            disablePortal: false,
                          }}
                          onMouseOver={() => {
                            if (closeTooltip) setCloseTooltip(false)
                          }}
                          title={category?.name ?? ''}
                          variant={LabelChipVariant.category}
                          color={
                            showAssetsTab
                              ? LabelChipColor.confident
                              : assignedCategory?.confidence === ConfidenceLevelV3DTO.MediumConfidenceLevelV3
                              ? LabelChipColor.fairly_confident
                              : LabelChipColor.confident
                          }
                          isDone={
                            assignedCategory?.confirmationStatus !== ConfirmationStatusV3DTO.NoneConfirmationStatusV3 ||
                            undefined
                          }
                          hideConfidence
                          closeTooltip={closeTooltip}
                          customLabelDetailsContent={
                            showAssetsTab ? (
                              <Button
                                sx={{
                                  marginTop: '4px',
                                  width: '190px',
                                  flexGrow: 1,
                                  padding: '0px',
                                  paddingLeft: '2px',
                                }}
                                variant="text"
                                onClick={() => {
                                  setCloseTooltip(true)
                                  setdataClassificationsRolloverProps({
                                    dataClassification: {
                                      name: category?.name || category?.code || '',
                                      code: category?.code || '',
                                      id: category?.id || '',
                                      type: 'dataCategories',
                                    },
                                  })
                                  setDataClassificationStaticFilter(['dataCategoryCodes', category?.code || ''])
                                }}
                              >
                                {
                                  <Box display="flex" justifyContent="space-between" alignItems="center" width="190px">
                                    <Box display="flex" gap={0.5} alignItems="center">
                                      <Icon name="FAi" width="16px" height="16px" />
                                      View Insights
                                    </Box>
                                    <Icon
                                      name="OArrowCRight"
                                      iconColor={theme.palette.Common.AppBackground}
                                      width="16px"
                                      height="16px"
                                    />
                                  </Box>
                                }
                              </Button>
                            ) : undefined
                          }
                          labelDetails={
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Box>
                                <Typography variant="body">{category?.description ?? ''}</Typography>
                              </Box>
                              {!!assignedCategory?.categorySource?.categorySourceCode && (
                                <Box>
                                  <Typography variant="label" color={theme.palette.darkGrey.main}>
                                    Source:
                                  </Typography>{' '}
                                  <Typography variant="label" color={theme.palette.white.main}>
                                    {assignedCategory?.categorySource?.categorySourceCode}
                                  </Typography>
                                  <br />
                                  <Typography variant="body">
                                    {assignedCategory?.categorySource?.categorySourceDescription}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          }
                          onConfirm={() =>
                            handleConfirmation(
                              assignedCategory?.id,
                              ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                            )
                          }
                          onDismiss={() =>
                            handleConfirmation(
                              assignedCategory?.id,
                              ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                            )
                          }
                        />
                      ) : (
                        <LabelChip
                          PopperProps={{
                            disablePortal: false,
                          }}
                          isViewOnly
                          title={category?.name ?? ''}
                          variant={LabelChipVariant.category}
                          closeTooltip={closeTooltip}
                          labelDetails={
                            showAssetsTab ? (
                              <Button
                                onClick={() => {
                                  // setdataClassificationsRolloverProps({
                                  //   dataCategory: {
                                  //     name: category?.name || category?.code || '',
                                  //     code: category?.code || '',
                                  //     id: category?.id || '',
                                  //     details: category?.description || '',
                                  //   },
                                  // })
                                  // setDataCategoryStaticFilter(category?.code || '')
                                  setCloseTooltip(true)
                                }}
                              >
                                View Insights
                              </Button>
                            ) : (
                              <Typography variant="body">{category?.description ?? ''}</Typography>
                            )
                          }
                        />
                      )}
                    </Box>
                  )
                })}
              </ScrollContainer>
            </Box>
          }
          footerComponent={
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <SubTitle>
                <Icon name="Link" width={16} height={16} iconColor={theme.palette.Text.Secondary} />

                <Typography variant="smallLabel" color="darkDuskFaded.main">
                  Data Map
                </Typography>
              </SubTitle>

              {isPermittedToWriteDataSystems && (
                <Button
                  color="tertiary"
                  startIcon={<Icon name="OBin" />}
                  onClick={!!assignedCategoriesDismissed.length ? handleRestoreModal : () => null}
                  sx={{
                    '&.MuiButtonBase-root.MuiButton-root': {
                      '& .MuiButton-endIcon': {
                        ml: 1,
                        '& > *:nth-of-type(1)': {
                          fontSize: '16px',
                        },
                      },
                    },
                  }}
                  endIcon={
                    <Typography variant="label" color={theme.palette.Text.Secondary}>
                      {assignedCategoriesDismissed.length}
                    </Typography>
                  }
                >
                  Dismissed
                </Button>
              )}
            </Box>
          }
          actionButton={
            isPermittedToWriteDataSystems && (
              <Button onClick={handleEditModal} color="tertiary" variant="icon" fullWidth={false}>
                <Icon name="FEdit" />
              </Button>
            )
          }
        />
      )}
      <AssignedCategoriesEditModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
      <AssignedCategoriesRestoreModal isOpen={isRestoreModalOpen} onClose={() => setIsRestoreModalOpen(false)} />
    </>
  )
}
