import React from 'react'
import { useFormikContext, FieldArrayRenderProps } from 'formik'
import { Box, Tooltip, Typography } from '@mui/material'
import {
  SubscriptionTopicFormData,
  OrchestrationData,
  ConnectionItem,
} from 'pages/policyCenter/subscriptions/interfaces'
import {
  salesforceMarketingCloudSubscriptionGroupItems,
  SfmcMasterListSubscriptionGroup,
} from 'pages/policyCenter/subscriptions/constants'
import { Chip, Icon, theme } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormInput } from 'components/form/FormInput'

type Props = {
  orchestration: OrchestrationData
  fieldArrayHelpers: FieldArrayRenderProps
  fieldArrayIndex: number
  channelCode: string
  isSystemConnectionsLoading: boolean
  connectionItems: ConnectionItem[]
}

export const SalesforceMarketingCloud: React.FC<Props> = ({
  orchestration,
  fieldArrayHelpers,
  fieldArrayIndex,
  channelCode,
  isSystemConnectionsLoading,
  connectionItems,
}) => {
  const { isSubmitting, setFieldValue, values } = useFormikContext<SubscriptionTopicFormData>()
  const { remove } = fieldArrayHelpers

  // Salesforce Marketing Cloud "All Subscribers List" (aka "master list") has special behavior, such as not requiring an input listID
  const isMasterList =
    values.contactMethods[channelCode]?.orchestrations[fieldArrayIndex]?.appParams?.subscriptionGroup ===
    SfmcMasterListSubscriptionGroup

  return (
    <Box display="flex" alignItems="flex-start" gap={3} py={1.5} flexWrap="wrap">
      <Chip
        sx={{
          width: 130,
        }}
        icon={<img src={orchestration?.appLogo?.url} alt={orchestration?.appLogo?.title} width={24} height={24} />}
        disabled={isSubmitting || isSystemConnectionsLoading}
        size="medium"
        label={orchestration.name}
        onDelete={() => remove(fieldArrayIndex)}
        deleteIcon={<Icon name="OCross" />}
      />

      <FormDroplistButton
        required
        sx={{ width: 220 }}
        size="small"
        disabled={isSubmitting || isSystemConnectionsLoading}
        items={connectionItems || []}
        valueKey="id"
        formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`}
        placeholder="Select Connection"
        onItemClick={option => {
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceId`, option.id)
          setFieldValue(`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appInstanceName`, option.name)
        }}
      />
      <FormDroplistButton
        required
        sx={{ width: 220 }}
        size="small"
        disabled={isSubmitting || isSystemConnectionsLoading}
        items={salesforceMarketingCloudSubscriptionGroupItems || []}
        valueKey="id"
        formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroup`}
        placeholder="Subscription Type"
        onItemClick={option => {
          setFieldValue(
            `contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroup`,
            option.id,
          )

          if (option.id === SfmcMasterListSubscriptionGroup) {
            // clear the listID
            setFieldValue(
              `contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroupId`,
              '',
            )
          }
        }}
      />
      {!isMasterList && (
        <Box display="flex" gap={1}>
          <FormInput
            shouldUpdateDebounced
            required={!isMasterList}
            size="small"
            disabled={isSubmitting || isSystemConnectionsLoading}
            formPropertyName={`contactMethods.${channelCode}.orchestrations.${fieldArrayIndex}.appParams.subscriptionGroupId`}
            placeholder="List Id"
          />
          <Tooltip
            placement="bottom"
            title={
              <Typography variant="body">
                {orchestration.name} require additional configuration to ensure orchestration function properly. Specify
                a field in {orchestration.name} that will be controlled by this channel.
              </Typography>
            }
          >
            <Box display="flex" alignItems="center" height={28}>
              <Icon name="FUnknown" iconColor={theme.palette.Text.Secondary} width={20} height={20} />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
