import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import { theme, Button, PopUp } from '@ketch-com/deck'
import { ReactComponent as DiagnosticsReport } from 'assets/icons/diagnostics/report.svg'

export type UnlockDiagnosticsProps = {
  shouldShowTrackerDiagnosticsModal: boolean
  setShouldShowTrackerDiagnosticsModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const DiagnosticsModal: React.FC<UnlockDiagnosticsProps> = ({
  shouldShowTrackerDiagnosticsModal,
  setShouldShowTrackerDiagnosticsModal,
}: UnlockDiagnosticsProps) => {
  return (
    <PopUp
      variant="modal"
      isOpen={shouldShowTrackerDiagnosticsModal}
      onClose={() => {
        setShouldShowTrackerDiagnosticsModal(false)
      }}
      popUpActionChildren={
        <Button
          color={'success'}
          size="large"
          onClick={() => {
            window.open('https://www.ketch.com/request-pricing', '_blank', 'noopener,noreferrer')
          }}
          sx={{ backgroundColor: theme.palette.Success.Primary }}
        >
          Contact Sales
        </Button>
      }
      title="Tracker Diagnostics"
      subTitle="Unlock this feature to receive a full report about your trackers."
      popUpWidth="825px"
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DiagnosticsReport />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="h3">
              Unlock{' '}
              <Typography component="span" variant="h3" color={theme.palette.Info.Primary}>
                Tracker Diagnostics
              </Typography>{' '}
              to dive into detailed reports on individual trackers.
            </Typography>
            <Typography variant="body">
              Analyze tracker behavior, origin, and relationships with other trackers to optimize performance, ensure
              data accuracy, and enhance user privacy.
            </Typography>
            <Typography variant="body">Contact our team for an upgrade!</Typography>
          </Box>
        </Grid>
      </Grid>
    </PopUp>
  )
}
