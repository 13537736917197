import React from 'react'
import Grid from '@mui/material/Grid'

import { FormRow } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'

export const TransponderFormDetails: React.FC = () => (
  <FormRow title="Transponder Details">
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormInput
          required
          fullWidth
          label="Name"
          formPropertyName="name"
          placeholder="Customer Snowflake"
          shouldUpdateDebounced
        />
      </Grid>
    </Grid>
  </FormRow>
)
