import React from 'react'
import { Avatar, AvatarSize } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import avatarImage from '../../../assets/images/login-logo-full.svg'
import sampleProfileImage from '../../../assets/images/sample-profile-photo.png'

export const AvatarExample: React.FC = () => {
  const theme = useTheme()

  const avatars = Object.values(AvatarSize).map((size, index) => {
    return (
      <Avatar
        key={index}
        size={size}
        textColor={theme.palette.darkDusk.main}
        backgroundColor={theme.palette.naviBlue.main}
      >
        A
      </Avatar>
    )
  })

  const iconAvatars = Object.values(AvatarSize).map((size, index) => {
    return (
      <Avatar
        key={index}
        size={size}
        textColor={theme.palette.Text.Secondary}
        backgroundColor={theme.palette.fadedHeliotrope.main}
        icon="OCalendar"
      />
    )
  })

  const imageAvatars = Object.values(AvatarSize).map((size, index) => {
    return <Avatar key={index} size={size} src={sampleProfileImage} />
  })

  const logoSizes = [...Object.values(AvatarSize)]
  const circularLogoAvatars = logoSizes.map((size, index) => {
    return <Avatar key={index} size={size} isLogo variant="circular" borderColor="darkGrey" src={avatarImage}></Avatar>
  })

  const roundedLogoAvatars = logoSizes.map((size, index) => {
    return <Avatar key={index} size={size} isLogo variant="rounded" src={avatarImage} borderColor="darkGrey"></Avatar>
  })
  return (
    <>
      <Typography variant="h3" mb={2}>
        DECK Avatar component
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>{avatars}</Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>{iconAvatars}</Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>{imageAvatars}</Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>{circularLogoAvatars}</Box>
      <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>{roundedLogoAvatars}</Box>
    </>
  )
}
