import { ApiQueryKeys } from 'api/common/queryKeys'
import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { fetchTrackerLineageManagedBy } from '../fetchers/fetchTrackerLineageManagedBy'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.trackerLineageManagedBy,
  queryFn: fetchTrackerLineageManagedBy,
  select: res => res?.data,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useTrackerLineageManagedBy = (config: Config) => {
  return useCustomQuery({
    ...config,
    params: {
      ...config.params,
    },
  })
}
