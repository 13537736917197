import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DSRIdentityLookup } from 'pages/assetManager/dsrIdentityLookup/DSRIdentityLookup'
import { UrlParams, RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useRelatedDataSets } from 'api/assets/queries/useRelatedDataSets'
import { MaybeNull } from 'interfaces'
import { getRelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/utils'
import { useAssetsHierarchiesDynamic } from 'api/assets/queries/useAssetsHierarchiesDynamic'
import { useScenario } from 'api/assets/queries/useScenario'
import { useAssetsSummaryDynamic } from 'api/assets/queries/useAssetsSummaryDynamic'

export const DSRIdentityLookupContainer: React.FC = () => {
  const [relatedPathsAndAssets, setRelatedPathsAndAssets] = useState<MaybeNull<RelatedPathsAndAssets>>(null)
  const { id: primaryDatasetId, scenarioId } = useParams<UrlParams>()

  const { data: identitySpaces, isLoading: isIdentitySpacesLoading } = useIdentitySpaces()

  const { data: scenarioData, isLoading: isScenarioLoading } = useScenario({
    params: {
      scenarioId,
    },
  })

  const { data: relatedDataSets, isLoading: isRelatedDataSetsLoading } = useRelatedDataSets({
    params: {
      assetId: primaryDatasetId,
      isPersonal: true,
      lookupType: 0,
      depth: 5,
    },
  })

  const { assetsHierarchies, isLoading: isAssetsHierarchiesLoading } = useAssetsHierarchiesDynamic({
    limit: 200,
    assetIds: [[primaryDatasetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    canonicalRightCode: scenarioData.scenario?.canonicalRight?.code || '',
    enabled: Boolean(relatedPathsAndAssets) && !!scenarioData.scenario?.canonicalRight?.code,
  })

  const { assetsSummary, isLoading: isAssetsSummaryLoading } = useAssetsSummaryDynamic({
    assetIds: [[primaryDatasetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    enabled: Boolean(relatedPathsAndAssets),
  })

  useEffect(() => {
    if (!isRelatedDataSetsLoading) {
      const relatedPathsAndAssets = getRelatedPathsAndAssets({ relatedDataSets, primaryDatasetId: primaryDatasetId! })
      setRelatedPathsAndAssets(relatedPathsAndAssets)
    }
  }, [relatedDataSets, isRelatedDataSetsLoading, primaryDatasetId])

  const isReady =
    !isAssetsHierarchiesLoading &&
    !isIdentitySpacesLoading &&
    !isRelatedDataSetsLoading &&
    !isScenarioLoading &&
    !isAssetsSummaryLoading &&
    Boolean(relatedPathsAndAssets)

  if (isReady)
    return (
      <DSRIdentityLookup
        identitySpaces={identitySpaces}
        assetsHierarchies={assetsHierarchies}
        relatedPathsAndAssets={relatedPathsAndAssets || {}}
        scenarioData={scenarioData}
        assetsSummary={assetsSummary}
      />
    )

  return <Spinner />
}
