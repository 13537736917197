import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/ui-kit/button/Button'
import { Flex } from 'components/ui-layouts/flex/Flex'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'
import { INSUFFICIENT_PERMISSIONS_TOOLTIP } from 'utils/constants/INSUFFICIENT_PERMISSIONS_TOOLTIP'

export const PoliciesListActions: React.FC = () => {
  const navigate = useNavigate()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWritePolicy = getIsPermitted([PERMISSIONS.ASSET_POLICY_WRITE])

  if (!isPermittedToWritePolicy) return null

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Button
        disabled={!isPermittedToWritePolicy}
        tooltip={!isPermittedToWritePolicy ? INSUFFICIENT_PERMISSIONS_TOOLTIP : undefined}
        onClick={() => {
          navigate(RoutesManager.policyCenter.policies.upsert.getURL({ id: 'new' }))
        }}
      >
        Create Policy
      </Button>
    </Flex>
  )
}
