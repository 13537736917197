import React from 'react'
import Box from '@mui/material/Box'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { styled } from '@mui/styles'

const LoadingWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const SpinnerWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
})

export const InitialLoadingScreen: React.FC = () => {
  return (
    <LoadingWrapper>
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    </LoadingWrapper>
  )
}
