import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useQueryParams } from 'utils/hooks'
import { FormTemplatePreviewQueryParams } from '../interfaces'
import { TabSegmented, TabSegmentedGroup } from '@ketch-com/deck'

export const PreviewModeToggle: React.FC = () => {
  const { queries, setQueryParam } = useQueryParams<{
    formDTO?: FormTemplatePreviewQueryParams['formDTO']
    previewMode?: FormTemplatePreviewQueryParams['previewMode']
  }>()

  // useEffect to set previewMode to 'desktop' if it's not set
  useEffect(() => {
    if (!queries.previewMode) {
      setQueryParam('previewMode', 'desktop')
    }
  }, [queries.previewMode, setQueryParam])

  return (
    <Box display="flex" alignItems="flex-start" width="100%" minWidth={1290} maxWidth={1290}>
      <TabSegmentedGroup
        size="medium"
        value={queries.previewMode || 'desktop'}
        exclusive
        onChange={(_, value) => {
          setQueryParam('previewMode', value)
        }}
      >
        <TabSegmented value={'desktop'} key={'desktop'}>
          Desktop
        </TabSegmented>
        <TabSegmented value={'mobile'} key={'mobile'}>
          Mobile
        </TabSegmented>
      </TabSegmentedGroup>
    </Box>
  )
}
