export enum FEATURE_FLAGS {
  ASSET_MANAGER_VERSION = 'asset_manager_version',
  ASSET_DATA_PREVIEW_VERSION = 'asset_data_preview_version',
  DATA_SYSTEMS_VERSION = 'data_systems_version',
  UPDATE_USER_PROFILE_DISABLED = 'update_user_profile_disabled', // KD-8297
  APPEALS_PERMISSION_PROXY = 'appeals',
  TRACKERS = 'trackers',
  CUSTOM_RIGHT_NAME = 'custom_right_name',
  EXPERIENCE_SERVER_VERSION = 'lanyard_version',
  PROPERTY_TAGS_TRACKER_BLOCKING = 'property_tags_tracker_blocking',
  IMPORT_CONTEXT = 'import_context',
  SYSTEMS_ASSET_TAB = 'systems_asset_tab',
  SYSTEMS_LEGACY_ASSETS_DISABLE = 'systems_legacy_assets_disable',
  SYSTEMS_RIGHTS_ORCHESTRATION_TAB = 'systems_rights_orchestration_tab',
}

export enum FEATURE_FLAGS_VALUES {
  ASSET_MANAGER_VERSION_2 = 'version2',
  ASSET_MANAGER_VERSION_3 = 'version3',
  ASSET_DATA_PREVIEW_VERSION_1 = 'version1',
  DATA_SYSTEMS_VERSION_2 = 'version2',
  UPDATE_USER_PROFILE_DISABLED_TRUE = 'true',
  APPEALS_ENABLED = 'enabled',
  TRACKERS_VERSION_0 = 'version0',
  CUSTOM_RIGHT_NAME_VERSION_1 = 'version1',
  EXPERIENCE_SERVER_VERSION_1 = 'v1',
  EXPERIENCE_SERVER_VERSION_2 = 'v2',
  PROPERTY_TAGS_TRACKER_BLOCKING_ENABLED = 'true',
  IMPORT_CONTEXT_SINGLE_IDENTITY = 'single_identity',
  SYSTEMS_ASSET_TAB_ENABLED = 'true',
  SYSTEMS_RIGHTS_ORCHESTRATION_TAB = 'true',
  SYSTEMS_LEGACY_ASSETS_DISABLE = 'true',
}
