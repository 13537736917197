import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { RoutesManager } from 'utils/routing/routesManager'
import { useRightInvocationV2 } from 'api/rightInvocationsV2/queries/useRightInvocationV2'
import { useWorkflowExecution } from 'api/workflowExecutions/queries/useWorkflowExecution'
import { useUsers } from 'api/users/queries/useUsers'
import { useTwilioConversationClient, useTwilioClientTimeoutTimer } from '../hooks'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { useUserMe } from 'api/users/queries/useUserMe'
import { useIsKetchFree } from 'utils/hooks/useIsKetchFree'
import { useForceCompleteRightInvocation } from 'api/rightInvocationsV2/mutations/useForceCompleteRightInvocation'
import { useEffect, useMemo, useState } from 'react'
import { useAppealWorkflows } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/EndFlowFormV3/hooks/useAppealWorkflows'
import { RightInvocationStatusDTO } from '@ketch-com/figurehead'
import { useWorkflow } from 'api/workflows/queries/useWorkflow'
import { useWorkflowActivities } from 'api/workflows/queries/useWorkflowActivities'
import { useUser } from 'api/users/queries/useUser'
import { useWorkflowPreview } from 'api/workflowExecutions/queries/useWorkflowPreview'
import { useExecutionsInfinite } from 'api/executions/queries/useExecutionsInfinite'
import { WorkflowActivityCode } from 'interfaces/workflowActivities/workflowActivity'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { WorkflowExecutionStepStatus } from 'interfaces/workflowExecutions/workflowExecutionStepStatus'
import { GroupChannelModule } from '@sendbird/chat/groupChannel'
import { SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import SendbirdChat from '@sendbird/chat'
import { getGroupChannel } from 'components/Sendbird/SendbirdGroupChannel/utils/getGroupChannel'
import { useAuth0 } from '@auth0/auth0-react'
import { SENDBIRD_CHANNEL_OWNER_ID } from 'components/Sendbird/SendbirdGroupChannel/utils/enums'
import { reconnectAs } from 'components/Sendbird/SendbirdGroupChannel/utils/reconnectAs'
import { generateSHA256 } from 'utils/helpers/getSha256Hash'

const CHAT_RELEASE_DATE = '2022-12-15'

export const useRightsQueueViewV2Utils = () => {
  const navigate = useNavigate()
  const { id: rightInvocationId } = useParams<{ id: string }>()
  const [isAssignOpen, setAssignOpen] = useState(false)
  const [isEditingAppealWorkflow, setIsEditingAppealWorkflow] = useState(false)
  const [sendbirdUnreadCount, setSendbirdUnreadCount] = useState(0)

  const { user } = useAuth0()
  const userId = useMemo(() => {
    return user?.sub?.includes('support@ketchsupport.com') ? SENDBIRD_CHANNEL_OWNER_ID : user?.sub || ''
  }, [user])

  // Fetch current org and determine if ketch free
  const { isKetchFree } = useIsKetchFree()

  const { data: userMe, isLoading: isLoadingUserMe } = useUserMe({
    params: {
      includeMetadata: true,
    },
  })

  const {
    data: rightInvocation,
    isLoading: isRightInvocationLoading,
    refetch: refetchRightInvocation,
  } = useRightInvocationV2({
    enabled: !!rightInvocationId,
    params: {
      rightInvocationId: rightInvocationId || '',
      includeMetadata: true,
      includeIssues: true,
    },
    onError: () => {
      navigate(RoutesManager.orchestration.rightsQueue.root.getURL())
    },
    refetchInterval: query => {
      return query?.data?.rightInvocation?.status !== RightInvocationStatusDTO.FulfilledRightInvocationStatus
        ? 10000
        : false
    },
  })

  const { data: appealWorkflows, isLoading: isAppealWorkflowsLoading } = useAppealWorkflows({
    canonicalRightCode: rightInvocation?.rightType || '',
  })

  const { data: workflowExecution, isLoading: isWorkflowExecutionLoading } = useWorkflowExecution({
    enabled: !!rightInvocation?.workflowExecutionID,
    params: {
      workflowExecutionId: rightInvocation?.workflowExecutionID as string,
    },
    refetchInterval: () => {
      return rightInvocation?.status !== RightInvocationStatusDTO.FulfilledRightInvocationStatus ? 10000 : false
    },
  })

  const { data: workflowDefinition, isLoading: isWorkflowDefinitionLoading } = useWorkflow({
    enabled: !!workflowExecution?.workflowDefinitionCode,
    params: { workflowCode: workflowExecution?.workflowDefinitionCode || '' },
  })

  const { data: appealWorkflowExecution, isLoading: isAppealWorkflowExecutionLoading } = useWorkflowExecution({
    enabled: !!rightInvocation?.appeal?.results?.appealWorkflowExecutionID,
    params: {
      workflowExecutionId: rightInvocation?.appeal?.results?.appealWorkflowExecutionID as string,
    },
    refetchInterval: () => {
      return rightInvocation?.status !== RightInvocationStatusDTO.FulfilledRightInvocationStatus ? 10000 : false
    },
  })

  const { isLoading: isLoadingDataSubjectTypes } = useDataSubjectTypes({
    itemsPerPage: 1000,
    params: {},
  })

  const { data: users, isLoading: isUsersLoading } = useUsers({
    params: {
      active: true,
      includeMetadata: true,
    },
  })

  const { data: workflowActivities, isLoading: isWorkflowActivitiesLoading } = useWorkflowActivities()

  const appealWorkflowDefinitionCode = rightInvocation?.appeal?.settings?.appealWorkflowDefinitionCode || ''

  const { data: appealWorkflow, isLoading: isAppealWorkflowLoading } = useWorkflow({
    enabled: !!appealWorkflowDefinitionCode,
    params: {
      workflowCode: appealWorkflowDefinitionCode,
    },
  })

  const appealFinalizedBy = useMemo(
    () => (rightInvocation?.appeal?.isAppealed ? rightInvocation?.finalization?.finalizedBy : undefined),
    [rightInvocation],
  )
  const originalFinalizedBy = useMemo(
    () =>
      rightInvocation?.appeal?.isAppealed
        ? rightInvocation?.appeal?.original?.finalizedBy
        : rightInvocation?.finalization?.finalizedBy,
    [rightInvocation],
  )

  const { data: appealFinalizer, isLoading: isLoadingAppealFinalizer } = useUser({
    enabled: !!appealFinalizedBy,
    params: {
      userId: appealFinalizedBy || '',
    },
  })
  const { data: originalFinalizer, isLoading: isLoadingOriginalFinalizer } = useUser({
    enabled: !!originalFinalizedBy,
    params: {
      userId: originalFinalizedBy || '',
    },
  })

  const { data: workflowPreview, isLoading: isWorkflowPreviewLoading } = useWorkflowPreview({
    params: { workflowExecutionId: rightInvocation?.workflowExecutionID as string },
    enabled: !!rightInvocation?.workflowExecutionID,
    refetchInterval: () => {
      return rightInvocation?.status !== RightInvocationStatusDTO.FulfilledRightInvocationStatus ? 10000 : false
    },
  })

  const { data: appealWorkflowPreview, isLoading: isAppealWorkflowPreviewLoading } = useWorkflowPreview({
    //@ts-ignore as the RightInvocationDTO here returns ID, might be the handrolled/generated types difference
    params: { workflowExecutionId: rightInvocation?.appealWorkflowExecution?.ID as string },
    enabled:
      !!rightInvocation?.workflowExecutionID &&
      //@ts-ignore as the RightInvocationDTO here returns ID, might be the handrolled/generated types difference
      !!rightInvocation?.appealWorkflowExecution?.ID,
    refetchInterval: () => {
      return rightInvocation?.status !== RightInvocationStatusDTO.FulfilledRightInvocationStatus ? 10000 : false
    },
  })

  const isRightsQueueAdmin = !!userMe?.roles?.find(role => role.code === 'queue_admin')

  const daysOld = moment().diff(moment((rightInvocation?.metadata?.createdAt || 0) * 1000), 'days')
  /* if right invocation was created after chat feature release date, then OK to enable/display Subject Communication tab */
  const subjectChatFeatureReleaseDate = moment(CHAT_RELEASE_DATE)
  const rightInvocationCreatedMoment = moment((rightInvocation?.metadata?.createdAt || 0) * 1000)
  const isRightInvocationCreatedAfterFeatureReleaseDate =
    Math.sign(rightInvocationCreatedMoment.diff(subjectChatFeatureReleaseDate, 'days')) >= 0

  const isOver400DaysOld = daysOld > 400

  const { hasTwilioClientInitTimedOut } = useTwilioClientTimeoutTimer({
    isOver400DaysOld,
    isRightInvocationLoading,
    isRightInvocationCreatedAfterFeatureReleaseDate,
  })

  const {
    twilioChatClient,
    isLoadingChatToken,
    twilioConversation,
    twilioMessages,
    avatarColorMap,
    unreadCount,
    setUnreadCount,
    connectionState: twilioClientConnectionState = 'unknown',
  } = useTwilioConversationClient({
    conversationId: rightInvocation?.conversationId || '',
  })

  // Get sendbird unread messages count
  useEffect(() => {
    const getUnreadCount = async () => {
      // Get Sendbird application ID environment variable
      const sendbirdAppId = (window as any)?.figureheadConfig?.REACT_APP_SENDBIRD_APPLICATION_ID

      if (!sendbirdAppId) return

      // Initialize SendbirdChat SDK
      const sb = SendbirdChat.init({
        appId: sendbirdAppId,
        modules: [new GroupChannelModule()],
      }) as SendbirdGroupChat

      // Get sendbird ID
      const sendbirdId = await generateSHA256(userId)

      // Connect user
      await sb.connect(sendbirdId)

      // Get channel as user
      let channel = await getGroupChannel(sb, rightInvocation?.channelURL || '', false)
      if (channel && !channel.members.some(member => member.userId === sendbirdId)) {
        // If user is not in channel, reconnect as owner and get channel again so we see the
        // unread count for messages sent while user was not in channel
        await reconnectAs(sb, SENDBIRD_CHANNEL_OWNER_ID)
        channel = await getGroupChannel(sb, rightInvocation?.channelURL || '', false)
      }

      // Get unread count
      setSendbirdUnreadCount(channel?.unreadMessageCount ?? 0)
    }

    // Setup interval to get unread count
    let intervalId: NodeJS.Timer | undefined = undefined
    if (userId && rightInvocation?.channelURL) {
      getUnreadCount()
      intervalId = setInterval(getUnreadCount, 5000)
    }

    // Cleanup interval on unmount
    return () => clearInterval(intervalId)
  }, [rightInvocation?.channelURL, userId])

  const { mutateAsync: forceCompleteRightInvocation, isLoading: isForceCompleteUpdating } =
    useForceCompleteRightInvocation()

  const internalSystemDsrStep =
    workflowExecution?.steps?.filter(
      step =>
        step?.activityStepCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR &&
        step?.status !== WorkflowExecutionStepStatus?.SKIPPED,
    )?.[0] || ({} as WorkflowExecutionStepDTO)

  const {
    data: dsrExecutionsV1,
    dataV2: dsrExecutionsV2,
    isLoading: isLoadingDsrExecutions,
  } = useExecutionsInfinite({
    params: {
      itemsPerPage: 20,
      stepId: internalSystemDsrStep?.stepID || '',
      executionId: workflowExecution?.ID || '',
      enabled: internalSystemDsrStep.activityStepCode === WorkflowActivityCode.INTERNAL_SYSTEM_DSR,
    },
  })

  const isReadyWithoutTwilioClient =
    !isRightInvocationLoading &&
    !isWorkflowExecutionLoading &&
    !isWorkflowPreviewLoading &&
    !isAppealWorkflowPreviewLoading &&
    !isUsersLoading &&
    !isLoadingChatToken &&
    !isLoadingDataSubjectTypes &&
    !isLoadingUserMe &&
    !isAppealWorkflowsLoading &&
    !isAppealWorkflowLoading &&
    !isWorkflowActivitiesLoading &&
    !isAppealWorkflowExecutionLoading &&
    !isWorkflowDefinitionLoading &&
    !isLoadingAppealFinalizer &&
    !isLoadingOriginalFinalizer &&
    !isLoadingDsrExecutions

  const isReadyWithTwilioClient = isReadyWithoutTwilioClient && !!twilioConversation

  const isReady =
    hasTwilioClientInitTimedOut && !twilioConversation ? isReadyWithoutTwilioClient : isReadyWithTwilioClient

  // details needed to populate "Original" and "Appeal" below
  const isAppealed = !!rightInvocation?.appeal?.isAppealed
  const originalFinalizedAs = isAppealed
    ? rightInvocation?.appeal?.original?.finalizedStatus
    : rightInvocation?.finalization?.finalizedStatus
  const originalFinalizedAt = isAppealed
    ? rightInvocation.appeal?.original?.finalizedAt
    : rightInvocation?.finalization?.finalizedAt
  const originalInvokedAt = isAppealed ? rightInvocation.appeal?.original?.invokedAt : rightInvocation?.invokedAt
  const originalDueAt = isAppealed ? rightInvocation.appeal?.original?.dueAt : rightInvocation?.dueAt
  const appealInvokedAt = isAppealed ? rightInvocation?.invokedAt : undefined
  const appealFinalizedAs = isAppealed ? rightInvocation?.appeal?.results?.finalizedStatus : undefined
  const appealFinalizedAt = isAppealed ? rightInvocation.appeal?.results?.finalizedAt : undefined

  // "Original" is details on the "original" workflow (not the "appeal"). In the case
  // there is no "appeal", look to the right spot for these details
  const original = {
    invokedAt: originalInvokedAt,
    dueAt: originalDueAt,
    finalizedAs: originalFinalizedAs,
    isPending: originalFinalizedAs === RightInvocationStatusDTO.PendingRightInvocationStatus,
    isFulfilled: originalFinalizedAs === RightInvocationStatusDTO.FulfilledRightInvocationStatus,
    isRejected: originalFinalizedAs === RightInvocationStatusDTO.RejectedRightInvocationStatus,
    finalizedAt: originalFinalizedAt,
    finalizedBy: originalFinalizer,
    finalizedByName:
      originalFinalizer?.firstName || originalFinalizer?.lastName
        ? [originalFinalizer?.firstName, originalFinalizer?.lastName].filter(Boolean).join(' ')
        : 'Unknown User',
    forcefullyCompleted: isAppealed
      ? rightInvocation.appeal?.original?.forcefullyCompleted
      : rightInvocation?.finalization?.forcefullyCompleted,
    forcefullyCompletedReason: isAppealed
      ? rightInvocation.appeal?.original?.forcefullyCompletedReason
      : rightInvocation?.finalization?.forcefullyCompletedReason,
    daysTakenToFulfillRequest: Math.ceil(((originalFinalizedAt ?? 0) - (originalInvokedAt ?? 0)) / (24 * 60 * 60)),
    daysAllowedToFulfillRequest: Math.ceil(((originalDueAt ?? 0) - (originalInvokedAt ?? 0)) / (24 * 60 * 60)),
    daysRemaining: Math.ceil(((rightInvocation?.dueAt ?? 0) - (new Date().getTime() ?? 0) / 1000) / (24 * 60 * 60)),
    workflowExecution,
  }

  // "Appeal" is details on the "appeal" workflow (not the "original")
  const appeal = {
    invokedAt: appealInvokedAt,
    dueAt: isAppealed ? rightInvocation?.dueAt : undefined,
    reason: rightInvocation?.appeal?.appealReason,
    finalizedAs: appealFinalizedAs,
    isPending: appealFinalizedAs === RightInvocationStatusDTO.PendingRightInvocationStatus,
    isFulfilled: appealFinalizedAs === RightInvocationStatusDTO.FulfilledRightInvocationStatus,
    isRejected: appealFinalizedAs === RightInvocationStatusDTO.RejectedRightInvocationStatus,
    finalizedAt: appealFinalizedAt,
    finalizedBy: appealFinalizer,
    finalizedByName:
      appealFinalizer?.firstName || appealFinalizer?.lastName
        ? [appealFinalizer?.firstName, appealFinalizer?.lastName].filter(Boolean).join(' ')
        : 'Unknown User',
    forcefullyCompleted: isAppealed ? rightInvocation.appeal?.results?.forcefullyCompleted : undefined,
    forcefullyCompletedReason: isAppealed ? rightInvocation.appeal?.results?.forcefullyCompletedReason : undefined,
    daysTakenToFulfillAppeal: moment.unix(appealFinalizedAt ?? 0).diff(moment.unix(appealInvokedAt ?? 0), 'days'),
    daysAllowedToFulfillAppeal: rightInvocation?.appeal?.settings?.timeToFulfillAppealInDays,
    invokeAllowedUntil:
      (originalFinalizedAt ?? 0) > 0
        ? moment
            .unix(originalFinalizedAt ?? 0)
            .add(rightInvocation?.appeal?.settings?.timeToInvokeAppealInDays, 'days')
            .unix()
        : 0,
    daysTakenToInvokeAppeal: moment.unix(appealInvokedAt || 0).diff(moment.unix(originalFinalizedAt || 0), 'days'),
    daysRemaining: Math.ceil(((rightInvocation?.dueAt ?? 0) - (new Date().getTime() ?? 0) / 1000) / (24 * 60 * 60)),
    workflowExecution: appealWorkflowExecution,
  }

  const rightAllowsAppeal = rightInvocation?.appeal?.settings?.rightAllowsAppeal

  const currentStep = rightInvocation?.workflowExecution?.steps?.at(-1)
  const assignee = users.find(u => u.ID === currentStep?.assignee || '')
  const isStandardActivity = currentStep?.activityStepCode?.includes('standard.')
  const dueAt = currentStep?.dueAt

  const appealWorkflowExecutionID = rightInvocation?.appeal?.results?.appealWorkflowExecutionID

  const workflowExecutionId = rightInvocation?.workflowExecutionID

  const payload = {
    appealWorkflow,
    appealWorkflowDefinitionCode,
    appealWorkflowExecution,
    appealWorkflowExecutionID,
    appealWorkflowPreview,
    appealWorkflows,
    appeal,
    assignee,
    avatarColorMap,
    currentStep,
    dueAt,
    forceCompleteRightInvocation,
    hasTwilioClientInitTimedOut,
    isAppealed,
    isAssignOpen,
    isEditingAppealWorkflow,
    isForceCompleteUpdating,
    isKetchFree,
    isReady,
    isReadyWithoutTwilioClient,
    isRightInvocationCreatedAfterFeatureReleaseDate,
    isRightsQueueAdmin,
    isStandardActivity,
    original,
    refetchRightInvocation,
    rightAllowsAppeal,
    rightInvocation,
    rightInvocationId,
    setAssignOpen,
    setIsEditingAppealWorkflow,
    setUnreadCount,
    sendbirdUnreadCount,
    twilioChatClient,
    twilioClientConnectionState,
    twilioConversation,
    twilioMessages,
    unreadCount,
    users,
    workflowActivities,
    workflowDefinition,
    workflowExecution,
    workflowExecutionId,
    workflowPreview,
    dsrExecutionsV1,
    dsrExecutionsV2,
  }

  return payload
}

export type UseRightsQueueViewV2UtilsReturn = ReturnType<typeof useRightsQueueViewV2Utils>
