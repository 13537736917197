import React from 'react'
import { Box, Chip } from '@mui/material'

import { Text } from 'components/ui-kit/typography/Text'

export const AllowAccessChip: React.FC = () => {
  return (
    <Chip
      clickable={false}
      label={
        <Box display="flex" alignItems="center">
          <Text size={14} color="white">
            Allow access
          </Text>
        </Box>
      }
      sx={{
        backgroundColor: 'darkDusk.main',
      }}
    />
  )
}
