import { Box } from '@mui/material'
import { RightScenarioMap } from 'pages/assetManager/scenario/interfaces'
import { GetDSRConfigCountResponseBodyDTO, ScenarioDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { Scenario } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/Scenario'

type Props = {
  eventBasedScenario: ScenarioDTO[]
  dsrConfigCount?: GetDSRConfigCountResponseBodyDTO
  isDatasetScenario?: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
  isCanonical?: boolean
}

export const Rights: React.FC<Props> = ({
  eventBasedScenario,
  dsrConfigCount = {},
  isDatasetScenario,
  assetSummary,
  isCanonical,
}) => {
  const rightScenarioMap = eventBasedScenario.reduce((prev, curr) => {
    const rightCode = isCanonical ? curr.canonicalRight?.code || '' : curr.right?.code || ''
    if (!prev[rightCode]) prev[rightCode] = []
    prev[rightCode].push(curr)
    return prev
  }, {} as RightScenarioMap)

  const rightScenarioMapKeys = Object.keys(rightScenarioMap)
  return (
    <>
      {rightScenarioMapKeys.map(rightCode => {
        const scenarioList = rightScenarioMap[rightCode]
        return (
          <Box key={rightCode} mt={4} mb={4}>
            <Typography paragraph variant="h4">
              {isCanonical ? scenarioList[0].canonicalRight?.name : scenarioList[0].right?.name}
            </Typography>
            {scenarioList.map(rightScenario => (
              <Scenario
                key={rightScenario.id}
                scenario={rightScenario}
                dsrConfigCount={dsrConfigCount}
                assetSummary={assetSummary}
                isDatasetScenario={isDatasetScenario}
              />
            ))}
          </Box>
        )
      })}
    </>
  )
}
