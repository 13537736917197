import { getIn, useFormikContext } from 'formik'
import { BannerContainerPosition, ExperienceConfig, ModalContainerPosition, ThemeConfig } from '@ketch-sdk/ketch-types'
import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { theme } from '@ketch-com/deck'
import { styled } from '@mui/styles'
import { ReactComponent as BannerBottomIcon } from 'assets/icons/banner-position-bottom-full-width.svg'
import { ReactComponent as BannerTopIcon } from 'assets/icons/banner-position-top-full-width.svg'
import { ReactComponent as BannerLeftIcon } from 'assets/icons/banner-position-left-corner.svg'
import { ReactComponent as BannerRightIcon } from 'assets/icons/banner-position-right-corner.svg'
import { ReactComponent as BannerMiddleIcon } from 'assets/icons/banner-position-middle.svg'
import { ReactComponent as BannerCenterIcon } from 'assets/icons/banner-position-center.svg'
import { ReactComponent as ModalCenterIcon } from 'assets/icons/modal-position-center.svg'
import { ReactComponent as ModalLeftIcon } from 'assets/icons/modal-position-left.svg'
import { ReactComponent as ModalRightIcon } from 'assets/icons/modal-position-right.svg'
import { themeUpsertFormKeys } from '../../../utils/useThemeValidationSchema'

type Props = {
  variant: 'banner' | 'modal'
}

type Option = {
  label: string
  value: string
  icon: React.ReactNode
}

const bannerOptions: Option[] = [
  {
    label: 'Bottom, Full-Width',
    value: BannerContainerPosition.Bottom,
    icon: <BannerBottomIcon />,
  },
  {
    label: 'Top, Full-Width',
    value: BannerContainerPosition.Top,
    icon: <BannerTopIcon />,
  },
  {
    label: 'Left Corner',
    value: BannerContainerPosition.LeftCorner,
    icon: <BannerLeftIcon />,
  },
  {
    label: 'Right Corner',
    value: BannerContainerPosition.RightCorner,
    icon: <BannerRightIcon />,
  },
  {
    label: 'Bottom Middle',
    value: BannerContainerPosition.BottomMiddle,
    icon: <BannerMiddleIcon />,
  },
  {
    label: 'Center',
    value: BannerContainerPosition.Center,
    icon: <BannerCenterIcon />,
  },
]

const modalOptions: Option[] = [
  {
    label: 'Centered',
    value: ModalContainerPosition.Center,
    icon: <ModalCenterIcon />,
  },
  {
    label: 'Left',
    value: ModalContainerPosition.Left,
    icon: <ModalLeftIcon />,
  },
  {
    label: 'Right',
    value: ModalContainerPosition.Right,
    icon: <ModalRightIcon />,
  },
]

const SelectionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 56,
  height: 48,
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.fadedGrey.main,
  borderRadius: 5,
}))

export const SidebarFormPositionRadioGroup: React.FC<Props> = ({ variant }) => {
  const { values, setFieldValue } = useFormikContext<ExperienceConfig | ThemeConfig>()

  // Set the form key and position options depending on the variant
  const formKey =
    variant === 'banner' ? themeUpsertFormKeys.bannerContainerPosition : themeUpsertFormKeys.modalContainerPosition
  const options = variant === 'banner' ? bannerOptions : modalOptions

  // Get current position value
  const selected = getIn(values, formKey)

  const handleClick = (value: string) => {
    setFieldValue(formKey, value)
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant={'label'} mb={'6px'}>
        Position
      </Typography>
      <Box display={'grid'} gridTemplateColumns={'repeat(6, 1fr)'} columnGap={variant === 'banner' ? '7.2px' : '24px'}>
        {options.map(({ label, value, icon }) => (
          <Box display={'flex'} flexDirection={'column'} gap={'6px'} key={value}>
            <SelectionBox
              onClick={() => handleClick(value)}
              sx={{
                border: selected === value ? `2px solid ${theme.palette.sphere.main}` : '',
                padding: selected === value ? '9px 8px' : '11px 10px',
              }}
            >
              {icon}
            </SelectionBox>
          </Box>
        ))}
      </Box>
      <Box display={'grid'} gridTemplateColumns={'repeat(6, 1fr)'} columnGap={variant === 'banner' ? '7.2px' : '24px'}>
        {options.map(({ label, value, icon }) => (
          <Box width={56} mt={'2px'} key={value}>
            {selected === value && (
              <Typography variant={'smallBody'} color={theme.palette.Text.Secondary} whiteSpace={'nowrap'}>
                {label}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
