import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { ReactComponent as ContextVariablesEmptyIcon } from 'assets/icons/context_variables.svg'
import { UpsertContextVariablesModal, ViewContextVariablesModal } from 'pages/settings/contextVariables/components'
import { useContextVariablesListUtils } from '../hooks'
import { contextVariableCategoryOptions } from '../constants'
import {
  TooltipButton,
  ListLayout,
  EmptyState,
  ActionSheetItem,
  ListItemText,
  Button,
  Icon,
  ActionSheetWrapper,
  DataGrid,
} from '@ketch-com/deck'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { useContextVariablesListColumns } from '../utils/contextVariablesListGridUtils'
import { GridRowParams } from '@mui/x-data-grid'
import { ContextVariableDTO } from '@ketch-com/figurehead'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

export const ContextVariablesList: React.FC = () => {
  const {
    contextVariables,
    categoryFilterOptions,
    isEmptyState,
    isEntitledToCreateContextVariable,
    isLoading,
    isPermittedToCreateContextVariable,
    isUpsertModalOpen,
    onCreateSubmit,
    pagination,
    isFetching,
    isRefetching,
    searchString,
    setIsUpsertModalOpen,
    isViewModalOpen,
    setIsViewModalOpen,
    setSearchString,
    searchParams,
    selectedContextVariable,
    setSelectedContextVariable,
    onEditSubmit,
  } = useContextVariablesListUtils()

  const breadcrumbs = [
    {
      title: 'Settings',
      link: RoutesManager.settings.root.getURL(),
    },
    { title: 'Variables', link: RoutesManager.settings.contextVariables.root.getURL() },
  ]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const columns = useContextVariablesListColumns()
  const isGridLoading = isLoading || isFetching || isRefetching
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        sx={{
          '&.DeckLayout-wrapper .DeckLayout-content': {
            minHeight: 'auto',
          },
        }}
        headerTitle="Variables"
        customHeaderRightActionComponent={
          !isEmptyState ? (
            <TooltipButton
              disabled={!isEntitledToCreateContextVariable || !isPermittedToCreateContextVariable}
              onClick={() => setIsUpsertModalOpen(true)}
              //@ts-expect-error
              title={
                !isPermittedToCreateContextVariable || !isEntitledToCreateContextVariable ? (
                  <>
                    {!isPermittedToCreateContextVariable ? (
                      <span>
                        You do not have sufficient permissions to perform this action, please contact your admin.
                      </span>
                    ) : (
                      <span>
                        No context variables entitlements remaining, please delete one or contact your admin for more.
                      </span>
                    )}
                  </>
                ) : null
              }
            >
              Create Variable
            </TooltipButton>
          ) : null
        }
        actionHeaderBottomBlockComponent={
          !isEmptyState ? (
            <>
              <SearchTextInput
                size="small"
                onChange={nextSearchString => setSearchString(nextSearchString)}
                showClearButton
                value={searchString}
                placeholder="Search"
              />
              <Button
                sx={{ ml: 2 }}
                size="medium"
                color="tertiary"
                onClick={handleButtonClick}
                endIcon={<Icon name={open ? 'OArrowCUp' : 'OArrowCDown'} />}
              >
                {contextVariableCategoryOptions?.find(({ id }) => id === searchParams.get('category'))?.label ??
                  'Variable Type'}
              </Button>
              <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose}>
                {categoryFilterOptions.map((option, index) => (
                  <ActionSheetItem
                    key={option.value}
                    selected={option.active}
                    onClick={_ => {
                      setAnchorEl(null)
                      option.onClick()
                    }}
                  >
                    <ListItemText selected={option.active}>{option.content}</ListItemText>
                  </ActionSheetItem>
                ))}
              </ActionSheetWrapper>
            </>
          ) : null
        }
        headerProps={{
          isDivider: true,
        }}
      >
        <Box>
          {isEmptyState ? (
            <Box
              sx={{
                backgroundColor: 'bone.main',
                padding: 5,
                borderRadius: '11px',
              }}
            >
              <EmptyState
                title="There are no Variables for this organization"
                subTitle="Create your first one to populate the table"
                customIcon={<ContextVariablesEmptyIcon />}
                primaryButtonTitle={isPermittedToCreateContextVariable ? 'Create Context Variables' : undefined}
                primaryButtonProps={{
                  onClick: () => setIsUpsertModalOpen(true),
                }}
              />
            </Box>
          ) : (
            <>
              <DataGrid
                sx={{
                  cursor: 'pointer',
                  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                    backgroundImage: 'unset',
                    cursor: 'auto',
                  },
                  ...(isLoading && {
                    '& .MuiDataGrid-virtualScroller': {
                      height: '300px',
                    },
                  }),
                }}
                autosizeOnMount
                autosizeOptions={{
                  expand: true,
                  includeHeaders: true,
                  includeOutliers: false,
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                disableChildrenSorting
                disableColumnMenu
                disableColumnPinning
                disableColumnResize
                disableColumnReorder
                disableRowSelectionOnClick
                disableMultipleRowSelection
                getRowId={row => row.code}
                rows={contextVariables || []}
                loading={isGridLoading}
                noRowsOverlayProps={{
                  buttonTitle: '',
                }}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={onPaginationModelChange}
                pageSizeOptions={[rowsPerPage]}
                rowCount={pagination.totalResults}
                hideFooter={pagination.totalPages < 2}
                onRowClick={({ row }: GridRowParams<ContextVariableDTO>) => {
                  if (row) {
                    setSelectedContextVariable(row)
                    setIsViewModalOpen(true)
                  }
                }}
              />
            </>
          )}
        </Box>
      </ListLayout>

      {/* View Modal */}
      {selectedContextVariable && isViewModalOpen && (
        <ViewContextVariablesModal
          contextVariable={selectedContextVariable}
          onEdit={() => {
            setIsViewModalOpen(false)
            setIsUpsertModalOpen(true)
          }}
          onCancel={() => {
            setIsViewModalOpen(false)
            setSelectedContextVariable(undefined)
          }}
        />
      )}

      {/* Upsert Modal */}
      {isUpsertModalOpen && (
        <UpsertContextVariablesModal
          contextVariable={selectedContextVariable}
          onCancel={() => {
            setIsUpsertModalOpen(false)
            setSelectedContextVariable(undefined)
          }}
          onSubmit={!selectedContextVariable ? onCreateSubmit : onEditSubmit}
        />
      )}
    </>
  )
}
