import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO, ScenarioDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { ScenarioAssetConfiguration, ScenarioStats } from 'pages/assetManager/scenario/list/components'
import Typography from '@mui/material/Typography'

const PREFIX = 'ScenarioStats'

const classes = {
  rightsConfiguration: `${PREFIX}-rights-configuration`,
}

const Root = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.rightsConfiguration}`]: {
    backgroundColor: palette.superLightGrey.main,
    margin: spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1.5),
    borderRadius: spacing(1.5),
  },
}))

type Props = {
  scenario: ScenarioDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  isDatasetScenario?: boolean
  assetSummary: MaybeNull<AssetSummaryDTO>
}

/**
 * @deprecated
 * @param scenario
 * @param dsrConfigCount
 * @param assetSummary
 * @param isDatasetScenario
 * @constructor
 */
export const Scenario: React.FC<Props> = ({ scenario, dsrConfigCount, assetSummary, isDatasetScenario }) => {
  return (
    <Root>
      <Box className={classes.rightsConfiguration}>
        <Typography>{scenario.name}</Typography>
        {isDatasetScenario ? (
          <ScenarioAssetConfiguration scenario={scenario} assetSummary={assetSummary} />
        ) : (
          <ScenarioStats scenario={scenario} dsrConfigCount={dsrConfigCount} assetSummary={assetSummary} />
        )}
      </Box>
    </Root>
  )
}
