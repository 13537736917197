import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from '@ketch-com/deck'
import { Box, Typography, Autocomplete as MuiAutocomplete } from '@mui/material'
import { AutoCompleteSingleSelectExample as DeckAutocomplete } from './AutoCompleteSingleSelectExample'
import TextField from '@mui/material/TextField'
import { useFormFields } from 'api/formFields/queries/useFormFields'

export const MuiAutocompleteExample: React.FC = () => {
  const { data: allFormFields } = useFormFields({
    itemsPerPage: 1000,
    params: {
      includeMetadata: true,
    },
  })

  return (
    <Formik
      initialValues={{ autocompleteField: '' }}
      onSubmit={values => console.info('Form submitted with values:', values)}
    >
      {formik => {
        const { values, setFieldValue } = formik

        return (
          <Form>
            <Typography variant="h3" mb={2}>
              Autocomplete straight from MUI
            </Typography>
            <MuiAutocomplete
              id="autocompleteField"
              options={allFormFields}
              getOptionLabel={option => option.name || ''}
              value={allFormFields.find(opt => opt.code === values.autocompleteField) || null}
              onChange={(_, newValue) => {
                setFieldValue('autocompleteField', newValue ? newValue.code : '')
              }}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              renderInput={params => <TextField {...params} label="Form Field type" />}
            />
            <Button type="submit">Submit Mui Auto</Button>
            <pre>{JSON.stringify(formik, null, 2) || 'nothing to preview'}</pre>
          </Form>
        )
      }}
    </Formik>
  )
}

export const AutocompleteCompareContrast: React.FC = () => {
  return (
    <Box display="flex">
      <Box flex={1}>
        <DeckAutocomplete />
      </Box>
      <Box flex={1}>
        <MuiAutocompleteExample />
      </Box>
    </Box>
  )
}
