import React from 'react'
import Box from '@mui/material/Box'
import { EditOutlined, DeleteOutline } from '@mui/icons-material'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'

import { Text } from 'components/ui-kit/typography/Text'
import { AccessUtilizationTable, AccessUtilizationQueriesGraph } from './components'
import { useParams } from 'react-router-dom'
import { teamMembersAlt } from 'pages/insights/activityAndAccess/__mocks__/generateDummyTeamMembers'
import { DetailsClasses, StyledBox } from 'pages/insights/activityAndAccess/components/detailsStyles'

export const AccessUtilizationDetails: React.FC = () => {
  const { code: userId } = useParams<{ code?: string }>()
  const member = teamMembersAlt.find(member => member.id === userId)

  return (
    <StyledBox className={DetailsClasses.container}>
      {/* Name and Actions Row */}

      <Box mb={6} display="flex" alignItems="center" justifyContent="space-between">
        <Text size={28} weight={800}>
          {member?.name}
        </Text>
        <ButtonOptions
          size="big"
          items={[
            {
              content: 'Edit',
              icon: <EditOutlined />,
              onClick: () => {},
            },
            {
              content: 'Delete',
              icon: <DeleteOutline />,
              onClick: () => {},
            },
          ]}
        />
      </Box>

      <AccessUtilizationQueriesGraph teamMemberName={member?.name} />

      <AccessUtilizationTable teamMemberName={member?.name} />
    </StyledBox>
  )
}
