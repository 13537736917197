import React from 'react'
import { Typography, Box } from '@mui/material'
import { ListLayout, EmptyState } from '@ketch-com/deck'
import { AuditLogFilters, AuditLogsInvokedRights, AuditLogsPermits } from './components'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

export const AuditLogs: React.FC = () => {
  const { queries } = useQueryParams<{ identifierSearch?: string; identitySpaceCode?: string }>()

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Audit Logs', link: RoutesManager.insights.auditLogs.root.getURL() },
  ]

  return (
    <ListLayout
      // Title Section with Filters
      headerProps={{
        isDivider: true,
        subTitle: 'Check current and historical consent or rights invocations for any identifier.',
        titleCustomComponent: (
          <Box sx={{ marginBottom: '4px' }}>
            <Typography variant="h1">Audit Log</Typography>
          </Box>
        ),
      }}
      actionHeaderBottomBlockComponent={<AuditLogFilters />}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {queries?.identifierSearch && (
        <Box display="flex" gap={1} mb={2}>
          <Typography variant="h2">Results for</Typography>
          <Typography variant="h2" color="darkGrey.main">
            {queries?.identifierSearch}
          </Typography>
        </Box>
      )}

      {/* Empty State */}

      {!queries?.identifierSearch && !queries?.identitySpaceCode && (
        <Box
          sx={{
            backgroundColor: 'white.main',
            borderRadius: '11px',
            height: '250px',
          }}
        >
          <EmptyState title="Search for identifier to see the results" iconName="OFingerprint" />
        </Box>
      )}

      {/* Results */}

      {queries?.identifierSearch && queries?.identitySpaceCode && (
        <>
          <Box display="flex" mb={2}>
            <AuditLogsPermits />
            <AuditLogsInvokedRights />
          </Box>
          {/* Interactions to wait until next sprint per priorities */}
          {/* <AuditLogsInteractionsLog /> */}
        </>
      )}
    </ListLayout>
  )
}
