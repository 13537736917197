import { createContext } from 'react'
import { TrackerDiagnosticsRecentScanUtils } from 'pages/policyCenter/trackers/interfaces'
import { useTrackerDiagnosticsRecentScanUtils } from 'pages/policyCenter/trackers/hooks/useTrackerDiagnosticsRecentScanUtils'

export const TrackerDiagnosticsRecentScanContext = createContext({} as TrackerDiagnosticsRecentScanUtils)

type Props = {
  children?: React.ReactNode
}

export const TrackerDiagnosticsRecentScanContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useTrackerDiagnosticsRecentScanUtils()

  return (
    <TrackerDiagnosticsRecentScanContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </TrackerDiagnosticsRecentScanContext.Provider>
  )
}

export const withTrackerDiagnosticsRecentScanContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <TrackerDiagnosticsRecentScanContextProvider>
        <Component {...props} />
      </TrackerDiagnosticsRecentScanContextProvider>
    )
  }
}
