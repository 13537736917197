import { DataSystemsProvider } from './context/DataSystemContext'
import { AssetsTabFilterProvider } from './assetsTab/context/AssetsTabFilterContext'
import { DataSystem } from './DataSystem'
import { DataCategoriesRolloverProvider } from './context/DataCategoriesRolloverContext'

export const DataSystemContainer: React.FC = () => (
  <DataSystemsProvider>
    <DataCategoriesRolloverProvider>
      <AssetsTabFilterProvider>
        <DataSystem />
      </AssetsTabFilterProvider>
    </DataCategoriesRolloverProvider>
  </DataSystemsProvider>
)
