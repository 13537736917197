import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { CircularProgress, CircularProgressSize, EmptyState, DataGrid } from '@ketch-com/deck'

import { allowanceBreakdownPerPurposeColumns } from '../utils'
import { useAppSelector } from 'store/hooks'
import { getPermitStatisticsFilterState } from 'store/permitStatisticsFilterStateSlice/selectors'
import { usePermitsStatsPurpose } from 'api/permitStatistics/queries/usePermitsStatsPurpose'
import { useIsLoadingAggregate } from 'pages/insights/permitsStatistics/utils'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'
import { useIsDemoOrg } from 'utils/hooks/useIsDemoOrg'

const PREFIX = 'AllowanceBreakdownPerPurpose'

const classes = {
  table: `${PREFIX}-table`,
}

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  [`& .${classes.table}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
  },
}))

export const AllowanceBreakdownPerPurpose: React.FC = () => {
  const { startDate, endDate, identitySpace, jurisdiction, environment, property, purpose } =
    useAppSelector(getPermitStatisticsFilterState)

  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])
  const isLoadingAggregate = useIsLoadingAggregate()
  const { isDemoOrg } = useIsDemoOrg()

  const {
    data: purposes,
    pagination,
    isLoading: isLoadingPermitsStatsPurpose,
  } = usePermitsStatsPurpose({
    itemsPerPage: 5,
    params: {
      startDate: `${moment(new Date(startDate)).format('YYYY-MM-DD')}`,
      endDate: `${moment(new Date(endDate)).format('YYYY-MM-DD')}`,
      identitySpace,
      jurisdiction,
      environment,
      property,
      purpose,
    },
  })

  const transformedFetchedData = purposes?.map(item => ({
    ...item,
    allowed: item?.allowedPermits || 0,
    allowanceRate: ((item?.allowedPermits || 0) / (item?.totalPermits || 1)) * 100,
  }))

  const totalPages = pagination.totalPages
  const isFetching = pagination.isPending

  const rowsPerPage = React.useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = React.useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  if (isLoadingAggregate || isLoadingPermitsStatsPurpose) {
    return (
      <StyledBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Purpose')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 0.5 }}>
            {t('How much personal data can be used for each purpose?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={CircularProgressSize.xLarge} />
        </Box>
      </StyledBox>
    )
  }

  if (!isLoadingAggregate && !isLoadingPermitsStatsPurpose && purposes.length === 0) {
    return (
      <StyledBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: ({ palette }) => palette.white.main,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{t('Per Purpose')}</Typography>
          <Typography variant="body" color="darkDuskFaded.main">
            {t('How much personal data can be used for each purpose?')}
          </Typography>
        </Box>

        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
          <EmptyState colorTheme="darkDuskFaded" iconName="OFilter" title="No data for selected filter criteria" />
        </Box>
      </StyledBox>
    )
  }

  return (
    <StyledBox
      sx={{
        borderRadius: 2,
        display: 'flex',
        background: theme => theme.palette.white.main,
        flexDirection: 'column',
        width: isDemoOrg ? 421 : 632,
        p: 4,
      }}
    >
      <Typography variant="h3">{t('Per Purpose')}</Typography>
      <Typography variant="body" color="darkDuskFaded.main" sx={{ mb: 4.25 }}>
        {t('How much personal data can be used for each purpose?')}
      </Typography>
      <DataGrid
        getRowId={row => row?.code || row?.allowanceRate}
        sx={{
          padding: 0,
        }}
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
          columns: allowanceBreakdownPerPurposeColumns(t).map((column, index) => column.field),
          expand: true,
        }}
        getRowHeight={() => 'auto'}
        columns={allowanceBreakdownPerPurposeColumns(t, isDemoOrg)}
        disableChildrenSorting
        disableColumnMenu
        disableColumnPinning
        disableColumnResize
        disableColumnReorder
        disableRowSelectionOnClick
        disableRowHoverStates
        loading={isLoadingPermitsStatsPurpose || isLoadingAggregate || isFetching}
        rows={transformedFetchedData}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={[rowsPerPage]}
        rowCount={pagination.totalResults}
        hideFooterPagination={totalPages < 2}
      />
    </StyledBox>
  )
}
