import { GetTrackerFoundOnDatesParams, GetTrackerFoundOnDates } from '@ketch-com/atlas/dist/apiTypes/foundOn'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

export const fetchTrackerFoundOnDates = ({
  property_code,
  encodedTrackerKey,
}: GetTrackerFoundOnDatesParams & {
  encodedTrackerKey?: string
}) => {
  return API.get<GetTrackerFoundOnDates>(
    formatRequestString({
      entityUrl: `/api/tracker-diagnostics/found-on/${encodedTrackerKey}/dates`,
      params: {
        filters: {
          ...(property_code && {
            property_code,
          }),
        },
      },
    }),
  )
}
