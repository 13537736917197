import React from 'react'
import { useFormikContext } from 'formik'
import { makeStyles, createStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { Checkbox } from 'components/ui-kit/checkbox/Checkbox'
import { Table } from 'components/ui-layouts/table/Table'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { Text } from 'components/ui-kit/typography/Text'

import { usePurposes } from 'api/purposes/queries/usePurposes'
import { EditDatabaseFormValues } from 'interfaces/assets'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      checkbox: {
        paddingLeft: spacing(2.25),
        marginBottom: spacing(3.625),
        marginTop: spacing(3.625),
      },
    }),
  { name: 'EditPurposesFormSection' },
)

export const EditPurposesFormSection: React.FC = () => {
  const classes = useStyles()
  const { data: purposes } = usePurposes({})
  const { values, setFieldValue } = useFormikContext<EditDatabaseFormValues>()
  const { purposes: formikPurposes } = values
  const selectedCount = formikPurposes?.length || 0
  const totalCount = purposes?.length
  const value =
    selectedCount > 0 && selectedCount < totalCount ? ('indeterminate' as const) : selectedCount === totalCount

  return (
    <FormSection title="Purposes" subTitle="Select purposes that this asset should be associated with.">
      <Box className={classes.checkbox}>
        <Checkbox
          title={
            <Box display="flex" alignItems="center">
              <Text size={14} weight={600}>
                {selectedCount}
              </Text>
              <Text size={14} weight={600} color="grey" mr={0.5}>
                /{totalCount}
              </Text>
              <Text size={14} weight={600}>
                Selected
              </Text>
            </Box>
          }
          value={value}
          onChange={() => {
            if (value === 'indeterminate' || value) {
              setFieldValue('purposes', [])
            } else {
              const payload = purposes.map(({ code, name }) => ({ code, name }))
              setFieldValue('purposes', payload)
            }
          }}
        />
      </Box>

      <Table
        items={purposes}
        cellSettings={{
          name: {
            label: 'Purpose',
            width: 250,
            cellRenderer: ({ code: purposeCode, name }, rowIndex) => {
              const isChecked = !!formikPurposes.find(({ code }) => code === purposeCode)
              const nextValue = !isChecked
              return (
                <Box display="flex" alignItems="center" gap={1.25}>
                  <Checkbox
                    value={isChecked}
                    onChange={() => {
                      if (nextValue) {
                        const payload = [...formikPurposes, { code: purposeCode, name }]
                        setFieldValue('purposes', payload)
                      } else {
                        const payload = formikPurposes.filter(({ code }) => code !== purposeCode)
                        setFieldValue('purposes', payload)
                      }
                    }}
                  />
                  <NameAndCodeCellRenderer name={name} code={purposeCode} />
                </Box>
              )
            },
          },
          description: {
            label: 'Description',
            cellRenderer: ({ code: purposeCode }) => {
              const right = purposes.find(({ code }) => code === purposeCode)
              return <Text size={14}>{right?.description}</Text>
            },
          },
        }}
      />
    </FormSection>
  )
}
