import {
  Avatar,
  AvatarSize,
  LabelChip,
  LabelChipColor,
  LabelChipVariant,
  Tab,
  TabGroup,
  TabPanel,
  TabSegmented,
  TabSegmentedGroup,
  theme,
} from '@ketch-com/deck'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { Box, Typography } from '@mui/material'
import useFilteredAndDedupedDataSystemAssets from '../hooks/useFilteredAndDedupedDataSystemAssets'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { dataSystemAssetsColumns } from '../utils/DataSystemAssetsDataGridUtil'
import { useDataCategoriesRolloverContext } from '../../context/DataCategoriesRolloverContext'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { DataSystemAssetsTable } from '../DataSystemAssetsTable'
import { getResourceType } from '../utils/getResourceType'
import { AssetsTabFilters } from './AssetsTabFilters'
import { DrillDownTracker } from './DrillDownTracker'
import { Drawer } from 'components/ui-kit/drawer/Drawer'
import { useManualClassificationVerification } from '../hooks/useManualClassificationVerification'
import { useAssetsTabFilterContext } from '../context/AssetsTabFilterContext'
import { useFetchDataTypes } from 'api/assets/queries/useFetchDataTypes'
import { useFetchDataSensitivities } from 'api/assets/queries/useFetchDataSensitivities'
import { fetchAssignedDataCategories } from 'api/dataSystemsCategories/fetchers/fetchAssignedDataCategories'
import { AssetsTableActionModal } from './AssetsTableActionModal'

export interface DataSystemsDataClassificationsRolloverProps {
  open?: boolean
}

export const DataSystemsDataCategoriesModal: React.FC<DataSystemsDataClassificationsRolloverProps> = ({ open }) => {
  const {
    appDescriptor,
    dataClassificationsRolloverProps,
    setdataClassificationsRolloverProps,
    allUserOptions,
    allTeamOptions,
    showAssetsTab,
  } = useDataSystemsContext()
  const {
    assetCategory,
    assetQueryPayload,
    setAssetCategory,
    drillDownLineage,
    setParentResourceID,
    paginationModel,
    setPaginationModel,
    assetsDataSystems,
    isLoadingAssetsDataSystems,
    snapshotCurrentQueryDetails,
    handleClearDrilldown,
    searchQuery,
    handleSearchInputChange,
    filterData,
    setFilterData,
    sections,
    setCounts,
    filterButtonText,
    resetFilters,
    traverseLineage,
    setShowEditModal,
    showEditModal,
  } = useDataCategoriesRolloverContext()

  const {
    dedupedDataSystemAssets,
    isLoadingInstalledDatasystemAssets,
    installedDatasystemPagination,
    refetchInstalledDatasystemAssets,
  } = useFilteredAndDedupedDataSystemAssets(assetQueryPayload, showAssetsTab)

  const { refetchInstalledDatasystemAssets: refetchAssetsTabTableData } = useAssetsTabFilterContext()

  const refetchOnEdit = useCallback(() => {
    refetchInstalledDatasystemAssets()
    refetchAssetsTabTableData()
  }, [refetchInstalledDatasystemAssets, refetchAssetsTabTableData])

  const { handleManualClassificationVerification } = useManualClassificationVerification(
    appDescriptor?.id!,
    refetchInstalledDatasystemAssets,
  )

  const [currentResourceLevel, setCurrentResourceLevel] = useState<string | undefined>(undefined)

  const megaFilterProps = {
    filterData,
    isLoadingAssetsDataSystems,
    setFilterData,
    sections,
    setCounts,
    filterButtonText,
    resetFilters,
    desyncfromUrlParams: true,
  }

  useEffect(() => {
    if (!currentResourceLevel && assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code) {
      setAssetCategory(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
      setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
    }
  }, [assetsDataSystems.dataSystems, currentResourceLevel, setAssetCategory])

  const [description, setDescription] = useState<string | undefined>(undefined)

  const { refetch: fetchDataTypes } = useFetchDataTypes({ enabled: false, params: { limit: 1000 } })
  const { refetch: fetchDataSensitivities } = useFetchDataSensitivities({ enabled: false, params: { limit: 1000 } })

  useEffect(() => {
    const queryForDescription = async () => {
      if (dataClassificationsRolloverProps?.dataClassification.type) {
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataSensitivities') {
          const dataSensitivities = await fetchDataSensitivities()
          setDescription(
            dataSensitivities?.data?.data?.dataSensitivities?.find(
              sensitivity => sensitivity.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.description || 'No description available',
          )
        }
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataTypes') {
          const dataTypes = await fetchDataTypes()
          setDescription(
            dataTypes?.data?.data?.dataTypes?.find(
              type => type.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.description || 'No description available',
          )
        }
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataCategories') {
          const dataCategories = await fetchAssignedDataCategories({
            installedDataSystemId: appDescriptor?.id,
          })
          setDescription(
            dataCategories?.data?.assignedCategories?.find(
              category => category.category?.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.category?.description || 'No description available',
          )
        }
      }
    }
    if (dataClassificationsRolloverProps?.dataClassification.type) {
      queryForDescription()
    }
  }, [
    appDescriptor?.id,
    dataClassificationsRolloverProps?.dataClassification.code,
    dataClassificationsRolloverProps?.dataClassification.type,
    fetchDataSensitivities,
    fetchDataTypes,
  ])

  const columns = dataSystemAssetsColumns({
    resourceType: getResourceType(currentResourceLevel),
    setAssetCategory,
    setCurrentResourceLevel,
    setParentResourceID,
    snapshotCurrentQueryDetails,
    handleManualClassificationVerification,
    setShowEditModal,
    allUserOptions,
    allTeamOptions,
  })

  const [tabValue, setTabValue] = useState(1)

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }, [])

  const modalProps = useMemo(() => {
    if (showEditModal) {
      return {
        ...showEditModal.modalProps,
        refetchInstalledDatasystemAssets: refetchOnEdit,
      }
    }
  }, [showEditModal, refetchOnEdit])

  const isLoading = isLoadingAssetsDataSystems || isLoadingInstalledDatasystemAssets

  const tableRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (
      dedupedDataSystemAssets &&
      dedupedDataSystemAssets.length < 10 &&
      installedDatasystemPagination?.totalCount &&
      installedDatasystemPagination?.totalCount > 10
    ) {
      if (tableRef && tableRef.current) tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [tableRef, columns, dedupedDataSystemAssets, installedDatasystemPagination?.totalCount])

  return (
    <Drawer
      open={open}
      onClose={() => {
        setdataClassificationsRolloverProps()
        handleClearDrilldown()
        setDescription(undefined)
        setCurrentResourceLevel(undefined)
      }}
      PaperProps={{
        sx: {
          height: '90%',
          width: '100%',
          maxWidth: '1300px',
          margin: 'auto',
          padding: '32px',
          borderRadius: '37px 37px 0px 0px',
          overflow: 'hidden',
        },
      }}
    >
      <Box height="100%" overflow="auto">
        <Box display="flex" flexDirection="column" gap={4} flexGrow={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <LabelChip
              isViewOnly
              PopperProps={{ disablePortal: true }}
              customTitleComponent={
                <Typography variant="h3">{dataClassificationsRolloverProps?.dataClassification.name!}</Typography>
              }
              variant={LabelChipVariant.category}
              color={LabelChipColor.confident}
              isDone={false}
            />
            <Typography variant="h3" color={theme.palette.Text.Secondary}>
              in
            </Typography>
            <Avatar
              isLogo
              src={appDescriptor?.dataSystem?.logoUrl}
              alt={appDescriptor?.dataSystem?.logoUrl}
              variant="rounded"
              size={AvatarSize['medium']}
              backgroundColor={theme.palette.tertiary.main}
            />
            <Typography variant="h3">{appDescriptor?.dataSystem?.name}</Typography>
          </Box>

          <Box width="100%">
            <TabGroup value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" showBottomDivider>
              <Tab label={<Typography variant="label">Overview</Typography>} size="small" />
              <Tab label={<Typography variant="label">Data Assets</Typography>} size="small" />
            </TabGroup>
          </Box>
        </Box>

        <Box paddingY={1}>
          <TabPanel value={tabValue} index={0} sx={{ paddingTop: 5, margin: 0 }}>
            <Box display="flex" flexDirection="column" gap={2} width="100%" paddingX={4}>
              <Typography variant="h3" color={theme.palette.Text.Secondary} fontSize={24}>
                About
              </Typography>
              <Typography variant="body" fontSize={20}>
                {description}
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
            sx={{ height: '100%', paddingTop: 2, margin: 0, overflow: 'auto', flexShrink: 1, paddingX: 0 }}
          >
            <Box width="100%">
              <Typography variant="body">{`Below are all the Data Assets within ${appDescriptor?.dataSystem?.name} that have ${dataClassificationsRolloverProps?.dataClassification.name} assigned.`}</Typography>
              {drillDownLineage.length > 0 && (
                <Box paddingTop={1}>
                  <AssetsTabFilters
                    searchQuery={searchQuery}
                    handleSearchInputChange={handleSearchInputChange}
                    megaFilterProps={megaFilterProps}
                  />
                </Box>
              )}
              <Box
                sx={{
                  backgroundColor:
                    drillDownLineage.length > 0 ? theme.palette.Black.o8 : theme.palette.Common.Container,
                  paddingTop: drillDownLineage.length > 0 ? 1 : 0,
                  paddingBottom: 2,
                  paddingX: drillDownLineage.length > 0 ? 2 : 0,
                  borderRadius: '16px',
                  marginTop: drillDownLineage.length > 0 ? 1 : 0,
                }}
              >
                {drillDownLineage.length > 0 ? (
                  <DrillDownTracker
                    drillDownLineage={drillDownLineage}
                    traverseLineage={traverseLineage}
                    handleClearDrilldown={handleClearDrilldown}
                    onClose={() => {
                      setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
                    }}
                  />
                ) : (
                  <Box display="flex" justifyContent="space-between" paddingY={1}>
                    <AssetsTabFilters
                      searchQuery={searchQuery}
                      handleSearchInputChange={handleSearchInputChange}
                      megaFilterProps={megaFilterProps}
                    />
                    {assetCategory && (
                      <Box>
                        <TabSegmentedGroup
                          value={currentResourceLevel}
                          exclusive
                          onChange={(e, nextVal) => {
                            setCurrentResourceLevel(nextVal)
                          }}
                        >
                          {assetsDataSystems.dataSystems?.[0]?.assetCategories?.map(category => {
                            return (
                              <TabSegmented
                                key={category.code}
                                value={category.code!}
                                size="small"
                                onClick={e => {
                                  setAssetCategory(category.code)
                                  setParentResourceID(undefined)
                                  setPaginationModel({
                                    page: 0,
                                    pageSize: 10,
                                  })
                                  handleSearchInputChange('')
                                }}
                              >
                                <Box display="flex" gap={0.5}>
                                  <Typography variant="label">{getResourceType(category.code)}</Typography>
                                  {/* We currently do not support count of specific classifications */}
                                  {/* <Typography variant="fadedLabel">{category.count}</Typography> */}
                                </Box>
                              </TabSegmented>
                            )
                          })}
                        </TabSegmentedGroup>
                      </Box>
                    )}
                  </Box>
                )}
                <DataSystemAssetsTable
                  columns={columns}
                  rows={dedupedDataSystemAssets as ListAssetItemDTO[]}
                  rowCount={installedDatasystemPagination?.totalCount}
                  isLoading={isLoading}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  elementRef={tableRef}
                  width="100%"
                />
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      {showEditModal && modalProps && (
        <AssetsTableActionModal modalType={showEditModal.editModalType} modalProps={modalProps} />
      )}
    </Drawer>
  )
}
