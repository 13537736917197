import { useStacks } from 'api/stacks/queries/useStacks'
import { PurposeStacksList } from './PurposeStacksList'

export const PurposeStacksListContainer: React.FC = () => {
  const { data: stacks, isLoading } = useStacks({
    params: {
      includeMetadata: true,
    },
  })

  return <PurposeStacksList stacks={stacks} isLoading={isLoading} />
}
