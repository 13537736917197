import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { RightsFilters, RightsSummary, RightsTrend } from './components'

export const RightsStatistics: React.FC = () => {
  const { language } = useParams<{ language: string }>()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(`${language}`)
    } else {
      i18n.changeLanguage('en')
    }
  }, [i18n, language])

  return (
    <Box sx={{ width: '1280px', margin: '0px auto' }}>
      <RightsFilters />
      <RightsSummary />
      <RightsTrend />
    </Box>
  )
}
