import { FormSectionSwitch } from '../FormSectionSwitch'
import Box from '@mui/material/Box'
import { SidebarFormGroup } from '../SidebarFormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material'
import { FormTextInput } from '../FormTextInput'
import { FormCheckbox } from '../FormCheckbox'
import { experienceUpsertFormKeys } from '../../../../utils/useExperienceValidationSchema'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { getIn, useFormikContext } from 'formik'
import { SwitchButtonDisplay } from '@ketch-sdk/ketch-types'
import { Button, Icon, theme } from '@ketch-com/deck'
import { SidebarFormRadioGroup } from '../SidebarFormRadioGroup'
import { SwitchButtonDisplayLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { RoutesManager } from 'utils/routing/routesManager'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { SyntheticEvent, useContext } from 'react'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getFormNameForLocale } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'
import { defaultExperienceV2DTO } from 'pages/consentAndRights/experiences-v2/upsert/utils/defaults'

export const ModalExperiencePurposesListForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const showSwitchButtonForm = getIn(values, experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsVisible)
  const showCustomSwitchButtonForm = !getIn(
    values,
    experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsUseDefaultText,
  )

  const handlePurposeStacksClick = () => {
    window.open(RoutesManager.deployment.purposeStacks.root.getURL(), '_blank')
  }

  const handleDefaultChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked && !isLanguagesLoading) {
      // Reset both the on and off text
      ;[
        experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOnText,
        experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOffText,
        experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsAlwaysOnText,
      ].forEach(englishFieldToReset => {
        // Reset to english default
        const defaultEnglishValue = getIn(defaultExperienceV2DTO, englishFieldToReset)
        setFieldValue(englishFieldToReset, defaultEnglishValue)

        // Reset to default for each locale
        languages.forEach(({ language: { code } }) => {
          const localeFieldToReset = getFormNameForLocale(englishFieldToReset, code)
          const defaultLocaleValue = getIn(defaultExperienceV2DTO, localeFieldToReset)
          setFieldValue(localeFieldToReset, defaultLocaleValue)
        })
      })
    }
  }

  return (
    <>
      {/* Legal bases */}
      <SidebarFormGroup sx={{ paddingTop: 0 }} hasBorderBottom>
        <Box>
          <FormControlLabel
            control={<FormCheckbox name={experienceUpsertFormKeys.modalPurposeListLegalBasisVisible} />}
            label={<Typography variant={'label'}>Show Legal Bases</Typography>}
          />
          <Typography component={'p'} variant={'body'} color={theme.palette.Text.Secondary}>
            The legal basis serves as your rationale for legally processing personal data. Choose whether you want this
            text label to be displayed beneath each Purpose.
          </Typography>
        </Box>
      </SidebarFormGroup>

      {/* Purpose stacks */}
      <SidebarFormGroup hasBorderBottom>
        <Box>
          <Typography component={'p'} variant={'body'} mb={1}>
            To group Purposes together, click on the button below to configure.
          </Typography>
          <Button
            onClick={handlePurposeStacksClick}
            color={'tertiary'}
            endIcon={<Icon name={'OShare'} iconColor={theme.palette.sphere.main} />}
          >
            Configure Purpose Stacks
          </Button>
        </Box>
      </SidebarFormGroup>

      {/* Switch button labels */}
      <SidebarFormGroup>
        <Box>
          <FormSectionSwitch
            name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsVisible}
            title={'Switch Button Labels'}
          />
          {showSwitchButtonForm && (
            <Box p={'16px 18px'}>
              <SidebarFormGroup hasBorderBottom sx={{ pt: 0, pb: 3 }}>
                <SidebarFormRadioGroup
                  name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsDisplay}
                  title={'Display'}
                  options={Object.values(SwitchButtonDisplay).map(display => ({
                    label: SwitchButtonDisplayLabels[display],
                    value: display,
                  }))}
                />
              </SidebarFormGroup>
              <SidebarFormGroup sx={{ pt: 3, pb: 4 }} hasBorderBottom={showCustomSwitchButtonForm && hasLanguages}>
                <Box>
                  <FormControlLabel
                    control={
                      <FormCheckbox
                        name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsUseDefaultText}
                        afterChange={handleDefaultChange}
                      />
                    }
                    label={<Typography variant={'label'}>Use Default Text</Typography>}
                  />
                  <Typography component={'p'} variant="body" color={theme.palette.Text.Secondary}>
                    The default text "Opted In" and "Opted Out" has translations for all available languages.
                  </Typography>
                </Box>
                {showCustomSwitchButtonForm && (
                  <>
                    <FormTextInput
                      label={'Switch Button ON Text'}
                      name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOnText}
                      fullWidth
                      charLimit={30}
                    />
                    <FormTextInput
                      label={'Switch Button OFF Text'}
                      name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOffText}
                      fullWidth
                      charLimit={30}
                    />
                    <FormTextInput
                      label={'Switch Button Always On Text'}
                      name={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsAlwaysOnText}
                      fullWidth
                      charLimit={30}
                      tooltip={
                        'This text will be displayed when the purpose legal basis affords the user no choice. Examples of such legal bases are Disclosure or Legitimate Interest - Non-Objectable.'
                      }
                    />
                  </>
                )}
              </SidebarFormGroup>
              {showCustomSwitchButtonForm && hasLanguages && (
                <SidebarFormTranslations
                  title={'Switch Button Text Translations'}
                  switchButtonOnName={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOnText}
                  switchButtonOffName={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsOffText}
                  switchButtonAlwaysOnName={experienceUpsertFormKeys.modalPurposeListSwitchButtonLabelsAlwaysOnText}
                  showAlwaysOnButton
                  variant={'switchButtons'}
                  charLimit={30}
                />
              )}
            </Box>
          )}
        </Box>
      </SidebarFormGroup>
    </>
  )
}
