import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import {
  DatabaseAssets,
  TableAssetsForiegn,
  TableAssetsPrimary,
  ColumnAssetsForiegn,
  ColumnAssetsPrimary,
} from 'pages/assetManager/relationshipMapping/components/CreatedRelationships/components/CreateRelationshipMappingModal/components/CreateRelationshipForm/components'
import { CreateRelationdhipFormData } from 'pages/assetManager/relationshipMapping/interfaces'
import { ReactComponent as RelationshipIcon } from 'assets/icons/pointer_right.svg'
import { theme } from '@ketch-com/deck'

export const CreateRelationshipForm: React.FC = () => {
  const { values } = useFormikContext<CreateRelationdhipFormData>()

  return (
    <Box>
      <Box my={3}>
        <Typography variant="label">Database</Typography>
        <DatabaseAssets />
      </Box>

      {values.databaseResourceId ? (
        <Box display="flex" justifyContent="space-between" alignItems="stretch" height={314}>
          <Box
            flex={4}
            border={`1px solid ${theme.palette.iron.main}`}
            borderRadius={1.5}
            padding={3}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Box display="flex" flexDirection="column" gap={3}>
              <Box>
                <Typography variant="label">Table</Typography>
                <TableAssetsForiegn />
              </Box>
              {values.foriegnTableResourceId ? (
                <Box>
                  <Typography variant="label">Foreign Key</Typography>
                  <ColumnAssetsForiegn />
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box flex={1} display="flex" justifyContent="center" paddingTop={8}>
            <RelationshipIcon />
          </Box>
          <Box
            flex={4}
            border={`1px solid ${theme.palette.iron.main}`}
            borderRadius={1.5}
            padding={3}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Box display="flex" flexDirection="column" gap={3}>
              <Box>
                <Typography variant="label" color="heliotrope.main">
                  Referenced Table
                </Typography>
                <TableAssetsPrimary />
              </Box>
              {values.primaryTableResourceId ? (
                <Box>
                  <Typography variant="label" color="heliotrope.main">
                    Primary Key
                  </Typography>
                  <ColumnAssetsPrimary />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
