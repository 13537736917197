import React, { useState } from 'react'
import { Box, Chip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { makeStyles, createStyles } from '@mui/styles'

import { Table } from 'components/ui-layouts/table/Table'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { Text } from 'components/ui-kit/typography/Text'
import { useLabelsV2OrV3BasedOnFeatureFlag } from 'api/labels/queries/useLabelsV2OrV3BasedOnFeatureFlag'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { ReviewIssuesModalActionsCellRenderer } from '.'
import { SuggestedLabelsCellRenderer } from './SuggestedLabelsCellRenderer'
import { useAssetsPaginatedV2 } from 'api/assets/queries/useAssetsPaginatedV2'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      tableRowSelected: {
        border: `1px solid ${palette.sphere.main}`,
      },
      assetsTable: {
        backgroundColor: palette.white.main,
        borderRadius: spacing(1),
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      labelsCountPill: {
        backgroundColor: palette.marine.main,
        borderRadius: '50%',
        padding: spacing(0.75, 0.75),
      },
      actionsTableCell: {
        justifyContent: 'space-between',
      },
      suggestedLabelsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      editIcon: {
        marginLeft: spacing(0.75),
        color: palette.lightGrey.main,
        cursor: 'pointer',
        borderRadius: spacing(1),
        padding: spacing(1, 1),
        '&:hover': {
          color: palette.fadedDarkGrey.main,
          backgroundColor: palette.fadedGrey.main,
        },
      },
    }),
  { name: 'ReviewLabelsChip' },
)

export const ReviewLabelsChip: React.FC = () => {
  const classes = useStyles()
  const [isReviewLabelsModalOpen, setIsReviewLabelsModalOpen] = useState<boolean>(false)
  const hasLabelsToReview = true

  const { data: labels, isLoading: isLoadingLabels } = useLabelsV2OrV3BasedOnFeatureFlag()
  const { data: assets, isLoading: isLoadingAssets } = useAssetsPaginatedV2()

  return (
    <>
      <Chip
        clickable={false}
        onClick={() => {
          if (hasLabelsToReview) {
            setIsReviewLabelsModalOpen(true)
          }
        }}
        label={
          <Box display="flex" alignItems="center">
            <Box mr={0.5} display="flex">
              <Text size={12} weight={600} color="muir" whiteSpace="nowrap">
                Review Classifications
              </Text>
            </Box>

            <Text size={12} color="muir">
              18
            </Text>
          </Box>
        }
        sx={{
          mr: 3,
          cursor: 'pointer',
          backgroundColor: `rgba(255, 186, 121, 0.45)`,
          '&:hover': {
            color: 'red',
            backgroundColor: `rgba(255, 186, 121, 0.8)`,
          },
        }}
        icon={
          <ErrorIcon
            sx={[
              {
                fill: ({ palette }) => palette.chileanFire.main,
              },
              ({ palette }) => ({
                '&:hover': {
                  color: palette.sphere.main,
                },
              }),
            ]}
          />
        }
      />

      {isReviewLabelsModalOpen && (
        <Dialog
          isLoading={isLoadingLabels || isLoadingAssets}
          isSaving={false}
          withTitleBorderBottom={true}
          title="Delete Account"
          subtitle="My Subtitle"
          contentWidth={940}
          submitBtnLabel="Apply"
          cancelBtnLabel="Cancel"
          declineAllBtnLabel="Decline All"
          onSubmitBtnClick={() => {}}
          onCancelBtnClick={() => setIsReviewLabelsModalOpen(false)}
          onDeclineAllBtnClick={() => {}}
        >
          <Table
            isSmallHeaderText
            items={assets}
            rowIdKey={rowData => {
              return rowData?.asset?.resource?.id || ''
            }}
            cellSettings={{
              type: {
                width: 20,
                cellRenderer: assetSummary => {
                  return (
                    <Box display="flex" alignItems="center">
                      <AssetTypeIcon
                        assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                        className={classes.dataSetIcon}
                      />
                    </Box>
                  )
                },
              },
              name: {
                width: 330,
                label: 'Name',
                cellRenderer: ({ asset }) => (
                  <NameAndCodeCellRenderer name={asset?.resource?.name} code={asset?.resource?.id || ''} />
                ),
              },

              suggestedLabels: {
                width: 400,
                label: 'Suggested Classifications',
                cellRenderer: (asset, index) => (
                  <SuggestedLabelsCellRenderer asset={asset} index={index} labels={labels} values={[]} />
                ),
              },

              actions: {
                cellRenderer: asset => <ReviewIssuesModalActionsCellRenderer asset={asset} />,
              },
            }}
          />
        </Dialog>
      )}
    </>
  )
}
