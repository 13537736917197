import { useDeleteTheme } from 'api/themes-v3/mutations/useDeleteTheme'
import { useTheme } from 'api/themes-v3/queries/useTheme'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { IDParams } from 'utils/routing/routes/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { ThemeType } from '../../upsert/utils/enums'
import { PreviewDisplayMode } from 'pages/consentAndRights/experiences-v2/view/utils/enums'
import { extractUniqueColors } from '../../upsert/utils/utils'

export const useThemeViewUtils = () => {
  // Utils
  const { id } = useParams<IDParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const handleRedirectToThemesList = () => navigate(RoutesManager.deployment.themesV3.root.getURL())

  const { data: theme, isLoading: isThemeLoading } = useTheme({
    params: {
      id: id || '',
      includeMetadata: true,
    },
    onError: handleRedirectToThemesList,
  })

  const { mutateAsync: handleDeleteTheme, isLoading: isThemeDeleting } = useDeleteTheme()

  // Preview state
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.Banner)
  const [displayMode, setDisplayMode] = useState<PreviewDisplayMode>(PreviewDisplayMode.Desktop)
  const [uniqueColors, setUniqueColors] = useState(extractUniqueColors(theme))
  const [isPreviewReady, setIsPreviewReady] = useState(false)

  return {
    location,
    navigate,
    isPermitted,
    theme,
    isThemeLoading,
    handleDeleteTheme,
    isThemeDeleting,
    themeType,
    setThemeType,
    displayMode,
    setDisplayMode,
    uniqueColors,
    setUniqueColors,
    isPreviewReady,
    setIsPreviewReady,
  }
}

export type ThemeViewUtils = ReturnType<typeof useThemeViewUtils>
