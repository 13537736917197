import { useState } from 'react'
import { DatabaseScenario } from 'pages/assetManager/scenario/list/components'
import { useDsrConfigCount } from 'api/assets/queries/useDsrConfigCount'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { UrlParams } from 'pages/assetManager/dsrConfig/interfaces'
import { useParams } from 'react-router-dom'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { RightType } from 'interfaces/events'

/**
 * @deprecated
 * @constructor
 */
export const DatabaseScenarioContainer: React.FC = () => {
  const [rightType, setRightType] = useState(RightType.RIGHT_TYPE_UNSPECIFIED)
  const { code, resourceTypeCode = '' } = useParams<UrlParams>()
  /*
    No pagination. Max limit is 100 scenarios for now.
    https://ketch-com.atlassian.net/browse/KD-5597
  */
  const { data: dsrConfigCount, isLoading: isDsrConfigCountLoading } = useDsrConfigCount({
    params: {
      assetId: code,
      start: 0,
      limit: 100,
      rightType,
      canonicalRightCode: 'get,delete',
    },
  })
  const { data: assetSummary, isLoading: isAssetSummaryLoading } = useAssetV2({
    params: {
      assetCode: code!,
      resourceTypeCode,
    },
  })

  const isReady = !isDsrConfigCountLoading && !isAssetSummaryLoading
  if (isReady)
    return (
      <DatabaseScenario
        dsrConfigCount={dsrConfigCount}
        rightType={rightType}
        setRightType={setRightType}
        assetSummary={assetSummary}
      />
    )
  return <Spinner />
}
