import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { DeleteDataSubjectTypeModal } from 'components/modals/dataSubjectTypes/DeleteDataSubjectTypeModal'
import { CannotDeleteLastDataSubjectTypeWarningModal } from 'components/modals/dataSubjectTypes/CannotDeleteLastDataSubjectTypeWarningModal'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

// utils
import { ApiQueryKeys } from 'api/common/queryKeys'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

// types
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
type Props = {
  dataSubjectType: DataSubjectTypeDTO
  dataSubjectTypes: DataSubjectTypeDTO[]
}

export const DataSubjectTypesListDropdown: React.FC<Props> = ({ dataSubjectType, dataSubjectTypes }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()
  const isSubjectTypeWriter = isPermitted(PERMISSIONS.SUBJECTTYPE_WRITE)
  const [isDeleteDataSubjectTypeModalOpen, setIsDeleteDataSubjectTypeModalOpen] = useState(false)
  const [isCannotDeleteLastSubjectTypeWarningModalOpen, setIsCannotDeleteLastSubjectTypeWarningModalOpen] =
    useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isSubjectTypeWriter) return null

  return (
    <Fragment>
      <Button size="medium" variant="iconSubtle" onClick={handleClickListItem}>
        <Icon name="OMore" />
      </Button>
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        onClick={e => e.stopPropagation()}
      >
        {isSubjectTypeWriter && (
          <ActionSheetItem
            onClick={e => {
              handleClose()
              navigate(RoutesManager.settings.dataSubjectTypes.upsert.root.getURL({ id: dataSubjectType.code }))
            }}
          >
            Edit
          </ActionSheetItem>
        )}
        {isSubjectTypeWriter && <ActionSheetItem divider />}
        {isSubjectTypeWriter && (
          <ActionSheetItem
            onClick={_ => {
              handleClose()
              if (dataSubjectTypes.length === 1) {
                setIsCannotDeleteLastSubjectTypeWarningModalOpen(true)
              } else {
                setIsDeleteDataSubjectTypeModalOpen(true)
              }
            }}
          >
            Delete
          </ActionSheetItem>
        )}
      </ActionSheetWrapper>

      {isDeleteDataSubjectTypeModalOpen && (
        <DeleteDataSubjectTypeModal
          dataSubjectType={dataSubjectType}
          onCancel={() => setIsDeleteDataSubjectTypeModalOpen(false)}
          onSubmit={async () => {
            await queryClient.refetchQueries(ApiQueryKeys.dataSubjectTypesPaginated)

            setIsDeleteDataSubjectTypeModalOpen(false)
          }}
        />
      )}

      {isCannotDeleteLastSubjectTypeWarningModalOpen && (
        <CannotDeleteLastDataSubjectTypeWarningModal
          dataSubjectType={dataSubjectType}
          onCancel={() => setIsCannotDeleteLastSubjectTypeWarningModalOpen(false)}
        />
      )}
    </Fragment>
  )
}
