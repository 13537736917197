export function formatTo12HourTime(timestamp: string): string {
  const date = new Date(timestamp)

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'

  // Convert to 12-hour format
  hours = hours % 12 || 12 // 0 => 12
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${hours}:${formattedMinutes} ${ampm}`
}
