import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Autocomplete, Box, InputAdornment, Typography } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import {
  ProcessingActivityAssetDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityDTO,
  ProcessingActivityTypeDTO,
} from '@ketch-com/figurehead'
import { useProcessingActivitiesUpsertSearch } from 'pages/policyCenter/processingActivities/hooks'
import { useInstalledDataSystems } from 'api/dataSystems/queries/useInstalledDataSystems'
import { PersonalDataTypesModal } from 'pages/policyCenter/processingActivities/upsert/components'
import { MaybeNull } from 'interfaces'
import { ActionSheetItem, Avatar, AvatarSize, FormRow, Icon, ListItemText, TextInput, theme } from '@ketch-com/deck'
import { StyledOrchestrationAutocompletePaper } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/components/Orchestration/utils'
import {
  GridRowId,
  GridRowClassNameParams,
  useGridApiRef,
  GridEventListener,
  GridGroupNode,
} from '@mui/x-data-grid-premium'

import { DataGrid } from '@ketch-com/deck'

import {
  groupingColDef,
  DataGridAssetObjectType,
  DataGridObjectType,
  dataSourcesListColumns,
  DataGridAutocompleteObjType,
  isDataGridAssetObjectType,
} from './dataSystemsUtils'

export const DataSystems: React.FC = () => {
  const expansionLookup = React.useRef<Record<GridRowId, boolean>>({})

  const [processingActivityDataSystem, setProcessingActivityDataSystem] =
    useState<MaybeNull<ProcessingActivityDataSystemDTO>>(null)
  const { isSubmitting, values, setFieldValue } = useFormikContext<ProcessingActivityDTO>()
  const isModelType = values.type === ProcessingActivityTypeDTO.ModelProcessingActivityType
  const dataSystemsSearchState = useProcessingActivitiesUpsertSearch<ProcessingActivityDataSystemDTO>()
  const { isLoading: isLoadingDataSystems } = useInstalledDataSystems({
    itemsPerPage: 300,
    params: {
      q: dataSystemsSearchState?.searchQueryValue,
    },
    onSuccess: ({ data = {} }) => {
      const { installedDataSystems = [] } = data
      const processingActivityData = installedDataSystems?.map(system => {
        return {
          dataSystemId: system?.id,
          dataSystemCode: system?.dataSystemCode,
          personalDataType: system?.personalDataType,
          dataCategories: system?.dataCategories,
          processingStages: [],
          dataSystem: system?.dataSystem,
        } as ProcessingActivityDataSystemDTO
      })

      dataSystemsSearchState.setOptions(processingActivityData)
    },
  })

  const apiRef = useGridApiRef()

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    params => {
      const rowNode = apiRef.current.getRowNode(params.id)
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded)
      }
    },
    [apiRef],
  )

  const getRowClassName = (params: GridRowClassNameParams) => {
    //add class name to row with Header. Row with header has only id and path
    const rowHeaderClass = Object.keys(params.row).length === 2 ? 'DeckRowHeader' : ''
    return `DeckRowSpan-${params.row.path.length} ${rowHeaderClass}`
  }

  const handleDataCategoriesClick = useCallback(
    (object: ProcessingActivityDataSystemDTO | ProcessingActivityAssetDTO) => {
      setProcessingActivityDataSystem(object)
    },
    [setProcessingActivityDataSystem],
  )

  const rows = useMemo(() => {
    const result: (DataGridObjectType | DataGridAutocompleteObjType | DataGridAssetObjectType)[] = []

    if (values?.dataSystems) {
      values?.dataSystems.forEach(elem => {
        if (elem?.dataSystem?.systemType !== 'Internal') {
          result.push({
            ...elem,
            path: [elem.dataSystemId || ''],
            handleDataCategoriesClick,
            rowId: elem.dataSystemId || '',
          })
        } else {
          // parent row
          result.push({
            ...elem,
            path: [elem.dataSystemId || ''],
            handleDataCategoriesClick,
            rowId: elem.dataSystemId || '',
          })
          // autocomplete row
          result.push({
            path: [elem.dataSystemId || '', `${elem.dataSystemId}-autocomplete`],
            rowId: `${elem.dataSystemId}-autocomplete`,
            isAutocomplete: true,
            parentElem: elem,
          } as DataGridAutocompleteObjType)
          // asset row
          elem.assets &&
            elem.assets.forEach((asset, index) => {
              result.push({
                ...asset,
                parentElem: elem,
                logoUrl: elem.dataSystem?.logoUrl,
                path: [elem.dataSystemId || '', `${elem.dataSystemId}-${index}`],
                handleDataCategoriesClick,
                rowId: `${elem.dataSystemId}-${index}`,
              })
            })
        }
      })
    }
    return result
  }, [handleDataCategoriesClick, values?.dataSystems])

  const initialData = useRef(rows)
  const getRowIdMemo = useRef((row: any) => row.rowId)

  useEffect(() => {
    // Get the IDs of all current data and identify which are excluded from the new data
    if (apiRef.current && apiRef.current.getRowModels) {
      const curRows = apiRef.current.getRowModels()
      const curIds = curRows.keys()

      const newIds = new Set(rows.map(getRowIdMemo.current))

      const removeIds = [...curIds].filter(id => !newIds.has(id))

      // Create delete actions for the removed IDs and merge them with the new data
      const deleteActions = removeIds.map(id => ({ ...curRows.get(id), _action: 'delete' as const }))
      apiRef.current.updateRows([...rows, ...deleteActions])
    }
  }, [apiRef, rows])

  const handleRemove = useCallback(
    (object: DataGridObjectType | DataGridAssetObjectType) => {
      if (!isDataGridAssetObjectType(object)) {
        // map to create a new array, filter does a shallow copy
        const updatedValues = values.dataSystems
          ? // we filter the data systems by dataSystemId
            [...values.dataSystems].filter(paDTO => paDTO.dataSystemId !== object.dataSystemId)
          : []

        setFieldValue('dataSystems', [...updatedValues])
      } else {
        const { parentElem } = object
        const assets = parentElem ? [...(parentElem?.assets || [])] : []
        // we filter out the asset we want to delete

        const filteredAssets = assets?.filter(
          elem => elem.asset?.asset?.resource?.id !== object.asset?.asset?.resource?.id,
        )

        const updatedValues = values.dataSystems ? [...values.dataSystems] : []
        // we update the correct dataSystem's assets

        updatedValues.forEach(elem => {
          if (elem.dataSystemId === parentElem?.dataSystemId) {
            elem.assets = filteredAssets
          }
        })

        // we update the dataSystems with the filtered out asset of the specific data system
        setFieldValue('dataSystems', [...updatedValues])
      }
    },
    [setFieldValue, values.dataSystems],
  )

  const columns = useMemo(
    () => dataSourcesListColumns({ isModelType, isSubmitting, remove: handleRemove }),
    [handleRemove, isModelType, isSubmitting],
  )

  React.useEffect(() => {
    if (apiRef.current && apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent('rowExpansionChange', node => {
        return (expansionLookup.current[node.id] = node.childrenExpanded || false)
      })
    }
  }, [apiRef])

  const isGroupExpandedByDefault = React.useCallback(
    (node: GridGroupNode) => {
      return !!expansionLookup.current[node.id]
    },
    [expansionLookup],
  )

  const getTreeDataPath = useCallback((row: any) => row.path, [])

  return (
    <FormRow
      title="Data Systems"
      subTitle="Select systems that will be used in the processing systems you select here will populate additional information like security measures, residency, data categories and privacy contacts. "
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          <FieldArray name="dataSystems">
            {({ push, remove }) => {
              return (
                <Box display="flex" flexDirection="column" gap={3}>
                  <Autocomplete
                    key={values?.dataSystems?.length}
                    loading={isLoadingDataSystems}
                    disabled={isSubmitting}
                    sx={{ width: 420 }}
                    filterOptions={x => x}
                    clearOnBlur
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="No Data Systems"
                    getOptionDisabled={option =>
                      Boolean(
                        values?.dataSystems?.some(dataSystem => dataSystem?.dataSystemId === option?.dataSystemId),
                      )
                    }
                    onChange={(e, dataSystem) => {
                      push(dataSystem)
                    }}
                    onInputChange={(e, inputVal) => dataSystemsSearchState.setInputValue(inputVal)}
                    onClose={() => {
                      dataSystemsSearchState.setInputValue('')
                      dataSystemsSearchState.setSearchQueryValue('')
                    }}
                    options={dataSystemsSearchState?.options || []}
                    renderInput={params => (
                      <TextInput
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon name="OMag" />
                            </InputAdornment>
                          ),
                          endAdornment: <></>,
                        }}
                        placeholder="Start typing a data system name"
                        value={dataSystemsSearchState.inputValue}
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, system, { selected }) => (
                      <ActionSheetItem {...props} key={system?.dataSystem?.code} selected={selected}>
                        <ListItemText selected={selected}>
                          <Box display="flex" alignItems="center" gap={1.5}>
                            <Avatar
                              isLogo
                              src={system?.dataSystem?.logoUrl}
                              alt={system?.dataSystem?.name}
                              variant="rounded"
                              size={AvatarSize.large}
                              backgroundColor={theme.palette.tertiary.main}
                            />
                            <Typography variant="label">{system?.dataSystem?.name}</Typography>
                          </Box>
                        </ListItemText>
                      </ActionSheetItem>
                    )}
                    PaperComponent={StyledOrchestrationAutocompletePaper}
                    getOptionLabel={system => system?.dataSystem?.code || ''}
                  />
                  {rows.length ? (
                    <DataGrid
                      sx={{
                        // we update the padding added by DataGrid for "groupingColDef" passed as prop below
                        '&.DeckExpandable:not(.DeckWithCheckbox)': {
                          '& .MuiDataGrid-columnHeader:first-of-type': {
                            '& .MuiDataGrid-columnHeaderDraggableContainer': {
                              paddingLeft: '18px',
                            },
                          },
                        },
                      }}
                      isGroupExpandedByDefault={isGroupExpandedByDefault}
                      apiRef={apiRef}
                      columns={columns}
                      disableColumnMenu
                      disableRowHoverStates
                      disableRowSelectionOnClick
                      disableColumnPinning
                      disableColumnReorder
                      disableColumnResize
                      getRowClassName={getRowClassName}
                      getRowHeight={() => 'auto'}
                      getTreeDataPath={getTreeDataPath}
                      groupingColDef={groupingColDef}
                      getRowId={getRowIdMemo.current}
                      hideFooter
                      onRowClick={onRowClick}
                      rows={initialData.current}
                      treeData
                      slotProps={{
                        baseIconButton: {
                          onClick: () => {
                            return false // let the rowClick handler deal with the click
                          },
                        },
                      }}
                    />
                  ) : null}
                </Box>
              )
            }}
          </FieldArray>
        </Box>
      </Box>
      {processingActivityDataSystem ? (
        <PersonalDataTypesModal
          dataCategoriesObject={processingActivityDataSystem}
          onCancel={() => setProcessingActivityDataSystem(null)}
        />
      ) : null}
    </FormRow>
  )
}
