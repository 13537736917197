import { Box } from '@mui/material'
import { DropdownV2 } from 'components/ui-kit/dropdown/DropdownV2'
import { useNavigate, useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ReactComponent as UnutilizedDataIcon } from 'assets/icons/unutilizedData.svg'
import { ReactComponent as ThirdPartyRiskIcon } from 'assets/icons/thirdPartyRisk.svg'
import { ReactComponent as PoliciesControlsIcon } from 'assets/icons/policiesAndControls.svg'
import { ReactComponent as OverAccessIcon } from 'assets/icons/overAccess.svg'
import { Text } from 'components/ui-kit/typography/Text'
import { useStyles } from './styles'

enum NavItemIds {
  UNUTILIZED_DATA = 'unutilized-data',
  OVER_ACCESS = 'over-access',
  THIRD_PARTY_RISK = 'third-party-risk',
  POLICIES_CONTROLS = 'policies-controls',
}

const NavItem: React.FC<{ title: string; icon: JSX.Element }> = ({ title, icon }) => (
  <Box display="flex" alignItems="center" mr={1}>
    {icon}
    <Text>{title}</Text>
  </Box>
)

export const DataRiskHeaderNav: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()

  const items = [
    {
      id: NavItemIds.UNUTILIZED_DATA,
      title: 'Unutilized Data',
      active: location?.pathname?.includes(NavItemIds.UNUTILIZED_DATA),
      content: <NavItem icon={<UnutilizedDataIcon className={classes.icon} />} title="Unutilized Data" />,
    },
    {
      id: NavItemIds.OVER_ACCESS,
      title: 'Over-Access',
      active: location?.pathname?.includes(NavItemIds.OVER_ACCESS),
      content: <NavItem icon={<OverAccessIcon className={classes.icon} />} title="Over-Access" />,
    },
    {
      id: NavItemIds.THIRD_PARTY_RISK,
      title: '3rd Party Risk',
      active: location?.pathname?.includes(NavItemIds.THIRD_PARTY_RISK),
      content: <NavItem icon={<ThirdPartyRiskIcon className={classes.icon} />} title="3rd Party Risk" />,
    },
    {
      id: NavItemIds.POLICIES_CONTROLS,
      title: 'Policies & Controls',
      active: location?.pathname?.includes(NavItemIds.POLICIES_CONTROLS),
      content: <NavItem icon={<PoliciesControlsIcon className={classes.icon} />} title="Policies & Controls" />,
    },
  ]

  return (
    <DropdownV2
      items={items}
      value={items.find(item => location?.pathname?.includes(item.id))?.id}
      onChange={(id: string) => {
        if (id === NavItemIds.UNUTILIZED_DATA) {
          navigate(RoutesManager.insights.dataRisks.unutilizedData.root.getURL())
        }
        if (id === NavItemIds.OVER_ACCESS) {
          navigate(RoutesManager.insights.dataRisks.overAccess.root.getURL())
        }
        if (id === NavItemIds.THIRD_PARTY_RISK) {
          navigate(RoutesManager.insights.dataRisks.thirdPartyRisk.root.getURL())
        }
        if (id === NavItemIds.POLICIES_CONTROLS) {
          navigate(RoutesManager.insights.dataRisks.policiesControls.root.getURL())
        }
      }}
      className={classes.dropdown}
    />
  )
}
