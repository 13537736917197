import { ConfigurationV2 } from '@ketch-sdk/ketch-types'
import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  experienceID?: string
  themeID?: string
  languageCode?: string
  jurisdictionCode?: string
  deploymentID?: string
}>

export const fetchPreviewConfig = ({ experienceID, themeID, languageCode, jurisdictionCode, deploymentID }: Params) =>
  API.get<ConfigurationV2>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/preview-config.json`,
      params: {
        filters: {
          // Params that will not be passed if undefined
          ...(experienceID && { experienceID }),
          ...(themeID && { themeID }),
          // ...(languageCode && { languageCode }),
          // ...(jurisdictionCode && { jurisdictionCode }),
          ...(deploymentID && { deploymentID }),

          // Params that will have some default passed if undefined
          languageCode: languageCode ?? 'en',
          jurisdictionCode: jurisdictionCode ?? 'default',
        },
      },
    }),
  )
